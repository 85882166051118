import {
  useMutation, UseMutationOptions, UseMutationResult,
} from 'react-query';
import { useTranslation } from 'react-i18next';
import { Dayjs } from 'dayjs';
import {
  appendQueryParamIfNotEmpty, downloadDiggerFile, downloadFile, formatDate, generateQueryKey,
} from '../../../constants/hookConstants';
import closableEnqueueSnackbar from '../../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';
import { TypeOfMutationFunction } from '../../../interfaces/query/queryHook.type';

interface GetExportAdsParamsInterface{
    name: string,
    campaignId: string,
    sortBy: string,
    orderBy: string,
    from: Dayjs | null,
    to: Dayjs | null,
    rows: number,
    page: number,
    campaignCode: number | string,
}

export const useGetExportAdGroupTable = (
  params : GetExportAdsParamsInterface,
) => {
  const { t } = useTranslation();

  const queryParams = new URLSearchParams();
  appendQueryParamIfNotEmpty(queryParams, 'name', params.name.trim());
  appendQueryParamIfNotEmpty(queryParams, 'campaignId', params.campaignId.trim());
  appendQueryParamIfNotEmpty(queryParams, 'sortBy', params.sortBy.trim());
  appendQueryParamIfNotEmpty(queryParams, 'orderBy', params.orderBy.trim());
  appendQueryParamIfNotEmpty(queryParams, 'from', formatDate(params.from));
  appendQueryParamIfNotEmpty(queryParams, 'to', formatDate(params.to));
  appendQueryParamIfNotEmpty(queryParams, 'rows', params.rows.toString());
  appendQueryParamIfNotEmpty(queryParams, 'page', params.page.toString());
  appendQueryParamIfNotEmpty(queryParams, 'campaignCode', params.campaignCode.toString());

  const mutationFunction: TypeOfMutationFunction = async (acceptHeader: string) => {
    const url = `ad-groups?${queryParams}`;
    await downloadDiggerFile(url, acceptHeader, t);
  };

  const options: UseMutationOptions<void, unknown, string> = {
    onError: () => {
      closableEnqueueSnackbar(t('somethingWentWrong'), 'error');
    },
  };

  const mutation: UseMutationResult<void, unknown, string> = useMutation(
    mutationFunction,
    options,
  );

  return mutation;
};
