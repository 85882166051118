import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import { appendQueryParamIfNotEmpty, formatDate, generateQueryKey } from '../../../constants/hookConstants';

const useGetCampaigns = (
  identifier: string,
  name: string,
  trackingSourceId: string,
  from: Dayjs | null,
  to: Dayjs | null,
  sortBy: string,
  orderBy: string,
  rows: number,
  page: number,
) => {
  const navigate = useNavigate();

  const queryParams = new URLSearchParams();
  appendQueryParamIfNotEmpty(queryParams, 'name', name.trim());
  appendQueryParamIfNotEmpty(queryParams, 'trackingSourceId', trackingSourceId.trim());
  appendQueryParamIfNotEmpty(queryParams, 'from', formatDate(from));
  appendQueryParamIfNotEmpty(queryParams, 'to', formatDate(to));
  appendQueryParamIfNotEmpty(queryParams, 'sortBy', sortBy.trim());
  appendQueryParamIfNotEmpty(queryParams, 'orderBy', orderBy.trim());
  appendQueryParamIfNotEmpty(queryParams, 'rows', rows.toString());
  appendQueryParamIfNotEmpty(queryParams, 'page', page.toString());

  const queryKey = generateQueryKey(identifier, queryParams);

  return useQuery(
    [`ads_management_${queryKey}`],
    () => dataFetch.get(`${DIGGER_API_SUFFIX}campaigns?${queryParams}`).then((res: any) => res.data),
    {
      onSuccess: () => {
        navigate(`${window.location.pathname}?${queryParams.toString()}`, { replace: true });
      },
    },
  );
};

export default useGetCampaigns;

export const useGetCampaignOptions = (identifier: string, name: string) => useQuery(
  [`ads_management_${identifier}`],
  () => dataFetch.get(`${DIGGER_API_SUFFIX}campaigns/options?name=${name}`).then((res: any) => res.data),
);

export const useGetCampaignById = (identifier: string, campaignId: string | null | undefined) => useQuery(
  [`ads_management_${identifier}`],
  () => campaignId && dataFetch.get(`${DIGGER_API_SUFFIX}campaigns/${campaignId}`).then((res: any) => res.data),
);
