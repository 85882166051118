import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import closableEnqueueSnackbar from '../../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';

interface UpdateNameInterface {
    firstName: string,
    lastName: string
}

export const useUpdateProfileName = () => {
  const { t } = useTranslation();

  return useMutation<ApiResponse<UpdateNameInterface>, ApiError<null>, UpdateNameInterface>(
    (nameInfo) => dataFetch.put(
      `${DIGGER_API_SUFFIX}users/profile/`,
      {
        firstName: nameInfo.firstName,
        lastName: nameInfo.lastName,
      },
    ),
    {
      onSuccess: () => {
        closableEnqueueSnackbar(t('nameSuccessFullyChanged'), 'success');
      },
      onError: () => {
        closableEnqueueSnackbar(t('somethingWentWrong'), 'error');
      },
    },
  );
};
