import React from 'react';
import EmptyData from '../../../assets/images/empty/no-data-small-image.svg';

const EmptyBigCard = () => (
  <div className="flex h-full w-full flex-col items-center justify-center text-center">
    <p className="font-[500] text-primary"> No data here! </p>
    <div className="mt-[20px] max-h-[170px] max-w-[214px]">
      <img src={EmptyData} alt="" className="h-full w-full object-contain" />
    </div>
  </div>
);

export default EmptyBigCard;
