import { useQuery, UseQueryResult } from 'react-query';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';

type ManagedOrganizationsNameInterface = {
    orgName: string,
    orgId: string,
    managerOrgName: string,
    managerOrgId: string,
}[]

const useGetManagedOrganizationsNames = (
  identifier: string,
): UseQueryResult<ApiResponse<ManagedOrganizationsNameInterface>, ApiError<ManagedOrganizationsNameInterface>> => useQuery<ApiResponse<ManagedOrganizationsNameInterface>, ApiError<ManagedOrganizationsNameInterface>>(
  [`organizations_name_${identifier}`],
  () => dataFetch.get(`${DIGGER_API_SUFFIX}managerial-accounts/organizations`),
);

export default useGetManagedOrganizationsNames;
