import React from 'react';
import { useLocation } from 'react-router-dom';
import SimpleTabs from '../../../../components/tabs/simpleTabs';
import HubspotTabIcon from '../../../../assets/icons/tab/hubspot-tab-icon.svg';
import { CRMTabLinks } from '../../../../components/enums/tabEnums';
import { useOrganizationSettingsStore } from '../../../../store/organizationSettingsStore';
import DisconnectHubspot from '../../../../components/disconnectHubspot/disconnectHubspot';

const Crm = () => {
  const location = useLocation();
  const activePanel = CRMTabLinks[location.pathname.split('/').pop() as keyof typeof CRMTabLinks] || 0;
  const organizationSettings = useOrganizationSettingsStore((state) => state.organizationSettings);

  return (
    <div className="h-full">
      <div className={`${organizationSettings.isConnectedToHubspot ? 'h-[75%]' : 'h-full'} rounded-lg border border-dark bg-white px-6 py-4`}>
        <SimpleTabs
          currentTab={activePanel}
          tabs={[{
            tabLabel: 'Hubspot',
            href: 'hubspot',
            identifierName: 'hubspot-panel',
            icon: HubspotTabIcon,
          },
          ]}
        />
      </div>
      {organizationSettings.isConnectedToHubspot && <DisconnectHubspot />}
    </div>
  );
};

export default Crm;
