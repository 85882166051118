import { useQuery, UseQueryResult } from 'react-query';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import { generateQueryKey } from '../../../constants/hookConstants';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';

export interface ConversionDataResponse {
    onConvert: any,
    onSubmit: any
    onQualify: any,
    organizationType: string
}

const useGetConversionData = (
  identifier: string,
): UseQueryResult<ApiResponse<ConversionDataResponse>, ApiError<ConversionDataResponse>> => {
  const queryParams = new URLSearchParams();

  const queryKey = generateQueryKey(identifier, queryParams);

  return useQuery<ApiResponse<ConversionDataResponse>, ApiError<ConversionDataResponse>>(
    [`conversion_data_${queryKey}`],
    () => dataFetch.get(`${DIGGER_API_SUFFIX}google/conversion`),
  );
};

export default useGetConversionData;
