import React, { FC } from 'react';
import PageViewsOrganizationSettingsForm
  from '../../../../forms/organizationSettingsForms/pageViewsOrganizationSettingsForm';
import { OrganizationSettingsInterface } from '../../../../interfaces/organizationSettings.interface';
import { useOrganizationSettingsStore } from '../../../../store/organizationSettingsStore';

const PageViewsPanel: FC = () => {
  const { organizationSettings } = useOrganizationSettingsStore();
  const { setOrganizationSettings } = useOrganizationSettingsStore();
  return (
    <PageViewsOrganizationSettingsForm
      organizationSettings={organizationSettings}
      setOrganizationSettings={setOrganizationSettings}
    />
  );
};

export default PageViewsPanel;
