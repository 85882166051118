import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import closableEnqueueSnackbar from '../../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';

interface newPasswordInterface {
    password: string,
    newPassword: string,
}

export const useChangePassword = () => {
  const { t } = useTranslation();

  return useMutation<ApiResponse<null>, ApiError<null>, newPasswordInterface>(
    (nameInfo) => dataFetch.put(
      `${DIGGER_API_SUFFIX}users/password`,
      {
        currentPassword: nameInfo.password,
        newPassword: nameInfo.newPassword,
      },
    ),
    {
      onSuccess: () => {
        closableEnqueueSnackbar(t('passwordSuccessFullyChanged'), 'success');
      },
      onError: () => {
        closableEnqueueSnackbar(t('somethingWentWrong'), 'error');
      },
    },
  );
};
