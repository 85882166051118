import React, { FC } from 'react';

interface PrimaryButtonProps extends React.HTMLProps<HTMLButtonElement> {
  children?: React.ReactNode,
  type?: 'button' | 'submit' | 'reset' | undefined,
  className?: string,
  disabled?: boolean,
  dataCy?: string;
}

const PrimaryButton: FC<PrimaryButtonProps> = (props) => (
  <button
    className={`rounded-[8px] bg-primary px-[25px] py-[9.5px] text-white hover:opacity-95 duration-200
      ${props.className} 
      ${props.disabled ? 'opacity-50 cursor-default hover:opacity-50' : 'cursor-pointer'}
      `}
    style={{ ...props.style, opacity: props.disabled ? '50%' : '' }}
    onClick={props.onClick}
    type={props.type !== undefined ? props.type : 'button'}
    disabled={props.disabled}
    data-cy={props.dataCy}
  >
    {props.children}
  </button>
);

export default PrimaryButton;
