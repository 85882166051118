import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import closableEnqueueSnackbar from '../../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';
import { DraggableStatusCardsInterface } from '../../../interfaces/draggableStatusCards';
import { handleApiError } from '../../requestHelpers/requestHelpers';
import { HubspotContactSettingsFormInterface } from '../../../interfaces/hubspot.interface';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';

export const useUpdateHubspotFormStageStatus = (pipelineId?: string, status?: boolean, refetchAll?: () => void) => {
  const { t } = useTranslation();
  return useMutation(
    (mutationData: DraggableStatusCardsInterface) => dataFetch.post(
      `${DIGGER_API_SUFFIX}hubspot/pipeline/${pipelineId}/stages/${mutationData.stageId}`,
      {
        status,
        label: mutationData.label,
      },
    ),
    {
      onError: (error: any) => {
        error?.response?.data?.status?.message.map((message: string) => {
          closableEnqueueSnackbar(t(message), 'error');
          return error;
        });
      },
      onSuccess: () => {
        refetchAll?.();
      },
    },
  );
};

export const useDeleteHubspotFormStageStatus = (pipelineId: string, refetchAll: () => void) => {
  const { t } = useTranslation();
  return useMutation(
    (stageId: string) => dataFetch.delete(
      `${DIGGER_API_SUFFIX}hubspot/pipeline/${pipelineId}/stages/${stageId}`,
    ),
    {
      onError: (error: any) => {
        error?.response?.data?.status?.message.map((message: string) => {
          closableEnqueueSnackbar(t(message), 'error');
          return error;
        });
      },
      onSuccess: () => {
        refetchAll();
      },
    },
  );
};

export const useDeleteHubspotContactSettingsCard = (refetch: () => void) => {
  const { t } = useTranslation();
  return useMutation(
    (id: number) => dataFetch.delete(
      `${DIGGER_API_SUFFIX}hubspot/contact-field/${id}`,
    ),
    {
      onError: (error: any) => {
        error?.response?.data?.status?.message.map((message: string) => {
          closableEnqueueSnackbar(t(message), 'error');
          return error;
        });
      },
      onSuccess: () => {
        refetch();
      },
    },
  );
};

export const useUpdateHubspotContactSettings = () => {
  const { t } = useTranslation();
  return useMutation<ApiResponse<null>, ApiError<null>, HubspotContactSettingsFormInterface>(
    (postRequest) => dataFetch.put(
      `${DIGGER_API_SUFFIX}hubspot/organization-settings`,
      {
        amount: postRequest.amount,
        status: postRequest.status,
      },
    ),
    {
      onError: (error) => {
        handleApiError(error, t);
      },
      onSuccess: () => {
        closableEnqueueSnackbar(t('settingsUpdatedSuccessfully'), 'success');
      },
    },
  );
};

export const useCreateHubspotContactSettingsContentCard = (status: boolean) => {
  const { t } = useTranslation();

  return useMutation(
    (label: string) => dataFetch.post(
      `${DIGGER_API_SUFFIX}hubspot/contact-field`,
      {
        label,
        status,
      },
    ),
    {
      onError: (error: any) => {
        error?.response?.data?.status?.message.map((message: string) => {
          closableEnqueueSnackbar(t(message), 'error');
          return error;
        });
      },
    },
  );
};
