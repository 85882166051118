import * as yup from 'yup';
import { TFunction } from 'i18next';

export const ResetPasswordFormValidationSchema = (t: TFunction) => yup.object({
  password: yup
    .string()
    .min(8, t('passwordMinimumEightChars') ?? '')
    .required(t('inputIsRequired') ?? ''),
  confirmPassword: yup
    .string()
    .required(t('inputIsRequired') ?? '')
    .oneOf([yup.ref('password')], t('passwordsMustMatch') ?? ''),
});
