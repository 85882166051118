import * as React from 'react';
import { VariantProps, cva } from 'class-variance-authority';
import { cn } from '../../lib/utils';

const buttonVariants = cva(
  'flex items-center gap-2.5 rounded-lg text-sm duration-200',
  {
    variants: {
      variant: {
        default:
          'bg-primary enabled:hover:bg-[#0a3a4b] text-white disabled:opacity-50 px-5 '
          + 'py-2 focus:outline-primary/50 focus:outline-[1px] focus:outline-offset-[3px] '
          + 'enabled:active:bg-opacity-80 ',
        outline:
          'flex gap-2 items-center justify-center border bg-transparent text-primary hover:border-gray '
            + 'enabled:border-gray enabled:hover:bg-dark enabled:active:bg-primary/20 px-5 py-2',
        sideBar:
              'flex h-[32px] w-[32px] px-2 items-center justify-center border bg-transparent text-primary hover:border-gray '
              + 'enabled:border-gray enabled:hover:bg-dark enabled:active:bg-primary/20',
        noOutline:
              'flex gap-2 items-center justify-center bg-transparent text-primary hover:border-gray '
              + 'enabled:hover:bg-dark enabled:active:bg-primary/20 px-5 py-2',
        icon:
          'h-[40px] w-[40px] grid place-content-center relative bg-primary px-0 border hover:bg-[#0a3a4b] '
          + 'hover:text-white',
        iconOutlined:
          'h-[40px] w-[40px] border bg-transparent px-0 grid place-content-center enabled:hover:bg-dark '
          + 'hover:text-white enabled:active:bg-[#0a3a4b33] disabled:opacity-90 disabled:cursor-not-allowed',
        gradient:
        'relative px-5 py-2 rounded-lg bg-white before:absolute before:w-[calc(100%+2px)] before:h-[calc(100%+2px)] '
        + 'before:z-[-1] before:rounded-[9px] before:top-[-1px] before:bottom-0 before:left-[-1px] before:right-0 '
        + 'before:bg-gradient enabled:hover:bg-dark enabled:active:bg-white disabled:opacity-90 '
        + 'disabled:cursor-not-allowed',
      },
      sizeVariant: {
        none: '',
        default: 'px-[20px] py-2 ',
      },
    },
    defaultVariants: {
      variant: 'default',
      sizeVariant: 'none',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  dataCy?: string;
  loading?: boolean;
}

const CustomButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => (
    <button
      ref={ref}
      {...props}
      type={props.type || 'button'}
      disabled={props.disabled}
      className={cn(
        buttonVariants({ variant: props.variant, className: props.className }),
      )}
      style={{ ...props.style }}
      data-cy={props.dataCy}
    >
      {props.children}
    </button>
  ),
);

export { CustomButton, buttonVariants };
