import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import closableEnqueueSnackbar from '../../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';
import { useIsTwoFaEnabledStore } from '../../../store/twoFaStore';

export const useDisableTwoFa = () => {
  const { t } = useTranslation();
  const { setTwoFaEnabled } = useIsTwoFaEnabledStore();

  return useMutation<ApiResponse<null>, ApiError<null>>(
    () => dataFetch.post(
      `${DIGGER_API_SUFFIX}users/disable2fa`,
    ),
    {
      onSuccess: () => {
        setTwoFaEnabled(false);
        closableEnqueueSnackbar(t('twoFaSuccessfullyDisabled'), 'success');
      },
      onError: () => {
        closableEnqueueSnackbar(t('somethingWentWrong'), 'error');
      },
    },
  );
};
