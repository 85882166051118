import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QrCodeGenerator from '../qrCodeGenerator/qrCodeGenerator';
import BasicDialog from '../../dialogs/basicDialog';
import TwoFactorInput from '../twoFactorInput/twoFactorInput';
import { useIsTwoFaEnabledStore } from '../../store/twoFaStore';
import { useDisableTwoFa } from '../../apiHooks/mutations/profileMutations/disableTwoFa';

const TwoFactorAuthentication = () => {
  const { t } = useTranslation();
  const { twoFaEnabled } = useIsTwoFaEnabledStore();
  const [openModule, setOpenModule] = useState<boolean>(false);
  const [nextModule, setNextModule] = useState<boolean>(false);
  const [twoFaCode, setTwoFaCode] = useState<string>('');

  const { mutate: disableTwoFa } = useDisableTwoFa();

  const handleCodeChange = (code: string) => {
    setTwoFaCode(code);
  };

  return (
    <>
      <div>
        <div className="mb-8 flex flex-col border-b-2">
          <span className="my-3 text-[22px] font-semibold text-primary">{t('twoFa')}</span>
          <p className="pb-3 text-[12px] text-[#0C485E99]">
            {t('twoFaText')}
          </p>
        </div>
        <div className="mb-8 flex max-w-[507px] items-center justify-between">
          <div>
            <span className="my-3 mb-4 text-xl font-semibold text-primary">2FA</span>
            <p className="pb-3 text-[12px] text-[#0C485E]">
              {t('enableToSetup')}
            </p>
          </div>
          {twoFaEnabled
            ? (
              <button
                onClick={() => disableTwoFa()}
                type="button"
                className="rounded-lg bg-red px-5 py-3 text-xs text-white"
              >
                {t('disable')}
              </button>
            )
            : (
              <button
                onClick={() => setOpenModule(true)}
                type="button"
                className="rounded-lg bg-green px-5 py-3 text-xs text-white"
              >
                {t('enable')}
              </button>
            )}
        </div>
      </div>
      {openModule && (
      <BasicDialog
        closeDialog={() => { setOpenModule(false); setNextModule(false); }}
        open={openModule}
        title={`${!nextModule ? t('stepOneQr') : t('stepTwoEnter')}`}
      >
        {!nextModule ? (
          <QrCodeGenerator closeDialog={() => setOpenModule(false)} next={setNextModule} />
        ) : (
          <TwoFactorInput
            title="enterAuthenticationBelow"
            onCodeChange={handleCodeChange}
            closeDialog={setOpenModule}
          />
        )}
      </BasicDialog>
      )}
    </>
  );
};

export default TwoFactorAuthentication;
