import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import { handleApiError } from '../../requestHelpers/requestHelpers';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';

interface SwitchOrganizationResponse {
    authorization : {
        token: string
    }
}

export const useSwitchOrganizations = () => {
  const { t } = useTranslation();

  return useMutation<ApiResponse<SwitchOrganizationResponse>, ApiError<null>, string>(
    (newId: string) => dataFetch.post(
      `${DIGGER_API_SUFFIX}auth/switch-org`,
      {
        newOrganizationId: newId,
      },
    ),
    {
      onSuccess: (response) => {
        localStorage.setItem('token', response?.data?.data?.authorization.token);
        window.location.reload();
      },
      onError: (error: any) => {
        handleApiError(error, t);
      },
    },
  );
};
