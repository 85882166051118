import React from 'react';
import { useTranslation } from 'react-i18next';
import SimpleAccordion from '../../../components/simpleAccordion/simpleAccordion';
import CustomFieldsHeader from '../../../components/customFields/customFieldsHeader/customFieldsHeader';
import CustomFieldsBody from '../../../components/customFields/customFieldsBody/customFieldsBody';
import { CustomFieldFormTypeEnum } from '../../../components/enums/customFieldFormTypeEnum';
import { CUSTOM_FIELDS, PAGE_VIEW_CUSTOM_FIELDS } from '../../../constants/pageIdentifiers';

const CustomFields = () => {
  const { t } = useTranslation();

  return (
    <>
      <SimpleAccordion title={t('customFieldsForm')} defaultExpanded>
        <CustomFieldsHeader />
        <CustomFieldsBody identifier={CUSTOM_FIELDS} formType={CustomFieldFormTypeEnum.basic} />
      </SimpleAccordion>
      <SimpleAccordion title={t('pageViewCustomFieldsForm')}>
        <CustomFieldsHeader />
        <CustomFieldsBody identifier={PAGE_VIEW_CUSTOM_FIELDS} formType={CustomFieldFormTypeEnum.pageView} />
      </SimpleAccordion>
    </>
  );
};

export default CustomFields;
