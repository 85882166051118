import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikValues, useFormik } from 'formik';
import { CustomButton } from '../../../../components/buttons/customButton';
import TextInput from '../../../../components/inputs/textInput';
import {
  HubspotContactSettingsFormValidationSchema,
} from '../../../../validations/hubspotContactSettingsFormValidationSchema';
import { useUpdateHubspotContactSettings } from '../../../../apiHooks/mutations/hubspotFormMutations/hubspotFormMutations';
import { HubspotContactSettingsFormInterface } from '../../../../interfaces/hubspot.interface';

interface HubspotContactSettingsFormProps {
  data: HubspotContactSettingsFormInterface
}

const HubspotContactSettingsForm:FC<HubspotContactSettingsFormProps> = (props) => {
  const { t } = useTranslation();
  const {
    mutateAsync: updateContactSettings,
    isLoading: isUpdatingContactSettings,
  } = useUpdateHubspotContactSettings();

  const formik: FormikValues = useFormik({
    initialValues: {
      amount: props.data?.amount || '',
      status: props.data?.status || '',
    },
    validationSchema: () => HubspotContactSettingsFormValidationSchema(t),
    onSubmit: async (values) => {
      await updateContactSettings(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="flex justify-between gap-[30px]">
      <div className="flex gap-[30px]">
        <TextInput
          myKey="amount"
          name="amount"
          value={formik.values.amount}
          label={t('amountField')}
          placeholder={t('amountField')}
          fullWidth
          size="small"
          onChange={formik.handleChange}
          error={Boolean(formik.touched.amount) && Boolean(formik.errors.amount)}
          helperText={Boolean(formik.touched.amount) && formik.errors.amount}
        />
        <TextInput
          myKey="status"
          name="status"
          value={formik.values.status}
          label={t('statusField')}
          placeholder={t('statusField')}
          fullWidth
          size="small"
          onChange={formik.handleChange}
          error={Boolean(formik.touched.status) && Boolean(formik.errors.status)}
          helperText={Boolean(formik.touched.status) && formik.errors.status}
        />
      </div>
      <div>
        <CustomButton
          type="submit"
          disabled={isUpdatingContactSettings}
        >
          {t('save')}
        </CustomButton>
      </div>
    </form>
  );
};

export default HubspotContactSettingsForm;
