import React, { FC } from 'react';
import { FormikValues, useFormik } from 'formik';
import { FormControlLabel, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCreateCustomField } from '../../../../apiHooks/mutations/customFieldsMutations/customFieldsMutations';
import TextInput from '../../../inputs/textInput';
import { customFieldsValidationsSchema } from '../../../../validations/customFieldsValidationsSchema';
import PlusIcon from '../../../../assets/icons/plus-icon.svg';
import PrimaryButton from '../../../buttons/primaryButton';
import SwitchInput from '../../../inputs/switchInput';
import FieldTypeSelect from '../../../dropdownSelects/fieldTypeSelect';
import { FieldTypeEnum } from '../../../enums/fieldTypeEnum';

const fieldTypes = [
  { id: FieldTypeEnum.text, label: 'Text' },
  { id: FieldTypeEnum.number, label: 'Number' },
  { id: FieldTypeEnum.decimal, label: 'Decimal' },
  { id: FieldTypeEnum.boolean, label: 'Boolean' },
];

interface CustomFieldsFormProps {
  refetch: () => void,
  formType: number,
}

const CustomFieldsForm: FC<CustomFieldsFormProps> = (props) => {
  const { mutateAsync: createCustomField, isLoading: isCreatingCustomField } = useCreateCustomField();

  const { t } = useTranslation();

  const formik: FormikValues = useFormik({
    initialValues: {
      fieldName: '',
      fieldType: FieldTypeEnum.text,
      isRequired: false,
      formType: props.formType,
    },
    validationSchema: () => customFieldsValidationsSchema(t),
    onSubmit: async (values: any) => {
      await createCustomField(values);
      props.refetch();
    },
  });

  return (
    <div className="pb-6 pt-3">
      <form onSubmit={formik.handleSubmit}>
        <Grid container gap={2} className="items-center justify-between">
          <Grid item md={0.5} />
          <Grid item md={2.5}>
            <TextInput
              name="fieldName"
              label={t('fieldName')}
              value={formik.values.fieldName}
              onChange={formik.handleChange}
              placeholder={t('fieldName')}
              fullWidth
              size="small"
              error={Boolean(formik.touched.fieldName) && Boolean(formik.errors.fieldName)}
              helperText={Boolean(formik.touched.fieldName) && formik.errors.fieldName}
            />
          </Grid>
          <Grid item md={2.5}>
            <FieldTypeSelect
              formik={formik}
              fieldTypes={fieldTypes}
            />
          </Grid>
          <Grid item md={2.5} className="flex items-center">
            <FormControlLabel
              control={(
                <SwitchInput
                  checked={formik.values.isRequired}
                />
              )}
              className="pl-[11px]"
              label=""
              name="isRequired"
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item md={2.5}>
            <PrimaryButton type="submit" disabled={isCreatingCustomField} className="px-[12px] py-[12px]">
              <img src={PlusIcon} alt="" />
            </PrimaryButton>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default CustomFieldsForm;
