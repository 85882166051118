import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import QRCode from 'qrcode';
import React from 'react';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import { generateQueryKey } from '../../../constants/hookConstants';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';

export interface QrCodeResponse {
    qrCode : string;
}

type SetSrcFunction = React.Dispatch<React.SetStateAction<string>>;

const useGetQrCode = (
  identifier: string,
  setSrc: SetSrcFunction,
): UseQueryResult<ApiResponse<QrCodeResponse>, ApiError<QrCodeResponse>> => {
  const queryParams = new URLSearchParams();
  const queryKey = generateQueryKey(identifier, queryParams);

  return useQuery<ApiResponse<QrCodeResponse>, ApiError<QrCodeResponse>>(
    [`qr_code_${queryKey}`],
    () => dataFetch.get(`${DIGGER_API_SUFFIX}users/enable2fa`),
    {
      onSuccess: (data) => {
        const qrCodeData = data?.data?.data?.qrCode || '';
        QRCode.toDataURL(qrCodeData).then(setSrc);
      },
    },
  );
};

export default useGetQrCode;
