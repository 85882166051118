import { create } from 'zustand';

interface SidebarState {
  isSidebarOpen: boolean,
  setIsSidebarOpen: (_newIsSidebarOpen: boolean) => void,
}

export const useSidebarStore = create<SidebarState>((set) => ({
  isSidebarOpen: false,
  setIsSidebarOpen: (newIsSidebarOpen: boolean) => set(() => ({
    isSidebarOpen: newIsSidebarOpen,
  })),
}));
