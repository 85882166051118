import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import closableEnqueueSnackbar from '../../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';
import { useOrganizationSettingsStore } from '../../../store/organizationSettingsStore';

export const useUpdateSubscription = () => {
  const { t } = useTranslation();
  const organizationSettings = useOrganizationSettingsStore((state) => state.organizationSettings);
  const setOrganizationSettings = useOrganizationSettingsStore((state) => state.setOrganizationSettings);

  return useMutation((priceId?: string) => dataFetch.patch(
    `${DIGGER_API_SUFFIX}organizations/update-subscription`,
    {
      priceId,
    },
  ), {
    onSuccess: (res) => {
      closableEnqueueSnackbar(t('subscriptionUpdatedSuccessfully'), 'success');
      setOrganizationSettings({ ...organizationSettings, productId: res.data.data.productId });
    },
    onError: () => {
      closableEnqueueSnackbar(t('somethingWentWrong'), 'error');
    },
  });
};
