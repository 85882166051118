import { useQuery, UseQueryResult } from 'react-query';
import { Dayjs } from 'dayjs';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import { generateQueryKey } from '../../../constants/hookConstants';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';

export interface ProfileNameResponse {
    id: number,
    firstName: string,
    lastName: string,
    email: string,
    organizationId: number,
    createdAt: Dayjs,
    updatedAt: Dayjs,
    isSuperAdmin: boolean,
}

const useGetProfileName = (
  identifier: string,
): UseQueryResult<ApiResponse<ProfileNameResponse>, ApiError<ProfileNameResponse>> => {
  const queryParams = new URLSearchParams();
  const queryKey = generateQueryKey(identifier, queryParams);

  return useQuery<ApiResponse<ProfileNameResponse>, ApiError<ProfileNameResponse>>(
    [`profile_name_${queryKey}`],
    async () => dataFetch.get(`${DIGGER_API_SUFFIX}users/me`),
  );
};

export default useGetProfileName;
