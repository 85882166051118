import { create } from 'zustand';

type TypeOfObjectID = {
  organizationId : string;
  userId : string;
}

interface AuthState {
  token: string | null;
  isLoggedIn: boolean;
  login: (_loginToken: string, _objectID: TypeOfObjectID) => void;
  logout: () => void;
}

export const useAuthStore = create<AuthState>((set) => ({
  token: localStorage.getItem('token'),
  isLoggedIn: !!localStorage.getItem('token'),
  organizationInfo: {},

  login: async (loginToken: string, objectID: TypeOfObjectID) => {
    set(() => {
      localStorage.setItem('token', loginToken);
      localStorage.setItem('organizationId', objectID.organizationId);
      localStorage.setItem('userId', objectID.userId);
      return {
        token: loginToken,
        isLoggedIn: true,
        organizationInfo: objectID,
      };
    });
  },

  logout: () => {
    set(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('userInitials');
      localStorage.removeItem('organizationId');
      localStorage.removeItem('userId');
      return {
        token: null,
        isLoggedIn: false,
      };
    });
  },
}));
