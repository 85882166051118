import * as yup from 'yup';

export const campaignsFormValidationSchema = (t: any) => yup.object({
  name: yup
    .string()
    .matches(/^[A-Za-z0-9\x80-\xFF_\- !@#$%^&*()_+=[\]{}|\\;:'",.<>/?`~]+$/, t('nameOnlyContainLetters'))
    .required(t('inputIsRequired')),
  campaignCode: yup
    .string()
    .matches(
      /^[A-Za-z0-9_\-]+$/,
      t('nameIsCode'),
    )
    .required(t('inputIsRequired')),
  conversionValue: yup.number()
    .required(t('inputIsRequired')),
});
