import { DateRange, PickersShortcutsItem } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import i18next from 'i18next';

export const shortcutsItems = (t: typeof i18next.t): PickersShortcutsItem<DateRange<Dayjs>>[] => [
  {
    label: t('lastSixMonths'),
    getValue: () => {
      const today = dayjs();
      const prev6Months = today.subtract(6, 'month');
      return [prev6Months.startOf('month'), today];
    },
  }, {
    label: t('lastMonth'),
    getValue: () => {
      const today = dayjs();
      const prevMonth = today.subtract(1, 'month');
      return [prevMonth.startOf('month'), prevMonth.endOf('month')];
    },
  }, {
    label: t('lastWeek'),
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(1, 'week');
      return [prevWeek.startOf('week'), prevWeek.endOf('week')];
    },
  }, {
    label: t('yesterday'),
    getValue: () => {
      const today = dayjs();
      const yesterday = today.subtract(1, 'day');
      return [yesterday.startOf('day'), yesterday.endOf('day')];
    },
  }, {
    label: t('thisYear'),
    getValue: () => {
      const today = dayjs();
      const startOfYear = today.startOf('year');
      return [startOfYear, today];
    },
  }, {
    label: t('thisMonth'),
    getValue: () => {
      const today = dayjs();
      const startOfMonth = today.startOf('month');
      return [startOfMonth, today];
    },
  }, {
    label: t('thisWeek'),
    getValue: () => {
      const today = dayjs();
      const startOfWeek = today.startOf('week');
      return [startOfWeek, today];
    },
  }, {
    label: t('allTime'),
    getValue: () => [null, null],
  },
];
