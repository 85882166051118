import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TableColumnInterface } from '../../interfaces/tableColumn.interface';
import BasicTable from './basicTable';
import { DEFAULT_ROWS_PER_PAGE, eCommerceExtendedColumns, extendedColumns } from '../../constants/constants';
import BasicDialog from '../../dialogs/basicDialog';
import DateCell from '../../partials/dateCell';
import { AdGroupInterface } from '../../interfaces/adGroup.interface';
import useGetAdGroups from '../../apiHooks/queries/adManagement/useGetAdGroups';
import { adGroupFormInputs } from '../../constants/modalInputs/adGroupInputs';
import AdGroupsForm from '../../forms/adGroupsForm';
import { useDeleteAdGroup } from '../../apiHooks/mutations/adManagementMutations/adGroupsMutations';
import { ADS_GROUPS_TABLE } from '../../constants/pageIdentifiers';
import CampaignSelect from '../dropdownSelects/campaignSelect';
import { useAdManagementFilterDateStore } from '../../store/dateRangeStore';
import { useOrganizationSettingsStore } from '../../store/organizationSettingsStore';
import OrganizationType from '../enums/organizationTypesEnum';
import { useGetExportAdGroupTable } from '../../apiHooks/mutations/reportsExportMutations/useGetExportAdGroupTable';

const columns: TableColumnInterface[] = [
  { field: 'name', headerName: 'name' },
  { field: 'campaign.name', headerName: 'campaign' },
  { field: 'adGroupCode', headerName: 'code' },
  {
    field: 'createdAt',
    headerName: 'createdAt',
    cellRenderer: (params: string) => <DateCell params={params} />,
  },
];

const sortablesList = ['name', 'createdAt'];

interface AdsGroupTableProps {
  selectedCampaignId: string;
  setSelectedCampaignId: (_newSelectedCampainId: string) => void;
}

const AdGroupsTable: FC<AdsGroupTableProps> = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [editData, setEditData] = useState<AdGroupInterface>();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { mutateAsync: deleteAdGroup } = useDeleteAdGroup();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState<string>(searchParams.get('sortBy') || '');
  const [orderBy, setOrderBy] = useState<string>(searchParams.get('orderBy') || '');
  const [campaignCode, setCampaignCode] = useState<string>(searchParams.get('campaignCode') || '');
  const organizationType = useOrganizationSettingsStore((state) => state.organizationSettings?.organizationType);

  const [searchInput, setSearchInput] = useState({
    name: searchParams.get('name') || '',
  });

  localStorage.setItem('from', searchParams.get('adManagementFilterDateFrom') || '');
  localStorage.setItem('to', searchParams.get('adManagementFilterDateTo') || '');

  const adManagementFilterDateRange = useAdManagementFilterDateStore(
    (state) => state.adManagementFilterDateRange,
  );
  const setAdManagementFilterDateRange = useAdManagementFilterDateStore(
    (state) => state.setAdManagementFilterDateRange,
  );

  const {
    data: adGroupsData,
    isError,
    refetch: refetchAdGroupsData,
    isFetching,
  } = useGetAdGroups(
    ADS_GROUPS_TABLE,
    searchInput.name,
    props.selectedCampaignId,
    sortBy,
    orderBy,
    adManagementFilterDateRange[0],
    adManagementFilterDateRange[1],
    rowsPerPage,
    page + 1,
    campaignCode,
  );

  const { mutate: fetchDownload } = useGetExportAdGroupTable(
    {
      name: searchInput.name,
      campaignId: props.selectedCampaignId,
      from: adManagementFilterDateRange[0],
      to: adManagementFilterDateRange[1],
      sortBy,
      orderBy,
      rows: rowsPerPage,
      page: page + 1,
      campaignCode,
    },
  );

  useEffect(() => {
    setPage(0);
  }, [adManagementFilterDateRange, sortBy, orderBy, props.selectedCampaignId]);

  const handleOnDelete = async (id: number) => {
    setIsDeleting(true);
    await deleteAdGroup(id);
    await refetchAdGroupsData();
    setIsDeleting(false);
  };

  const closeEditDialogAndRefetch = async () => {
    setIsEditDialogOpen(false);
    await refetchAdGroupsData();
  };

  const handleGoToAdGroups = (adGroup: number, adGroupCode : number) => {
    navigate(`/ad-management/ads?adGroupId=${adGroup}&adGroupCode=${adGroupCode}`);
  };

  return (
    <>
      <BasicDialog
        closeDialog={() => setIsEditDialogOpen(false)}
        open={isEditDialogOpen}
        title={editData?.id ? t('updateAdGroup') : t('createAdGroup')}
      >
        <AdGroupsForm data={editData && editData} closeDialog={closeEditDialogAndRefetch} />
      </BasicDialog>
      <BasicTable
        data={adGroupsData?.data}
        refetch={refetchAdGroupsData}
        fetchDownload={fetchDownload}
        onDelete={(id) => handleOnDelete(id)}
        onEdit={(item: AdGroupInterface) => setEditData(item)}
        setEditDialogIsOpen={(isOpen) => setIsEditDialogOpen(isOpen)}
        setPage={(newPage) => setPage(newPage)}
        setRowsPerPage={(newRowsPerPage) => setRowsPerPage(newRowsPerPage)}
        formInputs={adGroupFormInputs}
        setSearchInput={setSearchInput}
        extendedColumns={organizationType === OrganizationType.ECOMMERCE ? eCommerceExtendedColumns : extendedColumns}
        handleNavigation={(adGroup) => handleGoToAdGroups(adGroup.id, adGroup.adGroupCode)}
        setSortBy={setSortBy}
        setOrderBy={setOrderBy}
        filterDateRange={adManagementFilterDateRange}
        setFilterDateRange={setAdManagementFilterDateRange}
        customFilters={(
          <CampaignSelect
            identifier="table-campaign-select"
            size="small"
            selectedCampaignId={props.selectedCampaignId}
            setSelectedCampaignId={props.setSelectedCampaignId}
          />
                )}
        {...{
          isError, isFetching, isDeleting, page, searchInput, columns, rowsPerPage, sortBy, orderBy, sortablesList,
        }}
      />
    </>
  );
};

export default AdGroupsTable;
