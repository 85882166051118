import { FormControlLabel, Grid, Switch } from '@mui/material';
import React, { FC } from 'react';
import { FormikValues, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  useUpdateOrganizationSettings,
} from '../../apiHooks/mutations/organizationMutations/organizationSettingsMutations';
import {
  pageViewsOrganizationSettingsFormValidationSchema,
} from '../../validations/organizationSettingsFormValidationSchema';
import { OrganizationSettingsInterface } from '../../interfaces/organizationSettings.interface';
import PrimaryButton from '../../components/buttons/primaryButton';
import SwitchInput from '../../components/inputs/switchInput';
import { Colors } from '../../constants/constants';

interface PageViewsOrganizationSettingsFormProps {
  organizationSettings: OrganizationSettingsInterface;
  setOrganizationSettings: (_newOrganizationSettings: OrganizationSettingsInterface) => void;
}

const PageViewsOrganizationSettingsForm: FC<PageViewsOrganizationSettingsFormProps> = (props) => {
  const { t } = useTranslation();
  const {
    mutateAsync: updateOrganizationSettings,
    isLoading: isUpdatingOrganizationSettings,
  } = useUpdateOrganizationSettings();

  const formik: FormikValues = useFormik({
    initialValues: {
      isPageViewsEnabled: props.organizationSettings?.isPageViewsEnabled || false,
    },
    validationSchema: () => pageViewsOrganizationSettingsFormValidationSchema(t),
    onSubmit: async (values: any) => {
      updateOrganizationSettings(values).then(() => {
        props.setOrganizationSettings({ ...props.organizationSettings, ...values });
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        gap={6}
        sx={{
          display: 'flex', paddingBlock: '1rem', alignItems: 'center',
        }}
      >
        <Grid item sm={12} md={6} lg={5}>
          <FormControlLabel
            control={(
              <SwitchInput
                checked={formik.values.isPageViewsEnabled}
              />
            )}
            labelPlacement="start"
            className="gap-2 text-primary"
            sx={{ '& .MuiFormControlLabel-label': { fontSize: 14 } }}
            label={t('allowPageViews')}
            name="isPageViewsEnabled"
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>
      <div className="flex w-full justify-end">
        <PrimaryButton type="submit" disabled={isUpdatingOrganizationSettings} dataCy="page-views-org-settings-submit">
          {t('save')}
        </PrimaryButton>
      </div>
    </form>
  );
};

export default PageViewsOrganizationSettingsForm;
