import { Navigate, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useAuthStore } from '../../../store/authStore';
import { useSendFacebookCode } from '../../../apiHooks/mutations/integrationMutations/facebookMutation';

const FacebookRoute = () => {
  const { isLoggedIn } = useAuthStore();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const codeParam = queryParams.get('code');
  const { mutateAsync: sendFacebookCode } = useSendFacebookCode();

  useEffect(() => {
    if (codeParam && isLoggedIn) {
      sendFacebookCode(codeParam).then(() => {});
    }

    if (codeParam && !isLoggedIn) {
      localStorage.setItem('facebookCode', codeParam);
    }

    if (localStorage.getItem('facebookCode') && isLoggedIn) {
      sendFacebookCode(localStorage.getItem('facebookCode') as string).then(() => {});
    }
  }, [isLoggedIn]);

  if (isLoggedIn) {
    return <Navigate to="/dashboard" />;
  }

  return <Navigate to="/auth" />;
};

export default FacebookRoute;
