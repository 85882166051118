import React, { FC } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useOrganizationSettingsStore } from '../store/organizationSettingsStore';

interface VatDisplayerProps {
  value: number;
}
const VatDisplayer: FC<VatDisplayerProps> = (props) => {
  const organizationSettings = useOrganizationSettingsStore((state) => state.organizationSettings);
  const vatPercentage = organizationSettings?.costVAT;

  if (props.value === 0 || props.value !== 0) {
    // remove ```|| props.value !== 0``` when VAT is implemented so the rest of the code will have a chance on being executed
    return (
      <span>
        {Math.round(Number(props.value)).toLocaleString()}
      </span>
    );
  }

  const vatAmount = vatPercentage && (props.value * (vatPercentage / 100)).toFixed(2);
  const totalValue = vatAmount && (props.value + parseFloat(vatAmount)).toFixed(2);

  const tooltipContent = vatAmount && totalValue && (
    <div className="text-left">
      {parseInt(vatAmount, 10) < 0 ? '' : (<span>&nbsp;</span>)}
      {props.value.toLocaleString()}
      <br />
      {parseInt(vatAmount, 10) < 0 ? '' : '+'}
      {parseFloat(vatAmount).toLocaleString()}
      &nbsp;
      <span className="font-[600]">
        (
        {vatPercentage}
        %)
      </span>
      <hr />
      {parseInt(vatAmount, 10) < 0 ? '' : <span>&nbsp;</span>}
      {parseFloat(totalValue).toLocaleString()}
    </div>
  );

  return (
    <Tooltip
      arrow
      placement="top"
      title={tooltipContent}
    >
      <span>
        {Math.round(Number(totalValue)).toLocaleString()}
      </span>
    </Tooltip>
  );
};
export default VatDisplayer;
