import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import { appendQueryParamIfNotEmpty, formatDate, generateQueryKey } from '../../../constants/hookConstants';

const useGetAdGroups = (
  identifier: string,
  name: string,
  campaignId: string,
  sortBy: string,
  orderBy: string,
  from: Dayjs | null,
  to: Dayjs | null,
  rows: number,
  page: number,
  campaignCode: number | string,
) => {
  const navigate = useNavigate();

  const queryParams = new URLSearchParams();
  appendQueryParamIfNotEmpty(queryParams, 'name', name.trim());
  appendQueryParamIfNotEmpty(queryParams, 'campaignId', campaignId.trim());
  appendQueryParamIfNotEmpty(queryParams, 'sortBy', sortBy.trim());
  appendQueryParamIfNotEmpty(queryParams, 'orderBy', orderBy.trim());
  appendQueryParamIfNotEmpty(queryParams, 'from', formatDate(from));
  appendQueryParamIfNotEmpty(queryParams, 'to', formatDate(to));
  appendQueryParamIfNotEmpty(queryParams, 'rows', rows.toString());
  appendQueryParamIfNotEmpty(queryParams, 'page', page.toString());
  appendQueryParamIfNotEmpty(queryParams, 'campaignCode', campaignCode.toString());

  const queryKey = generateQueryKey(identifier, queryParams);

  return useQuery(
    [`ads_management_${queryKey}`],
    () => dataFetch.get(`${DIGGER_API_SUFFIX}ad-groups?${queryParams}`).then((res: any) => res.data),
    {
      onSuccess: () => {
        navigate(`${window.location.pathname}?${queryParams.toString()}`, { replace: true });
      },
    },
  );
};

export const useGetAdGroupOptions = (identifier: string, name: string) => useQuery(
  [`tracking_source_${identifier}`],
  () => dataFetch.get(`${DIGGER_API_SUFFIX}ad-groups/options?name=${name}`).then((res: any) => res.data),
);

export const useGetAdGroupById = (identifier: string, adGroupId: string | null | undefined) => useQuery(
  [`ads_management_${identifier}`],
  () => adGroupId && dataFetch.get(`${DIGGER_API_SUFFIX}ad-groups/${adGroupId}`).then((res: any) => res.data),
);

export default useGetAdGroups;
