import { Autocomplete, TextField } from '@mui/material';
import React, { FC, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import CaretDown from '../../assets/icons/caret-down-primary.svg';
import SmallCrossIcon from '../../assets/icons/small-cross-icon.svg';

interface AutoCompleteInputInterface {
  myKey?: string,
  name: string,
  options: any;
  label: string;
  value?: any;
  onChange: any;
  error?: boolean,
  helperText?: string,
  placeholder: string,
  fullWidth?: boolean,
  size?: 'small' | 'medium',
  style?: React.CSSProperties,
  inputValue?: string,
  onInputChange?: (_event: SyntheticEvent, _value: string) => void,
  isOptionEqualToValue?: (_option: any, _value: any) => boolean,
  getOptionLabel?: (_option: any) => string;
  loading?: boolean,
  disabled?: boolean,
}

const AutoCompleteInput: FC<AutoCompleteInputInterface> = (props) => {
  const { t } = useTranslation();

  return (
    <Autocomplete
      options={props.options}
      value={props.value}
      getOptionLabel={props.getOptionLabel}
      onChange={props.onChange}
      inputValue={props.inputValue}
      loading={props.loading}
      fullWidth={props.fullWidth}
      size={props.size}
      onInputChange={props.onInputChange}
      isOptionEqualToValue={props.isOptionEqualToValue}
      style={props.style}
      disabled={props.disabled}
      sx={{ borderRadius: '8px', border: 'none' }}
      clearIcon={(
        <img src={SmallCrossIcon} alt="icon" className="max-h-[20px] max-w-[20px] object-contain" />
      )}
      popupIcon={(
        <div className="flex h-[24px] w-[24px] items-center justify-center">
          <img src={CaretDown} alt="icon" className="object-contain" />
        </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          name={props.name}
          label={t(props.label)}
          error={props.error}
          helperText={props.helperText}
          placeholder={t(props.placeholder) || ''}
          sx={{
            border: 'none',
            '& .MuiFormHelperText-root': {
              transform: 'translateY(0.15rem)',
              lineHeight: '1.28',
            },
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px',
              '& fieldset': {
                borderColor: 'none',
              },
              '&:hover fieldset, &.Mui-focused fieldset': {
                borderColor: '#0c485e',
              },
              '& input': {
                color: '#0c485e',
              },
            },
            'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
              WebkitAppearance: 'none',
              margin: 0,
            },
            '& .MuiInputLabel-root': {
              color: '#0c485e',
              fontWeight: '500',

              '&.Mui-focused': {
                color: '#0c485e',
              },
            },
          }}
        />
      )}
    />
  );
};

export default AutoCompleteInput;
