import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ConversionSetupInterface {
    title: string;
    imgSource?: string;
    onSelected?: () => void;
    selected?: boolean;
    isLast?: boolean;
    horizontal? : boolean;
    onClick?: () => void;
}

const ConversionSetupBox: FC<ConversionSetupInterface> = (props) => {
  const { t } = useTranslation();
  return (
    <div
      className={`
        ${props.horizontal ? 'mb-4 max-h-[95px] flex-row-reverse items-center bg-white'
        : 'max-h-[150px] max-w-[395px] flex-col items-end border hover:cursor-pointer'}   
        ${props.selected && !props.horizontal ? 'border border-primary' : 'border-gray-4'}  
        ${props.selected && !props.horizontal ? 'shadow-lg' : ''} ${props.isLast ? '' : 'mr-4'}
        flex h-full w-full min-w-[204px] rounded-lg p-4 duration-500 
      `}
      onClick={props.onSelected}
    >
      {props.horizontal
        ? (
          <button
            type="button"
            className="rounded-lg bg-primary px-5 py-2.5 text-sm text-white hover:cursor-pointer"
            onClick={props.onClick}
          >
            Create
          </button>
        )
        : (
          <input
            type="radio"
            value={props.title}
            className="accent-primary"
            checked={props.selected}
          />
        )}

      <div
        className={`
          ${props.horizontal ? 'items-center justify-start pl-4' : 'flex-col items-start justify-center pl-12'} 
          flex h-full w-full  
        `}
      >
        <div className={`${props.horizontal ? 'mr-4' : 'mb-4'} text-black`}>
          <img src={props.imgSource} alt="" />
        </div>
        <p className={`text-xl ${props.horizontal ? 'font-normal' : 'font-semibold'} text-primary`}>
          {t(props.title)}
        </p>
      </div>
    </div>
  );
};

export default ConversionSetupBox;
