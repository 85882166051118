import { useQuery, UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import { CORE_API_SUFFIX, dataFetch } from '../../../axios/customAxios';
import { appendQueryParamIfNotEmpty, formatDate, generateQueryKey } from '../../../constants/hookConstants';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';

interface IncomeInsightResponse {
    lostIncome: number,
    potentialIncome: number,
    earnedIncome: number,
}

const useGetIncomeInsight = (
  identifier: string,
  id: string | undefined,
  from: Dayjs | null,
  to: Dayjs | null,
) : UseQueryResult<ApiResponse<IncomeInsightResponse>, ApiError<IncomeInsightResponse>> => {
  const navigate = useNavigate();

  const queryParams = new URLSearchParams();
  appendQueryParamIfNotEmpty(queryParams, 'from', formatDate(from));
  appendQueryParamIfNotEmpty(queryParams, 'to', formatDate(to));

  const queryKey = generateQueryKey(identifier, queryParams);

  return useQuery<ApiResponse<IncomeInsightResponse>, ApiError<IncomeInsightResponse>>(
    [`ad_income-insight_${queryKey}`],
    () => dataFetch.get(`${CORE_API_SUFFIX}ad/income-insight/${id}?${queryParams}`),
    {
      onSuccess: () => {
        navigate(`${window.location.pathname}?${queryParams.toString()}`, { replace: true });
      },
    },
  );
};

export default useGetIncomeInsight;
