import React from 'react';
import { useLocation } from 'react-router-dom';
import SimpleTabs from '../../../components/tabs/simpleTabs';
import { EcommerceProviderTabLinks } from '../../../components/enums/tabEnums';
import ShopifyIcon from '../../../assets/icons/sidebar/shopify.svg';

const EcommerceTab = () => {
  const location = useLocation();
  const lastPathSegment = location.pathname.split('/').pop();
  const key = lastPathSegment as keyof typeof EcommerceProviderTabLinks;
  const activePanel = EcommerceProviderTabLinks[key] || 0;

  const tabs = [{
    tabLabel: 'Shopify',
    href: 'shopify',
    identifierName: 'shopify-panel',
    icon: ShopifyIcon,
  }];

  return (
    <div className="h-full rounded-lg border border-dark bg-white px-6 py-4">
      <SimpleTabs currentTab={activePanel} tabs={tabs} />
    </div>
  );
};

export default EcommerceTab;
