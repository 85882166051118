import React, { FC } from 'react';

interface RoundedCellProps {
    params: number;
}

const RoundedCell: FC<RoundedCellProps> = (props) => (
  <>
    {Math.round(props.params).toLocaleString() || ''}
  </>
);

export default RoundedCell;
