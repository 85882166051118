import { useQuery, UseQueryResult } from 'react-query';
import { Dayjs } from 'dayjs';
import { CORE_API_SUFFIX, dataFetch } from '../../../axios/customAxios';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';
import { SeoReportRowInterface } from '../../../interfaces/seoReportRow.interface';
import { appendQueryParamIfNotEmpty, formatDate, generateQueryKey } from '../../../constants/hookConstants';

const useGetSeoReport = (
  identifier: string,
  from: Dayjs | null,
  to: Dayjs | null,
): UseQueryResult<ApiResponse<SeoReportRowInterface[]>, ApiError<SeoReportRowInterface[]>> => {
  const queryParams = new URLSearchParams();
  appendQueryParamIfNotEmpty(queryParams, 'from', formatDate(from));
  appendQueryParamIfNotEmpty(queryParams, 'to', formatDate(to));

  const queryKey = generateQueryKey(identifier, queryParams);

  return useQuery<ApiResponse<SeoReportRowInterface[]>, ApiError<SeoReportRowInterface[]>>(
    [queryKey],
    () => dataFetch.get(`${CORE_API_SUFFIX}form-submission/seo-report?${queryParams}`),
  );
};

export default useGetSeoReport;
