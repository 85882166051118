import * as React from 'react';
import { FC, ReactNode, CSSProperties } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { accordionDetailsClasses, accordionSummaryClasses } from '@mui/material';
import { formatDateAndTime } from '../../constants/constants';

interface DevAccordionProps {
    color?: string;
    children: ReactNode;
    expanded: boolean;
    toggleAccordion: () => void;
    adCode: string,
    id: string,
    createdAt: string,
    index: string,
    clickedAccordion: string | null,
}

const DevAccordion: FC<DevAccordionProps> = (props) => {
  const isHidden = props.index !== props.clickedAccordion;
  const allAccordion = props.clickedAccordion == null;
  const color = props.color === 'yellow'
    ? '#FF9F1C' : props.color === 'red' ? '#DB3A34'
      : props.color === 'green'
        ? '#4C956C' : '';

  const accordionStyle: CSSProperties = {
    backgroundColor: !isHidden ? '#FAFAFA' : '#FFF',
    paddingTop: '0px',
    display: allAccordion ? 'block' : isHidden ? 'none' : 'block',
    position: 'relative',
  };

  const leftBorderStyle: CSSProperties = {
    backgroundColor: props.expanded ? '#0C485E' : 'transparent',
    width: '3px',
    height: '45px',
    position: 'absolute',
    top: 11,
    left: 0,
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
  };

  return (
    <Accordion
      disableGutters
      expanded={props.expanded}
      onChange={props.toggleAccordion}
      style={accordionStyle}
      sx={{
        [`& .${accordionDetailsClasses.root}`]: {
          backgroundColor: 'white',
          padding: '0px',
        },
        boxShadow: 'none',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          [`& .${accordionSummaryClasses.content}`]: {
            display: 'flex',
            justifyContent: 'space-between',
            height: '45px',
          },
        }}
      >

        <div style={leftBorderStyle} />
        <Typography className="flex items-center text-sm text-primary" fontWeight="300">
          {props.adCode}
        </Typography>
        <Typography className="flex w-[320px] items-center justify-start text-sm text-primary" fontWeight="300">
          {props.id}
        </Typography>
        <Typography className="flex min-w-[210px] items-center text-sm text-primary" fontWeight="300">
          {formatDateAndTime(props.createdAt)}
        </Typography>
        <Typography />
        <div
          style={{
            content: '""',
            position: 'absolute',
            left: 5,
            right: 0,
            bottom: 0,
            height: '4px',
            width: '99.5%',
            backgroundColor: color,
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
          }}
        />
      </AccordionSummary>
      <AccordionDetails style={{ paddingTop: '0', boxShadow: 'none' }}>
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
};

export default DevAccordion;
