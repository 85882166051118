import React from 'react';
import { useTranslation } from 'react-i18next';
import LoginImage from '../../assets/images/login/login-background_lavender.png';
import AuthContent from './authContent';

const Auth = () => {
  const { t } = useTranslation();
  document.title = `Digtective - ${t('authentication')}`;

  return (
    <div className="flex h-[100vh] items-center justify-center">
      <div className="mx-auto flex h-[100vh] max-h-[1000px] w-[100vw] max-w-[1920px] items-center justify-between gap-5 p-5">
        <AuthContent />
        <div
          className="relative hidden h-full w-[50%] max-w-[1040px] items-center justify-center rounded-[48px] shadow-inner lg:flex xl:w-[57%]"
        >
          <img src={LoginImage} alt="" className="h-full w-full rounded-[48px]" />
          <div className="absolute inset-0 flex flex-col items-center justify-center p-5 text-center text-primary">
            <h1 className="mb-4 font-normal xl:text-[48px] 2xl:text-6xl ">{t('inAPerfectWorld')}</h1>
            <p className="text-sm font-normal">{t('youWouldHaveAccurate')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
