import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import { handleApiError } from '../../requestHelpers/requestHelpers';

export const useSendFacebookCode = () => {
  const { t } = useTranslation();
  return useMutation(
    (code: string) => dataFetch.post(
      `${DIGGER_API_SUFFIX}facebook/initiate`,
      {
        code,
      },
    ),
    {
      onError: (error: any) => {
        handleApiError(error, t);
      },
    },
  );
};
