import React, { FC, useEffect } from 'react';
import StatusContainer from '../../../../components/containers/statusContainer';
import StatusGrid from './statusGrid';
import DraggableStatusCard from './draggableStatusCard';
import useBoard from '../../../../hooks/useBoard';
import LoadingSpinner from '../../../../components/loadingSpinner/loadingSpinner';
import { DraggableStatusCardsInterface } from '../../../../interfaces/draggableStatusCards';
import { useDeleteHubspotFormStageStatus } from '../../../../apiHooks/mutations/hubspotFormMutations/hubspotFormMutations';
import { useTranslation } from 'react-i18next';

interface DealSettingsContentInterface {
  pipelineStagesData?: DraggableStatusCardsInterface[];
  isFetchingPipelineStages?: boolean;
  isErrorPipelineStages?: boolean;
  pipelineStagesMappedData?: DraggableStatusCardsInterface[];
  isFetchingPipelineStagesMapped?: boolean;
  isErrorPipelineStagesMapped?: boolean;
  refetchAll: () => void;
  pipelineId: string;
}

const DealSettingsContent: FC<DealSettingsContentInterface> = (props) => {
  const { t } = useTranslation();
  const wonPipelineStagesMappedData = props.pipelineStagesMappedData?.filter(
    (card) => card.status,
  );
  const lostPipelineStagesMappedData = props.pipelineStagesMappedData?.filter(
    (card) => !card.status,
  );

  const {
    board: wonBoard,
    isOver: isOverWon,
    drop: dropWon,
    isUpdatingPipelineStage: isUpdatingWonPipelineStage,
  } = useBoard(
    wonPipelineStagesMappedData,
    props.pipelineId,
    true,
    props.refetchAll,
  );

  const {
    board: lostBoard,
    isOver: isOverLost,
    drop: dropLost,
    isUpdatingPipelineStage: isUpdatingLostPipelineStage,
  } = useBoard(
    lostPipelineStagesMappedData,
    props.pipelineId,
    false,
    props.refetchAll,
  );

  const {
    mutateAsync: deleteHubspotForm,
    isLoading: isDeletingHubspotForm,
  } = useDeleteHubspotFormStageStatus(
    props.pipelineId,
    props.refetchAll,
  );

  return (
    <div className="mt-4 flex h-full gap-3">
      <div className="flex w-full gap-2">
        {props.pipelineStagesMappedData && (
          <>
            <StatusContainer
              title="won"
              titleColor="green"
              isError={props.isErrorPipelineStagesMapped}
              isFetching={
                props.isFetchingPipelineStagesMapped || isUpdatingWonPipelineStage
              }
            >
              <StatusGrid
                board={wonBoard}
                dropRef={dropWon}
                isOver={isOverWon}
                deleteHubspotForm={deleteHubspotForm}
                isDeletingHubspotForm={isDeletingHubspotForm}
              />
            </StatusContainer>
            <StatusContainer
              title="lost"
              titleColor="red"
              isError={props.isErrorPipelineStagesMapped}
              isFetching={
                props.isFetchingPipelineStagesMapped || isUpdatingLostPipelineStage
              }
            >
              <StatusGrid
                board={lostBoard}
                dropRef={dropLost}
                isOver={isOverLost}
                deleteHubspotForm={deleteHubspotForm}
                isDeletingHubspotForm={isDeletingHubspotForm}
              />
            </StatusContainer>
          </>
        )}
      </div>
      <div className="grid h-min w-full max-w-[298px] grid-cols-2 gap-2.5">
        {!props.isErrorPipelineStages && props.isFetchingPipelineStages && (
          <div className="col-span-2">
            <LoadingSpinner color="primary" center />
          </div>
        )}
        {props.isErrorPipelineStages && !props.isFetchingPipelineStages && (
          <p className="col-span-2">{t('somethingWentWrong')}</p>
        )}
        {!props.isErrorPipelineStages && !props.isFetchingPipelineStages && !isUpdatingWonPipelineStage
          && !isUpdatingLostPipelineStage && props.pipelineStagesData?.map((card: DraggableStatusCardsInterface) => (
            <DraggableStatusCard card={card} key={card.stageId} />
        ))}
      </div>
    </div>
  );
};

export default DealSettingsContent;
