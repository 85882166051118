import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../loadingSpinner/loadingSpinner';
import { Colors } from '../../constants/constants';

interface StatusCardProps {
  title: string;
  titleColor: 'red' | 'green' | 'yellow';
  children: React.ReactNode;
  isError?: boolean,
  isFetching?: boolean,
}

const StatusContainer: FC<StatusCardProps> = (props) => {
  const { t } = useTranslation();
  return (
    <div className="h-full w-full rounded-lg border border-darkGray">
      <div className="border-b border-darkGray px-5 pb-2.5 pt-3.5">
        <p className="text-xl font-[500]" style={{ color: Colors[props.titleColor] }}>
          {t(props.title)}
        </p>
      </div>
      <div className="min-h-[478px] px-[9px] py-2.5">
        {!props.isError && props.isFetching && (
          <div className="h-full">
            <LoadingSpinner color="primary" center />
          </div>
        )}

        {props.isError && !props.isFetching && (
          <p>{t('somethingWentWrong')}</p>
        )}

        {!props.isError && !props.isFetching && (
          props.children
        )}
      </div>
    </div>
  );
};
export default StatusContainer;
