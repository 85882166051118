import React, { FC } from 'react';
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

const UpgradeTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#0C485E',
    padding: 12,
    boxShadow: '2px 2px 30px 0px #0C485E33',
    fontWeight: 400,
    width: 200,
    fontSize: 12,
    borderRadius: 8,
    margin: '0 0 8px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#0C485E',
  },
}));

const upgradeToolTipContent = (t: TFunction) => {
  const navigate = useNavigate();
  return (
    <p className="leading-[1.5] text-white">
      {t('wantToSeeMore?')}
      <Link
        to="/settings/subscription"
        className="text-yellow underline hover:no-underline"
        onMouseDown={() => navigate('/settings/subscription')}
      >
        {t('upgradeYourPlan')}
      </Link>
    </p>
  );
};

interface LockedWrapperProps {
    disableLockedWrapper: boolean;
    disableBlur?: boolean;
    children: React.ReactNode;
}

const LockedWrapper: FC<LockedWrapperProps> = (props) => {
  const { t } = useTranslation();

  if (props.disableLockedWrapper) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {props.children}
      </>
    );
  }

  return (
    <UpgradeTooltip
      title={upgradeToolTipContent(t)}
      arrow
      placement="top-end"
    >
      <div
        className={`select-none ${props.disableBlur ? '' : 'blur-sm'}`}
      >
        {props.children}
      </div>
    </UpgradeTooltip>
  );
};

export default LockedWrapper;
