import React, { FC, useEffect, useState } from 'react';
import { ConnectDropTarget } from 'react-dnd';
import DraggableStatusCard from './draggableStatusCard';
import { useDeleteHubspotFormStageStatus } from '../../../../apiHooks/mutations/hubspotFormMutations/hubspotFormMutations';

interface StatusGridProps {
  board?: any[];
  dropRef: ConnectDropTarget;
  isOver: boolean;
  deleteHubspotForm?: (_stageId: string) => void;
  isDeletingHubspotForm?: boolean;
}

const StatusGrid: FC<StatusGridProps> = (props) => (
  <div
    className="group grid h-max grid-cols-2 gap-x-3 gap-y-2.5 rounded-lg duration-150"
    ref={props.dropRef}
  >
    {props.board && props.board.map((card) => (
      <DraggableStatusCard
        key={card.stageId}
        card={card}
        deleteHubspotForm={props.deleteHubspotForm}
        isDeletingHubspotForm={props.isDeletingHubspotForm}
      />
    ))}
    {props.board && Array.from({ length: 8 - props.board.length })
      .map((_, index) => {
        // We love ESLint therefore this line exists
        const indexToUse = index + 1;

        return (
          <div
            key={indexToUse}
            className={`
              h-[106px] w-full rounded-lg border border-dashed border-primary/20 
              ${props.isOver && index === 0 ? 'bg-primary/10' : ''}
            `}
          />
        );
      })}
  </div>
);
export default StatusGrid;
