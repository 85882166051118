import { useQuery, UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import { CORE_API_SUFFIX, dataFetch } from '../../../axios/customAxios';
import { appendQueryParamIfNotEmpty, formatDate, generateQueryKey } from '../../../constants/hookConstants';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';

interface TimeToIncomeStatus {
    avgDays: number,
    minDays: number,
    maxDays: number,
}

const useGetTimeToIncome = (
  identifier: string,
  id: string | undefined,
  from: Dayjs | null,
  to: Dayjs | null,
) : UseQueryResult<ApiResponse<TimeToIncomeStatus>, ApiError<TimeToIncomeStatus>> => {
  const navigate = useNavigate();

  const queryParams = new URLSearchParams();
  appendQueryParamIfNotEmpty(queryParams, 'from', formatDate(from));
  appendQueryParamIfNotEmpty(queryParams, 'to', formatDate(to));

  const queryKey = generateQueryKey(identifier, queryParams);

  return useQuery<ApiResponse<TimeToIncomeStatus>, ApiError<TimeToIncomeStatus>>(
    [`ad_time-to-income_${queryKey}`],
    () => dataFetch.get(`${CORE_API_SUFFIX}ad/time-to-income/${id}?${queryParams}`),
    {
      onSuccess: () => {
        navigate(`${window.location.pathname}?${queryParams.toString()}`, { replace: true });
      },
    },
  );
};

export default useGetTimeToIncome;
