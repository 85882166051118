import React, {FC} from 'react';
import CustomFieldsData from './customFieldsData/customFieldsData';
import CustomFieldsForm from './customFieldsForm/customFieldsForm';
import useGetCustomFields from '../../../apiHooks/queries/useGetCustomFields';
import { PAGE_VIEW_CUSTOM_FIELDS } from '../../../constants/pageIdentifiers';
import { useDeleteCustomField } from '../../../apiHooks/mutations/customFieldsMutations/customFieldsMutations';
import { CustomFieldFormTypeEnum } from '../../enums/customFieldFormTypeEnum';
import LoadingSpinner from '../../loadingSpinner/loadingSpinner';

interface CustomFieldsBodyProps {
  identifier: string;
  formType: CustomFieldFormTypeEnum;
}
const CustomFieldsBody: FC<CustomFieldsBodyProps> = (props) => {
  const {
    data: customFields,
    isError,
    refetch: refetchCustomFields,
    isFetching,
  } = useGetCustomFields(props.identifier, props.formType);

  const { mutateAsync: deleteCustomField } = useDeleteCustomField();

  if (isError) {
    return <p>something went wrong!</p>;
  }

  if (isFetching) {
    return (
      <LoadingSpinner center color="primary"/>
    );
  }
  return (
    <div>
      {customFields.data.map((customField: any) => (
        <CustomFieldsData
          key={customField.id}
          field={customField}
          deleteCustomField={deleteCustomField}
          refetchCustomFields={refetchCustomFields}
        />
      ))}
      <CustomFieldsForm refetch={refetchCustomFields} formType={props.formType} />
    </div>
  );
};

export default CustomFieldsBody;
