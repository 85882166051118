import React from 'react';
import { useLocation } from 'react-router-dom';
import SimpleTabs from '../../../components/tabs/simpleTabs';
import PaddleTabIcon from '../../../assets/icons/tab/paddle-tab-icon.svg';
import { PaymentProviderTabLinks } from '../../../components/enums/tabEnums';

const PaymentProvider = () => {
  const location = useLocation();
  const lastPathSegment = location.pathname.split('/').pop();
  const key = lastPathSegment as keyof typeof PaymentProviderTabLinks;
  const activePanel = PaymentProviderTabLinks[key] || 0;

  const tabs = [{
    tabLabel: 'Paddle',
    href: 'paddle',
    identifierName: 'paddle-panel',
    icon: PaddleTabIcon,
  }];

  return (
    <div className="h-full rounded-lg border border-dark bg-white px-6 py-4">
      <SimpleTabs currentTab={activePanel} tabs={tabs} />
    </div>
  );
};

export default PaymentProvider;
