import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../loadingSpinner/loadingSpinner';

interface AdInfoRowsInterface {
    title ? :string[],
    numbers ?: number[],
    suffix ?: string,
    colors ?: boolean,
    isLoading ?: boolean,
}

const AdInfoRows: FC<AdInfoRowsInterface> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="mt-4 flex flex-1 flex-col justify-between">
      <div className={`flex items-center justify-between ${props.colors && 'border-red'} border-b-2 pb-2 `}>
        <p className={`${props.colors ? 'text-red' : 'text-primary'}`}>{props.title && t(props.title[0])}</p>
        <div className="flex items-center gap-1">
          {props.isLoading
            ? <LoadingSpinner color="primary" size={15} />
            : props.numbers && parseFloat(props.numbers[0].toFixed(2))}
          <span>{props.suffix}</span>
        </div>
      </div>
      <div className={`mt-6 flex items-center justify-between ${props.colors && 'border-yellow'} mt-2 border-b-2 pb-2 `}>
        <p className={`${props.colors ? 'text-yellow' : 'text-primary'}`}>{props.title && t(props.title[1])}</p>
        <div className="flex items-center gap-1">
          {props.isLoading
            ? <LoadingSpinner color="primary" size={15} />
            : props.numbers && parseFloat(props.numbers[1].toFixed(2))}
          <span>{props.suffix}</span>
        </div>
      </div>
      <div className={`mt-6 flex items-center justify-between ${props.colors && 'border-green'} mt-2 border-b-2 pb-2 `}>
        <p className={`${props.colors ? 'text-green' : 'text-primary'}`}>{props.title && t(props.title[2])}</p>
        <div className="flex items-center gap-1">
          {props.isLoading
            ? <LoadingSpinner color="primary" size={15} />
            : props.numbers && parseFloat(props.numbers[2].toFixed(2))}
          <span>{props.suffix}</span>
        </div>
      </div>
    </div>
  );
};

export default AdInfoRows;
