import React, { FC } from 'react';
import { Tooltip } from '@mui/material';
import { trackingSourcesMap } from '../components/staticCard/StaticCard';

interface AdNameCellProps {
  params: number;
  rowObject: any;
}

const AdNameCell: FC<AdNameCellProps> = (props) => (
  <div className="flex w-full items-center justify-between gap-2">
    <Tooltip
      arrow
      placement="top"
      title={props.params}
    >
      <div className="flex max-w-[150px] flex-col items-start text-left 2xl:max-w-[150px]">
        <div className="w-[150px] truncate font-semibold 2xl:w-[150px]">
          {props.params}
        </div>
        <div className="w-[150px] 2xl:w-[150px]">
          {props.rowObject?.adId}
        </div>
      </div>
    </Tooltip>
    <div className="h-3.5 w-3.5">
      {trackingSourcesMap?.[props.rowObject?.trackingSourceId]?.iconPrimary && (
        <img
          src={trackingSourcesMap[props.rowObject.trackingSourceId].iconPrimary}
          alt={trackingSourcesMap[props.rowObject.trackingSourceId].name}
          className="block h-full w-full object-contain"
        />
      )}
    </div>
  </div>
);

export default AdNameCell;
