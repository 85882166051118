import {
  useMutation, UseMutationOptions, UseMutationResult,
} from 'react-query';
import { useTranslation } from 'react-i18next';
import { Dayjs } from 'dayjs';
import {
  appendQueryParamIfNotEmpty, downloadFile, formatDate,
} from '../../../constants/hookConstants';
import closableEnqueueSnackbar from '../../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';
import { TypeOfMutationFunction } from '../../../interfaces/query/queryHook.type';

interface GetExportProfitableAdsParamsInterface{
  from?: Dayjs | null;
  to?: Dayjs | null;
  page: number;
  enabled: boolean;
}

export const useGetExportProfitableAds = (
  params : GetExportProfitableAdsParamsInterface,
) => {
  const { t } = useTranslation();

  const queryParams = new URLSearchParams();
  queryParams.append('page', params.page.toString());
  appendQueryParamIfNotEmpty(queryParams, 'from', formatDate(params.from || null));
  appendQueryParamIfNotEmpty(queryParams, 'to', formatDate(params.to || null));
  const mutationFunction: TypeOfMutationFunction = async (acceptHeader: string) => {
    const url = `ad/top-profitable?${queryParams}&rows=1000`;
    await downloadFile(url, acceptHeader, t);
  };

  const options: UseMutationOptions<void, unknown, string> = {
    onError: () => {
      closableEnqueueSnackbar(t('somethingWentWrong'), 'error');
    },
  };

  const mutation: UseMutationResult<void, unknown, string> = useMutation(
    mutationFunction,
    options,
  );

  return mutation;
};
