import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableColumnInterface } from '../../interfaces/tableColumn.interface';
import BasicTable from './basicTable';
import { HUBSPOT_FORMS_TABLE } from '../../constants/pageIdentifiers';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants/constants';
import { userFormInputs } from '../../constants/modalInputs/userFormInputs';
import DateCell from '../../partials/dateCell';
import { useGetHubspotForms, useGetHubspotFormEmbedData } from '../../apiHooks/queries/useGetHubspotForms';
import BasicDialog from '../../dialogs/basicDialog';
import EmbedFormDialogContent from '../../dialogs/dialogContents/embedFormDialogContent';

const columns: TableColumnInterface[] = [
  {
    field: 'id',
    headerName: 'id',
  },
  {
    field: 'name',
    headerName: 'name',
  },
  {
    field: 'createdAt',
    headerName: 'createdAt',
    cellRenderer: (params: string) => <DateCell params={params} />,
  }, {
    field: 'updatedAt',
    headerName: 'updatedAt',
    cellRenderer: (params: string) => <DateCell params={params} />,
  },
];

const HubspotFormsTable = () => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_ROWS_PER_PAGE);
  const [isEmbedDialogOpen, setIsEmbedDialogOpen] = useState<boolean>(false);
  const [embedId, setEmbedId] = useState<string | null>(null);
  const { t } = useTranslation();

  const {
    data: hubspotFormsData,
    isError: isErrorHubspotFormsData,
    refetch: refetchHubspotFormsData,
    isFetching,
  } = useGetHubspotForms(
    HUBSPOT_FORMS_TABLE,
    rowsPerPage,
    page + 1,
  );

  const {
    data: embedData,
    isFetching: isFetchingEmbedData,
    isError: isErrorEmbedData,
  } = useGetHubspotFormEmbedData();

  return (
    <>
      <BasicDialog
        open={isEmbedDialogOpen}
        closeDialog={() => setIsEmbedDialogOpen(false)}
        title={t('embedYourForm')}
      >
        <EmbedFormDialogContent
          closeDialog={() => setIsEmbedDialogOpen(false)}
          formId={embedId}
          isFetchingEmbedData={isFetchingEmbedData}
          isErrorEmbedData={isErrorEmbedData}
          embedData={embedData?.data?.data}
        />
      </BasicDialog>
      <BasicTable
        data={hubspotFormsData?.data?.data}
        refetch={refetchHubspotFormsData}
        formInputs={userFormInputs}
        setPage={(newPage) => setPage(newPage)}
        setRowsPerPage={(newRowsPerPage) => setRowsPerPage(newRowsPerPage)}
        setIsEmbedDialogOpen={(isOpen) => setIsEmbedDialogOpen(isOpen)}
        onEmbed={(id) => setEmbedId(id)}
        isError={isErrorHubspotFormsData}
        hasNextPage={hubspotFormsData?.data?.data?.hasNextPage}
        borderless
        isFetching={isFetching}
        page={page}
        columns={columns}
        rowsPerPage={rowsPerPage}
      />
    </>
  );
};
export default HubspotFormsTable;
