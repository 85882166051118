import { Grid } from '@mui/material';
import React, { FC } from 'react';
import { FormikValues, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { OrganizationSettingsInterface } from '../../interfaces/organizationSettings.interface';
import {
  useUpdateOrganizationSettings,
} from '../../apiHooks/mutations/organizationMutations/organizationSettingsMutations';
import {
  reportingOrganizationSettingsFormValidationSchema,
} from '../../validations/organizationSettingsFormValidationSchema';
import TextInput from '../../components/inputs/textInput';
import CurrencySelect from '../../components/dropdownSelects/currencySelect';
import PrimaryButton from '../../components/buttons/primaryButton';
import LandingPageSelect from '../../components/dropdownSelects/landingPageSelect';

interface ReportingOrganizationSettingsFormProps {
  organizationSettings: OrganizationSettingsInterface;
  setOrganizationSettings: (_newOrganizationSettings: OrganizationSettingsInterface) => void;
}

const ReportingOrganizationSettingsForm: FC<ReportingOrganizationSettingsFormProps> = (props) => {
  const { t } = useTranslation();
  const {
    mutateAsync: updateOrganizationSettings,
    isLoading: isUpdatingOrganizationSettings,
  } = useUpdateOrganizationSettings();

  const formik: FormikValues = useFormik({
    initialValues: {
      monthsForAdReports: props.organizationSettings?.monthsForAdReports || 1,
      currency: props.organizationSettings?.currency || '',
      landingPage : props.organizationSettings?.landingPage|| '',
    },
    validationSchema: () => reportingOrganizationSettingsFormValidationSchema(t),
    onSubmit: async (values: any) => {
      updateOrganizationSettings(values).then(() => {
        props.setOrganizationSettings({ ...props.organizationSettings, ...values });
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        gap={6}
        sx={{
          display: 'flex', paddingBlock: '1rem', alignItems: 'center',
        }}
      >
        <Grid item sm={12} md={6} lg={2.5}>
          <TextInput
            myKey="monthsForAdReports"
            name="monthsForAdReports"
            value={formik.values.monthsForAdReports}
            label={t('monthsForAdReports')}
            placeholder={t('monthsForAdReports')}
            size="small"
            fullWidth
            textEndAdornment="months"
            type="number"
            onChange={formik.handleChange}
            error={Boolean(formik.touched.monthsForAdReports) && Boolean(formik.errors.monthsForAdReports)}
            helperText={Boolean(formik.touched.monthsForAdReports) && formik.errors.monthsForAdReports}
          />
        </Grid>
        <Grid item sm={12} md={6} lg={2.5}>
          <CurrencySelect
            size="small"
            formik={formik}
          />
        </Grid>
        <Grid item sm={12} md={6} lg={2.5}>
         <LandingPageSelect 
         size="small"
         formik={formik}
         />
        </Grid>
      </Grid>
      <div className="flex w-full justify-end">
        <PrimaryButton type="submit" disabled={isUpdatingOrganizationSettings} dataCy="reporting-org-settings-submit">
          {t('save')}
        </PrimaryButton>
      </div>
    </form>
  );
};

export default ReportingOrganizationSettingsForm;
