import { Button, Tooltip } from '@mui/material';
import React, {
  ChangeEvent, CSSProperties, FC, useState,
} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from 'react-i18next';
import TextInput from './textInput';
import VisibleEyeIcon from '../../assets/icons/visible-icon.svg';
import InvisibleEyeIcon from '../../assets/icons/invisible-icon.svg';

interface PasswordInputInterface {
  id: string,
  myKey: string,
  name: string,
  value: string,
  label: string,
  placeholder: string,
  onChange: (_event: ChangeEvent<HTMLInputElement>) => void,
  size?: 'small' | 'medium',
  error: boolean,
  helperText?: string,
  fullWidth?: boolean,
  isVisibilityToggleable?: boolean,
  hasCopyToClipboard?: boolean,
  style?: CSSProperties,
  dataCy?: string;
}

const PasswordInput: FC<PasswordInputInterface> = (props) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { t } = useTranslation();

  const togglePasswordIsVisible = () => setIsPasswordVisible((prev) => !prev);

  return (
    <TextInput
      id={props.id}
      key={props.myKey}
      name={props.name}
      value={props.value}
      label={props.label}
      type={isPasswordVisible ? 'text' : 'password'}
      placeholder={props.placeholder}
      onChange={props.onChange}
      error={props.error}
      helperText={props.helperText}
      fullWidth={props.fullWidth}
      style={props.style}
      size={props.size}
      InputProps={{ 'data-cy': props.dataCy } as any}
      endAdornment={(
        <div className="flex items-center gap-2">
          {props.isVisibilityToggleable && (
            <Tooltip
              title={isPasswordVisible ? t('hidePassword') : t('showPassword')}
              arrow
              placement="top"
            >
              <Button
                color="primary"
                onClick={togglePasswordIsVisible}
                style={{
                  minWidth: '10px',
                }}
              >
                <div>
                  {isPasswordVisible ? <img src={InvisibleEyeIcon} alt="" /> : <img src={VisibleEyeIcon} alt="" />}
                </div>
              </Button>
            </Tooltip>
          )}
          {props.hasCopyToClipboard && (
            <CopyToClipboard text={props.value}>
              <Tooltip title="Copy to clipboard" arrow placement="top">
                <Button
                  style={{
                    minWidth: '10px',
                  }}
                >
                  <ContentCopyIcon />
                </Button>
              </Tooltip>
            </CopyToClipboard>
          )}
        </div>
      )}
    />
  );
};

export default PasswordInput;
