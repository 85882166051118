import { Grid } from '@mui/material';
import React from 'react';
import { FormikValues, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import TextInput from '../components/inputs/textInput';
import EmailInput from '../components/inputs/emailInput';
import { usersFormValidationSchema } from '../validations/usersFormValidationSchema';
import { UserInterface } from '../interfaces/user.interface';
import { useUpdateUser } from '../apiHooks/mutations/userMutations/userMutations';
import SecondaryButton from '../components/buttons/secondaryButton';
import PrimaryButton from '../components/buttons/primaryButton';

interface UsersFormInterface {
    data?: UserInterface;
    closeDialog: () => void;
}

const UsersForm: React.FC<UsersFormInterface> = (props) => {
  const { mutateAsync: updateUser, isLoading: isUpdatingUser } = useUpdateUser();
  const { t } = useTranslation();

  const formik: FormikValues = useFormik<UserInterface>({
    initialValues: {
      id: props.data?.id,
      firstName: props.data?.firstName || '',
      lastName: props.data?.lastName || '',
      email: props.data?.email || '',
      password: '',
    },
    validationSchema: () => usersFormValidationSchema(t),
    onSubmit: async (values) => {
      await updateUser(values);

      props.closeDialog();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        sx={{
          display: 'flex', columnGap: '3rem', rowGap: '2.5rem', paddingBlock: '1rem',
        }}
      >
        <Grid item xs={12} sm={5}>
          <TextInput
            myKey="firstName"
            name="firstName"
            value={formik.values.firstName}
            label={t('firstName')}
            placeholder={t('firstName')}
            fullWidth
            onChange={formik.handleChange}
            error={Boolean(formik.touched.firstName) && Boolean(formik.errors.firstName)}
            helperText={Boolean(formik.touched.firstName) && formik.errors.firstName}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextInput
            myKey="lastName"
            name="lastName"
            value={formik.values.lastName}
            label={t('lastName')}
            placeholder={t('lastName')}
            fullWidth
            onChange={formik.handleChange}
            error={Boolean(formik.touched.lastName) && Boolean(formik.errors.lastName)}
            helperText={Boolean(formik.touched.lastName) && formik.errors.lastName}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <EmailInput
            id="email"
            myKey="email"
            name="email"
            value={formik.values.email}
            label={t('email')}
            placeholder={t('email')}
            fullWidth
            onChange={formik.handleChange}
            error={Boolean(formik.touched.email) && Boolean(formik.errors.email)}
            helperText={Boolean(formik.touched.email) && formik.errors.email}
            dataCy="user-editor-email"
          />
        </Grid>
      </Grid>
      <div className="ml-auto mt-5 flex w-fit gap-3">
        <SecondaryButton type="button" onClick={props.closeDialog} dataCy="user-editor-cancel">
          {t('cancel')}
        </SecondaryButton>
        <PrimaryButton type="submit" disabled={isUpdatingUser} dataCy="user-editor-submit">
          {t('submit')}
        </PrimaryButton>
      </div>
    </form>
  );
};

export default UsersForm;
