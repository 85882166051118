import React, {
  FC, FormEvent, SyntheticEvent, useState,
} from 'react';
import { FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import AutoCompleteInput from '../inputs/autoCompleteInput';

interface landingPageListInterface {
  value: string;
  label: string;
}

const landingPageList = [
  { value: 'dashboard', label: 'dashboard' },
  { value: 'ad-management/ads', label: 'ads' },
  { value: 'ad-management/ad-groups', label: 'ad-groups' },
  { value: 'ad-management/campaigns', label: 'campaigns' },
  { value: 'ad-management/tracking-sources', label: 'tracking-sources' },
];

interface LandingPageSelectInterface {
  formik?: FormikValues;
  fullWidth?: boolean;
  size?: 'small' | 'medium';
  disabled?: boolean;
}

const LandingPageSelect: FC<LandingPageSelectInterface> = (props) => {
  const initialLangingPage = landingPageList.find(
    (landingPage) => landingPage.value === props.formik?.values?.landingPage,
  );
  const { t } = useTranslation();

  const [selectedValue, setSelectedValue] = useState<landingPageListInterface | null>(initialLangingPage || null);
  const [searchedValue, setSearchedValue] = useState<string>('');

  const onChangeAutoComplete = (
    event: FormEvent,
    newValue: landingPageListInterface,
  ) => {
    if (newValue?.value) {
      setSelectedValue(newValue);
      props.formik?.setFieldValue?.('landingPage', newValue.value);
    } else {
      setSelectedValue(null);
      props.formik?.setFieldValue?.('landingPage', '');
    }
  };

  const onInputChangeAutoComplete = async (
    event: SyntheticEvent,
    value: string,
  ) => {
    setSelectedValue(null);
    setSearchedValue(value);
  };

  return (
    <AutoCompleteInput
      key="landingPage"
      name="landingPage"
      label="landingPage"
      placeholder={t('landingPage')}
      size={props.size}
      style={{ minWidth: 200 }}
      options={landingPageList}
      getOptionLabel={(option: landingPageListInterface) => t(option.label)}
      value={selectedValue}
      onChange={(event: FormEvent, newValue: landingPageListInterface) => onChangeAutoComplete(event, newValue)}
      inputValue={
        (selectedValue?.label ? t(selectedValue.label) : searchedValue) || ''
      }
      onInputChange={async (event: FormEvent, value: string) => {
        await onInputChangeAutoComplete(event, value);
      }}
      isOptionEqualToValue={(
        option: landingPageListInterface,
        value: landingPageListInterface,
      ) => option.value === value.value}
      error={
        Boolean(props.formik?.touched?.currency)
        && Boolean(props.formik?.errors?.currency)
      }
      helperText={
        Boolean(props.formik?.touched?.currency)
        && props.formik?.errors?.currency
      }
      fullWidth={props.fullWidth}
      disabled={props.disabled}
    />
  );
};

export default LandingPageSelect;
