import React, { FC } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import SecondaryButton from '../../components/buttons/secondaryButton';
import PrimaryButton from '../../components/buttons/primaryButton';
import closableEnqueueSnackbar from '../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';
import LoadingSpinner from '../../components/loadingSpinner/loadingSpinner';
import { embedScript } from '../../constants/constants';
import { EmbedDataInterface } from '../../interfaces/hubspot.interface';

interface EmbedFormDialogContentProps {
  closeDialog: () => void,
  isFetchingEmbedData?: boolean,
  isErrorEmbedData?: boolean,
  formId?: string | null,
  embedData?: EmbedDataInterface,
}

const EmbedFormDialogContent: FC<EmbedFormDialogContentProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="mx-auto w-full max-w-[515px]">
      <p className="xs:px-10 text-center text-sm text-primary">
        {t('copyAndPasteEmbedFormCode')}
      </p>
      <div className="mt-[30px] h-[250px] w-full overflow-auto rounded-lg bg-primary/10 p-4">
        {props.isFetchingEmbedData && !props.isErrorEmbedData && <LoadingSpinner center color="primary" />}
        {props.isErrorEmbedData && (
          <p className="text-center text-sm text-primary">
            {t('somethingWentWrong')}
          </p>
        )}
        {!props.isErrorEmbedData && !props.isFetchingEmbedData && !props.formId && (
          <p className="text-center text-sm text-primary">
            {t('noEmbedData')}
          </p>
        )}
        {!props.isFetchingEmbedData && !props.isErrorEmbedData && props.formId && props.embedData && (
          <pre>{embedScript(props.embedData?.region, props.embedData?.portalId, props.formId)}</pre>
        )}
      </div>
      <div className="ml-auto mt-5 flex w-fit">
        <SecondaryButton onClick={props.closeDialog}>
          {t('cancel')}
        </SecondaryButton>
        <CopyToClipboard
          text={(props.formId && props.embedData
            && embedScript(props.embedData?.region, props.embedData?.portalId, props.formId)) || ''}
        >
          <PrimaryButton
            disabled={!props.formId || !props.embedData}
            onClick={() => closableEnqueueSnackbar(t('copySuccessful'), 'success')}
          >
            {t('copy')}
          </PrimaryButton>
        </CopyToClipboard>
      </div>
    </div>
  );
};

export default EmbedFormDialogContent;
