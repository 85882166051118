import { useQuery, UseQueryResult } from 'react-query';
import { Dayjs } from 'dayjs';
import { CORE_API_SUFFIX, dataFetch } from '../../../axios/customAxios';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';
import { appendQueryParamIfNotEmpty, formatDate, generateQueryKey } from '../../../constants/hookConstants';

type OperatingSystemInterface = {
        operatingSystem: string,
        count: number,
}[]

const useGetOperatingSystem = (
  identifier: string,
  isEnabled: boolean,
  adCode: string,
  from: Dayjs | null,
  to: Dayjs | null,
): UseQueryResult<ApiResponse<OperatingSystemInterface>, ApiError<OperatingSystemInterface>> => {
  const queryParams = new URLSearchParams();
  appendQueryParamIfNotEmpty(queryParams, 'from', formatDate(from));
  appendQueryParamIfNotEmpty(queryParams, 'to', formatDate(to));

  const queryKey = generateQueryKey(identifier, queryParams);

  return useQuery<ApiResponse<OperatingSystemInterface>, ApiError<OperatingSystemInterface>>(
    [`latest_operatingSystems_${queryKey}`],
    () => dataFetch.get(`${CORE_API_SUFFIX}form-submission/operating-systems/${adCode}?${queryParams}`),
    {
      enabled: isEnabled,
    },
  );
};

export default useGetOperatingSystem;
