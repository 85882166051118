import React, {
  FC, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import {
  parseField, RenderEmptyTable, RenderError, RenderLoader,
} from '../components/tables/tableHelpers/tableHelpers';
import { TableColumnInterface } from '../interfaces/tableColumn.interface';
import LockedWrapper from '../lockedContent/lockedWrapper';
import { useOrganizationSettingsStore } from '../store/organizationSettingsStore';
import OrganizationType from '../components/enums/organizationTypesEnum';

interface AdsReportTableProps {
  columns?: TableColumnInterface[];
  fixedColumns?: TableColumnInterface[];
  data?: any[];
  isError?: boolean;
  isFetching?: boolean;
  navigateToAd?: (_adId: number) => void;
  disabledFields?: string[];
}

const AdsReportTable: FC<AdsReportTableProps> = (props) => {
  const { t } = useTranslation();
  const tableRef = useRef<HTMLTableElement>(null);
  const [showBoxShadow, setShowBoxShadow] = useState(false);
  const organizationType = useOrganizationSettingsStore((state) => state.organizationSettings.organizationType);

  const COLUMN_LENGTH = props.columns?.length || 5;

  useEffect(() => {
    const handleScroll = () => {
      if (tableRef.current) {
        setShowBoxShadow(tableRef.current?.scrollLeft > 5);
      }
    };
    tableRef.current?.addEventListener('scroll', handleScroll);
  }, []);

  const renderHeaders = (column: TableColumnInterface) => (
    <th
      key={column.field}
      className={`
        ${column.field === 'status' ? 'min-w-[50px] max-w-[50px]'
        : 'w-full'}
        ${column.field === 'name' ? ' border-r border-dark pl-2' : ''}
        ${column.field === 'referrer' ? 'border-r border-dark pl-4 2xl:min-w-[251px]' : ''}
        grid w-full content-center bg-primary/10 
      `}
    >
      <p className="w-full truncate uppercase text-primary">
        {t(column.headerName)}
      </p>
    </th>
  );

  const renderTableContent = (item: any, index: number, columns?: TableColumnInterface[]) => (
    <tr
      key={crypto.randomUUID()}
      className={`group flex w-full min-w-[50px] text-center text-xs text-primary duration-100 
      ${props.navigateToAd ? 'hover:cursor-pointer' : ''}`}
    >
      {columns?.map((column: TableColumnInterface) => {
        const itemToRender = parseField(column.field, item);

        return (
          <td
            key={crypto.randomUUID()}
            className={`
              ${index % 2 !== 0 ? 'bg-[#FAFAFA]' : 'bg-white'} 
              ${column.field === 'status'
              ? ' -mr-2 min-w-[50px] max-w-[50px]'
              : 'min-w-[45px] px-4 '
               }
              ${column.field === 'name' ? 'border-r border-r-dark' : ''}
              ${column.field === 'referrer' ? 'border-r border-dark pl-4 text-left' : ''}
              ${props.columns?.includes(column) || column.field === 'status' ? 'justify-center' : ''}
              flex h-[44px] w-full items-center truncate border-b border-dark
                 group-hover:bg-[#F5F5F5] group-active:bg-primary/10 
              `}
            onClick={() => {
              if (column.field !== 'status') {
                props.navigateToAd?.(item.adId);
              }
            }}
          >
            <LockedWrapper
              key={crypto.randomUUID()}
              disableLockedWrapper={!props.disabledFields?.includes(column.field)}
            >
              {column.cellRenderer?.(itemToRender, item) || (
              <Tooltip
                arrow
                placement="top"
                title={itemToRender}
              >
                <span>
                  {itemToRender}
                </span>
              </Tooltip>
              )}
            </LockedWrapper>
          </td>
        );
      })}
    </tr>
  );

  return (
    <div className="flex">
      <table
        className={`${props.columns?.length === 2 || props.columns?.length === 3 ? 'w-1/2' : ''} z-50 duration-200`}
        style={{
          boxShadow: showBoxShadow ? '6px -2px 4px -4px rgba(12, 72, 94, 0.25)' : 'none',
        }}
      >
        <thead className="w-full">
          <tr className="relative flex h-[38px] w-full text-center text-xs font-semibold text-primary">
            {props.fixedColumns?.map((column: TableColumnInterface) => renderHeaders(column))}
          </tr>
        </thead>
        <tbody className="w-full">
          {!props.isFetching
            && !props.isError
            && props.data?.map((item: any, index: number) => (
              renderTableContent(item, index, props.fixedColumns)
            ))}
        </tbody>

        {!props.isFetching
          && !props.isError
          && !props.data?.length && (
            <RenderEmptyTable columnLength={COLUMN_LENGTH} />
        )}
      </table>
      <table
        ref={tableRef}
        className={`
          ${props.columns?.length === 2 ? 'w-full' : 'w-full'}
          flex flex-col
          `}
      >
        <thead className="w-full">
          <tr className="relative flex h-[38px] w-full text-center text-xs font-semibold text-primary">
            {props.columns?.map((column: TableColumnInterface) => renderHeaders(column))}
          </tr>
        </thead>
        <tbody className="w-full">
          {!props.isFetching
          && !props.isError
          && props.data?.map((item: any, index: number) => (
            renderTableContent(item, index, props.columns)
          ))}
        </tbody>
      </table>

      {props.isError && <RenderError columnLength={COLUMN_LENGTH} />}

      {props.isFetching && <RenderLoader columnLength={COLUMN_LENGTH} />}
    </div>
  );
};

export default AdsReportTable;
