import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import closableEnqueueSnackbar from '../../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';
import { useIsTwoFaEnabledStore } from '../../../store/twoFaStore';

interface SendTwoFaCodeInterface {
  '2fa_code' : string,
}

export const useSendTwoFaCode = () => {
  const { t } = useTranslation();
  const { setTwoFaEnabled } = useIsTwoFaEnabledStore();

  return useMutation<ApiResponse<SendTwoFaCodeInterface>, ApiError<null>, string>(
    (twoFaCode) => dataFetch.post(
      `${DIGGER_API_SUFFIX}users/verify2fa`,
      {
        '2fa_code': twoFaCode,
      },
    ),
    {
      onSuccess: () => {
        setTwoFaEnabled(true);
        closableEnqueueSnackbar(t('twoFaSuccessfullyActivated'), 'success');
      },
      onError: () => {
        closableEnqueueSnackbar(t('somethingWentWrong'), 'error');
      },
    },
  );
};
