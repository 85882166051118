import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import closableEnqueueSnackbar from '../../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';
import { handleApiError } from '../../requestHelpers/requestHelpers';

export const useUpdateCampaign = () => {
  const { t } = useTranslation();
  return useMutation(
    (postRequest: any) => dataFetch[postRequest.id ? 'put' : 'post'](
      `${DIGGER_API_SUFFIX}campaigns${postRequest.id ? (`/${postRequest.id}`) : ''}`,
      {
        name: postRequest.name,
        trackingSourceId: postRequest.trackingSourceId,
        campaignCode: postRequest.campaignCode,
        conversionValue: postRequest.conversionValue,
      },
    ),
    {
      onSuccess: () => {
        closableEnqueueSnackbar(t('CampaignCreatedSuccesfully'), 'success');
      },
      onError: (error: any) => {
        handleApiError(error, t);
      },
    },
  );
};

export const useDeleteCampaign = () => {
  const { t } = useTranslation();

  return useMutation(
    (id: number) => dataFetch.delete(
      `${DIGGER_API_SUFFIX}campaigns/${id}`,
    ),
    {
      onSuccess: () => {
        closableEnqueueSnackbar(t('campaignDeletedSuccessfully'), 'success');
      },
      onError: () => {
        closableEnqueueSnackbar(t('somethingWentWrong'), 'error');
      },
    },
  );
};
