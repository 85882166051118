import React, { FC } from 'react';
import { Switch, SwitchProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Colors } from '../../constants/constants';

interface SwitchInputInterface extends SwitchProps {

}

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 36,
  height: 18,
  padding: 0,
  display: 'flex',
  '& .MuiSwitch-switchBase': {
    padding: 3,
    color: Colors.primary,
    '&.Mui-checked': {
      transform: 'translateX(19px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#4C956C',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 12,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: 'white',
  },
  '& .MuiSwitch-track': {
    borderRadius: 18 / 2,
    opacity: 1,
    backgroundColor: '#D9D9D9',
    boxSizing: 'border-box',
  },
}));

const SwitchInput: FC<SwitchInputInterface> = (props) => (
  <AntSwitch
    {...props}
  />
);

export default SwitchInput;
