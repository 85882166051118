import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import adsTrackedIcon from '../../assets/icons/adsTrackedIcon.svg';
import revenueTrackedIcon from '../../assets/icons/revenueTrackedIcon.svg';
import leadsAttributeIcon from '../../assets/icons/leadsAttributeIcon.svg';
import infoIcon from '../../assets/icons/infoIconProvider.svg';
import LoadingSpinner from '../loadingSpinner/loadingSpinner';

interface AdProviderInterface {
    stats: number;
    tracked?: boolean;
    revenue?: boolean;
    leads?: boolean;
    isLoading?: boolean;
}

const ProviderNumberBoxes : FC<AdProviderInterface> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="flex h-[140px] w-1/3  items-start justify-between rounded-xl border border-[#CEDADF] bg-[#F5F5F5] p-6">
      <div className="flex h-full flex-col justify-between">
        {
              props.isLoading
                ? <LoadingSpinner color="primary" size={25} />
                : <h4 className="text-3xl font-medium text-primary">{props.stats.toLocaleString()}</h4>
        }
        {props.tracked && (
        <div className="flex items-center gap-3">
          <img src={adsTrackedIcon} alt="" />
          <p className="text-base text-[#6D919E]">{t('adTracked')}</p>
        </div>
        )}
        {props.revenue && (
        <div className="flex items-center gap-3">
          <img src={revenueTrackedIcon} alt="" />
          <p className="text-base text-[#6D919E]">{t('revenueTracked')}</p>
        </div>
        )}
        {props.leads && (
        <div className="flex items-center gap-3">
          <img src={leadsAttributeIcon} alt="" />
          <p className="text-base text-[#6D919E]">{t('leadAttributed')}</p>
        </div>
        )}
      </div>
      <img className="hover:cursor-pointer" src={infoIcon} alt="i" />
    </div>
  );
};

export default ProviderNumberBoxes;
