import React, { FC, ReactNode } from 'react';
import Tooltip from '@mui/material/Tooltip';
import LoadingSpinner from '../../loadingSpinner/loadingSpinner';
import EmptyBigCard from './emptyBigCard';
import InfoIcon from '../../../assets/icons/info-icon.svg';

interface DashboardBigCardProps {
  title: string;
  isLoading?: boolean;
  isError?: boolean;
  children: ReactNode;
  info?: string;
}

const DashboardBigCard: FC<DashboardBigCardProps> = (props) => (
  <div className="flex h-full flex-col text-clip rounded-[8px]">
    <div className="mb-4 flex max-h-[47px] items-center justify-start gap-4 rounded-t-[8px] border-b px-5 py-2">
      <h2 className="text-[18px] font-[500] text-primary">
        {props.title}
      </h2>
      <div>
        <Tooltip title={props.info}>
          <img src={InfoIcon} alt="" className="max-w-[20px]" />
        </Tooltip>
      </div>
    </div>
    <div className="flex flex-1 flex-col px-[20px]">
      {!props.isError && !props.children && (
        <div className="flex h-full -translate-y-[40px] flex-col items-center justify-center gap-4 text-center">
          {!props.isLoading ? (
            <EmptyBigCard />
          ) : (
            <LoadingSpinner center color="primary" />
          )}
        </div>
      )}
      {!props.isError && !props.isLoading && props.children && (
        props.children
      )}
      {props.isError && (
        <p> There was an error retrieving the data </p>
      )}
    </div>
  </div>
);
export default DashboardBigCard;
