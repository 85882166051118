import React, { FC } from 'react';

interface PrimaryButtonProps extends React.HTMLProps<HTMLButtonElement> {
  children?: React.ReactNode;
  type?: 'button' | 'submit' | 'reset' | undefined;
  dataCy?: string;
  ref ?: any;
  onMouseDown ?: any;
}

const SecondaryButton: FC<PrimaryButtonProps> = (props) => (
  <button
    className={`
       h-[40px] rounded-[8px] px-[25px] text-primary duration-200 hover:bg-[#9992] hover:opacity-95
       ${props.disabled ? 'cursor-default opacity-50 hover:opacity-50' : 'cursor-pointer'}
     `}
    style={props.style}
    ref={props.ref}
    onClick={props.onClick}
    type={props.type !== undefined ? props.type : 'button'}
    disabled={props.disabled}
    data-cy={props.dataCy}
    onMouseDown={props.onMouseDown}
  >
    {props.children}
  </button>
);

export default SecondaryButton;
