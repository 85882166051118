import { useQuery } from 'react-query';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';

const useSendLoginHours = (isLoggedIn: boolean) => useQuery(
  ['loginHours'],
  () => isLoggedIn && dataFetch.post(`${DIGGER_API_SUFFIX}login-tracker`).then((res) => res.data),
  {
    refetchInterval: 5 * 60 * 1000,
  },
);
export default useSendLoginHours;
