import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import closableEnqueueSnackbar from '../../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';
import { handleApiError } from '../../requestHelpers/requestHelpers';

interface UpdateOrganizationSettingsRequest {
    costVAT?: number;
    incomeVAT?: number;
    isPageViewsEnabled?: true;
    monthsForAdReports?: number;
    microsoftAccountId?: number;
    currency: string;
    landingPage: string;
    facebookCustomerId: string;
}

export const useUpdateOrganizationSettings = () => {
  const { t } = useTranslation();
  return useMutation(
    (postRequest: UpdateOrganizationSettingsRequest) => dataFetch.patch(
      `${DIGGER_API_SUFFIX}organization-settings`,
      {
        costVAT: postRequest.costVAT,
        incomeVAT: postRequest.incomeVAT,
        isPageViewsEnabled: postRequest.isPageViewsEnabled,
        monthsForAdReports: postRequest.monthsForAdReports,
        microsoftAccountId: postRequest.microsoftAccountId,
        currency: postRequest.currency,
        landingPage: postRequest.landingPage,
        facebookCustomerId: postRequest.facebookCustomerId,
      },
    ),
    {
      onSuccess: () => {
        closableEnqueueSnackbar(t('organizationSettingsUpdatedSuccessfully'), 'success');
      },
      onError: (error: any) => {
        handleApiError(error, t);
      },
    },
  );
};
