import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useConversionStepsStore, useOtherConversionStore } from '../../store/conversionStepsStore';

const ConversionName = () => {
  const { t } = useTranslation();
  const { currentStep, setCurrentStep } = useConversionStepsStore();
  const { updateName } = useOtherConversionStore((state) => state);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      conversionName: '',
    },
    validationSchema: Yup.object({
      conversionName: Yup.string().required('Conversion name is required'),
    }),
    onSubmit: (values) => {
      if (formik.values.conversionName !== '') {
        updateName(values.conversionName);
        setCurrentStep(currentStep + 1);
        navigate('/tracking/google-conversions/other-conversions/value', { replace: true });
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="w-full">
      <div className="mb-4 flex h-[50px] w-full flex-col justify-around border-b">
        <p className="text-lg font-semibold text-primary">Conversion Name</p>
      </div>
      <div className="flex h-[110px] w-[420px] flex-col justify-center rounded-lg border p-3">
        <p className="mb-3 text-xs text-[#9EB6BF]">Enter a conversion name</p>
        <input
          type="text"
          id="conversionName"
          name="conversionName"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.conversionName}
          placeholder="Conversion name"
          className={`rounded-lg border ${formik.touched.conversionName && formik.errors.conversionName ? 'border-red-500' : 'border-primary'} p-2 text-sm text-primary outline-none`}
        />
      </div>
      {formik.touched.conversionName && formik.errors.conversionName ? (
        <div className="ml-2 mt-1 text-xs text-red">{formik.errors.conversionName}</div>
      ) : null}
      <div className="flex w-full justify-end">
        <button
          type="submit"
          className="mt-4 rounded bg-primary px-4 py-2 text-white"
          disabled={!formik.isValid}
        >
          Continue
        </button>
      </div>
    </form>
  );
};

export default ConversionName;
