import { TFunction } from 'i18next';
import closableEnqueueSnackbar from '../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';
import { ApiError } from '../../interfaces/apiResponse.type';

export const handleAuthError = (err: ApiError<null>, t: TFunction) => {
  closableEnqueueSnackbar(t(err.response?.data.status.message) || t('somethingWentWrong'), 'error');
};

export const handleApiError = (err: ApiError<null>, t: TFunction) => {
  if (err?.response?.status === 404) {
    closableEnqueueSnackbar(t('somethingWentWrong'), 'error');
    return err;
  }
  const errorMessage = err.response?.data.status.message;

  if (!errorMessage) {
    closableEnqueueSnackbar(t('somethingWentWrong'), 'error');
    return err;
  }

  if (Array.isArray(errorMessage)) {
    errorMessage.forEach((message: string) => {
      closableEnqueueSnackbar(t(message), 'error');
    });
  } else {
    closableEnqueueSnackbar(t(errorMessage), 'error');
  }

  return err;
};
