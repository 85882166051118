import React from 'react';
import GenerateToken from '../../../components/generateToken/generateToken';
import DeveloperTable from '../../../components/tables/developerTable/developerTable';
import DeveloperLegends from '../../../components/DeveloperLegend/developerLegends';

const DeveloperConsole = () => (
  <div>
    <div className="flex items-center justify-between gap-4">
      <GenerateToken />
      <DeveloperLegends />
    </div>
    <DeveloperTable />
  </div>
);

export default DeveloperConsole;
