import React, {
  FC, FormEvent, SyntheticEvent, useEffect, useState,
} from 'react';
import { FormikValues } from 'formik';
import _ from 'lodash';
import AutoCompleteInput from '../inputs/autoCompleteInput';
import { CampaignInterface } from '../../interfaces/campaign.interface';
import { CAMPAIGN_OPTIONS, SELECTED_CAMPAIGN_OPTION } from '../../constants/pageIdentifiers';
import { useGetCampaignById, useGetCampaignOptions } from '../../apiHooks/queries/adManagement/useGetCampaigns';

interface CampaignSelectInterface {
  identifier: string;
  formik?: FormikValues,
  fullWidth?: boolean,
  size?: 'small' | 'medium',
  selectedCampaignId?: string,
  setSelectedCampaignId?: (_newSelectedCampaignId: string) => void,
  disabled?: boolean
}

const CampaignSelect: FC<CampaignSelectInterface> = (props) => {
  const { data: selectedCampaignData } = useGetCampaignById(
    `${SELECTED_CAMPAIGN_OPTION}_${props.identifier}`,
    props.selectedCampaignId,
  );

  const [
    selectedValue,
    setSelectedValue,
  ] = useState<CampaignInterface | null>(selectedCampaignData?.data || null);
  const [searchedValue, setSearchedValue] = useState<string>('');

  const {
    data: campaignOptionsData,
    refetch: refetchCampaignOptionsData,
    isFetching: isRefetchingCampaignOptionsData,
  } = useGetCampaignOptions(CAMPAIGN_OPTIONS, searchedValue);

  useEffect(() => {
    if (selectedCampaignData?.data) {
      setSelectedValue(selectedCampaignData?.data);
    }
  }, [selectedCampaignData?.data]);

  const debouncedRefetchCampaignsData = _.debounce(refetchCampaignOptionsData, 500);

  const onChangeAutoComplete = (event: FormEvent, newValue: CampaignInterface) => {
    if (newValue?.id) {
      setSelectedValue(newValue);
      props.formik?.setFieldValue?.('campaignId', newValue.id);
      props.setSelectedCampaignId?.(JSON.stringify(newValue.id));
      setSearchedValue('');
    } else {
      setSelectedValue(null);
      props.formik?.setFieldValue?.('campaignId', '');
      props.setSelectedCampaignId?.('');
      setSearchedValue('');
    }
  };

  const onInputChangeAutoComplete = async (event: SyntheticEvent, value: string) => {
    setSelectedValue(null);
    setSearchedValue(value);
    await debouncedRefetchCampaignsData();
  };

  return (
    <AutoCompleteInput
      key="campaignId"
      name="campaignId"
      label="Campaign"
      placeholder="Campaign"
      size={props.size}
      style={{ minWidth: 212 }}
      options={campaignOptionsData?.data ? campaignOptionsData.data : []}
      getOptionLabel={(option: CampaignInterface) => option?.name || ''}
      value={selectedValue}
      loading={isRefetchingCampaignOptionsData}
      onChange={(event: FormEvent, newValue: CampaignInterface) => onChangeAutoComplete(event, newValue)}
      inputValue={selectedValue?.name || searchedValue}
      onInputChange={async (event: FormEvent, value: string) => {
        await onInputChangeAutoComplete(event, value);
      }}
      isOptionEqualToValue={(option: CampaignInterface, value: CampaignInterface) => (option.id === value.id)}
      error={Boolean(props.formik?.touched.campaignId) && Boolean(props.formik?.errors.campaignId)}
      helperText={Boolean(props.formik?.touched.campaignId) && props.formik?.errors.campaignId}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
    />
  );
};

export default CampaignSelect;
