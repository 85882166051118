import { useQuery, UseQueryResult } from 'react-query';
import { CORE_API_SUFFIX, dataFetch } from '../../../axios/customAxios';
import { generateQueryKey } from '../../../constants/hookConstants';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';

type LatestFormSubmissionsDataType = {
    id: string;
    adCode: string;
    sessionId: string;
    path: string;
    referrer: string;
    browser: string;
    createdAt: string;
    status: number;
    income: number;
    organizationId: number;
    incomeAt: string;
    originalReferrer: string;
    hubspotContactId: number;
}

export interface LatestFormSubmissionsResponse {
    data: LatestFormSubmissionsDataType[];
}

const useGetLatestFormSubmissions = (
  identifier: string,
): UseQueryResult<ApiResponse<LatestFormSubmissionsResponse>, ApiError<LatestFormSubmissionsResponse>> => {
  const queryParams = new URLSearchParams();

  const queryKey = generateQueryKey(identifier, queryParams);

  return useQuery<ApiResponse<LatestFormSubmissionsResponse>, ApiError<LatestFormSubmissionsResponse>>(
    [`latest_submissions_${queryKey}`],
    () => dataFetch.get(`${CORE_API_SUFFIX}form-submission/latest-ten-today`),
  );
};

export default useGetLatestFormSubmissions;
