import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import SimpleTabs from '../../components/tabs/simpleTabs';
import { CustomButton } from '../../components/buttons/customButton';
import { useAuthStore } from '../../store/authStore';

enum ProfileTabLinks {
  profile,
  password,
  authentication,
}

const ProfilePage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentTab = location.pathname.split('/').pop();
  const activePanel = ProfileTabLinks[currentTab as keyof typeof ProfileTabLinks] || 0;

  return (
    <div>
      <div className="rounded-lg border bg-white px-[30px] py-[25px]">
        <SimpleTabs
          currentTab={activePanel}
          tabs={[
            {
              tabLabel: t('profile') || '',
              href: 'profile',
              identifierName: 'profile-panel',
            },
            {
              tabLabel: t('password') || '',
              href: 'password',
              identifierName: 'password-panel',
            },
            {
              tabLabel: t('security') || '',
              href: 'authentication',
              identifierName: 'authentication-panel',
            },
          ]}
        />

      </div>
    </div>
  );
};

export default ProfilePage;
