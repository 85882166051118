import { Grid } from '@mui/material';
import React, { FC } from 'react';
import { FormikValues, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import TextInput from '../../components/inputs/textInput';
import {
  calculationsOrganizationSettingsFormValidationSchema,
} from '../../validations/organizationSettingsFormValidationSchema';
import { OrganizationSettingsInterface } from '../../interfaces/organizationSettings.interface';
import {
  useUpdateOrganizationSettings,
} from '../../apiHooks/mutations/organizationMutations/organizationSettingsMutations';
import { CustomButton } from '../../components/buttons/customButton';

interface CalculationsOrganizationSettingsFormProps {
  organizationSettings: OrganizationSettingsInterface;
  setOrganizationSettings: (_newOrganizationSettings: OrganizationSettingsInterface) => void;
}

const CalculationsOrganizationSettingsForm: FC<CalculationsOrganizationSettingsFormProps> = (props) => {
  const { t } = useTranslation();
  const {
    mutateAsync: updateOrganizationSettings,
    isLoading: isUpdatingOrganizationSettings,
  } = useUpdateOrganizationSettings();

  const formik: FormikValues = useFormik({
    initialValues: {
      costVAT: props.organizationSettings?.costVAT || '',
      incomeVAT: props.organizationSettings?.incomeVAT || '',
    },
    validationSchema: () => calculationsOrganizationSettingsFormValidationSchema(t),
    onSubmit: async (values: any) => {
      updateOrganizationSettings(values).then(() => {
        props.setOrganizationSettings({ ...props.organizationSettings, ...values });
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        gap={6}
        sx={{
          display: 'flex', paddingBlock: '1rem', alignItems: 'center',
        }}
      >
        <Grid item sm={12} md={6} lg={2.5}>
          <TextInput
            myKey="costVAT"
            name="costVAT"
            value={formik.values.costVAT}
            label={t('costVAT')}
            placeholder={t('costVAT')}
            size="small"
            fullWidth
            textEndAdornment="%"
            type="number"
            onChange={formik.handleChange}
            error={Boolean(formik.touched.costVAT) && Boolean(formik.errors.costVAT)}
            helperText={Boolean(formik.touched.costVAT) && formik.errors.costVAT}
          />
        </Grid>
        <Grid item sm={12} md={6} lg={2.5}>
          <TextInput
            myKey="incomeVAT"
            name="incomeVAT"
            value={formik.values.incomeVAT}
            label={t('incomeVAT')}
            placeholder={t('incomeVAT')}
            size="small"
            fullWidth
            textEndAdornment="%"
            type="number"
            onChange={formik.handleChange}
            error={Boolean(formik.touched.incomeVAT) && Boolean(formik.errors.incomeVAT)}
            helperText={Boolean(formik.touched.incomeVAT) && formik.errors.incomeVAT}
          />
        </Grid>
      </Grid>
      <div className="flex w-full justify-end">
        <CustomButton type="submit" disabled={isUpdatingOrganizationSettings} dataCy="calc-org-settings-submit">
          {t('save')}
        </CustomButton>
      </div>
    </form>
  );
};

export default CalculationsOrganizationSettingsForm;
