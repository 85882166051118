import { useTranslation } from 'react-i18next';
import { dataFetch } from './customAxios';
import closableEnqueueSnackbar from '../components/closableEnqueueSnackbar/closableEnqueueSnackbar';
import { useAuthStore } from '../store/authStore';

const axiosInterceptor = () => {
  const { t } = useTranslation();
  const { logout } = useAuthStore();

  dataFetch.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401) {
        logout();
        closableEnqueueSnackbar(t('sessionExpired'), 'error');
      }
      return Promise.reject(error);
    },
  );
};

export default axiosInterceptor;
