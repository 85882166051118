import React, {
  forwardRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useOrganizationSettingsStore, useOrganizationStore } from '../../store/organizationSettingsStore';
import profileIcon from '../../assets/icons/sidebar/myProfileIcon_sidebar.svg';
import passwordIcon from '../../assets/icons/sidebar/myPassowrdIcon_sidebar.svg';
import securityIcon from '../../assets/icons/sidebar/mySecurityIcon_sidebar.svg';
import logoutIcon from '../../assets/icons/sidebar/logoutIcon_sidebar.svg';
import { useAuthStore } from '../../store/authStore';

export interface Organization {
  orgId: string;
  orgName: string;
}

interface OrganizationDropdownListProps {
  isOpen: boolean;
  setIsOpen: (_open: boolean) => void;
  organizations: { data: { data: Organization[] } } | undefined;
  switchOrganization: (_orgId: string) => void;
}

const OrganizationDropdownList = forwardRef<HTMLDivElement, OrganizationDropdownListProps>((props, ref) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const setSelectedOrganization = useOrganizationStore((state) => state.setSelectedOrganization);
  const { organizationSettings } = useOrganizationSettingsStore((state) => state);
  const { logout } = useAuthStore();

  const hasOrganization = props.organizations && props.organizations?.data?.data.length > 0;

  const handleSelect = (organization: Organization) => {
    props.switchOrganization(organization.orgId);
    props.setIsOpen(false);
    setSelectedOrganization(organization);
  };

  const handleNavigate = (url: string) => {
    props.setIsOpen(false);
    navigate(url);
  };

  if (!props.isOpen) {
    return null;
  }

  return (
    <div ref={ref} className="left-50 fixed bottom-0 z-50 mb-4 flex h-[300px] items-center rounded-md border bg-white drop-shadow-md">
      <div className="flex h-full w-[340px] flex-col justify-between py-1 text-primary">
        <div>
          <div className="border-b-gray-300 mx-4 flex h-[42px] items-center border-b text-sm font-medium text-primary">
            {t('personalSettings')}
          </div>
          <div onClick={() => handleNavigate('/profile-page/profile')} className=" flex rounded-xl px-4 py-2 hover:cursor-pointer hover:bg-[#CEDADF]">
            <img src={profileIcon} className="mr-2" />
            {t('myProfile')}
          </div>
          <div onClick={() => handleNavigate('/profile-page/password')} className="flex rounded-xl px-4 py-2 hover:cursor-pointer hover:bg-[#CEDADF]">
            <img src={passwordIcon} className="mr-2" />
            {t('myPassword')}
          </div>
          <div onClick={() => handleNavigate('/profile-page/authentication')} className="flex rounded-xl px-4 py-2 hover:cursor-pointer hover:bg-[#CEDADF]">
            <img src={securityIcon} className="mr-2" />
            {t('mySecurity')}
          </div>
        </div>
        <div onClick={() => logout()} className="border-t-gray-300 mx-4 flex h-[42px] items-center border-t hover:cursor-pointer">
          <img src={logoutIcon} className="mr-2" />
          {t('Logout')}
        </div>
      </div>
      {hasOrganization
      && (
      <div className="h-full w-[320px] rounded-md border-l bg-white">
        <div className="h-full w-full py-1">
          <div className="border-b-gray-300 mx-4 flex h-[42px] items-center border-b text-sm font-medium text-primary">
            {t('switchOrganization')}
          </div>
          <div className="mx-4 flex items-center justify-between border-b border-b-[#CEDADF] py-2 text-xs text-[#3D6D7E]">
            <p>{organizationSettings?.name}</p>
            <p>{t('signedIn')}</p>
          </div>
          {props.organizations?.data?.data.map((org, index) => (
            <button
              key={org.orgId}
              onClick={() => handleSelect(org)}
              className="flex h-[42px] w-full items-center gap-2 rounded-xl bg-white px-4 text-left text-sm text-primary hover:bg-[#CEDADF]"
            >
              <div>{org.orgName}</div>
              <div className="font-light">
                #
                {org.orgId}
              </div>
            </button>
          ))}
        </div>
      </div>
      )}
    </div>
  );
});

export default OrganizationDropdownList;
