import * as yup from 'yup';
import { TFunction } from 'i18next';

export const HubspotContactSettingsFormValidationSchema = (t: TFunction) => yup.object({
  amount: yup
    .string()
    .required(t('inputIsRequired') ?? ''),
  status: yup
    .string()
    .required(t('inputIsRequired') ?? ''),
});

export const HubspotContactSettingsContentFormValidationSchema = (t: TFunction) => yup.object({
  label: yup.string().required(t('fieldNameIsRequired') ?? ''),
});
