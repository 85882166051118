import React, { FC } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar/AppBar';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import AccountMenu from '../accountMenu/accountMenu';
import LanguageMenu from '../languageMenu/languageMenu';
import { drawerWidth } from '../../constants/constants';
import { useOrganizationSettingsStore } from '../../store/organizationSettingsStore';
import { CustomButton } from '../buttons/customButton';
import { DiggerPlanEnum } from '../enums/DiggerPlanEnum';
import OrganizationDropdown from '../organizationDropdown/organizationDropdown';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({
  theme,
  open,
}) => ({
  zIndex: 1300,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface HeaderProps {
  open: boolean,
}

const Header: FC<HeaderProps> = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const routeSegments = location.pathname.split('/');
  const pageName = routeSegments[2] ? routeSegments[2] : routeSegments[1];
  const { organizationSettings } = useOrganizationSettingsStore();
  document.title = `Digtective - ${t(pageName)}`;

  return (
    <AppBar
      position="fixed"
      open={props.open}
      sx={{
        width: `calc(100% - ${props.open ? drawerWidth : '80px'})`,
        backgroundColor: 'white',
        color: '#0C485E',
        boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.00)',
      }}
    >
      <Toolbar style={{
        paddingLeft: '0.5rem',
        minHeight: '64px',
        maxWidth: '2000px',
      }}
      >
        <Typography variant="h6" noWrap component="div" className="flex items-center gap-5 pl-4">
          <p className="font-[600] ">
            {pageName && t(pageName) }
          </p>
        </Typography>
        <div className="relative ml-auto mr-5 flex items-center gap-4">
          {organizationSettings.productId && (
            organizationSettings.productId === DiggerPlanEnum.lite
                || organizationSettings.productId === DiggerPlanEnum.standard
          ) ? (
            <CustomButton
              variant="gradient"
              type="button"
              onClick={() => navigate('settings/subscription')}
            >
              {t('upgrade')}
            </CustomButton>
            ) : null}
          <LanguageMenu />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
