import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import AdsTable from '../../../components/tables/adsTable';
import InfoboxContainer from '../../../components/infoBox/InfoboxContainer';

const Ads = () => {
  const [searchParams] = useSearchParams();
  const [selectedAdGroupId, setSelectedAdGroupId] = useState<string>(
    searchParams.get('adGroupId') || '',
  );

  return (
    <>
      <InfoboxContainer
        groupingType="ads"
      />
      <AdsTable
        selectedAdGroupId={selectedAdGroupId}
        setSelectedAdGroupId={setSelectedAdGroupId}
      />
    </>
  );
};

export default Ads;
