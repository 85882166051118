import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import greenTick from '../../assets/icons/trackingGreenTick.svg';
import redX from '../../assets/icons/trackingRedX.svg';
import { CustomButton } from '../buttons/customButton';
import RoundedCell from '../../partials/roundedCell';
import { formatDateAndTime } from '../../constants/constants';

interface TrackingCodeInterface {
    open?: (_code: string) => void;
    cancel?: (_cancelCode: string) => void;
    code: string;
    count: number;
    createdAt: string;
    id: number;
    setId: (_id : number) => void;
}

const TrackingCodeComponent : FC<TrackingCodeInterface> = (props) => {
  const { t } = useTranslation();

  const handleClick = () => {
    if (props.open) {
      props.open(props.code);
    }
    if (props.setId) {
      props.setId(props.id);
    }
  };

  const handleCancelClick = () => {
    if (props.cancel) {
      props.cancel(props.code);
    }
    if (props.setId) {
      props.setId(props.id);
    }
  };

  return (
    <div className="flex w-full justify-center border-b">
      <div className="flex h-[69px] w-[95%] items-center justify-between  text-sm text-primary">
        <p className="flex w-[30%] justify-start pr-6 text-sm font-light">
          {props.code}
        </p>
        <p className="flex w-[30%] justify-start text-sm font-light">
          {formatDateAndTime(props.createdAt)}
        </p>
        <p className="flex w-[24%] justify-start text-sm font-light">
          <RoundedCell params={props.count} />
        </p>
        <div className="flex w-[16%] items-center justify-start ">
          <div className="flex w-[170px] justify-start gap-4">
            <CustomButton
              onClick={handleClick}
              className="h-[28px] py-1 text-[10px] text-green"
              variant="outline"
              type="button"
            >
              <img src={greenTick} alt="tick icon" className="h-[14px] w-[14px]" />
              {t('yes')}
            </CustomButton>
            <CustomButton
              onClick={handleCancelClick}
              className="h-[28px] py-2.5 text-[10px] text-red"
              variant="outline"
              type="button"
            >
              <img src={redX} alt="X icon" className="h-[8px] w-[8px]" />
              {t('no')}
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackingCodeComponent;
