import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../../../components/buttons/customButton';
import BasicDialog from '../../../dialogs/basicDialog';
import ConnectToPaddleForm from '../../../forms/connectToPaddleForm';

interface ConnectToPaddleScreenProps {
    refetchOrganizationSettings: () => void;
}

const ConnectToPaddleScreen: FC<ConnectToPaddleScreenProps> = (props) => {
  const { t } = useTranslation();
  const [isConnectToPaddleDialogOpen, setIsConnectToPaddleDialogOpen] = useState<boolean>(false);

  return (
    <>
      <BasicDialog
        open={isConnectToPaddleDialogOpen}
        title={t('insertPaddleToken')}
        trackingModal
        headerColor="white"
      >
        <ConnectToPaddleForm
          onCloseDialog={() => setIsConnectToPaddleDialogOpen(false)}
          refetchOrganizationSettings={props.refetchOrganizationSettings}
        />
      </BasicDialog>
      <div className="flex items-center justify-between gap-5 rounded-lg border border-dark px-5 py-4">
        <div className="max-lg:w-[60%]">
          <p className="text-xs font-semibold leading-[1.5] text-primary">
            {t('connectPaddleIntegrationAsPaymentProvider')}
          </p>
          <p className="text-xs leading-[1.5] text-primary">
            {t('connectPaddleIntegrationAsPaymentProviderDescription')}
          </p>
        </div>
        <CustomButton
          disabled={false}
          className="px-6 py-2.5 text-sm"
          onClick={() => setIsConnectToPaddleDialogOpen(true)}
        >
          {t('connectToPaddle')}
        </CustomButton>
      </div>
    </>

  );
};

export default ConnectToPaddleScreen;
