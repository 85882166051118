import React from 'react';
import { useLocation } from 'react-router-dom';
import SimpleTabs from '../../../components/tabs/simpleTabs';
import GoogleTabIcon from '../../../assets/icons/tab/google-tab-icon.svg';
import FacebookTabIcon from '../../../assets/icons/tab/facebook-tab-icon.svg';
import BingTabIcon from '../../../assets/icons/tab/bing-tab-icon.svg';
import { AdProvidersTabLinks } from '../../../components/enums/tabEnums';

const Integrations = () => {
  const location = useLocation();
  const activePanel = AdProvidersTabLinks[location.pathname.split('/').pop() as keyof typeof AdProvidersTabLinks] || 0;

  return (
    <div className="h-full rounded-lg border border-dark bg-white px-6 py-4">
      <SimpleTabs
        currentTab={activePanel}
        tabs={[
          {
            tabLabel: 'Google',
            href: 'google',
            identifierName: 'google-panel',
            icon: GoogleTabIcon,
          }, {
            tabLabel: 'Facebook',
            href: 'facebook',
            identifierName: 'facebook-panel',
            icon: FacebookTabIcon,
          }, {
            tabLabel: 'Bing',
            href: 'bing',
            identifierName: 'bing-panel',
            icon: BingTabIcon,
          },
        ]}
      />
    </div>
  );
};

export default Integrations;
