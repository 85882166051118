import { useQuery, UseQueryResult } from 'react-query';
import { Dayjs } from 'dayjs';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';
import { appendQueryParamIfNotEmpty, generateQueryKey } from '../../../constants/hookConstants';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';

type UntrackedCodeType = {
    code: string,
    count: number,
    createdAt: Dayjs,
}

export interface UntrackedCodeDataResponse {
    data : UntrackedCodeType[];
    meta: {
        current_page: number;
        from: number;
        last_page: number;
        links: {
            first: string;
            last: string;
            prev: string | null;
            next: string | null;
        };
        path: string;
        per_page: number;
        to: number;
        total: number;
    };
}

const useGetUntrackedCodes = (
  identifier: string,
  pageNumber: number,
): UseQueryResult<ApiResponse<UntrackedCodeDataResponse>, ApiError<UntrackedCodeDataResponse>> => {
  const queryParams = new URLSearchParams();

  const queryKey = generateQueryKey(identifier, queryParams);
  appendQueryParamIfNotEmpty(queryParams, 'pageNumber', pageNumber.toString());

  return useQuery<ApiResponse<UntrackedCodeDataResponse>, ApiError<UntrackedCodeDataResponse>>(
    [`untracked_code_${queryKey}`],
    () => dataFetch.get(`${DIGGER_API_SUFFIX}untracked-code?page=${pageNumber}`),
  );
};

export default useGetUntrackedCodes;
