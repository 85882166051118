import { useQuery, UseQueryResult } from 'react-query';
import { Dayjs } from 'dayjs';
import { CORE_API_SUFFIX, dataFetch } from '../../../axios/customAxios';
import { appendQueryParamIfNotEmpty, formatDate, generateQueryKey } from '../../../constants/hookConstants';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';
import { ReferrerReportRowInterface } from '../../../interfaces/referrerReportRow.interface';

interface TopReferrersReport {
  hasNextPage: boolean;
  report: ReferrerReportRowInterface[];
}

export const useGetReferrersReport = (
  identifier: string,
  from: Dayjs | null,
  to: Dayjs | null,
  page: number,
  original: boolean,
): UseQueryResult<ApiResponse<TopReferrersReport>, ApiError<TopReferrersReport>> => {
  const queryParams = new URLSearchParams();
  appendQueryParamIfNotEmpty(queryParams, 'page', page.toString());
  appendQueryParamIfNotEmpty(queryParams, 'from', formatDate(from));
  appendQueryParamIfNotEmpty(queryParams, 'to', formatDate(to));
  if (original) {
    appendQueryParamIfNotEmpty(queryParams, 'original', original.toString());
  }

  const queryKey = generateQueryKey(identifier, queryParams);

  return useQuery(
    [`reports_${queryKey}`],
    () => dataFetch.get(`${CORE_API_SUFFIX}form-submission/referrer-report?${queryParams}`),
  );
};
