import React, { useState } from 'react';
import List from './list';
import { useGetTopAds } from '../../../apiHooks/queries/reports/useGetTopAds';
import { TOP_ADS_THAT_LOSE_MONEY } from '../../../constants/pageIdentifiers';
import { AdStatusEnum } from '../../enums/adStatusEnum';
import { useDashboardFilterDateStore } from '../../../store/dateRangeStore';
import { useGetExportAds } from '../../../apiHooks/mutations/reportsExportMutations/useGetExportAds';
import { eCommerceTopAdsColumns, topAdsColumns, topAdsFixedColumns } from '../../../constants/constants';
import OrganizationType from '../../enums/organizationTypesEnum';
import { useOrganizationSettingsStore } from '../../../store/organizationSettingsStore';

const AdsThatLoseMoney = () => {
  const [page, setPage] = useState(0);
  const dashboardFilterDateRange = useDashboardFilterDateStore((state) => state.dashboardFilterDateRange);
  const organizationType = useOrganizationSettingsStore((state) => state.organizationSettings.organizationType);

  const {
    data, isFetching, isError,
  } = useGetTopAds(
    TOP_ADS_THAT_LOSE_MONEY,
    AdStatusEnum.Lost,
    dashboardFilterDateRange[0],
    dashboardFilterDateRange[1],
    page,
  );

  const { mutate: fetchDownload } = useGetExportAds(
    {
      from: dashboardFilterDateRange[0],
      to: dashboardFilterDateRange[1],
      page: page || 0,
      enabled: false,
      status: AdStatusEnum.Lost,
    },
  );

  return (
    <List
      cardTitle="unprofitableAds"
      listData={data?.data}
      bgColor="red"
      page={page}
      setPage={setPage}
      isFetching={isFetching}
      isError={isError}
      fetchDownload={fetchDownload}
      columns={organizationType === OrganizationType.ECOMMERCE ? eCommerceTopAdsColumns : topAdsColumns}
      fixedColumns={topAdsFixedColumns}
    />
  );
};

export default AdsThatLoseMoney;
