import React, { useState } from 'react';
import { useDashboardFilterDateStore } from '../../../store/dateRangeStore';
import { TOP_ADS_PROFITABLE } from '../../../constants/pageIdentifiers';
import List from './list';
import { useGetProfitableAds } from '../../../apiHooks/queries/reports/useGetProfitableAds';
import { useGetExportProfitableAds } from '../../../apiHooks/mutations/reportsExportMutations/useGetExportProfitableAds';
import { eCommerceTopAdsColumns, topAdsColumns, topAdsFixedColumns } from '../../../constants/constants';
import { useOrganizationSettingsStore } from '../../../store/organizationSettingsStore';
import OrganizationType from '../../enums/organizationTypesEnum';

const ProfitableAds = () => {
  const [page, setPage] = useState(0);
  const dashboardFilterDateRange = useDashboardFilterDateStore((state) => state.dashboardFilterDateRange);
  const organizationType = useOrganizationSettingsStore((state) => state.organizationSettings.organizationType);

  const {
    data, isFetching, isError,
  } = useGetProfitableAds(
    {
      identifier: TOP_ADS_PROFITABLE,
      from: dashboardFilterDateRange[0],
      to: dashboardFilterDateRange[1],
      page: page || 0,
      enabled: true,
    },
  );

  const { mutate: fetchDownload } = useGetExportProfitableAds(
    {
      from: dashboardFilterDateRange[0],
      to: dashboardFilterDateRange[1],
      page: page || 0,
      enabled: false,
    },
  );

  return (
    <List
      cardTitle="profitableAds"
      listData={data?.data?.data}
      bgColor="green"
      page={page}
      setPage={setPage}
      isFetching={isFetching}
      isError={isError}
      fetchDownload={fetchDownload}
      columns={organizationType === OrganizationType.ECOMMERCE ? eCommerceTopAdsColumns : topAdsColumns}
      fixedColumns={topAdsFixedColumns}
    />
  );
};

export default ProfitableAds;
