import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import closableEnqueueSnackbar from '../../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';
import { appendQueryParamIfNotEmpty, formatDate, generateQueryKey } from '../../../constants/hookConstants';

const useGetAds = (
  identifier: string,
  name: string,
  code: string,
  selectedAdGroupId: string,
  from: Dayjs | null,
  to: Dayjs | null,
  sortBy: string,
  orderBy: string,
  rows: number,
  page: number,
  adGroupCode: number | string,
) => {
  const navigate = useNavigate();

  const queryParams = new URLSearchParams();
  appendQueryParamIfNotEmpty(queryParams, 'name', name.trim());
  appendQueryParamIfNotEmpty(queryParams, 'code', code.trim());
  appendQueryParamIfNotEmpty(queryParams, 'adGroupId', selectedAdGroupId.trim());
  appendQueryParamIfNotEmpty(queryParams, 'from', formatDate(from));
  appendQueryParamIfNotEmpty(queryParams, 'to', formatDate(to));
  appendQueryParamIfNotEmpty(queryParams, 'sortBy', sortBy.trim());
  appendQueryParamIfNotEmpty(queryParams, 'orderBy', orderBy.trim());
  appendQueryParamIfNotEmpty(queryParams, 'rows', rows.toString());
  appendQueryParamIfNotEmpty(queryParams, 'page', page.toString());
  appendQueryParamIfNotEmpty(queryParams, 'adGroupCode', adGroupCode.toString());

  const queryKey = generateQueryKey(identifier, queryParams);

  return useQuery(
    [`ads_management_${queryKey}`],
    () => dataFetch.get(`${DIGGER_API_SUFFIX}ads?${queryParams}`).then((res: any) => res.data),
    {
      onSuccess: () => {
        navigate(`${window.location.pathname}?${queryParams.toString()}`, { replace: true });
      },
    },
  );
};

export default useGetAds;
