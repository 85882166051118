import React from 'react';
import { useTranslation } from 'react-i18next';
import redIcon from '../../assets/icons/redIcon.svg';
import greenIcon from '../../assets/icons/greenIcon.svg';
import yellowIcon from '../../assets/icons/yellowIcon.svg';

const DeveloperLegends = () => {
  const { t } = useTranslation();

  return (
    <div className="flex w-full max-w-2xl flex-col items-center rounded-[8px] border-[1px]
        bg-white px-6 py-3"
    >
      <div className="flex w-full items-center">
        <div className="grow border-b" />
        <p className="mx-3 text-sm text-primary">{t('statusLegend')}</p>
        <div className="grow border-b" />
      </div>
      <div
        className="flex w-full max-w-2xl items-center justify-between  text-xs font-medium text-primary"
      >
        <div className="flex items-center gap-2">
          <img className="w-[22px]" src={redIcon} alt="" />
          {t('needsFixed')}
        </div>
        <div className="flex items-center gap-2">
          <img className="w-[22px]" src={yellowIcon} alt="" />
          {t('needsCheck')}
        </div>
        <div className="flex items-center gap-2">
          <img className="w-[22px]" src={greenIcon} alt="" />
          {t('looksGood')}
        </div>
      </div>
    </div>
  );
};

export default DeveloperLegends;
