import { useLocation, useNavigate } from 'react-router-dom';
import { Tab } from '@mui/material';
import React, { ReactElement } from 'react';
import { Colors } from '../../constants/constants';

interface LinkTabProps {
  id: string;
  label: string;
  href: string;
  icon?: ReactElement;
}

const VerticalLinkTab = (props: LinkTabProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentLocation = location.pathname.split('/');

  return (
    <div className="relative">
      <Tab
        icon={props?.icon}
        iconPosition="start"
        component="a"
        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          event.preventDefault();
          navigate(`/${currentLocation[1]}/${currentLocation[2]}/${currentLocation[3]}/${props.href}`);
        }}
        sx={{
          color: '#0C485E80',
          paddingBlock: 1.75,
          marginBottom: 2,
          minHeight: '25px',
          height: '25px',
          position: 'relative',
          borderRadius: '8px',
          textTransform: 'none',
          justifyContent: 'flex-start',
          fontSize: 12,
          padding: '8px',
          '&.Mui-selected': {
            fontWeight: '500',
            color: Colors.primary,
          },
        }}
        {...props}
      />
      <div className="absolute top-6 h-[2px] w-full rounded-t-lg bg-green/50" />
    </div>

  );
};

export default VerticalLinkTab;
