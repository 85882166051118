import React, { FC, FormEvent } from 'react';
import { FormikValues } from 'formik';
import AutoCompleteInput from '../inputs/autoCompleteInput';

interface FieldTypesInterface {
  id: number,
  label: string,
}

interface FieldTypeSelectProps {
  formik: FormikValues,
  fieldTypes: FieldTypesInterface[],
}
const FieldTypeSelect: FC<FieldTypeSelectProps> = (props) => {
  const onChangeAutoComplete = (event: FormEvent, newValue: FieldTypesInterface) => {
    if (newValue?.id !== undefined) {
      props.formik.setFieldValue?.('fieldType', newValue.id);
    } else {
      props.formik.setFieldValue?.('fieldType', null);
    }
  };

  return (
    <div>
      <AutoCompleteInput
        size="small"
        name="fieldType"
        options={props.fieldTypes}
        value={props.fieldTypes.find((type: FieldTypesInterface) => type.id === props.formik.values.fieldType) || null}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.label}
        onChange={(event: FormEvent, newValue: FieldTypesInterface) => onChangeAutoComplete(event, newValue)}
        label="fieldType"
        placeholder="Select a field type..."
        error={Boolean(props.formik?.touched.fieldType) && Boolean(props.formik?.errors.fieldType)}
        helperText={Boolean(props.formik?.touched.fieldType) && props.formik?.errors.fieldType}
      />
    </div>
  );
};

export default FieldTypeSelect;
