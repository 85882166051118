import { useQuery, UseQueryResult } from 'react-query';
import { CORE_API_SUFFIX, dataFetch } from '../../../axios/customAxios';
import { generateQueryKey } from '../../../constants/hookConstants';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';

interface AdProviderDetailsInterface {
    ads_tracked: number,
    revenue_tracked: number,
    leads_attributed: number,
}

const useGetAdProviderDetails = (
  identifier: string,
  trackingSourceId: number,
  isEnabled: boolean,
): UseQueryResult<ApiResponse<AdProviderDetailsInterface>, ApiError<AdProviderDetailsInterface>> => {
  const queryParams = new URLSearchParams();

  const queryKey = generateQueryKey(identifier, queryParams);

  return useQuery<ApiResponse<AdProviderDetailsInterface>, ApiError<AdProviderDetailsInterface>>(
    [`latest_fsUpdates_${queryKey}`],
    () => dataFetch.get(`${CORE_API_SUFFIX}ad/provider/${trackingSourceId}`),
    {
      enabled: isEnabled,
    },
  );
};

export default useGetAdProviderDetails;
