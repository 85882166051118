import { useQuery } from 'react-query';
import { Dayjs } from 'dayjs';
import { CORE_API_SUFFIX, dataFetch } from '../../../axios/customAxios';
import { appendQueryParamIfNotEmpty, formatDate, generateQueryKey } from '../../../constants/hookConstants';

export const useGetFormSubmissionsByOrganizationId = (identifier: string, from: Dayjs | null, to: Dayjs | null) => {
  const queryParams = new URLSearchParams();
  appendQueryParamIfNotEmpty(queryParams, 'from', formatDate(from));
  appendQueryParamIfNotEmpty(queryParams, 'to', formatDate(to));

  const queryKey = generateQueryKey(identifier, queryParams);

  return useQuery(
    [`reports_${queryKey}`],
    () => dataFetch.get(`${CORE_API_SUFFIX}form-submission/count?${queryParams}`).then((res) => res.data),
  );
};
