import { useQuery, UseQueryResult } from 'react-query';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import { generateQueryKey } from '../../../constants/hookConstants';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';

interface LastingTokenResponse {
    user: {
        id: number;
        firstName: string;
        lastName: string;
        email: string;
        organizationId: number;
        createdAt: string;
        updatedAt: string;
        isSuperAdmin: boolean;
    };
    authorization: {
        token: string;
        type: string;
    };
}

const useGetLongLastingToken = (
  identifier: string,
): UseQueryResult<ApiResponse<LastingTokenResponse>, ApiError<LastingTokenResponse>> => {
  const queryParams = new URLSearchParams();

  const queryKey = generateQueryKey(identifier, queryParams);

  return useQuery<ApiResponse<LastingTokenResponse>, ApiError<LastingTokenResponse>>(
    [`long_token_${queryKey}`],
    () => dataFetch.get(`${DIGGER_API_SUFFIX}auth/long-living-token`),
    {
      enabled: false,
    },
  );
};

export default useGetLongLastingToken;
