import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { useGetDeviceDistribution } from '../../apiHooks/queries/graphs/useGetDeviceDistribution';
import { AD_DEVICE_DISTRIBUTION } from '../../constants/pageIdentifiers';
import DonutChart from '../graphs/donutChart';

interface DeviceChartProps {
  id: number,
  dateRange: DateRange<Dayjs>,
  formSubmissions: number,
}

interface DeviceChartData {
  isDesktop: string;
  isMobile: string;
  isTablet: string;
  noRecordedDevice: string;
}

interface SegmentInfo {
  active: boolean;
  order: number;
  colorClass: string;
  number: number;
  type: string;
}

interface Segments {
  [key: string]: SegmentInfo;
}

const DeviceDistributionGraph: FC<DeviceChartProps> = (props) => {
  const { t } = useTranslation();
  // const [breadcrumb, setBreadcrumb] = useState<string[]>([]);
  const [segments, setSegments] = useState<Segments>({});
  const predefinedColors = ['#9EB6BF', '#B7D5C4', '#FFC577', '#E98985'];

  const getRandomColor = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`;

  const getColorClass = (index: number) => {
    if (index < 4) {
      return `${predefinedColors[index]}`;
    }
    return `${getRandomColor()}`;
  };

  const {
    data: deviceDistributionData,
  } = useGetDeviceDistribution(
    AD_DEVICE_DISTRIBUTION,
    props.id,
    props.dateRange[0],
    props.dateRange[1],
  );

  const deviceTypeMap = {
    isDesktop: 'Desktop',
    isMobile: 'Mobile',
    isTablet: 'Tablet',
    noRecordedDevice: 'Unknown',
  };

  useEffect(() => {
    if (deviceDistributionData?.data?.data) {
      const newSegments = Object.keys(deviceDistributionData.data.data).reduce((acc, deviceTypeKey, index) => ({
        ...acc,
        [deviceTypeMap[deviceTypeKey as keyof DeviceChartData]]: {
          active: true,
          order: Object.keys(acc).length + 1,
          colorClass: getColorClass(index),
          number: deviceDistributionData.data.data[deviceTypeKey as keyof DeviceChartData],
          type: 'device',
        },
      }), {});
      setSegments(newSegments);
    }
  }, [deviceDistributionData]);

  const activeSegments = Object.entries(segments).filter(([, info]) => info.active);

  return (
    <div className="mt-2 flex h-[145px] items-center justify-start space-y-4">
      <div className="mr-5 mt-3 w-[150px]">
        <DonutChart
          data={[
            deviceDistributionData?.data.data.isDesktop,
            deviceDistributionData?.data.data.isTablet,
            deviceDistributionData?.data.data.isMobile,
            deviceDistributionData?.data.data.noRecordedDevice,
          ]}
          // data={[2, 2, 2, 2]}
        />
      </div>
      <div className="flex w-full flex-col">
        <div className="mb-4 flex w-full justify-between">
          <div className="flex w-full max-w-[240px] rounded-xl">
            <h2 className="flex w-1/2 items-center justify-center rounded-l-xl border py-1 font-medium text-primary">
              {props.formSubmissions}
            </h2>
            <p className="flex w-1/2 items-center justify-center rounded-r-xl
            border py-1 text-[8px] font-semibold text-primary"
            >
              {t('formSubmissions')}
            </p>
          </div>
        </div>
        <div className="grid h-full min-h-[42px] w-full grid-cols-2 items-center gap-2">
          {activeSegments.map(([key, info]) => (
            <div
              key={key}
              style={{ borderBottom: `2px solid ${info.colorClass}` }}
              className="flex w-full grid-cols-2 items-center gap-2
              bg-white px-4 py-2 text-primary outline-none focus:outline-none"
            >
              <div className="h-3 w-3 rounded-[50%]" style={{ backgroundColor: `${info.colorClass}` }} />
              {t(key)}
              <span className="ml-auto">{info.number}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DeviceDistributionGraph;
