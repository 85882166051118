import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import { handleApiError } from '../../requestHelpers/requestHelpers';
import { ApiError } from '../../../interfaces/apiResponse.type';

export const useConnectToPaddle = () => {
  const { t } = useTranslation();
  return useMutation(
    (bearerToken: string) => dataFetch.patch(
      `${DIGGER_API_SUFFIX}organizations/connect-paddle`,
      {
        bearerToken,
      },
    ),
    {
      onError: (error: ApiError<null>) => {
        handleApiError(error, t);
      },
    },
  );
};

export const useDisconnectFromPaddle = () => {
  const { t } = useTranslation();
  return useMutation(
    () => dataFetch.put(
      `${DIGGER_API_SUFFIX}organizations/disconnect-paddle`,
    ),
    {
      onError: (error: ApiError<null>) => {
        handleApiError(error, t);
      },
    },
  );
};
