import React, { FC, useState } from 'react';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../components/loadingSpinner/loadingSpinner';
import PauseButton from '../assets/icons/pause-icon.svg';
import PlayButton from '../assets/icons/play-icon.svg';
import DisabledPauseButton from '../assets/icons/disabled-pause-icon.svg';
import closableEnqueueSnackbar from '../components/closableEnqueueSnackbar/closableEnqueueSnackbar';
import { useStartAdMutation, useStopAdMutation } from '../apiHooks/mutations/adManagementMutations/startOrStopAdMutation';
import { TrackingSourceEnum } from '../components/enums/trackingSourceEnum';

interface PlayStopAdCellProps {
  params?: boolean;
  rowObject?: any;
}

const PlayStopAdCell: FC<PlayStopAdCellProps> = (props) => {
  const { t } = useTranslation();
  const [isStopped, setIsStopped] = useState(props.params);

  const onStartSuccess = () => {
    closableEnqueueSnackbar(t('adStartSuccess'), 'success');
    setIsStopped(false);
  };

  const onStopSuccess = () => {
    closableEnqueueSnackbar(t('adStopSuccess'), 'success');
    setIsStopped(true);
  };

  const {
    mutateAsync: startAd,
    isLoading: isStartAdLoading,
  } = useStartAdMutation(props.rowObject.trackingSourceId, onStartSuccess);

  const {
    mutateAsync: stopAd,
    isLoading: isStopAdLoading,
  } = useStopAdMutation(props.rowObject.trackingSourceId, onStopSuccess);

  if (isStartAdLoading || isStopAdLoading) {
    return (
      <div className="h-[30px] w-[30px]">
        <LoadingSpinner size={12} color="primary" center />
      </div>
    );
  }

  if (
    props.rowObject.trackingSourceId !== TrackingSourceEnum.Google
    && props.rowObject.trackingSourceId !== TrackingSourceEnum.Facebook
  ) {
    return (
      <button
        type="button"
        disabled
        onClick={() => props.rowObject?.adId && stopAd(String(props.rowObject.adId))}
        className="grid h-[30px] w-[30px] cursor-not-allowed place-content-center rounded-md border border-dark bg-white
          duration-150"
      >
        <Tooltip arrow placement="top" title={t('cannotBeControlled')} className="h-full w-full p-1.5">
          <img src={DisabledPauseButton} alt="disabled pause button icon" className="h-3 w-3 object-contain" />
        </Tooltip>
      </button>
    );
  }

  if (isStopped) {
    return (
      <button
        type="button"
        onClick={() => props.rowObject?.adId && stopAd(String(props.rowObject.adId))}
        className="grid h-[30px] w-[30px] place-content-center rounded-md border border-dark bg-white duration-150
                  hover:border-primary/50 active:border-primary"
      >
        <Tooltip arrow placement="top" title={t('stopAd')} className="h-full w-full p-2">
          <img src={PauseButton} alt="pause button icon" className="h-3 w-3 object-contain" />
        </Tooltip>
      </button>
    );
  }

  return (
    <button
      type="button"
      onClick={() => props.rowObject?.adId && startAd(String(props.rowObject.adId))}
      className="grid h-[30px] w-[30px] place-content-center rounded-md border border-dark bg-white duration-150
                  hover:border-primary/50 active:border-primary"
    >
      <Tooltip arrow placement="top" title={t('startAd')} className="h-full w-full p-2">
        <img src={PlayButton} alt="play button icon" className="h-3 w-3 object-contain" />
      </Tooltip>
    </button>
  );
};

export default PlayStopAdCell;
