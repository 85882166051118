import React, { FC, useState } from 'react';
import { Box, Tabs } from '@mui/material';
import { TabInterface } from '../../interfaces/simpleTabs.interface';
import { Colors } from '../../constants/constants';
import LinkTab from './linkTab';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface SimpleTabProps {
  tabs: TabInterface[],
  currentTab: number,
}

const SimpleTabs: FC<SimpleTabProps> = (props) => {
  const [activePanel, setActivePanel] = useState<number>(props.currentTab);
  const { t } = useTranslation();

  const onPanelChange = (_e: React.SyntheticEvent, value: any) => {
    setActivePanel(value);
  };

  return (
    <>
      <Box sx={{
        borderBottom: 1,
        borderColor: 'divider',
        height: 39,
        width: 'calc(100% + 48px)',
        marginLeft: '-1.5rem',
        paddingLeft: '1.5rem',
      }}
      >
        <Tabs
          value={activePanel}
          onChange={onPanelChange}
          aria-label="basic tabs"
          TabIndicatorProps={{
            style: {
              backgroundColor: Colors.primary,
              height: 3,
              borderTopLeftRadius: 25,
              borderTopRightRadius: 25,
              bottom: 9,
            },
          }}
        >
          {props.tabs.map((tab, index) => (
            <LinkTab
              key={index}
              label={t(tab.tabLabel) || tab.tabLabel}
              id={`tab.identifierName-${index}`}
              href={tab.href}
              icon={tab?.icon ? <img src={tab?.icon} alt="tab-icon" className="h-[16px] object-contain"/> : undefined}
            />
          ))}
        </Tabs>
      </Box>
      <Box className="h-full w-full py-6">
        <Outlet/>
      </Box>
    </>
  );
};

export default SimpleTabs;
