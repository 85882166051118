import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toNumber } from 'lodash';
import StaticCard from '../../components/staticCard/StaticCard';
import { GroupedBarChart } from '../../components/graphs/groupedBarChart';
import { useAdManagementFilterDateStore } from '../../store/dateRangeStore';
import AdInfoRows from '../../components/adInfoRows/AdInfoRows';
import useGetAdDetails from '../../apiHooks/queries/adDetails/useGetAdDetails';
import { AD_DETAILS, AD_INCOME_INSIGHT, AD_TIME_TO_INCOME } from '../../constants/pageIdentifiers';
import useGetTimeToIncome from '../../apiHooks/queries/useGetTimeToIncome/useGetTimeToIncome';
import useGetIncomeInsight from '../../apiHooks/queries/incomeInsight/useGetIncomeInsight';
import { useOrganizationSettingsStore } from '../../store/organizationSettingsStore';
import AdPageSmallBox from '../../components/adPageSmallBox/adPageSmallBox';
import LoadingSpinner from '../../components/loadingSpinner/loadingSpinner';
import DeviceDistributionGraph from '../../components/deviceDistributionGraph/deviceDistributionGraph';
import OperatingSystems from '../../components/operatingSystems/OperatingSystems';
import { useAdPageBackStore } from '../../store/adPageStore';
import { formatToTwoDecimalPlaces } from '../../constants/constants';

const AdPage: FC = () => {
  const { id } = useParams();

  const organizationSettings = useOrganizationSettingsStore((state) => state.organizationSettings);

  const { selectedOs } = useAdPageBackStore((state) => state);

  const adManagementFilterDateRange = useAdManagementFilterDateStore(
    (state) => state.adManagementFilterDateRange,
  );
  const setAdManagementFilterDateRange = useAdManagementFilterDateStore(
    (state) => state.setAdManagementFilterDateRange,
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    data: adDetailsData,
    isFetching,
  } = useGetAdDetails(
    AD_DETAILS,
    id,
    adManagementFilterDateRange[0],
    adManagementFilterDateRange[1],
  );

  const {
    data: timeToIncomeData,
    isFetching: timeToIncomeIsLoading,
  } = useGetTimeToIncome(
    AD_TIME_TO_INCOME,
    id,
    adManagementFilterDateRange[0],
    adManagementFilterDateRange[1],
  );

  const {
    data: incomeInsightData,
    isFetching: incomeInsightIsLoading,
  } = useGetIncomeInsight(
    AD_INCOME_INSIGHT,
    id,
    adManagementFilterDateRange[0],
    adManagementFilterDateRange[1],
  );

  return (
    <div className="grid grid-cols-3 grid-rows-3 gap-4 max-md:grid-cols-1">
      <StaticCard
        alternateHeader
        variant="primary"
        filterDateRange={adManagementFilterDateRange}
        setFilterDateRange={setAdManagementFilterDateRange}
        classGrid="col-span-3 max-md:col-span-1"
        trackingSourceID={adDetailsData?.data?.data?.trackingSourceId}
        active={adDetailsData?.data?.data?.status}
        adName={adDetailsData?.data?.data?.name}
      >
        {isFetching
          ? (
            <div className="mt-6 flex h-full w-full items-center justify-center">
              <LoadingSpinner color="primary" variant="primary" size={50} />
            </div>
          )
          : (
            <div>
              <div className="mb-2 flex items-center gap-3">
                <AdPageSmallBox title="adCode" value={formatToTwoDecimalPlaces(adDetailsData?.data?.data?.adId || 0)} />
                <AdPageSmallBox title="formSubmissions" value={formatToTwoDecimalPlaces(adDetailsData?.data?.data?.formSubmissionsCount || 0)} />
                <AdPageSmallBox title="clicks" value={formatToTwoDecimalPlaces(adDetailsData?.data?.data?.clicks || 0)} />
                <AdPageSmallBox title="cost" value={formatToTwoDecimalPlaces(adDetailsData?.data?.data?.cost || 0)} />
                <AdPageSmallBox title="netIncome" value={formatToTwoDecimalPlaces(adDetailsData?.data?.data?.netIncome || 0)} />
              </div>
              <div className="flex items-center gap-3">
                <AdPageSmallBox title="ctr" value={`${formatToTwoDecimalPlaces(adDetailsData?.data?.data?.ctr || 0)}%`} />
                <AdPageSmallBox title="impressions" value={formatToTwoDecimalPlaces(adDetailsData?.data?.data?.impressions || 0)} />
                <AdPageSmallBox title="interactions" value={formatToTwoDecimalPlaces(adDetailsData?.data?.data?.interactions || 0)} />
                <AdPageSmallBox title="interactionsRate" value={`${formatToTwoDecimalPlaces(adDetailsData?.data?.data?.interactionsRate || 0)}%`} />
                <AdPageSmallBox title="averageCpc" value={formatToTwoDecimalPlaces(adDetailsData?.data?.data?.averageCpc || 0)} />
              </div>
            </div>
          )}
      </StaticCard>
      <StaticCard
        title="Device Distribution"
        variant="primary"
        classGrid="col-span-2"
        info="Distribution of user devices"
        border
      >
        <DeviceDistributionGraph
          id={toNumber(id)}
          dateRange={adManagementFilterDateRange}
          formSubmissions={adDetailsData?.data?.data?.formSubmissionsCount || 0}
        />
      </StaticCard>
      <StaticCard
        variant="primary"
        classGrid="col-span-1"
        border
        title="Ad Status"
      >
        <AdInfoRows
          colors
          numbers={[
            incomeInsightData?.data?.data?.lostIncome || 0,
            incomeInsightData?.data?.data?.potentialIncome || 0,
            incomeInsightData?.data?.data?.earnedIncome || 0,
          ]}
          title={['lostIncome', 'potentialIncome', 'earnedIncome']}
          suffix={organizationSettings?.currency}
          isLoading={incomeInsightIsLoading}
        />
      </StaticCard>
      <StaticCard
        classGrid="row-span-2"
        title="Operative System"
        variant="primary"
        variantStatus="adDetails"
        border
        backExist={selectedOs}
      >
        <OperatingSystems dateRange={adManagementFilterDateRange} adCode={id || ''} />
      </StaticCard>
      <StaticCard
        classGrid="col-span-2 max-md:col-span-1"
        title="Ad Income Histogram"
        variant="primary"
        info="Monthly based income graph"
        border
      >
        <GroupedBarChart
          id={toNumber(id)}
          adManagementFilterDateRange={adManagementFilterDateRange}
        />
      </StaticCard>
      <StaticCard
        classGrid="col-span-1"
        variant="primary"
        border
        title="Time to Income"
      >
        <AdInfoRows
          numbers={[
            timeToIncomeData?.data?.data?.avgDays || 0,
            timeToIncomeData?.data?.data?.minDays || 0,
            timeToIncomeData?.data?.data?.maxDays || 0,
          ]}
          title={['average', 'shortest', 'longest']}
          suffix="Days"
          isLoading={timeToIncomeIsLoading}
        />
      </StaticCard>
    </div>
  );
};

export default AdPage;
