import { useQuery, UseQueryResult } from 'react-query';
import { CORE_API_SUFFIX, dataFetch } from '../../../axios/customAxios';
import { generateQueryKey } from '../../../constants/hookConstants';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';

type LatestFsUpdateType = {
    fsId: string;
    createdAt: string;
    fsJson :string;
}

export interface LatestFsUpdateResponse {
    data: LatestFsUpdateType[];
}

const useGetLatestFsUpdate = (
  identifier: string,
): UseQueryResult<ApiResponse<LatestFsUpdateResponse>, ApiError<LatestFsUpdateResponse>> => {
  const queryParams = new URLSearchParams();

  const queryKey = generateQueryKey(identifier, queryParams);

  return useQuery<ApiResponse<LatestFsUpdateResponse>, ApiError<LatestFsUpdateResponse>>(
    [`latest_fsUpdates_${queryKey}`],
    () => dataFetch.get(`${CORE_API_SUFFIX}fs-update/latest-ten`),
  );
};

export default useGetLatestFsUpdate;
