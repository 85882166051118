import axios, { AxiosInstance } from 'axios';

const BASE_URL = import.meta.env.VITE_API_URL;
export const CORE_API_SUFFIX = 'digger-core-api/';
export const DIGGER_API_SUFFIX = 'digger-dashboard/api/';

function createAxiosInstance(baseURL: string) {
  const instance: AxiosInstance = axios.create({
    baseURL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    const organizationId = localStorage.getItem('organizationId');
    const userId = localStorage.getItem('userId');

    const modifiedConfig = { ...config };

    modifiedConfig.headers = modifiedConfig.headers || {};

    if (token) {
      modifiedConfig.headers.Authorization = `Bearer ${token}`;
    }

    if (organizationId) {
      modifiedConfig.headers['X-Organization-Id'] = organizationId;
    }

    if (userId) {
      modifiedConfig.headers['X-User-Id'] = userId;
    }

    return modifiedConfig;
  });

  return instance;
}

export const dataFetch = createAxiosInstance(BASE_URL);
