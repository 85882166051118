import React, { FC, useEffect, useState } from 'react';

interface CheckboxInterface {
    id ?: string,
    defaultChecked : boolean,
    onChange ?: () => void;
}

const Checkbox : FC<CheckboxInterface> = (props) => {
  const [isChecked, setIsChecked] = useState(props.defaultChecked || false);

  const handleToggle = () => {
    if (props.onChange) {
      props.onChange();
    }
  };

  useEffect(() => {
    setIsChecked(props.defaultChecked);
  }, [props.defaultChecked]);

  return (
    <div className="flex items-center">
      <input
        type="checkbox"
        className="hidden"
        id={props.id}
        checked={isChecked}
        onChange={handleToggle}
      />
      <label
        className={`cursor-pointer rounded-md  ${isChecked ? 'border border-primary bg-primary p-0.5' : 'border bg-white p-2'}`}
        htmlFor={props.id}
      >
        <svg className={`h-3 w-3 text-white ${isChecked ? '' : 'hidden'}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
        </svg>
      </label>
    </div>
  );
};

export default Checkbox;
