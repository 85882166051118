import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TableColumnInterface } from '../../interfaces/tableColumn.interface';
import BasicTable from './basicTable';
import { ADS_TABLE } from '../../constants/pageIdentifiers';
import BasicDialog from '../../dialogs/basicDialog';
import DateCell from '../../partials/dateCell';
import useGetAds from '../../apiHooks/queries/adManagement/useGetAds';
import { useDeleteAd } from '../../apiHooks/mutations/adManagementMutations/adsMutations';
import AdsForm from '../../forms/adsForm';
import { adFormInputs } from '../../constants/modalInputs/adFormInputs';
import { AdInterface } from '../../interfaces/ads.interface';
import AdGroupSelect from '../dropdownSelects/adGroupSelect';
import { DEFAULT_ROWS_PER_PAGE, eCommerceExtendedColumns, extendedColumns } from '../../constants/constants';
import { useAdManagementFilterDateStore } from '../../store/dateRangeStore';
import OrganizationType from '../enums/organizationTypesEnum';
import { useOrganizationSettingsStore } from '../../store/organizationSettingsStore';
import { useGetExportAds } from '../../apiHooks/mutations/reportsExportMutations/useGetExportAds';
import { AdStatusEnum } from '../enums/adStatusEnum';
import { useGetExportAdsTable } from '../../apiHooks/mutations/reportsExportMutations/useGetExportAdsTable';

const columns: TableColumnInterface[] = [
  { field: 'code', headerName: 'code' },
  { field: 'name', headerName: 'name' },
  { field: 'description', headerName: 'description' },
  { field: 'adGroup.name', headerName: 'adGroup' },
  {
    field: 'createdAt',
    headerName: 'createdAt',
    cellRenderer: (params: string) => <DateCell params={params} />,
  },
];

const sortablesList = ['code', 'name', 'createdAt'];

interface AdsTableProps {
  selectedAdGroupId: string;
  setSelectedAdGroupId: (_newSelectedAdGroupId: string) => void;
}

const AdsTable: FC<AdsTableProps> = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [editData, setEditData] = useState<AdInterface>();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { mutateAsync: deleteAd } = useDeleteAd();
  const [searchParams] = useSearchParams();
  const [sortBy, setSortBy] = useState<string>(searchParams.get('sortBy') || '');
  const [orderBy, setOrderBy] = useState<string>(searchParams.get('orderBy') || '');
  const [adGroupCode] = useState<string>(searchParams.get('adGroupCode') || '');
  const navigate = useNavigate();
  const organizationType = useOrganizationSettingsStore((state) => state.organizationSettings?.organizationType);

  const [searchInput, setSearchInput] = useState({
    name: searchParams.get('name') || '',
    adCode: searchParams.get('adCode') || '',
  });

  const adManagementFilterDateRange = useAdManagementFilterDateStore(
    (state) => state.adManagementFilterDateRange,
  );
  const setAdManagementFilterDateRange = useAdManagementFilterDateStore(
    (state) => state.setAdManagementFilterDateRange,
  );

  localStorage.setItem('from', searchParams.get('adManagementFilterDateFrom') || '');
  localStorage.setItem('to', searchParams.get('adManagementFilterDateTo') || '');

  const {
    data: adsData,
    isError,
    refetch: refetchAdsData,
    isFetching,
  } = useGetAds(
    ADS_TABLE,
    searchInput.name,
    searchInput.adCode,
    props.selectedAdGroupId,
    adManagementFilterDateRange[0],
    adManagementFilterDateRange[1],
    sortBy,
    orderBy,
    rowsPerPage,
    page + 1,
    adGroupCode,
  );

  const { mutate: fetchDownload } = useGetExportAdsTable(
    {
      name: searchInput.name,
      code: searchInput.adCode,
      selectedAdGroupId: props.selectedAdGroupId,
      from: adManagementFilterDateRange[0],
      to: adManagementFilterDateRange[1],
      sortBy,
      orderBy,
      rows: rowsPerPage,
      page: page + 1,
      adGroupCode,
    },
  );

  useEffect(() => {
    setPage(0);
  }, [adManagementFilterDateRange, sortBy, orderBy, props.selectedAdGroupId]);

  const { t } = useTranslation();

  const handleOnDelete = async (id: number) => {
    setIsDeleting(true);
    await deleteAd(id);
    await refetchAdsData();
    setIsDeleting(false);
  };

  const closeEditDialogAndRefetch = async () => {
    setIsEditDialogOpen(false);
    await refetchAdsData();
  };

  const navigateToAd = (code:number) => {
    navigate(`../../ad-page/${code}`);
  };

  return (
    <>
      <BasicDialog
        closeDialog={() => setIsEditDialogOpen(false)}
        open={isEditDialogOpen}
        title={editData?.id ? t('updateAd') : t('createAd')}
      >
        <AdsForm
          data={editData && editData}
          closeDialog={closeEditDialogAndRefetch}
        />
      </BasicDialog>
      <BasicTable
        data={adsData?.data}
        fetchDownload={fetchDownload}
        refetch={refetchAdsData}
        onDelete={(id) => handleOnDelete(id)}
        onEdit={(item: AdInterface) => setEditData(item)}
        setEditDialogIsOpen={(isOpen) => setIsEditDialogOpen(isOpen)}
        setPage={(newPage) => setPage(newPage)}
        setRowsPerPage={(newRowsPerPage) => setRowsPerPage(newRowsPerPage)}
        formInputs={adFormInputs}
        setSearchInput={setSearchInput}
        extendedColumns={organizationType === OrganizationType.ECOMMERCE ? eCommerceExtendedColumns : extendedColumns}
        filterDateRange={adManagementFilterDateRange}
        setFilterDateRange={setAdManagementFilterDateRange}
        handleNavigation={(item) => navigateToAd(item.code)}
        setSortBy={setSortBy}
        setOrderBy={setOrderBy}
        customFilters={(
          <AdGroupSelect
            identifier="table-ad-group-select"
            size="small"
            selectedAdGroupId={props.selectedAdGroupId}
            setSelectedAdGroupId={props.setSelectedAdGroupId}
          />
        )}
        {...{
          isError,
          isFetching,
          isDeleting,
          page,
          searchInput,
          columns,
          rowsPerPage,
          sortBy,
          orderBy,
          sortablesList,
        }}
      />
    </>
  );
};

export default AdsTable;
