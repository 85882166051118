import { useQuery, UseQueryResult } from 'react-query';
import { Dayjs } from 'dayjs';
import { CORE_API_SUFFIX, dataFetch } from '../../../axios/customAxios';
import { appendQueryParamIfNotEmpty, formatDate, generateQueryKey } from '../../../constants/hookConstants';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';

interface DeviceDistributionResponse {
    isMobile: number,
    isTablet: number,
    isDesktop: number,
    noRecordedDevice: number,
}

export const useGetDeviceDistribution = (
  identifier: string,
  id: number | undefined,
  from: Dayjs | null,
  to: Dayjs | null,
): UseQueryResult<ApiResponse<DeviceDistributionResponse>, ApiError<DeviceDistributionResponse>> => {
  const queryParams = new URLSearchParams();
  appendQueryParamIfNotEmpty(queryParams, 'from', formatDate(from));
  appendQueryParamIfNotEmpty(queryParams, 'to', formatDate(to));

  const queryKey = generateQueryKey(identifier, queryParams);

  return useQuery<ApiResponse<DeviceDistributionResponse>, ApiError<DeviceDistributionResponse>>(
    [`device_reports_${queryKey}`],
    () => dataFetch.get(`${CORE_API_SUFFIX}ad/device-distribution/${id}?${queryParams}`),
  );
};
