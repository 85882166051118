import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ConversionTableInterface {
  title?: string;
  imgSource?: string;
  conversionData?: any;
}

const ConversionTable: FC<ConversionTableInterface> = (props) => {
  const { t } = useTranslation();

  const array = [
    'Conversion Name',
    'Click Through Lookback Window Days',
    'Counting Type',
    'Attribution',
    'Value',
  ];

  const keyArray = [
    'name',
    'clickThroughLookbackWindowDays',
    'countingType',
    'attribution',
    'value',
  ];

  return (
    <div className="mb-4 w-full rounded-lg bg-white px-5 py-4">
      <div className="mb-2 flex w-full items-center justify-between text-xl text-primary">
        <div className="flex items-center gap-4">
          <img src={props.imgSource} className="h-6" alt="Icon" />
          <p>{props.title}</p>
        </div>
        <div className="flex h-10 items-center" />
      </div>
      <div className="flex h-[90px] w-full items-center justify-between">
        {array.map((item: string, index: number) => (
          <div className={`flex w-full flex-col ${index === array.length ? '' : 'mr-2'}`} key={index}>
            <p className="h-full w-full truncate rounded-t-lg bg-[#e7edef] px-4 py-3 text-sm font-medium text-primary">
              {item}
            </p>
            <p className="h-full w-full rounded-b-lg border px-4 py-3 text-xs text-primary">
              {props.conversionData ? props.conversionData[keyArray[index]] : 'No data'}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConversionTable;
