import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { CustomButton } from '../../../components/buttons/customButton';
import BasicDialog from '../../../dialogs/basicDialog';
import TextInput from '../../../components/inputs/textInput';
import { shopifyIntegrationUrl } from '../../../constants/integrationConstants';
import { statusCard } from '../../settings/integrations/googleTabPanel';
import useGetOrganizationSettings from '../../../apiHooks/queries/useGetOrganizations';
import { ORGANIZATION_SETTINGS } from '../../../constants/pageIdentifiers';
import LoadingSpinner from '../../../components/loadingSpinner/loadingSpinner';
import ConfirmDialog from '../../../dialogs/confirmDialog';
import { useDisconnectShopify } from '../../../apiHooks/mutations/shopifyMutations/shopifyDisconnectMutation';

const ConnectToShopify: FC = () => {
  const { t } = useTranslation();
  const [storeName, setStoreName] = useState('');
  const [isStoreNameDialogOpen, setIsStoreNameDialogOpen] = useState(false);
  const [isDisconnectDialogOpen, setIsDisconnectDialogOpen] = useState(false);

  const { data, isLoading } = useGetOrganizationSettings(ORGANIZATION_SETTINGS);
  const { mutateAsync } = useDisconnectShopify();
  const queryClient = useQueryClient();

  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <LoadingSpinner color="primary" size={100} />

      </div>
    );
  }

  if (data?.data.data.shopifyShop) {
    return (
      <div className="flex gap-3.5">
        <ConfirmDialog
          variant="danger"
          open={isDisconnectDialogOpen}
          title="areYouSureYouWantToDisconnect"
          text="thisActionIsGoingToDisconnectTheAppFromShopify"
          onCloseDialog={() => setIsDisconnectDialogOpen(false)}
          onSubmit={() => {
            mutateAsync().then(() => {
              queryClient.invalidateQueries({
                queryKey: [ORGANIZATION_SETTINGS],
                exact: true,
              });

              setIsDisconnectDialogOpen(true);
            });
          }}
          isDisabled={isLoading}
        />
        <div className="flex min-w-[245px] items-center justify-between gap-3.5 rounded-lg border border-dark px-5 py-3.5">
          <p className="text-xs font-medium text-primary">
            {t('accountStatus')}
          </p>
          {statusCard(t, true)}
        </div>
        <div className="flex w-full items-center justify-between gap-5 rounded-lg border border-dark px-5 py-4">
          <div className="max-lg:w-[60%]">
            <p className="text-xs font-semibold leading-[1.5] text-primary">
              {t('disconnectShopifyTitle')}
            </p>
            <p className="text-xs leading-[1.5] text-primary">
              {t('disconnectShopifySubTitle')}
            </p>
          </div>
          <CustomButton
            disabled={false}
            className="px-6 py-2.5 text-sm"
            onClick={() => {
              setIsDisconnectDialogOpen(true);
            }}
          >
            {t('disconnectFromShopify')}
          </CustomButton>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-between gap-5 rounded-lg border border-dark px-5 py-4">
      <div className="max-lg:w-[60%]">
        <p className="text-xs font-semibold leading-[1.5] text-primary">
          {t('connectToShopifyTitle')}
        </p>
        <p className="text-xs leading-[1.5] text-primary">
          {t('connectToShopifySubTitle')}
        </p>
      </div>
      <CustomButton
        disabled={false}
        className="px-6 py-2.5 text-sm"
        onClick={() => setIsStoreNameDialogOpen(true)}
      >
        {t('connectToShopify')}
      </CustomButton>
      <BasicDialog
        closeDialog={() => setIsStoreNameDialogOpen(false)}
        open={isStoreNameDialogOpen}
        title={t('enterShopName')}
      >
        <div className="flex flex-col items-center justify-center gap-5">
          <TextInput
            name="shopiy"
            value={storeName}
            label={t('storeName')}
            fullWidth
            placeholder={t('storeName')}
            onChange={(e) => setStoreName(e.target.value)}
          />

          <CustomButton
            disabled={!storeName}
            className="h-[48px] w-full justify-center"
            onClick={() => {
              window.location.href = shopifyIntegrationUrl(storeName, data?.data.data.organizationId ?? 1);
            }}
          >
            {t('connectToShopify')}
          </CustomButton>
        </div>
      </BasicDialog>
    </div>
  );
};

export default ConnectToShopify;
