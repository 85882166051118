import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import EmailInput from '../components/inputs/emailInput';
import PasswordInput from '../components/inputs/passwordInput';
import useMutateLogin from '../apiHooks/mutations/authMutations/authMutations';
import { authFormValidationSchema } from '../validations/authFormValidationSchema';
import PrimaryButton from '../components/buttons/primaryButton';
import { AuthRequest } from '../types/api/auth';
import TwoFaDialog from '../dialogs/twoFaDialog';
import { useTwoFaStore } from '../store/twoFaStore';

const AuthForm = () => {
  const { mutate: sendMutateLogin, isLoading } = useMutateLogin();
  const { t } = useTranslation();
  const { twoFaActive, setTwoFaActive } = useTwoFaStore();
  const navigate = useNavigate();

  const location = useLocation();
  const showAuthForm = !location.pathname.includes('reset-password') && !location.pathname.includes('forgot-password');

  const formik = useFormik<AuthRequest>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: () => authFormValidationSchema(t),
    onSubmit: (values) => {
      sendMutateLogin(values);
    },
  });

  return (
    <>
      <TwoFaDialog
        open={twoFaActive}
        onClose={() => setTwoFaActive(false)}
      />
      <Outlet />
      {showAuthForm
      && (
      <div className="flex w-full items-center justify-center">
        <form
          onSubmit={formik.handleSubmit}
          className="flex max-h-[400px] w-full  max-w-[400px] flex-col items-center justify-center sm:py-[67px]"
        >
          <div className="flex w-full flex-col items-start gap-2">
            <h4 className="text-[32px] font-semibold leading-[1.5] text-primary">
              {t('login')}
            </h4>
            <p className="text-primary">{t('welcomeBacktoDigtective')}</p>
          </div>
          <div className="mt-8 flex w-full flex-col gap-5 lg:mt-10">
            <EmailInput
              id="email"
              myKey="email"
              name="email"
              label={t('email')}
              placeholder={t('email')}
              size="small"
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={Boolean(formik.touched.email) && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
              dataCy="login-email"
            />
            <PasswordInput
              id="password"
              myKey="password"
              name="password"
              label={t('password')}
              placeholder={t('password')}
              size="small"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={Boolean(formik.touched.password) && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password ? formik.errors.password : ''}
              fullWidth
              isVisibilityToggleable
              dataCy="login-password"
            />
            <PrimaryButton disabled={isLoading} type="submit" className="w-full" dataCy="login-submit">
              {t('login')}
            </PrimaryButton>
          </div>
          <button
            type="button"
            onClick={() => navigate('/auth/forgot-password')}
            className="mt-5 text-sm text-primary hover:underline active:text-primary/80"
          >
            {t('forgotPassword')}
          </button>
        </form>
      </div>
      )}
    </>

  );
};

export default AuthForm;
