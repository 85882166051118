import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import { handleApiError } from '../../requestHelpers/requestHelpers';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';
import closableEnqueueSnackbar from '../../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';

export const useSendAcceptManage = () => {
  const { t } = useTranslation();

  return useMutation<ApiResponse<null>, ApiError<null>, string>(
    (token: string) => dataFetch.post(
      `${DIGGER_API_SUFFIX}organizations/accept-request`,
      {
        manageToken: token,
      },
    ),
    {
      onSuccess: () => {
        closableEnqueueSnackbar(t('acceptedRequest'), 'success');
      },
      onError: (error: any) => {
        handleApiError(error, t);
      },
    },
  );
};
