import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { SnackbarProvider } from 'notistack';
import './index.css';
import './i18n';
import { LicenseInfo } from '@mui/x-license-pro';
import * as Sentry from '@sentry/react';
import Fallback from './components/fallback/fallback';
import App from './App';

LicenseInfo.setLicenseKey(import.meta.env.VITE_REACT_APP__MUI_PRO_LICENCE_KEY);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

Sentry.init({
  environment: import.meta.env.VITE_APP_ENV,
  sampleRate: 0.1,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 0,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider maxSnack={5} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <React.Suspense fallback={<Fallback />}>
          <App />
        </React.Suspense>
      </SnackbarProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
);
