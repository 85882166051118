import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import closableEnqueueSnackbar from '../../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';

export const useDisconnectHubspot = () => {
  const { t } = useTranslation();

  return useMutation(
    () => dataFetch.delete(
      `${DIGGER_API_SUFFIX}hubspot/uninstall`,
    ),
    {
      onSuccess: () => {
        closableEnqueueSnackbar(t('hubspotDisconnectedSuccessfully'), 'success');
      },
      onError: () => {
        closableEnqueueSnackbar(t('somethingWentWrong'), 'error');
      },
    },
  );
};
