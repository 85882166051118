import * as yup from 'yup';

export const welcomeSignUpValidationSchema = (t: any) => yup.object({
  firstName: yup
    .string()
    .required(t('inputIsRequired')),
  lastName: yup
    .string()
    .required(t('inputIsRequired')),
  orgName: yup
    .string()
    .required(t('inputIsRequired')),
  companyNumber: yup
    .string()
    .required(t('inputIsRequired')),
  taxIdentifier: yup
    .string()
    .required(t('inputIsRequired')),
  country: yup
    .string()
    .required(t('inputIsRequired')),
  region: yup
    .string()
    .required(t('inputIsRequired')),
  street: yup
    .string()
    .required(t('inputIsRequired')),
  zipCode: yup
    .string()
    .required(t('inputIsRequired')),
  password: yup
    .string()
    .min(8, t('passwordMinimumEightChars'))
    .required(t('inputIsRequired') ?? ''),
  confirmPassword: yup
    .string()
    .required(t('inputIsRequired') ?? '')
    .oneOf([yup.ref('password')], t('passwordsMustMatch') ?? ''),
});
