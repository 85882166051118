import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSendTwoFaCode } from '../../apiHooks/mutations/profileMutations/sendTwoFaCode';
import useMutateLogin from '../../apiHooks/mutations/authMutations/authMutations';
import { useLoginInfoStore } from '../../store/twoFaStore';

interface TwoFactorInputProps {
    onCodeChange: (_code: string) => void;
    closeDialog: (_dialogState: boolean) => void;
    closeButton ?: boolean;
    login ?: boolean;
    title : string;
}

const TwoFactorInput: React.FC<TwoFactorInputProps> = (props) => {
  const { t } = useTranslation();
  const [code, setCode] = useState<string>('');
  const [focusedIndex, setFocusedIndex] = useState<number>(0);
  const [isHighlightOn, setIsHighlightOn] = useState<boolean>(false);
  const digitRefs = useRef<Array<HTMLInputElement | null>>(Array(6).fill(null));
  const { loginInfo } = useLoginInfoStore();

  const { mutate: sendCode } = useSendTwoFaCode();
  const { mutate: sendLogin } = useMutateLogin();

  useEffect(() => {
    props.onCodeChange(code);
    if (code.length === 6) {
      if (props.login) {
        sendLogin({ email: loginInfo.email, password: loginInfo.password, twoFACode: code });
      } else {
        sendCode(code);
      }
      setCode('');
      props.closeDialog(false);
    }
  }, [code, props.onCodeChange]);

  const handleInputChange = (index: number, value: string) => {
    const newCode = code.split('');
    newCode[index] = value;
    setCode(newCode.join(''));

    if (index < digitRefs.current.length - 1 && value !== '') {
      digitRefs.current[index + 1]?.focus();
      setFocusedIndex(index + 1);
      setIsHighlightOn(true);
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text/plain').replace(/\D/g, '').substr(0, 6);
    const newCode = pastedData.padEnd(6, ' ');
    setCode(newCode);
    props.onCodeChange(newCode);
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && index > 0) {
      digitRefs.current[index - 1]?.focus();
      setFocusedIndex(index - 1);
      setIsHighlightOn(false);
    }
  };

  const getNextBoxBorderColor = (currentIndex: number) => (currentIndex === focusedIndex && isHighlightOn ? '#0C485E' : '#BDBDBD');

  return (
    <div className="flex h-[140px] items-center justify-center">
      <div className="flex h-full w-full max-w-[450px] flex-col items-center justify-between">
        <p className="mt-4 text-sm text-primary">{t(props.title)}</p>
        <div className="mb-6 flex flex-col items-center">
          <div style={{ display: 'flex' }}>
            {Array.from({ length: 6 }, (_, index) => (
              <input
                key={index}
                type="text"
                maxLength={1}
                value={code[index]}
                onChange={(e) => handleInputChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                onPaste={handlePaste}
                ref={(ref) => (digitRefs.current[index] = ref)}
                style={{
                  border: `1px solid ${getNextBoxBorderColor(index)}`,
                  borderRadius: '5px',
                  width: '50px',
                  height: '50px',
                  textAlign: 'center',
                  marginRight: '5px',
                  outline: 'none',
                  fontSize: '1.5em',
                }}
              />
            ))}
          </div>
        </div>
        <div className="flex w-full items-center justify-end gap-2">
          {props.closeButton && (
            <button onClick={() => props.closeDialog(false)} className="px-4 py-2 text-primary" type="button">
              {t('cancel')}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TwoFactorInput;
