import React, { FC } from 'react';
import {
  Chart as ChartJS, ArcElement, Legend, ChartOptions,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Legend);

interface DonutChartProps {
    data: any,
}

const DonutChart: FC<DonutChartProps> = (props) => {
  const options: ChartOptions<'doughnut'> = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: 45,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const sum = props.data.reduce((acc: any, val: any) => acc + val, 0);
  const hasData = props.data.length > 0 && sum > 0;

  const deviceData = {
    labels: hasData ? ['Desktop', 'Tablet', 'Mobile', 'Unknown Device'] : ['No data'],
    datasets: [
      {
        label: 'Number of Users',
        data: hasData ? props.data : [1],
        backgroundColor: hasData ? [
          'rgba(158, 182, 191, 1)',
          'rgba(183, 213, 196, 1)',
          'rgba(255, 197, 119, 1)',
          'rgba(233, 137, 133, 1)',
          'rgba(248, 150, 30, 1)',
          'rgba(249, 199, 79, 1)',
        ] : ['rgba(234, 234, 234, 1)'],
        borderColor: [
          'rgba(234, 234, 234, 1)',
          'rgba(234, 234, 234, 1)',
          'rgba(234, 234, 234, 1)',
          'rgba(234, 234, 234, 1)',
          'rgba(234, 234, 234, 1)',
          'rgba(234, 234, 234, 1)',
        ],
        borderWidth: 5,
      },
    ],
  };

  return (
    <Doughnut data={deviceData} options={options} />
  );
};

export default DonutChart;
