import React, { FC } from 'react';
import CloseIcon from '../../../../assets/icons/close-icon.svg';
import { HubspotContactSettingsContentInterface } from '../../../../interfaces/hubspot.interface';
import {
  useDeleteHubspotContactSettingsCard,
} from '../../../../apiHooks/mutations/hubspotFormMutations/hubspotFormMutations';

interface HubspotContactSettingsContentCardProps {
    card: HubspotContactSettingsContentInterface;
    refetch: () => void;
}
const HubspotContactSettingsContentCard: FC<HubspotContactSettingsContentCardProps> = (props) => {
  const {
    mutateAsync: deleteCard,
    isLoading: isDeletingCard,
  } = useDeleteHubspotContactSettingsCard(props.refetch);

  return (
    <div key={props.card.id} className="relative min-h-[90px] rounded-lg border border-gray p-3.5">
      <p className="text-sm text-primary">
        {props.card.label}
      </p>
      <button
        type="button"
        className="absolute right-1.5 top-1.5 box-border rounded-2xl p-1 hover:bg-primary/10 active:bg-primary/20"
        onClick={() => deleteCard(props.card.id)}
        disabled={isDeletingCard}
      >
        <img src={CloseIcon} alt="" className="h-4 w-4 object-contain" />
      </button>
    </div>
  );
}

export default HubspotContactSettingsContentCard;
