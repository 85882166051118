import { useDrop } from 'react-dnd';
import { useUpdateHubspotFormStageStatus } from '../apiHooks/mutations/hubspotFormMutations/hubspotFormMutations';
import { DraggableStatusCardsInterface } from '../interfaces/draggableStatusCards';

const useBoard = (statusCards?: DraggableStatusCardsInterface[], pipelineId?: string, won?: boolean, refetchAll?: () => void) => {
  const {
    mutateAsync: mutateAsyncUpdatePipelineStage, isLoading: isUpdatingPipelineStage,
  } = useUpdateHubspotFormStageStatus(pipelineId, won, refetchAll);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'card',
    drop: async (item: DraggableStatusCardsInterface) => {
      await mutateAsyncUpdatePipelineStage({
        stageId: item.stageId,
        label: item.label,
      });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  return {
    board: statusCards, isOver, drop, isUpdatingPipelineStage,
  };
};

export default useBoard;
