import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import useGetOperatingSystem from '../../apiHooks/queries/getOperatingSystem/useGetOperatingSystem';
import useGetOperatingSystemVersions from '../../apiHooks/queries/getOperatingSystemVersion/useGetOperatingSystemVersion';
import { useAdPageBackStore } from '../../store/adPageStore';

interface OperatingSystemInterface {
  adCode: string,
  dateRange: DateRange<Dayjs>,
}

const OperatingSystems: FC<OperatingSystemInterface> = (props) => {
  const { t } = useTranslation();
  const {
    selectedOs, setSelectedOs, selectedIndex, setSelectedIndex,
  } = useAdPageBackStore((state) => state);

  const { data: osData } = useGetOperatingSystem(
    'AD_OS_SYSTEM',
    true,
    props.adCode,
    props.dateRange[0],
    props.dateRange[1],
  );

  const { data: osVersionData, refetch: refetchOsVersions, isFetching } = useGetOperatingSystemVersions(
    'AD_OS_VERSIONS',
    selectedOs || '',
    selectedOs !== '',
    props.adCode,
    props.dateRange[0],
    props.dateRange[1],
  );

  useEffect(() => {
    if (selectedOs !== '') {
      refetchOsVersions();
    }
  }, [selectedOs, refetchOsVersions]);

  const handleClick = (operatingSystem: string, index: number) => {
    if (selectedOs === operatingSystem) {
      setSelectedOs('');
      setSelectedIndex(null);
    } else {
      setSelectedOs(operatingSystem);
      setSelectedIndex(index);
    }
  };

  return (
    <div className="mt-4 flex flex-col items-center justify-center">
      {osData && osData?.data?.data.length > 0 ? osData?.data?.data?.map((item: any, index: number) => (
        <div key={item.operatingSystem} className="w-[90%]">
          {selectedIndex === null || selectedIndex === index ? (
            <button
              disabled={item.operatingSystem === ''}
              onClick={() => handleClick(item.operatingSystem, index)}
              className={`mt-4 flex w-full justify-between rounded-xl border p-4 ${selectedOs === item.operatingSystem ? 'border-primary' : ''}`}
            >
              <p className="text-sm text-primary">
                {item.operatingSystem === '' ? 'Unknown' : item.operatingSystem}
              </p>
              <span className="text-sm font-semibold text-primary">{item.count}</span>
            </button>
          ) : null}
        </div>
      )) : <div className="flex h-full w-full items-center justify-center">No data available...</div>}

      {selectedOs && selectedIndex !== null && (
        <div className="mt-4 flex w-full items-start justify-start ">
          <ul className="flex max-h-[400px] w-full flex-col overflow-y-scroll">
            {isFetching ? (
              <li className="flex w-full justify-between px-6 py-4">Loading...</li>
            ) : (
              osVersionData?.data?.data.map((version: any, index: number) => (
                <li
                  key={version.version}
                  className={`flex w-full justify-between px-6 py-4 
                ${index % 2 === 0 ? 'bg-[#FAFAFA]' : 'bg-white'}`}
                >
                  <span>{version.version === '' ? 'Unknown' : version.version}</span>
                  <span className="font-normal">{version.count}</span>
                </li>
              ))
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default OperatingSystems;
