import React, { FC, useState } from 'react';
import { useDrag } from 'react-dnd';
import DeleteIcon from '../../../../assets/icons/close-icon.svg';
import { DraggableStatusCardsInterface } from '../../../../interfaces/draggableStatusCards';
import LoadingSpinner from '../../../../components/loadingSpinner/loadingSpinner';

interface DraggableStatusCardProps {
  card: DraggableStatusCardsInterface;
  deleteHubspotForm?: (_stageId: string) => void;
  isDeletingHubspotForm?: boolean;
}

const DraggableStatusCard: FC<DraggableStatusCardProps> = (props) => {
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: {
      stageId: props.card.stageId,
      label: props.card.label,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div
      key={props.card.stageId}
      className="relative h-[106px] w-full rounded-lg border border-gray bg-green/10 p-2.5"
      style={{ opacity: isDragging ? 0.4 : 1 }}
      ref={drag}
      onMouseEnter={() => setShowDeleteIcon(true)}
      onMouseLeave={() => setShowDeleteIcon(false)}
    >
      {showDeleteIcon && props.deleteHubspotForm && (
      <button
        type="button"
        className="absolute right-0 top-0 box-content rounded-2xl p-1 hover:bg-primary/10 active:bg-primary/20"
        onClick={() => props.deleteHubspotForm?.(props.card.stageId)}
      >
        {!props.isDeletingHubspotForm ? (
        <img
          src={DeleteIcon}
          alt=""
          className="h-4 w-4 object-contain"
        />) : (
          <LoadingSpinner color="primary" center size={15} />
        )}

      </button>
      )}
      <p className="break-all text-xs font-[500] leading-[1.1] text-green">{props.card.stageId}</p>
      <p className="mt-4 break-all text-lg font-[600] leading-[1.1] text-green">{props.card.label}</p>
    </div>
  );
};

export default DraggableStatusCard;
