import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro';
import { TableColumnInterface } from '../../interfaces/tableColumn.interface';
import BasicTable from './basicTable';
import useGetUsers from '../../apiHooks/queries/useGetUsers';
import { USERS_TABLE } from '../../constants/pageIdentifiers';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants/constants';
import { UserInterface } from '../../interfaces/user.interface';
import BasicDialog from '../../dialogs/basicDialog';
import UsersForm from '../../forms/usersForm';
import { useDeleteUser, useGenerateNewPassword } from '../../apiHooks/mutations/userMutations/userMutations';
import { userFormInputs } from '../../constants/modalInputs/userFormInputs';
import DateCell from '../../partials/dateCell';
import NewPasswordDialogContent from '../newPasswordDialogContent/newPasswordDialogContent';
import ConfirmDialog from '../../dialogs/confirmDialog';

const columns: TableColumnInterface[] = [
  { field: 'firstName', headerName: 'firstName' },
  { field: 'lastName', headerName: 'lastName' },
  { field: 'email', headerName: 'email' },
  {
    field: 'createdAt',
    headerName: 'createdAt',
    cellRenderer: (params: string) => <DateCell params={params} />,
  },
];

const sortablesList = ['firstName', 'lastName', 'email', 'createdAt'];

const UsersTable = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [editData, setEditData] = useState<UserInterface>();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false);
  const { mutateAsync: deleteUser, isLoading: isDeleting } = useDeleteUser();
  const { mutateAsync: generateNewPassword, isLoading: isGeneratingNewPassword } = useGenerateNewPassword();
  const [searchParams] = useSearchParams();
  const [isAreYouSureDialogOpen, setIsAreYouSureDialogOpen] = useState(false);
  const [usersFilterDateRange, setUsersFilterDateRange] = useState<DateRange<Dayjs>>([
    searchParams.get('from') ? dayjs(searchParams.get('from')) : null,
    searchParams.get('to') ? dayjs(searchParams.get('to')) : null,
  ]);
  const [sortBy, setSortBy] = useState<string>(searchParams.get('sortBy') || '');
  const [orderBy, setOrderBy] = useState<string>(searchParams.get('orderBy') || '');
  const [searchInput, setSearchInput] = useState({
    firstName: searchParams.get('firstName') || '',
    lastName: searchParams.get('lastName') || '',
    email: searchParams.get('email') || '',
  });

  const { t } = useTranslation();

  const {
    data: usersData,
    isError,
    refetch: refetchUsersData,
    isFetching,
  } = useGetUsers(
    USERS_TABLE,
    searchInput.firstName,
    searchInput.lastName,
    searchInput.email,
    orderBy,
    sortBy,
    usersFilterDateRange[0],
    usersFilterDateRange[1],
    rowsPerPage,
    page + 1,
  );

  useEffect(() => {
    setPage(0);
  }, [usersFilterDateRange, sortBy, orderBy]);

  const handleOnDelete = async (id: number) => {
    await deleteUser(id);
    await refetchUsersData();
  };

  const closeEditDialogAndRefetch = async () => {
    setIsEditDialogOpen(false);
    await refetchUsersData();
  };

  const handleGenerateNewPassword = async (id: number | undefined) => {
    const response = await generateNewPassword(id);
    setNewPassword(response?.data?.data?.password);
    setIsPasswordDialogOpen(true);
  };

  return (
    <>
      <BasicDialog
        closeDialog={() => setIsEditDialogOpen(false)}
        open={isEditDialogOpen}
        title={editData?.id ? t('updateUser') : t('createUser')}
      >
        <UsersForm
          data={editData && editData}
          closeDialog={closeEditDialogAndRefetch}
        />
      </BasicDialog>
      <BasicDialog
        closeDialog={() => setIsPasswordDialogOpen(false)}
        open={isPasswordDialogOpen && !!newPassword}
        title={t('newPassword')}
      >
        <NewPasswordDialogContent
          newPassword={newPassword}
          setNewPassword={setNewPassword}
          setIsPasswordDialogOpen={setIsPasswordDialogOpen}
        />
      </BasicDialog>
      <ConfirmDialog
        text="areYouSureNewPassword"
        title="generateNewPassword"
        variant="warning"
        onCloseDialog={() => setIsAreYouSureDialogOpen(false)}
        open={isAreYouSureDialogOpen}
        onSubmit={() => handleGenerateNewPassword(editData?.id).then(() => setIsAreYouSureDialogOpen(false))}
        isDisabled={isGeneratingNewPassword}
      />
      <BasicTable
        data={usersData?.data}
        refetch={refetchUsersData}
        onDelete={(id) => handleOnDelete(id)}
        formInputs={userFormInputs}
        setPage={(newPage) => setPage(newPage)}
        onEdit={(item: UserInterface) => setEditData(item)}
        setEditDialogIsOpen={(isOpen) => setIsEditDialogOpen(isOpen)}
        setRowsPerPage={(newRowsPerPage) => setRowsPerPage(newRowsPerPage)}
        setSearchInput={setSearchInput}
        setIsAreYouSureDialogOpen={setIsAreYouSureDialogOpen}
        setSortBy={setSortBy}
        setOrderBy={setOrderBy}
        filterDateRange={usersFilterDateRange}
        setFilterDateRange={setUsersFilterDateRange}
        canGenerateNewPassword
        {...{
          isError,
          isFetching,
          isDeleting,
          page,
          searchInput,
          columns,
          rowsPerPage,
          orderBy,
          sortBy,
          sortablesList,
        }}
      />
    </>
  );
};
export default UsersTable;
