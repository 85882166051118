import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import TextInput from '../inputs/textInput';
import CampaignSelect from '../dropdownSelects/campaignSelect';
import { useUpdateAdGroup } from '../../apiHooks/mutations/adManagementMutations/adGroupsMutations';
import { CustomButton } from '../buttons/customButton';

interface AdGroupFormProps {
    initialValues?: any;
    onSubmit: () => void;
    close: () => void;
    setSelectedAdGroupId: (_adGroupCode : string) => void;
    selectedCampaignCode : string;
    setSelectedCampaignCode : (_campaignCode : string) => void;
}

const AdGroupForm: React.FC<AdGroupFormProps> = (props) => {
  const { t } = useTranslation();
  const { mutateAsync: updateAdGroup } = useUpdateAdGroup();
  const formik = useFormik({
    initialValues: props.initialValues || {
      id: 0,
      name: '',
      campaignId: '',
      adGroupCode: '',
    },
    onSubmit: async (values) => {
      await updateAdGroup(values).then(
        (response) => props.setSelectedAdGroupId(response?.data?.data?.id),
      );
      props.onSubmit();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="flex w-full flex-col items-center">
      <div className="flex w-full flex-col">
        <div className="w-full">
          <TextInput
            myKey="adGroupMame"
            className="w-full"
            size="small"
            name="name"
            value={formik.values.name}
            placeholder={t('name')}
            onChange={(e) => formik.handleChange(e)}
            label={t('name')}
          />
        </div>
        <div className="my-4 w-full">
          <TextInput
            name="adGroupCode"
            myKey="adGroupCode"
            size="small"
            label={t('adGroupCode')}
            value={formik.values.adGroupCode}
            placeholder={t('adGroupCode')}
            onChange={(e) => formik.handleChange(e)}
            className="w-full"
          />
        </div>
        <CampaignSelect
          size="small"
          identifier="campaign"
          formik={formik}
          selectedCampaignId={props.selectedCampaignCode}
          setSelectedCampaignId={props.setSelectedCampaignCode}
        />
      </div>
      <div className="flex w-full items-center justify-end gap-2 pb-2 pt-8">
        <CustomButton
          variant="noOutline"
          type="button"
          onClick={() => props.close()}
          className="rounded-lg px-4 py-2.5 text-primary"
        >
          {t('cancel')}
        </CustomButton>
        <CustomButton type="submit" className="rounded-lg bg-primary px-4 py-2.5 text-white">
          {t('create')}
        </CustomButton>
      </div>
    </form>
  );
};

export default AdGroupForm;
