export enum HubspotIntegrationsTabLinks {
  forms,
  'contact-settings',
  'deal-settings'
}

export enum CRMTabLinks {
  hubspot
}

export enum AdProvidersTabLinks {
  google,
  facebook,
  bing,
}

export enum PaymentProviderTabLinks {
  paddle,
}

export enum EcommerceProviderTabLinks {
  shopify,
}
