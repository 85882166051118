import React, { FC, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { FormControlLabel } from '@mui/material';
import LeftAngle from '../../assets/icons/left-angle.svg';
import RightAngle from '../../assets/icons/right-angle.svg';
import LoadingSpinner from '../loadingSpinner/loadingSpinner';
import InfoIcon from '../../assets/icons/info-icon.svg';
import EmptyBigCard from '../dashboard/dashboardCards/emptyBigCard';
import { REFERRER_REPORT } from '../../constants/pageIdentifiers';
import { useDashboardFilterDateStore } from '../../store/dateRangeStore';
import { useGetReferrersReport } from '../../apiHooks/queries/reports/useGetReferrersReport';
import SwitchInput from '../inputs/switchInput';
import AdsReportTable from '../../partials/adsReportTable';
import { TableColumnInterface } from '../../interfaces/tableColumn.interface';
import VatDisplayer from '../../partials/VATDisplayer';
import { useOrganizationSettingsStore } from '../../store/organizationSettingsStore';
import OrganizationType from '../enums/organizationTypesEnum';

export const referrerReportColumns: TableColumnInterface[] = [
  {
    field: 'formSubmissions',
    headerName: 'fsCountSeo',
    cellRenderer: (params) => (
      <span>
        {
          params
            ? (Math.round(params)
              ?.toLocaleString())
            : (0)
        }
      </span>
    ),
  },
  {
    field: 'sales',
    headerName: 'sales',
    cellRenderer: (params) => (
      <span>
        {
          params
            ? (Math.round(params)
              ?.toLocaleString())
            : (0)
        }
      </span>
    ),
  },
  {
    field: 'income',
    headerName: 'income',
    cellRenderer: (params) => <VatDisplayer value={params} />,
  },
  {
    field: 'cvRateSeo',
    headerName: 'cvRateSeo',
    cellRenderer: (params, rowObject) => (
      <span>
        {rowObject?.sales && rowObject?.formSubmissions && (
          Math.round((rowObject.sales / (rowObject.formSubmissions || 1)) * 100)
        )}
        %
      </span>
    ),
  },
];

export const eCommerceReferrerReportColumns: TableColumnInterface[] = [
  {
    field: 'sales',
    headerName: 'sales',
    cellRenderer: (params) => (
      <span>
        {
          params
            ? (Math.round(params)
              ?.toLocaleString())
            : (0)
        }
      </span>
    ),
  },
  {
    field: 'income',
    headerName: 'income',
    cellRenderer: (params) => <VatDisplayer value={params} />,
  },
];

export const referrerReportFixedColumns: TableColumnInterface[] = [
  {
    field: 'referrer',
    headerName: 'referrer',
    cellRenderer: (params: string) => (
      <Tooltip
        arrow
        placement="top"
        title={params}
      >
        <span className="max-w-[190px] truncate 2xl:max-w-[250px]">
          {params === '' ? 'Direct' : params}
        </span>
      </Tooltip>
    ),
  },
];

const ReferrerReport: FC = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const dashboardFilterDateRange = useDashboardFilterDateStore((state) => state.dashboardFilterDateRange);
  const [isToggledOriginalReferrer, setIsToggledOriginalReferrer] = useState(false);
  const organizationType = useOrganizationSettingsStore((state) => state.organizationSettings?.organizationType);

  const {
    data: referrerReport,
    isFetching,
    isError,
  } = useGetReferrersReport(
    REFERRER_REPORT,
    dashboardFilterDateRange[0],
    dashboardFilterDateRange[1],
    page || 0,
    isToggledOriginalReferrer,
  );

  const reportData = referrerReport?.data?.data?.report;

  const handlePrevPage = () => {
    setPage(page - 1);
  };

  const handleNextPage = () => {
    setPage(page + 1);
  };

  if (referrerReport?.data?.data?.report === null && page > 0) {
    setPage(0);
  }

  const toggleReferrer = () => {
    setIsToggledOriginalReferrer(!isToggledOriginalReferrer);
    setPage(0);
  };

  return (
    <div className="flex h-full flex-1 flex-col text-clip rounded-lg">
      <div
        className="flex max-h-[47px] items-center justify-between border-b border-dark px-5 py-2"
      >
        <div className="flex items-center gap-4">
          <h2 className="text-lg font-medium leading-[36px] text-primary">
            {t('referrerReportTitle')}
          </h2>
          <Tooltip title={t('referrerReportInfo')}>
            <img src={InfoIcon} alt="" className="max-w-[20px]" />
          </Tooltip>
        </div>
        <div className="flex items-center gap-1">
          <FormControlLabel
            control={(
              <SwitchInput
                checked={isToggledOriginalReferrer}
              />
            )}
            className="relative left-3 flex flex-row-reverse gap-5 pl-[11px] text-xs leading-[1.5] text-primary"
            label={isToggledOriginalReferrer ? t('seeLastEntries') : t('seeEntryPoints')}
            name="isRequired"
            onChange={toggleReferrer}
          />
        </div>
      </div>
      <div className="flex flex-1 flex-col gap-3">
        <div className="relative w-full flex-1 flex-col">
          {!isFetching && !isError && reportData === null && (
            <EmptyBigCard />
          )}
          {isFetching && <LoadingSpinner center color="primary" />}
          {isError && !isFetching && (
            <p>
              {t('errorRetrievingData')}
            </p>
          )}
          {!isFetching && !isError && reportData !== null && (
            <AdsReportTable
              data={referrerReport?.data?.data?.report}
              isFetching={isFetching}
              isError={isError}
              columns={organizationType === OrganizationType.ECOMMERCE
                ? eCommerceReferrerReportColumns
                : referrerReportColumns}
              fixedColumns={referrerReportFixedColumns}
            />
          )}
        </div>
        {!isFetching && !isError && reportData !== null && (
          <div className="mb-[22px] mt-3 flex items-center justify-center gap-4">
            <button
              type="button"
              onClick={handlePrevPage}
              className={`
              ${page === 0
                ? 'cursor-default opacity-30'
                : 'cursor-pointer hover:bg-primary/20 active:bg-primary/30'}
               grid h-[28px] w-[28px] place-content-center rounded-lg bg-primary/10 
            `}
              disabled={page === 0 || isFetching}
            >
              <img src={LeftAngle} alt="" />
            </button>
            <button
              type="button"
              onClick={handleNextPage}
              className={`
              ${!referrerReport?.data?.data?.hasNextPage
                ? 'cursor-default opacity-30'
                : 'cursor-pointer hover:bg-primary/20 active:bg-primary/30'}
               grid h-[28px] w-[28px] place-content-center rounded-lg bg-primary/10
            `}
              disabled={!referrerReport?.data?.data?.hasNextPage || isFetching}
            >
              <img src={RightAngle} alt="" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default ReferrerReport;
