import React, { FC } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Button, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from 'react-i18next';
import SecondaryButton from '../buttons/secondaryButton';
import { CustomButton } from '../buttons/customButton';
import closableEnqueueSnackbar from '../closableEnqueueSnackbar/closableEnqueueSnackbar';
import { Colors } from '../../constants/constants';

interface NewPasswordDialogContentProps {
  newPassword: string,
  setNewPassword: (_newPassword: string) => void,
  setIsPasswordDialogOpen: (_bool: boolean) => void,
}

const NewPasswordDialogContent: FC<NewPasswordDialogContentProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <p className="flex items-center pb-3 text-xl font-bold text-primary">
        <CopyToClipboard text={props.newPassword}>
          <Tooltip title={t('copyToClipboard')} arrow placement="top">
            <Button
              style={{
                minWidth: '10px',
                maxWidth: '40px',
                width: '100%',
                color: Colors.primary,
              }}
            >
              <ContentCopyIcon />
            </Button>
          </Tooltip>
        </CopyToClipboard>
        {props.newPassword}
      </p>
      <p className="text-primary">
        {t('copyBeforeExiting')}
      </p>
      <div className="ml-auto mt-4 w-fit">
        <SecondaryButton onClick={() => {
          props.setIsPasswordDialogOpen(false);
          props.setNewPassword('');
        }}
        >
          {t('close')}
        </SecondaryButton>
      </div>
    </>
  );
};

export default NewPasswordDialogContent;
