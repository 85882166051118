import { createBrowserRouter, Navigate } from 'react-router-dom';
import React, { FC } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import ProtectedRoute from './components/protectedRoute/protectedRoute';
import Sidebar from './components/sidebar/sidebar';
import Dashboard from './pages/dashboard/dashboard';
import Users from './pages/administration/users/users';
import CustomFields from './pages/settings/customFields/customFields';
import OrganizationSettings from './pages/administration/organizationSettings/organizationSettings';
import Ads from './pages/adsManagement/ads/ads';
import AdGroups from './pages/adsManagement/adGroups/adGroups';
import Campaigns from './pages/adsManagement/campaigns/campaigns';
import Auth from './pages/auth/auth';
import TrackingSources from './pages/adsManagement/trackingSources/trackingSources';
import HubspotRoute from './components/protectedRoute/integrationRoutes/hubspotRoute';
import MicrosoftRoute from './components/protectedRoute/integrationRoutes/microsoftRoute';
import AdPage from './pages/adPage/AdPage';
import DeveloperConsole from './pages/settings/developerConsole/DeveloperConsole';
import ResetPasswordDialog from './dialogs/resetPasswordDialog';
import Crm from './pages/integrations/crm/crm/crm';
import AdProviders from './pages/integrations/adProviders/adProviders';
import HubSpotPanel from './pages/settings/integrations/hubSpotPanel';
import GoogleTabPanel from './pages/settings/integrations/googleTabPanel';
import BingPanel from './pages/settings/integrations/bingPanel';
import DealSettings from './pages/integrations/crm/dealSettings/dealSettings';
import HubspotFormsTable from './components/tables/hubspotFormsTable';
import CalculationsPanel
  from './pages/administration/organizationSettings/organizationSettingsPanels/calculationsPanel';
import ReportingPanel from './pages/administration/organizationSettings/organizationSettingsPanels/reportingPanel';
import PageViewsPanel from './pages/administration/organizationSettings/organizationSettingsPanels/pageViewsPanel';
import ContactSettings from './pages/integrations/crm/contactSettings/contactSettings';
import Error from './components/error/error';
import ProfilePage from './pages/profile/profilePage';
import ResetPassword from './components/resetPassword/resetPassword';
import TwoFactorAuthentication from './components/twoFactorAuthentication/twoFactorAuthentication';
import MyProfile from './components/myProfile/myProfile';
import FacebookRoute from './components/protectedRoute/integrationRoutes/facebookRoute';
import FacebookPanel from './pages/settings/integrations/facebookPanel';
import ConversionPage from './pages/conversionPage/conversionPage';
import OtherConversion from './components/otherConversion/otherConversion';
import GoalOptimization from './components/goalOptimization/GoalOptimization';
import ConversionName from './components/conversionName/conversionName';
import ConversionValue from './components/conversionValue/ConversionValue';
import ConfiguredOtherConversion from './components/configuredOtherConversion/configuredOtherConversion';
import ConversionSubmitTypePage from './pages/submitType/conversionSubmitTypePage';
import Welcome from './pages/welcome/welcome';
import Subscription from './pages/settings/subscription';
import ImpersonateRoute from './pages/admin/impersonateRoute/impersonateRoute';
import GoogleConversionsPage from './pages/googleConversions/googleConversions';
import TrackingCodePage from './pages/trackingCodePage/trackingCodePage';
import PaymentProvider from './pages/integrations/paymentProvider/paymentProvider';
import PaddleTabPanel from './pages/integrations/paymentProvider/paddleTabPanel';
import ForgotPasswordDialog from './dialogs/forgotPasswordDialog';
import ManagerialAccountPanel
  from './pages/administration/organizationSettings/organizationSettingsPanels/managerialAccountPanel';
import ShopifyRoute from './components/protectedRoute/integrationRoutes/shopifyRoute';
import EcommerceTab from './pages/integrations/eCommerce/ecommerceTab';
import ConnectToShopify from './pages/integrations/eCommerce/connectToShopify';
import NewDashboard from './pages/newDashboard/NewDashboard';

const newLocation = localStorage.getItem('landingPage') ? localStorage.getItem('landingPage') : 'dashboard';

const Redirect: FC<{to: string}> = (props) => {
  window.location.replace(props.to);
  return null;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <Sidebar />
      </ProtectedRoute>
    ),
    errorElement: <Error />,
    children: [
      {
        path: 'dashboard',
        index: true,
        element: <NewDashboard />,
      },
      {
        path: 'profile-page',
        element: <ProfilePage />,
        children: [
          {
            path: 'profile',
            element: <MyProfile />,
          }, {
            path: 'password',
            element: <ResetPassword />,
          }, {
            path: 'authentication',
            element: <TwoFactorAuthentication />,
          },
        ],
      },
      {
        path: 'ad-page/:id',
        element: <AdPage />,
      },
      {
        path: 'administration',
        children: [
          {
            path: 'users',
            element: <Users />,
          },
        ],
      },
      {
        path: 'administration',
        children: [
          {
            path: 'users',
            element: <Users />,
          },
        ],
      },
      {
        path: 'integrations',
        children: [{
          path: 'crm',
          element: <Crm />,
          children: [
            {
              path: 'hubspot',
              element: <HubSpotPanel />,
              children: [
                {
                  path: 'forms',
                  element: <HubspotFormsTable />,
                }, {
                  path: 'contact-settings',
                  element: <ContactSettings />,

                }, {
                  path: 'deal-settings',
                  element: <DndProvider backend={HTML5Backend}><DealSettings /></DndProvider>,
                }],
            },
          ],
        },
        {
          path: 'ad-providers',
          element: <AdProviders />,
          children: [
            {
              path: 'google',
              element: <GoogleTabPanel />,
            }, {
              path: 'facebook',
              element: <FacebookPanel />,
            }, {
              path: 'bing',
              element: <BingPanel />,
            },
          ],
        },
        {
          path: 'payment-provider',
          element: <PaymentProvider />,
          children: [
            {
              path: 'paddle',
              element: <PaddleTabPanel />,
            },
          ],
        },
        {
          path: 'e-commerce',
          element: <EcommerceTab />,
          children: [
            {
              path: 'shopify',
              element: <ConnectToShopify />,
            },
          ],
        },
        ],
      }, {
        path: 'settings',
        children: [
          {
            path: 'custom-fields',
            element: <CustomFields />,
          },
          {
            path: 'organization-settings',
            element: <OrganizationSettings />,
            children: [
              {
                path: 'managerialAccount',
                element: <ManagerialAccountPanel />,
              },
              {
                path: 'calculations',
                element: <CalculationsPanel />,
              }, {
                path: 'reporting',
                element: <ReportingPanel />,
              }, {
                path: 'page-views',
                element: <PageViewsPanel />,
              },
            ],
          },
          {
            path: 'developer-console',
            element: <DeveloperConsole />,
          }, {
            path: 'subscription',
            element: <Subscription />,
          },
        ],
      }, {
        path: 'ad-management',
        children: [
          {
            path: 'ads',
            element: <Ads />,
          },
          {
            path: 'ad-groups',
            element: <AdGroups />,
          }, {
            path: 'campaigns',
            element: <Campaigns />,
          },
          {
            path: 'tracking-sources',
            element: <TrackingSources />,
          },
        ],
      },
      {
        path: 'developers',
        children: [{
          path: 'console',
          element: <DeveloperConsole />,
        }, {
          path: 'api-docs',
          element: <Redirect to="https://digtective.docs.apiary.io/" />,
        },
        ],
      },
      {
        path: '/tracking/tracking-code',
        element: <TrackingCodePage />,
      },
      {
        path: '/tracking/google-conversions',
        element: <GoogleConversionsPage />,
      },
      {
        path: '/tracking/google-conversions/conversions',
        element: <ConversionPage />,
      },
      {
        path: '/tracking/google-conversions/customise-other',
        element: <ConversionSubmitTypePage />,
      },
      {
        path: '/tracking/google-conversions/other-conversions',
        element: <OtherConversion />,
        children: [
          {
            index: true,
            element: <Navigate to="/tracking/google-conversions/other-conversions/goals" replace />,
          },
          {
            path: 'goals',
            element: <GoalOptimization />,
          }, {
            path: 'conversion-name',
            element: <ConversionName />,

          }, {
            path: 'value',
            element: <ConversionValue />,
          },
          {
            path: 'configured',
            element: <ConfiguredOtherConversion />,
          },
        ],
      },
    ],
  },
  {
    path: '/auth',
    element: (
      <ProtectedRoute>
        <Auth />
      </ProtectedRoute>
    ),
    children: [{
      path: 'reset-password',
      element: <ResetPasswordDialog />,
    },
    {
      path: 'forgot-password',
      element: <ForgotPasswordDialog />,
    },
    ],
    errorElement: <Error />,
  },
  {
    path: '/welcome',
    element: <Welcome />,
    errorElement: <Error />,
  },
  {
    path: '/facebook',
    element: <FacebookRoute />,
  },
  {
    path: '/hubspot',
    element: <HubspotRoute />,
  },
  {
    path: '/microsoft',
    element: <MicrosoftRoute />,
  },
  {
    path: '/shopify',
    element: <ShopifyRoute />,
  },
  {
    path: '/admin/user',
    element: <ImpersonateRoute />,
  },
  {
    path: '*',
    element: <Navigate to={`/${newLocation}`} />,
    errorElement: <Error />,
  },
]);

export default router;
