import * as yup from 'yup';

export const adsFormValidationSchema = (t: any) => yup.object({
  name: yup
    .string()
    .matches(/^[A-Za-z0-9\x80-\xFF_\- ]+$/, t('nameOnlyContainLetters'))
    .required(t('inputIsRequired')),
  code: yup
    .string()
    .matches(
      /^[A-Za-z0-9_\-]+$/,
      t('nameIsCode'),
    )
    .required(t('inputIsRequired')),
  adGroupId: yup
    .number()
    .required(),
  description: yup
    .string()
    .required(t('inputIsRequired')),
});
