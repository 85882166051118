import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikValues, useFormik } from 'formik';
import { CustomButton } from '../components/buttons/customButton';
import TextInput from '../components/inputs/textInput';
import { useSendResetPasswordEmail } from '../apiHooks/mutations/authMutations/authMutations';
import { ForgotPasswordFormValidationSchema } from '../validations/forgotPasswordFormValidationSchema';

const ForgotPasswordDialog: FC = () => {
  const [hasSentEmail, setHasSentEmail] = useState(false);
  const [countdown, setCountdown] = useState(60);

  const { t } = useTranslation();
  const {
    mutateAsync: sendEmail,
    isLoading: isSendingEmail,
  } = useSendResetPasswordEmail(setHasSentEmail, setCountdown);

  const formik: FormikValues = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: () => ForgotPasswordFormValidationSchema(t),
    onSubmit: async (values) => {
      await sendEmail(values.email);
    },
  });

  useEffect(() => {
    let interval: any;

    if (hasSentEmail && countdown > 0) {
      interval = setInterval(() => {
        setCountdown((prevCountdown: number) => prevCountdown - 1);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [hasSentEmail, countdown]);

  const formattedCountdown = `${String(Math.floor(countdown / 60))
    .padStart(2, '0')}:${String(countdown % 60)
    .padStart(2, '0')}`;

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="mx-auto flex w-full max-w-[400px] flex-col gap-3.5 pb-3 pt-1"
    >
      <h4 className="text-[24px] font-semibold text-[#4C956C]">{t('letsSendNewPassword')}</h4>
      <p className="mb-4 w-fit text-start text-sm text-primary">
        {t('whatIsYourEmail')}
      </p>
      <TextInput
        myKey="email"
        name="email"
        value={formik.values.email}
        label={t('email')}
        placeholder={t('email')}
        fullWidth
        size="small"
        onChange={formik.handleChange}
        error={Boolean(formik.touched.email) && Boolean(formik.errors.email)}
        helperText={Boolean(formik.touched.email) && formik.errors.email}
      />
      <CustomButton type="submit" className="grid" disabled={isSendingEmail || hasSentEmail}>
        {t('sendLink')}
      </CustomButton>
      <p className={`mx-auto w-fit text-xs text-[#929292] ${!hasSentEmail ? 'invisible' : ''}`}>
        {t('didNotReceiveEmail')}
        {formattedCountdown}
        .
      </p>
    </form>
  );
};

export default ForgotPasswordDialog;
