import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import closableEnqueueSnackbar from '../../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';
import { handleApiError } from '../../requestHelpers/requestHelpers';

export const useUpdateAd = () => {
  const { t } = useTranslation();
  return useMutation(
    (postRequest: any) => dataFetch[postRequest.id ? 'put' : 'post'](
      `${DIGGER_API_SUFFIX}ads${postRequest.id ? (`/${postRequest.id}`) : ''}`,
      {
        name: postRequest.name,
        code: postRequest.code,
        description: postRequest.description,
        adGroupId: postRequest.adGroupId,
      },
    ),
    {
      onSuccess: () => {
        closableEnqueueSnackbar(t('adCreatedSuccesfully'), 'success');
      },
      onError: (error: any) => {
        handleApiError(error, t);
      },
    },
  );
};

export const useDeleteAd = () => {
  const { t } = useTranslation();

  return useMutation(
    (id: number) => dataFetch.delete(
      `${DIGGER_API_SUFFIX}ads/${id}`,
    ),
    {
      onSuccess: () => {
        closableEnqueueSnackbar(t('adDeletedSuccessfully'), 'success');
      },
      onError: () => {
        closableEnqueueSnackbar(t('somethingWentWrong'), 'error');
      },
    },
  );
};
