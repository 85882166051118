import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../components/buttons/customButton';
import TextInput from '../components/inputs/textInput';
import { useConnectToPaddle } from '../apiHooks/mutations/paddleMutations/paddleConnectionMutations';
import { connectToPaddleFormValidationSchema } from '../validations/connectToPaddleFormValidationSchema';

interface ConnectToPaddleFormProps {
  onCloseDialog: () => void;
  refetchOrganizationSettings: () => void;
}

const ConnectToPaddleForm: React.FC<ConnectToPaddleFormProps> = (props) => {
  const { t } = useTranslation();
  const { mutateAsync: connectToPaddle, isLoading } = useConnectToPaddle();

  const formik = useFormik({
    initialValues: {
      bearerToken: '',
    },
    validationSchema: () => connectToPaddleFormValidationSchema(t),
    onSubmit: async (values) => {
      await connectToPaddle(values.bearerToken).then(() => {
        props.refetchOrganizationSettings();
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="flex w-full flex-col items-center">
      <div className="flex w-full flex-col">
        <div className="w-full">
          <TextInput
            myKey="bearerToken"
            className="w-full"
            size="small"
            name="bearerToken"
            value={formik.values.bearerToken}
            label={t('bearerToken')}
            placeholder={t('bearerToken')}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.bearerToken) && Boolean(formik.errors.bearerToken)}
            helperText={formik.touched.bearerToken && formik.errors.bearerToken ? formik.errors.bearerToken : ''}
          />
        </div>
      </div>
      <div className="flex w-full items-center justify-end gap-2 pb-2 pt-8">
        <CustomButton
          variant="noOutline"
          type="button"
          onClick={() => props.onCloseDialog()}
        >
          {t('cancel')}
        </CustomButton>
        <CustomButton
          type="submit"
          disabled={isLoading}
        >
          {t('connect')}
        </CustomButton>
      </div>
    </form>
  );
};

export default ConnectToPaddleForm;
