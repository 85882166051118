import React from 'react';
import { useTranslation } from 'react-i18next';
import SubscriptionCard from '../../components/cards/subscription-card';
import { useOrganizationSettingsStore } from '../../store/organizationSettingsStore';
import { DiggerPlanEnum } from '../../components/enums/DiggerPlanEnum';

const Subscription = () => {
  const { t } = useTranslation();
  const { productId } = useOrganizationSettingsStore((state) => state.organizationSettings);

  return (
    <div className="rounded-lg border border-dark bg-white px-[30px] py-[20px]">
      <div className="flex flex-col border-b-2 leading-[1.5]">
        <span className="my-3 text-[22px] font-semibold text-primary">{t('manageYourSubscription')}</span>
        <p className="pb-3 text-xs text-[#BDBDBD]">
          {t('manageYourSubscriptionText')}
        </p>
      </div>
      <div
        className="small-scroll relative mx-auto flex max-w-[1172px] items-center gap-5 overflow-x-auto overflow-y-hidden
          px-2"
      >
        <SubscriptionCard
          title="litePlan"
          description="ourMostLightheartedPlan"
          price={50}
          buttonText="chooseLite"
          color="gray-4"
          isActive={productId === DiggerPlanEnum.lite}
          priceId="pri_01hk7fcwce6ea1pappp7jxrqr7"
          features={[
            'One source connection',
            'Basic Reports',
            'Limited Conversions',
            'Limited Ad Spend',
            'One domain',
            'Support: Email',
          ]}

        />
        <SubscriptionCard
          title="standardPlan"
          description="bestForEmergingBusinesses"
          price={250}
          buttonText="chooseStandard"
          color="yellow"
          isActive={productId === DiggerPlanEnum.standard}
          priceId="pri_01hkvxxapfz3f2mywyzb2g4mgv"
          featureInclusionText="everythingIncludedInLitePlus"
          features={[
            'allSourceConnections',
            'basicReportsPlus',
            'limitedAdSpend',
            'limitedConversions',
          ]}
        />
        <SubscriptionCard
          title="proPlan"
          price={1000}
          description="bestForGrowingBusinesses"
          buttonText="chooseProfessional"
          color="green"
          isActive={productId === DiggerPlanEnum.pro}
          priceId="pri_01hkvxz4k8tqbs3acm525r28fa"
          featureInclusionText="everythingIncludedInStandardPlus"
          features={[
            'advancedReporting',
            'connectToOneCRM',
            'offlineConversionReporting',
            'limitedAdSpend',
            'unlimitedConversions',
            'unlimitedActiveAds',
          ]}
        />
        <SubscriptionCard
          title="enterPrisePlan"
          description="bestForLargeOrganizations"
          buttonText="talkToUs"
          color="primary"
          isActive={productId === DiggerPlanEnum.enterprise}
          featureInclusionText="everythingIncludedInProPlus"
          features={[
            'unlimitedLeads',
            'unlimitedTraffic',
            'connectToMultipleCRM',
            'unlimitedDomains',
            'quarterlyReviews',
            'dedicatedSuccessManager',
            'supportEmailPhone',
          ]}
        />
      </div>
    </div>
  );
};

export default Subscription;
