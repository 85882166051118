import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import { handleApiError } from '../../requestHelpers/requestHelpers';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';
import { useConversionNameStore } from '../../../store/conversionStepsStore';

interface SendOtherConversionInterface {
    name: string;
    conversionType: string;
    value: any;
    currency: string;
    googleConversionType: any;
}

interface SendOtherConversionResponse {
    name : string,
}

export const useSendOtherConversion = () => {
  const { t } = useTranslation();
  const { setConversionName } = useConversionNameStore((state) => state);

  return useMutation<ApiResponse<SendOtherConversionResponse>, ApiError<null>, SendOtherConversionInterface>(
    (postRequest) => dataFetch.post(
      `${DIGGER_API_SUFFIX}google/conversion`,
      postRequest,
    ),
    {
      onSuccess: (response) => {
        setConversionName(response.data?.data?.name);
      },
      onError: (error: any) => {
        handleApiError(error, t);
      },
    },
  );
};
