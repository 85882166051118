import { useLocation, useNavigate } from 'react-router-dom';
import { Tab } from '@mui/material';
import React, { JSXElementConstructor, ReactElement } from 'react';
import { Colors } from '../../constants/constants';

interface LinkTabProps {
  id?: string;
  label?: string;
  href?: string;
  icon?: ReactElement;
}

const LinkTab = (props: LinkTabProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentLocation = location.pathname.split('/');
  const currentLocationIteration = currentLocation.map(
    (item: string, index: number) => (index === currentLocation.length - 1 ? '' : `${item}/`),
  ).join('');

  return (
    <Tab
      icon={props?.icon}
      iconPosition="start"
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        navigate(`${currentLocationIteration}${props.href}`);
      }}
      sx={{
        color: Colors.primary,
        paddingInline: 1,
        paddingBlock: 2,
        marginRight: '1.25rem',
        minHeight: '21px',
        height: '21px',
        borderRadius: '8px',
        textTransform: 'none',
        '&.Mui-selected': {
          fontWeight: '600',
          color: Colors.primary,
        },
      }}
      {...props}
    />
  );
};

export default LinkTab;
