import React, {
  FC, FormEvent, SyntheticEvent, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import AutoCompleteInput from '../inputs/autoCompleteInput';
import { useGetHubspotPipelineOptions } from '../../apiHooks/queries/useGetHubspotForms';
import { HUBSPOT_PIPELINE__OPTIONS } from '../../constants/pageIdentifiers';
import { HubspotPipelineOptionsInterface } from '../../interfaces/hubspot.interface';

interface HubspotPipelineSelectProps {
  fullWidth?: boolean,
  size?: 'small' | 'medium',
  disabled?: boolean,
  initialSelectedHubspotPipelineOptionId: string,
  setHubspotPipelineId: (_id: string) => void,
}

const HubspotPipelineSelect: FC<HubspotPipelineSelectProps> = (props) => {
  const {
    data: hubspotPipelineOptionsData,
    isFetching: isRefetchingHubspotPipelineOptionsData,
  } = useGetHubspotPipelineOptions(HUBSPOT_PIPELINE__OPTIONS);
  const navigate = useNavigate();

  const selectedHubspotOption = hubspotPipelineOptionsData?.data?.data?.find(
    (obj: HubspotPipelineOptionsInterface) => obj.id === props.initialSelectedHubspotPipelineOptionId,
  );

  const [
    selectedValue,
    setSelectedValue,
  ] = useState<HubspotPipelineOptionsInterface | null>(null);
  const [searchedValue, setSearchedValue] = useState<string>('');

  useEffect(() => {
    if (hubspotPipelineOptionsData?.data?.data && props.initialSelectedHubspotPipelineOptionId && selectedHubspotOption) {
      setSelectedValue(selectedHubspotOption);
    }
  }, [hubspotPipelineOptionsData?.data?.data]);

  const onChangeAutoComplete = (event: FormEvent, newValue: HubspotPipelineOptionsInterface) => {
    if (newValue?.id) {
      setSelectedValue(newValue);
      props.setHubspotPipelineId(newValue.id);
      navigate(`${window.location.pathname}?hubspotPipelineId=${newValue.id}`, { replace: true });
      setSearchedValue('');
    } else {
      setSelectedValue(null);
      props.setHubspotPipelineId('');
      navigate(`${window.location.pathname}`, { replace: true });
      setSearchedValue('');
    }
  };

  const onInputChangeAutoComplete = async (event: SyntheticEvent, value: string) => {
    setSelectedValue(null);
    setSearchedValue(value);
  };

  return (
    <AutoCompleteInput
      key="hubspotPipeline"
      name="hubspotPipeline"
      label="hubspotPipeline"
      placeholder="hubspotPipeline"
      size={props.size}
      style={{ minWidth: 300 }}
      options={hubspotPipelineOptionsData?.data?.data || []}
      getOptionLabel={(option: HubspotPipelineOptionsInterface) => option?.label || ''}
      value={selectedValue}
      loading={isRefetchingHubspotPipelineOptionsData}
      onChange={(event: FormEvent, newValue: HubspotPipelineOptionsInterface) => onChangeAutoComplete(event, newValue)}
      inputValue={selectedValue?.label || searchedValue}
      onInputChange={async (event: FormEvent, value: string) => {
        await onInputChangeAutoComplete(event, value);
      }}
      isOptionEqualToValue={(option: HubspotPipelineOptionsInterface, value: HubspotPipelineOptionsInterface) => (
        option.id === value.id
      )}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
    />
  );
};

export default HubspotPipelineSelect;
