import React, { FC } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSidebarStore } from '../../store/sidebarStore';

interface BasicNavbarProps {
    item: any,
    handleSubMenuClick: (_href: string) => void,
}

const BasicNavbar: FC<BasicNavbarProps> = (props) => {
  const location = useLocation();
  const isSidebarOpen = useSidebarStore((state) => state.isSidebarOpen);
  const setIsSidebarOpen = useSidebarStore((state) => state.setIsSidebarOpen);
  const { t } = useTranslation();

  const isActive = location.pathname.startsWith(props.item.href);
  const navigationWrapperClasses = isActive ? 'bg-white' : '';
  const navigationItemClasses = isActive ? 'text-primary' : '';

  const toggleSidebarOpen = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <NavLink
      onClick={() => {
        if (isActive) {
          toggleSidebarOpen();
          props.handleSubMenuClick('');
          return;
        }
        setIsSidebarOpen(false);
        props.handleSubMenuClick(props.item.href);
      }}
      to={props.item.href}
      className="text-white"
      data-cy={`nav${props.item.href}`}
    >
      <ListItem
        key={props.item.label}
        disablePadding
        sx={{ display: 'block', borderEndStartRadius: 25, borderStartStartRadius: 25 }}
        className={`${navigationWrapperClasses}`}
      >
        <ListItemButton
          sx={{
            minHeight: 50,
            justifyContent: isSidebarOpen ? 'initial' : 'center',
            pl: isSidebarOpen ? '21px' : '30px',
            mt: 1.5,
            transition: 'all 0.2s ease-in-out',
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isSidebarOpen ? 2 : 'auto',
              justifyContent: 'center',
              color: isActive ? '#0C485E' : 'white',
            }}
          >
            {isActive ? (
              <img src={props.item.iconPrimary} alt="" className="h-4 w-4 object-contain" />
            ) : (
              <img src={props.item.iconSecondary} alt="" className="h-4 w-4 object-contain" />
            )}
          </ListItemIcon>
          <p className={`${navigationItemClasses} ${!isSidebarOpen ? 'hidden' : ''} font-[500] duration-200`}>
            {t(props.item.label)}
          </p>
        </ListItemButton>
      </ListItem>
    </NavLink>
  );
};

export default BasicNavbar;
