import React, { FC } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LeftAngle from '../../../assets/icons/left-angle.svg';
import RightAngle from '../../../assets/icons/right-angle.svg';
import LoadingSpinner from '../../loadingSpinner/loadingSpinner';
import { ColorKey, Colors } from '../../../constants/constants';
import EmptyBigCard from '../dashboardCards/emptyBigCard';
import InfoIconGray from '../../../assets/icons/info-icon-gray.svg';
import ExportButton from '../../exportButton/exportButton';
import { TableColumnInterface } from '../../../interfaces/tableColumn.interface';
import AdsReportTable from '../../../partials/adsReportTable';
import { AdsReportInterface } from '../../../interfaces/adsReport.interface';
import { useOrganizationSettingsStore } from '../../../store/organizationSettingsStore';
import { DiggerPlanEnum } from '../../enums/DiggerPlanEnum';
import OrganizationType from '../../enums/organizationTypesEnum';
import { eCommerceReferrerReportColumns, referrerReportColumns } from '../../referrerReport/referrerReport';

interface ListProps {
  cardTitle: string;
  listData?: { ads: AdsReportInterface[] | null; hasNextPage: boolean };
  bgColor: ColorKey;
  page: number;
  setPage: (_page: number) => void;
  isFetching?: boolean;
  isError?: boolean;
  info?: string;
  fetchDownload?: (_acceptHeader: string) => void;
  columns?: TableColumnInterface[];
  fixedColumns?: TableColumnInterface[];
}

const List: FC<ListProps> = (props) => {
  const { t } = useTranslation();
  const disabledFields = ['sales', 'cost', 'cvRateSeo', 'reportedNumber'];
  const productId = useOrganizationSettingsStore((state) => state.organizationSettings.productId);
  const navigate = useNavigate();

  const handlePrevPage = () => {
    props.setPage(props.page - 1);
  };

  const handleNextPage = () => {
    props.setPage(props.page + 1);
  };

  if (props.listData?.ads === null && props.page > 0) {
    props.setPage(0);
  }

  const navigateToAd = (id: number) => {
    navigate(`../ad-page/${id}`);
  };

  return (
    <div className="relative flex h-full flex-1 flex-col text-clip rounded-lg">
      <div className="relative flex max-h-[47px] items-center justify-between border-b border-dark px-5 py-[15px]">
        <div className="flex gap-5 text-lg font-medium text-primary">
          {t(props.cardTitle)}
          <Tooltip title={props.info}>
            <img src={InfoIconGray} alt="" className="max-w-[20px]" />
          </Tooltip>
        </div>
        {props.fetchDownload && (
          <ExportButton fetchDownload={props.fetchDownload} />
        )}
        <div
          className="absolute bottom-0 left-[50%] h-[3px] w-[calc(100%-20px)] translate-x-[-50%] rounded-t-lg"
          style={{ backgroundColor: Colors[props.bgColor] }}
        />
      </div>
      <div className="relative w-full flex-1 flex-col">
        {!props.isFetching && !props.isError && props.listData?.ads === null && (
          <EmptyBigCard />
        )}
        {props.isFetching && <LoadingSpinner center color="primary" />}
        {props.isError && !props.isFetching && (
          <p>
            {t('errorRetrievingData')}
          </p>
        )}
        {!props.isFetching && !props.isError && props.listData?.ads !== null && (
          <AdsReportTable
            data={props.listData?.ads}
            isFetching={props.isFetching}
            isError={props.isError}
            columns={props.columns}
            fixedColumns={props.fixedColumns}
            disabledFields={productId === DiggerPlanEnum.standard ? disabledFields : []}
            navigateToAd={navigateToAd}
          />
        )}
      </div>
      {!props.isFetching && !props.isError && props.listData?.ads !== null && (
        <div className="mb-[15px] flex items-center justify-center gap-4">
          <button
            type="button"
            onClick={handlePrevPage}
            className={`
              ${props.page === 0
              ? 'cursor-default opacity-30'
              : 'cursor-pointer hover:bg-primary/20 active:bg-primary/30'}
               grid h-[28px] w-[28px] place-content-center rounded-lg bg-primary/10 
            `}
            disabled={props.page === 0 || props.isFetching}
          >
            <img src={LeftAngle} alt="" />
          </button>
          <button
            type="button"
            onClick={handleNextPage}
            className={`
              ${!props.listData?.hasNextPage
              ? 'cursor-default opacity-30'
              : 'cursor-pointer hover:bg-primary/20 active:bg-primary/30'}
               grid h-[28px] w-[28px] place-content-center rounded-lg bg-primary/10
            `}
            disabled={!props.listData?.hasNextPage || props.isFetching}
          >
            <img src={RightAngle} alt="" />
          </button>
        </div>
      )}
    </div>
  );
};
export default List;
