import React, { forwardRef } from 'react';
import { useOrganizationSettingsStore, useOrganizationStore } from '../../store/organizationSettingsStore';
import arrowIcon from '../../assets/icons/downPointingArrowOrganization.svg';
import rightArrowIcon from '../../assets/icons/sidebar/rightIconSidebarButton.svg';
import { truncateText } from '../../constants/constants';
import { Organization } from '../organizationDropdownList/organizationDropdownList';
import AccountMenu from '../accountMenu/accountMenu';

interface OrganizationDropdownButtonProps {
  handleToggle: () => void;
  isOpen: boolean;
  organizations: { data: { data: Organization[] } } | undefined;
}

const OrganizationDropdownButton = forwardRef<HTMLButtonElement, OrganizationDropdownButtonProps>((props, ref) => {
  const selectedOrganization = useOrganizationStore((state) => state.selectedOrganization);
  const { organizationSettings } = useOrganizationSettingsStore((state) => state);

  return (
    <button
      onClick={props.handleToggle}
      className="flex h-[45px] w-full items-center justify-between gap-2 rounded-md bg-primary py-2.5 text-sm text-white hover:bg-[#3d6d7e]"
      ref={ref}
    >
      <AccountMenu />
      <div className="flex w-full items-center justify-between">
        {selectedOrganization
          ? (
            <div className="flex flex-col items-start">
              <p className="text-xs font-light">{truncateText(selectedOrganization.name ?? '', 21)}</p>
              <span className="text-[10px] font-medium">{truncateText(selectedOrganization.orgName ?? '', 21)}</span>
              <span>
                #
                {selectedOrganization.orgId}
              </span>
            </div>
          )
          : (
            <div className="flex flex-col items-start">
              <span className="text-xs font-medium">{truncateText(organizationSettings?.name ?? '', 21)}</span>
              <span className="text-[10px] font-light">
                #
                {organizationSettings.organizationId}
              </span>
            </div>
          )}

        <img
          className={`ml-1 w-6 transition-transform duration-200 ${!props.isOpen ? 'rotate-90' : 'rotate-0'}`}
          src={rightArrowIcon}
          alt="arrow icon"
        />
      </div>
    </button>
  );
});

export default OrganizationDropdownButton;
