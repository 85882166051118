import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { dataFetch, DIGGER_API_SUFFIX } from '../../axios/customAxios';
import { OrganizationSettingsInterface } from '../../interfaces/organizationSettings.interface';
import { ApiError, ApiResponse } from '../../interfaces/apiResponse.type';
import { OrganizationInterface } from '../../interfaces/organization.interface';
import closableEnqueueSnackbar from '../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';

const useGetOrganizationSettings = (
  identifier: string,
  onSuccessRetrieved?: (_organizationSettings: OrganizationSettingsInterface) => void,
) => useQuery<AxiosResponse, ApiError<null>, ApiResponse<OrganizationSettingsInterface>>(
  [`${identifier}`],
  () => dataFetch.get(`${DIGGER_API_SUFFIX}organization-settings`),
  {
    onSuccess: (res) => {
      localStorage.setItem('landingPage', res?.data?.data?.landingPage || '');
      onSuccessRetrieved?.(res?.data?.data);
    },
  },
);

export const useGetOrganizationsOptions = (identifier: string, name: string) => {
  const { t } = useTranslation();

  return useQuery<AxiosResponse, ApiError<null>, ApiResponse<OrganizationInterface>>(
    [`organization_${identifier}`],
    () => dataFetch.get(`/organizations/options?name=${name}`).then((res: any) => res.data),
    {
      onError: () => {
        closableEnqueueSnackbar(t('somethingWentWrong'), 'error');
      },
    },
  );
};

export default useGetOrganizationSettings;
