import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ConversionTable from '../../components/conversionTable/ConversionTable';
import submitLeadIcon from '../../assets/icons/submitLeadFormIcon.svg';
import qualifiedLead from '../../assets/icons/qualifiedLeadIcon.svg';
import convertedLead from '../../assets/icons/convertedLeadIcon.svg';
import useGetConversionData from '../../apiHooks/queries/getConversionData/useGetConversionData';
import { useOtherConversionStore } from '../../store/conversionStepsStore';
import ConversionSetupBox from '../../components/conversionSetupBox/conversionSetupBox';
import LoadingSpinner from '../../components/loadingSpinner/loadingSpinner';

const ConversionPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateConversionType } = useOtherConversionStore();

  const createSubmitType = (conversionType: string) => {
    updateConversionType(conversionType);
    navigate('/tracking/google-conversions/other-conversions');
  };

  const { data, isLoading } = useGetConversionData('CONVERSION_DATA');

  return (
    <div className="h-full">
      {isLoading && <LoadingSpinner center color="primary" />}
      {!isLoading && typeof data?.data?.data?.onSubmit === 'object' && !Array.isArray(data?.data?.data?.onSubmit) && (
      <ConversionTable
        imgSource={submitLeadIcon}
        title="Submit Lead Form"
        conversionData={data?.data?.data?.onSubmit || []}
      />
      )}

      {!isLoading && typeof data?.data?.data?.onQualify === 'object' && !Array.isArray(data?.data?.data?.onQualify) && (
      <ConversionTable
        imgSource={qualifiedLead}
        title="Qualified Lead"
        conversionData={data?.data?.data?.onQualify || []}
      />
      )}

      {!isLoading && typeof data?.data?.data?.onConvert === 'object' && !Array.isArray(data?.data?.data?.onConvert) && (
      <ConversionTable
        imgSource={convertedLead}
        title="Converted Lead"
        conversionData={data?.data?.data?.onConvert || []}
      />
      )}

      {data?.data?.data?.organizationType === 'other' && Array.isArray(data?.data?.data.onSubmit) && data?.data?.data.onSubmit.length === 0 && (
        <ConversionSetupBox
          title="On Form Submit"
          onClick={() => createSubmitType('ON_SUBMIT')}
          imgSource={submitLeadIcon}
          horizontal
        />
      )}

      {data?.data?.data?.organizationType === 'other' && Array.isArray(data?.data?.data?.onQualify) && data?.data?.data.onQualify.length === 0 && (
        <ConversionSetupBox
          title="On Approve"
          onClick={() => createSubmitType('ON_QUALIFY')}
          imgSource={qualifiedLead}
          horizontal
        />
      )}

      {data?.data?.data?.organizationType === 'other' && Array.isArray(data?.data?.data.onConvert) && data?.data?.data.onConvert.length === 0 && (
        <ConversionSetupBox
          title="On Convert"
          onClick={() => createSubmitType('ON_CONVERT')}
          imgSource={convertedLead}
          horizontal
        />
      )}
    </div>
  );
};

export default ConversionPage;
