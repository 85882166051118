import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import closableEnqueueSnackbar from '../../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';
import { handleApiError } from '../../requestHelpers/requestHelpers';

export const useCreateCustomField = () => {
  const { t } = useTranslation();

  return useMutation(
    (postRequest: any) => dataFetch.post(
      `${DIGGER_API_SUFFIX}custom-fields`,
      {
        fieldName: postRequest.fieldName,
        fieldType: postRequest.fieldType,
        isRequired: postRequest.isRequired,
        formType: postRequest.formType,
      },
    ),
    {
      onError: (error: any) => {
        handleApiError(error, t);
      },
    },
  );
};

export const useDeleteCustomField = () => {
  const { t } = useTranslation();

  return useMutation(
    (id: number) => dataFetch.delete(
      `${DIGGER_API_SUFFIX}custom-fields/${id}`,
    ),
    {
      onError: () => {
        closableEnqueueSnackbar(t('somethingWentWrong'), 'error');
      },
    },
  );
};
