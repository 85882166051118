import React, { useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { DateRange } from '@mui/x-date-pickers-pro';
import type { Dayjs } from 'dayjs';
import { useGetGroupedHistogram } from '../../apiHooks/queries/graphs/useGetGroupedHistogram';
import { GROUPED_AD_HISTOGRAM } from '../../constants/pageIdentifiers';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface GroupedBarChartInterface {
  id: number;
  adManagementFilterDateRange: DateRange<Dayjs>;
}

interface VisibilityState {
  [key: string]: boolean;
}

export const GroupedBarChart: React.FC<GroupedBarChartInterface> = (props) => {
  const {
    data: groupedHistogramData,
  } = useGetGroupedHistogram(
    GROUPED_AD_HISTOGRAM,
    props.id,
    props.adManagementFilterDateRange[0],
    props.adManagementFilterDateRange[1],
  );
  // const labels = ['Jan', 'Feb', 'Mar', 'Jan', 'Feb', 'Mar'];
  const labels: string[] | undefined = groupedHistogramData?.data?.data?.labels;
  const initialVisibility = { 'Earned Income': true, 'Lost Income': true, 'Potential Income': true };

  const [visibility, setVisibility] = useState<VisibilityState>(initialVisibility);
  const toggleDataset = (datasetLabel: string) => {
    setVisibility((prev) => ({
      ...prev,
      [datasetLabel]: !prev[datasetLabel],
    }));
  };

  const visibleCount = Object.values(visibility).filter((v) => v).length;

  const barThickness = visibleCount === 3 ? 0.5 : visibleCount === 2 ? 0.6 : 1;
  const categoryThickness = visibleCount === 3 ? 0.3 : visibleCount === 2 ? 0.5 : 1;
  const borderRadius = visibleCount === 3 ? 100 : visibleCount === 2 ? 50 : 10;

  const options : any = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        font: {
          size: 18,
          family: 'Poppins',
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    barPercentage: barThickness,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        border: {
          dash: [2, 2],
        },
        grid: {
          display: true,
        },
        beginAtZero: true,
        suggestedMax: 7,
      },
    },
  };

  const groupedData = {
    labels,
    datasets: [
      {
        label: 'Earned Income',
        // data: [3, 2, 1, 2, 4, 2, 3, 2, 1, 2, 4, 2].map((value) => (visibility['Earned Income'] ? value : 0)),
        data: labels && labels.map((param, index) => groupedHistogramData?.data?.data?.dataSets?.earned[index] || 0),
        backgroundColor: '#4C956C',
        stack: 'Stack 0',
        borderRadius,
        barPercentage: barThickness,
        categoryPercentage: categoryThickness,
      },
      {
        label: 'Potential Income',
        // data: [3, 2, 1, 2, 4, 2, 3, 2, 1, 2, 4, 2].map((value) => (visibility['Potential Income'] ? value : 0)),
        data: labels && labels.map((param, index) => groupedHistogramData?.data?.data?.dataSets?.potentialIncome[index] || 0),
        backgroundColor: '#FF9F1C',
        stack: 'Stack 1',
        borderRadius,
        barPercentage: barThickness,
        categoryPercentage: categoryThickness,
      },
      {
        label: 'Lost Income',
        // data: [3, 2, 1, 2, 4, 2, 3, 2, 1, 2, 4, 2].map((value) => (visibility['Lost Income'] ? value : 0)),
        data: labels && labels.map((param, index) => groupedHistogramData?.data?.data?.dataSets?.lostIncome[index] || 0),
        backgroundColor: '#DB3A34',
        stack: 'Stack 2',
        borderRadius,
        barPercentage: barThickness,
        categoryPercentage: categoryThickness,
      },
    ],
  };

  return (
    <div className="flex" style={{ height: '200px' }}>
      <div style={{ width: '620px', maxWidth: '960px' }}>
        <Bar options={options} data={groupedData} />
      </div>
      <div className="flex min-w-[160px] flex-col justify-start gap-2 px-4 pb-6 pt-2">
        {groupedData.datasets.map((dataset) => (
          <button
            key={dataset.label}
            onClick={() => toggleDataset(dataset.label)}
            className={`flex h-[42px] w-full min-w-[130px] items-center gap-2 rounded-xl border py-2 pl-4 pr-2 text-[10px] outline-none focus:outline-none ${
              visibility[dataset.label] ? 'bg-white text-primary' : 'text-black'
            }`}
          >
            <div
              className="h-2 w-2 rounded-full"
              style={{ backgroundColor: visibility[dataset.label] ? dataset.backgroundColor : 'gray' }}
            />
            {dataset.label}
          </button>
        ))}
      </div>
    </div>
  );
};
