import { useSearchParams } from 'react-router-dom';
import React, { useState } from 'react';
import DashboardSmallCard from '../dashboard/dashboardCards/dashboardSmallCard';
import useGetAdTotals from '../../apiHooks/queries/adManagement/useGetAdTotals';
import { AD_TOTALS } from '../../constants/pageIdentifiers';
import { useAdManagementFilterDateStore } from '../../store/dateRangeStore';
import { useOrganizationSettingsStore } from '../../store/organizationSettingsStore';
import OrganizationType from '../enums/organizationTypesEnum';

interface InfoBoxInterface {
  groupingType: 'adGroups' | 'ads' | 'campaigns' | 'trackingSources';
}

const groupType: Record<InfoBoxInterface['groupingType'], string> = {
  adGroups: 'campaignCode',
  ads: 'adGroupCode',
  campaigns: 'trackingSourceId',
  trackingSources: '',
};

const InfoboxContainer = (props: InfoBoxInterface) => {
  const [searchParams] = useSearchParams();
  const [selectedId] = useState<string>(
    searchParams.get(groupType[props.groupingType]) || '',
  );
  const adManagementFilterDateRange = useAdManagementFilterDateStore(
    (state) => state.adManagementFilterDateRange,
  );
  const organizationType = useOrganizationSettingsStore(
    (state) => state.organizationSettings?.organizationType,
  );

  const {
    data: adTotals,
    isFetching,
    isError,
  } = useGetAdTotals(
    AD_TOTALS,
    props.groupingType,
    adManagementFilterDateRange[0],
    adManagementFilterDateRange[1],
    selectedId || '',
  );

  return (
    <div className="mb-5 flex h-full max-h-[116px] items-center justify-between gap-1 rounded-lg bg-white p-1">
      {organizationType !== OrganizationType.ECOMMERCE && (
        <DashboardSmallCard
          title="formSubmissions"
          value={adTotals?.data?.formSubmissions}
          isLoading={isFetching}
          isError={isError}
          size="small"
        />
      )}
      <DashboardSmallCard
        title="sales"
        value={adTotals?.data?.sales}
        isLoading={isFetching}
        isError={isError}
        size="small"
      />
      <DashboardSmallCard
        title="cost"
        value={adTotals?.data?.cost}
        isLoading={isFetching}
        isError={isError}
        size="small"
      />
      <DashboardSmallCard
        title="netIncome"
        value={adTotals?.data?.netIncome || 0}
        isLoading={isFetching}
        isError={isError}
        size="small"
      />
      <DashboardSmallCard
        title="earnedIncome"
        value={adTotals?.data?.earnedIncome}
        isLoading={isFetching}
        isError={isError}
        size="small"
      />
      <DashboardSmallCard
        title="potentialIncome"
        value={adTotals?.data?.potentialIncome}
        isLoading={isFetching}
        isError={isError}
        size="small"
      />
    </div>
  );
};

export default InfoboxContainer;
