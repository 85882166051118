import { useQuery, UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import { CORE_API_SUFFIX, dataFetch } from '../../../axios/customAxios';
import { appendQueryParamIfNotEmpty, formatDate, generateQueryKey } from '../../../constants/hookConstants';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';

interface AdDetailsResponse {
    adId: number,
    name: string,
    formSubmissionsCount: number,
    createdAt: string,
    netIncome: number,
    lostIncome: number,
    earnedIncome: number,
    potentialIncome: number,
    impressions: number,
    interactions: number,
    interactionsRate: number,
    ctr: number,
    clicks: number,
    averageCpc: number,
    cost: number,
    campaignName: string,
    campaignId: number,
    adGroupName: string,
    adGroupId: number,
    status: boolean,
    trackingSourceId: number,
}

const useGetAdDetails = (
  identifier: string,
  id: string | undefined,
  from: Dayjs | null,
  to: Dayjs | null,
): UseQueryResult<ApiResponse<AdDetailsResponse>, ApiError<AdDetailsResponse>> => {
  const navigate = useNavigate();

  const queryParams = new URLSearchParams();
  appendQueryParamIfNotEmpty(queryParams, 'from', formatDate(from));
  appendQueryParamIfNotEmpty(queryParams, 'to', formatDate(to));

  const queryKey = generateQueryKey(identifier, queryParams);

  return useQuery<ApiResponse<AdDetailsResponse>, ApiError<AdDetailsResponse>>(
    [`ad_details_${queryKey}`],
    () => dataFetch.get(`${CORE_API_SUFFIX}ad/detail/${id}?${queryParams}`),
    {
      onSuccess: () => {
        navigate(`${window.location.pathname}?${queryParams.toString()}`, { replace: true });
      },
    },
  );
};

export default useGetAdDetails;
