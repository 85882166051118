import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import TextInput from '../inputs/textInput';
import { useUpdateAd } from '../../apiHooks/mutations/adManagementMutations/adsMutations';
import AdGroupSelect from '../dropdownSelects/adGroupSelect';
import { CustomButton } from '../buttons/customButton';

interface AdFormProps {
    initialValues?: any;
    onSubmit: () => void;
    close: () => void;
    clickedCode : string;
    sendTrackingCodeRequest : (_code : string, _type :number) => void;
    selectedAdGroupId : string;
    setSelectedAdGroupId : (_selectedAdGroupId : string) => void;
}

const AdForm: React.FC<AdFormProps> = (props) => {
  const { t } = useTranslation();
  const { mutateAsync: updateAds } = useUpdateAd();
  const formik = useFormik({
    initialValues: props.initialValues || {
      id: 0,
      name: '',
      code: props.clickedCode,
      description: '',
      adGroupId: 0,
    },
    onSubmit: async (values) => {
      await updateAds(values);
      props.sendTrackingCodeRequest(props.clickedCode, 1);
      props.onSubmit();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="flex w-full flex-col items-center">
      <div className="flex w-full flex-col">
        <div className="w-full">
          <TextInput
            myKey="name"
            className="w-full"
            size="small"
            name="name"
            value={formik.values.name}
            placeholder={t('name')}
            onChange={(e) => formik.handleChange(e)}
            label={t('name')}
          />
        </div>
        <div className="my-4 w-full">
          <TextInput
            myKey="description"
            className="w-full"
            size="small"
            name="description"
            value={formik.values.description}
            placeholder={t('description')}
            onChange={(e) => formik.handleChange(e)}
            label={t('description')}
          />
        </div>
        <AdGroupSelect
          size="small"
          identifier="adGroup"
          formik={formik}
          selectedAdGroupId={props.selectedAdGroupId}
          setSelectedAdGroupId={props.setSelectedAdGroupId}
        />
      </div>
      <div className="flex w-full items-center justify-end gap-2 pb-2 pt-8">
        <CustomButton
          variant="noOutline"
          type="button"
          onClick={() => props.close()}
          className="rounded-lg px-4 py-2.5 text-primary"
        >
          {t('cancel')}
        </CustomButton>
        <CustomButton type="submit" className="rounded-lg bg-primary px-4 py-2.5 text-white">
          {t('create')}
        </CustomButton>
      </div>
    </form>
  );
};

export default AdForm;
