import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { TableColumnInterface } from '../../interfaces/tableColumn.interface';
import BasicTable from './basicTable';
import { CAMPAIGNS_TABLE, SELECTED_TRACKING_SOURCE_OPTION } from '../../constants/pageIdentifiers';
import { DEFAULT_ROWS_PER_PAGE, eCommerceExtendedColumns, extendedColumns } from '../../constants/constants';
import BasicDialog from '../../dialogs/basicDialog';
import DateCell from '../../partials/dateCell';
import { CampaignInterface } from '../../interfaces/campaign.interface';
import { useDeleteCampaign } from '../../apiHooks/mutations/adManagementMutations/campaignsMutations';
import { campaignFormInputs } from '../../constants/modalInputs/campaignFormInputs';
import CampaignsForm from '../../forms/campaignsForm';
import useGetCampaigns from '../../apiHooks/queries/adManagement/useGetCampaigns';
import TrackingSourceSelect from '../dropdownSelects/trackingSourceSelect';
import { useGetTrackingSourceById } from '../../apiHooks/queries/adManagement/useGetTrackingSources';
import { useAdManagementFilterDateStore } from '../../store/dateRangeStore';
import { useOrganizationSettingsStore } from '../../store/organizationSettingsStore';
import OrganizationType from '../enums/organizationTypesEnum';
import { useGetExportCampaignsTable } from '../../apiHooks/mutations/reportsExportMutations/useGetExportCampaignsTable';

const columns: TableColumnInterface[] = [
  { field: 'name', headerName: 'name' },
  { field: 'trackingSource.name', headerName: 'trackingSource' },
  { field: 'campaignCode', headerName: 'code' },
  { field: 'conversionValue', headerName: 'conversionValue' },
  {
    field: 'createdAt',
    headerName: 'createdAt',
    cellRenderer: (params: string) => <DateCell params={params} />,
  },
];

const sortablesList = ['name', 'createdAt'];

interface CampaignsTableProps {
  selectedTrackingSourceId: string;
  setSelectedTrackingSourceId: (_newselectedTrackingSourceId: string) => void;
}

const CampaignsTable: FC<CampaignsTableProps> = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [editData, setEditData] = useState<CampaignInterface>();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { mutateAsync: deleteAd } = useDeleteCampaign();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState<string>(searchParams.get('sortBy') || '');
  const [orderBy, setOrderBy] = useState<string>(searchParams.get('orderBy') || '');
  const organizationType = useOrganizationSettingsStore((state) => state.organizationSettings?.organizationType);

  const [searchInput, setSearchInput] = useState({
    name: searchParams.get('name') || '',
  });
  const { t } = useTranslation();

  // TODO: Why is this on the component level ?
  localStorage.setItem('from', searchParams.get('adManagementFilterDateFrom') || '');
  localStorage.setItem('to', searchParams.get('adManagementFilterDateTo') || '');

  const {
    data: selectedTrackingSourceData,
  } = useGetTrackingSourceById(SELECTED_TRACKING_SOURCE_OPTION, props.selectedTrackingSourceId);

  const adManagementFilterDateRange = useAdManagementFilterDateStore(
    (state) => state.adManagementFilterDateRange,
  );
  const setAdManagementFilterDateRange = useAdManagementFilterDateStore(
    (state) => state.setAdManagementFilterDateRange,
  );

  const {
    data: campaignsData,
    isError,
    refetch: refetchCampaignsData,
    isFetching,
  } = useGetCampaigns(
    CAMPAIGNS_TABLE,
    searchInput.name,
    props.selectedTrackingSourceId,
    adManagementFilterDateRange[0],
    adManagementFilterDateRange[1],
    sortBy,
    orderBy,
    rowsPerPage,
    page + 1,
  );

  const { mutate: fetchDownload } = useGetExportCampaignsTable(
    {
      name: searchInput.name,
      trackingSourceId: props.selectedTrackingSourceId,
      from: adManagementFilterDateRange[0],
      to: adManagementFilterDateRange[1],
      sortBy,
      orderBy,
      rows: rowsPerPage,
      page: page + 1,
    },
  );

  useEffect(() => {
    setPage(0);
  }, [adManagementFilterDateRange, sortBy, orderBy, props.selectedTrackingSourceId]);

  const handleOnDelete = async (id: number) => {
    setIsDeleting(true);
    await deleteAd(id);
    await refetchCampaignsData();
    setIsDeleting(false);
  };

  const closeEditDialogAndRefetch = async () => {
    setIsEditDialogOpen(false);
    await refetchCampaignsData();
  };

  const handleGoToAdGroups = (campaign: any) => {
    navigate(`/ad-management/ad-groups?campaignId=${campaign.id}&campaignCode=${campaign.campaignCode}`);
  };

  return (
    <>
      <BasicDialog
        closeDialog={() => setIsEditDialogOpen(false)}
        open={isEditDialogOpen}
        title={editData?.id ? t('updateCampaign') : t('createCampaign')}
      >
        <CampaignsForm
          data={editData && editData}
          closeDialog={closeEditDialogAndRefetch}
        />
      </BasicDialog>
      <BasicTable
        data={campaignsData?.data}
        refetch={refetchCampaignsData}
        fetchDownload={fetchDownload}
        onDelete={(id) => handleOnDelete(id)}
        onEdit={(item: CampaignInterface) => setEditData(item)}
        setEditDialogIsOpen={(isOpen) => setIsEditDialogOpen(isOpen)}
        setPage={(newPage) => setPage(newPage)}
        setRowsPerPage={(newRowsPerPage) => setRowsPerPage(newRowsPerPage)}
        formInputs={campaignFormInputs}
        setSearchInput={setSearchInput}
        handleNavigation={(campaignId) => handleGoToAdGroups(campaignId)}
        extendedColumns={organizationType === OrganizationType.ECOMMERCE ? eCommerceExtendedColumns : extendedColumns}
        setSortBy={setSortBy}
        setOrderBy={setOrderBy}
        filterDateRange={adManagementFilterDateRange}
        setFilterDateRange={setAdManagementFilterDateRange}
        customFilters={(
          <TrackingSourceSelect
            data={selectedTrackingSourceData?.data}
            size="small"
            setSelectedTrackingSourceId={props.setSelectedTrackingSourceId}
          />
                )}
        {...{
          isError, isFetching, isDeleting, page, searchInput, columns, rowsPerPage, sortBy, orderBy, sortablesList,
        }}
      />
    </>
  );
};

export default CampaignsTable;
