import React, {
  FC, FormEvent, SyntheticEvent, useState,
} from 'react';
import { FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import AutoCompleteInput from '../inputs/autoCompleteInput';

interface CurrencyListInterface {
  name: string,
}

export const currencyList = [
  { name: 'NOK' },
  { name: 'SEK' },
  { name: 'EUR' },
  { name: 'GBP' },
  { name: 'USD' },
];

interface CurrencySelectInterface {
  formik?: FormikValues,
  fullWidth?: boolean,
  size?: 'small' | 'medium',
  disabled?: boolean,
}

const CurrencySelect: FC<CurrencySelectInterface> = (props) => {
  const initialCurrency = currencyList.find((currency) => currency.name === props.formik?.values?.currency);
  const { t } = useTranslation();

  const [
    selectedValue,
    setSelectedValue,
  ] = useState<CurrencyListInterface | null>(initialCurrency || null);
  const [searchedValue, setSearchedValue] = useState<string>('');

  const onChangeAutoComplete = (event: FormEvent, newValue: CurrencyListInterface) => {
    if (newValue?.name) {
      setSelectedValue(newValue);
      props.formik?.setFieldValue?.('currency', newValue.name);
    } else {
      setSelectedValue(null);
      props.formik?.setFieldValue?.('currency', '');
    }
  };

  const onInputChangeAutoComplete = async (event: SyntheticEvent, value: string) => {
    setSelectedValue(null);
    setSearchedValue(value);
  };

  return (
    <AutoCompleteInput
      key="currency"
      name="currency"
      label={t('currency')}
      placeholder="currency"
      size={props.size}
      style={{ minWidth: 200 }}
      options={currencyList}
      getOptionLabel={(option: CurrencyListInterface) => option?.name || ''}
      value={selectedValue}
      onChange={
        (event: FormEvent, newValue: CurrencyListInterface) => onChangeAutoComplete(event, newValue)
      }
      inputValue={selectedValue?.name || searchedValue}
      onInputChange={async (event: FormEvent, value: string) => {
        await onInputChangeAutoComplete(event, value);
      }}
      isOptionEqualToValue={(option: CurrencyListInterface, value: CurrencyListInterface) => (
        option.name === value.name
      )}
      error={Boolean(props.formik?.touched?.currency)
        && Boolean(props.formik?.errors?.currency)}
      helperText={Boolean(props.formik?.touched?.currency)
        && props.formik?.errors?.currency}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
    />
  );
};

export default CurrencySelect;
