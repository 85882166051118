import React, { FC } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../loadingSpinner/loadingSpinner';
import InfoIcon from '../../../assets/icons/info-icon.svg';
import InfoIconWhite from '../../../assets/icons/info-icon-white.svg';
import VatDisplayer from '../../../partials/VATDisplayer';
import { useOrganizationSettingsStore } from '../../../store/organizationSettingsStore';

interface DashboardSmallCardProps {
  title: string,
  value?: number,
  variant?: 'primary' | 'secondary',
  isLoading?: boolean,
  isError?: boolean,
  quickStat?: number,
  quickStatTitle?: string,
  hasCurrency?: boolean,
  info?: string,
  size?: string,
}

const DashboardSmallCard: FC<DashboardSmallCardProps> = (props) => {
  const organizationSettings = useOrganizationSettingsStore((state) => state.organizationSettings);
  const { t } = useTranslation();

  return (
    <div
      className={` 
        ${props.size === 'small' ? 'px-4 py-2' : 'p-5'} flex h-full flex-1 flex-col justify-between rounded-lg 
        ${props.variant === 'primary' ? 'bg-white text-primary' : 'bg-[#CEDADF] text-primary'}
      `}
    >
      <div className="flex items-center justify-between gap-2">
        <h2 className={`
          ${props.size === 'small' ? 'text-xs  leading-[18px]' : 'text-base  leading-[18px]'} font-medium
        `}
        >
          {t(props.title)}
        </h2>
        <Tooltip title={props.info}>
          {props.variant === 'primary' ? (
            <img
              src={InfoIcon}
              alt="Info Icon"
              className={`${props.size === 'small' ? 'max-w-[16px]' : 'max-w-[20px]'}`}
            />
          ) : (
            <div />
          )}
        </Tooltip>
      </div>
      <div>
        {props.isLoading
          && (
            <LoadingSpinner
              size={props.size === 'small' ? 25 : undefined}
              color={props.variant !== 'primary' ? 'white' : 'primary'}
            />
          )}
        {!props.isLoading && props.isError && 'error'}
        {!props.isLoading && !props.isError && props.value !== undefined && (
          <p className={`
            ${props.size === 'small' ? 'items-start text-[22px] font-[600]' : 'text-[28px] font-[300]'} 
            flex items-baseline gap-2 
          `}
          >
            <VatDisplayer
              value={props.value}
            />
            {props.hasCurrency && (
              <span className="text-xl">
                {organizationSettings?.currency}
              </span>
            )}
          </p>
        )}
      </div>
      <div className={`${props.size === 'small' ? 'h-[18px]' : 'h-[40px]'} flex items-center gap-3.5`}>
        <p className={`px-5 py-2 font-medium ${props.quickStat ? 'bg-[#ffffff33]' : ''} w-fit rounded-lg`}>
          {props.quickStat ? (
            <>
              {props.quickStat.toFixed(2)}
              %
            </>
          ) : null}
        </p>
        {props.quickStatTitle && (
          <span className="font-light">
            {props.quickStatTitle}
          </span>
        )}
      </div>
    </div>
  );
};
export default DashboardSmallCard;
