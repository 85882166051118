import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import TextInput from '../inputs/textInput';
import SearchIcon from '../../assets/icons/search-icon.svg';

interface TableFiltersProps {
  searchInput: any,
  setSearchInput: (_newSearchInput: number) => void,
  refetch: () => void,
  setPage?: (_newPage: number) => void,
  children?: React.ReactNode;
}

const TableFilters: FC<TableFiltersProps> = (props) => {
  const { t } = useTranslation();

  const debouncedRefetchData = debounce(props.refetch, 500);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>, field: string) => {
    props.setSearchInput?.({
      ...props.searchInput,
      [field]: e.target.value,
    });
    props.setPage?.(0);
    debouncedRefetchData();
  };

  return (
    <div className="flex flex-wrap gap-5 my-2">
      {Object.entries(props.searchInput).map(([key]) => (
        <TextInput
          key={key}
          name={`${key}Search`}
          label={t(`${key}Search`)}
          placeholder={t(key)}
          type="search"
          size="small"
          className="max-w-[213px]"
          value={props.searchInput[key] || ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleSearchInputChange(e, key)}
          startAdornment={<img src={SearchIcon} alt="" />}
        />
      ))}
      {props.children}
    </div>
  );
};

export default TableFilters;
