import { Grid } from '@mui/material';
import React from 'react';
import { FormikValues, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import TextInput from '../components/inputs/textInput';
import { useUpdateAdGroup } from '../apiHooks/mutations/adManagementMutations/adGroupsMutations';
import { AdGroupInterface } from '../interfaces/adGroup.interface';
import { adGroupsFormValidationSchema } from '../validations/adGroupsFormValidationSchema';
import CampaignSelect from '../components/dropdownSelects/campaignSelect';
import SecondaryButton from '../components/buttons/secondaryButton';
import PrimaryButton from '../components/buttons/primaryButton';
import LoadingSpinner from '../components/loadingSpinner/loadingSpinner';

interface AdGroupsFormInterface {
  data?: AdGroupInterface;
  closeDialog: () => void;
}

const AdGroupsForm: React.FC<AdGroupsFormInterface> = (props) => {
  const { mutateAsync: updateAdGroup, isLoading: isUpdatingAdGroup } = useUpdateAdGroup();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const formik: FormikValues = useFormik({
    initialValues: {
      id: props.data?.id,
      name: props.data?.name || '',
      campaignId: searchParams.get('campaignId') || props.data?.campaign?.id || '',
      adGroupCode: props.data?.adGroupCode || '',
    },
    validationSchema: () => adGroupsFormValidationSchema(t),
    onSubmit: async (values: any) => {
      await updateAdGroup(values);

      props.closeDialog();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        sx={{
          display: 'flex', columnGap: '3rem', rowGap: '2.5rem', paddingBlock: '1rem',
        }}
      >
        <Grid item xs={12}>
          <TextInput
            myKey="name"
            name="name"
            value={formik.values.name}
            label={t('name')}
            placeholder={t('name')}
            fullWidth
            onChange={formik.handleChange}
            error={Boolean(formik.touched.name) && Boolean(formik.errors.name)}
            helperText={Boolean(formik.touched.name) && formik.errors.name}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            myKey="adGroupCode"
            name="adGroupCode"
            value={formik.values.adGroupCode}
            label={t('adGroupCode')}
            placeholder={t('adGroupCode')}
            fullWidth
            onChange={formik.handleChange}
            error={Boolean(formik.touched.adGroupCode) && Boolean(formik.errors.adGroupCode)}
            helperText={Boolean(formik.touched.adGroupCode) && formik.errors.adGroupCode}
          />
        </Grid>
        <Grid item xs={12}>
          <CampaignSelect
            identifier={`form-campaign-select-${formik.values.campaignId}`}
            selectedCampaignId={formik.values.campaignId}
            formik={formik}
          />
        </Grid>
      </Grid>
      <div className="ml-auto mt-5 flex w-fit gap-3">
        <SecondaryButton type="button" onClick={props.closeDialog} dataCy="ad-groups-editor-cancel">
          {t('cancel')}
        </SecondaryButton>
        {isUpdatingAdGroup
          ? (
            <div className="flex h-[43px] w-[102px] items-center justify-center">
              <LoadingSpinner size={25} variant="primary" color="primary" />
            </div>
          )
          : (
            <PrimaryButton type="submit" disabled={isUpdatingAdGroup} dataCy="ads-editor-submit">
              {t('submit')}
            </PrimaryButton>
          )}
      </div>
    </form>
  );
};

export default AdGroupsForm;
