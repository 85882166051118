export const microsoftIntegrationURL = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize'
  + '?client_id=e2810e39-0334-4088-80d4-bb1f693efc51&response_type=code'
  + '&redirect_uri=https%3A%2F%2Fdigger-v2.digtective.com%2Fmicrosoft'
  + '&scope=openid%20offline_access%20https%3A%2F%2Fads.microsoft.com%2Fmsads.manage&state=12345'
  + '&response_mode=query';

export const hubspotIntegrationUrl = 'https://app-eu1.hubspot.com/oauth/authorize?'
    + 'client_id=26f95496-c09f-4569-9ea8-f0971cdebfca'
    + '&redirect_uri=https://digger-v2.digtective.com/hubspot'
    + '&scope=forms%20crm.objects.contacts.read'
    + '%20crm.objects.companies.read'
    + '%20crm.objects.deals.read';

export const facebookIntegrationUrl = 'https://www.facebook.com/v18.0/dialog/oauth?'
    + 'client_id=118806125480870'
    + '&redirect_uri=https://digger-v2.digtective.com/facebook/'
    + '&scope=ads_management%2Cads_read%2Cread_insights';

export const shopifyIntegrationUrl = (shop: string, state: number) => `https://${shop}.myshopify.com/admin/oauth/authorize?`
    + 'client_id=68161625c59e0ce25ad4b31d928bb5dd'
    + '&scope=write_orders,read_orders&'
    + `redirect_uri=https://digger-v2.digtective.com/shopify&state=${state}`;
