import * as yup from 'yup';

export const profileUpdateNameFormValidationSchema = (t: any) => yup.object({
  firstName: yup.string().typeError(t('inputMustBeString')),
  lastName: yup.string().typeError(t('inputMustBeString')),
});

export const profileUpdatePasswordValidationSchema = (t: any) => yup.object({
  password: yup.string().typeError(t('inputMustBeString')),
  newPassword: yup.string().typeError(t('inputMustBeString')),
});
