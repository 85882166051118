import { create } from 'zustand';

interface TwoFaState {
    twoFaActive : boolean;
    setTwoFaActive : (_newTwoFaState : boolean) => void;
}

interface LoginInfoState{
    loginInfo : {
        email :string,
        password: string,
    };
    setLoginInfo : (_email: any) => void;
}

interface TwoFaEnabledState{
    twoFaEnabled : boolean;
    setTwoFaEnabled : (_newTwoFaEnabled : boolean) => void;
}

export const useTwoFaStore = create<TwoFaState>((set) => ({
  twoFaActive: false,
  setTwoFaActive: (newTwoFaState: boolean) => {
    set(() => ({
      twoFaActive: newTwoFaState,
    }));
  },
}));

export const useIsTwoFaEnabledStore = create<TwoFaEnabledState>((set) => ({
  twoFaEnabled: localStorage.getItem('isTwoFaEnabled') === 'true' || false,
  setTwoFaEnabled: (newTwoFaEnabled: boolean) => {
    localStorage.setItem('isTwoFaEnabled', newTwoFaEnabled.toString());
    set(() => ({
      twoFaEnabled: newTwoFaEnabled,
    }));
  },
}));

export const useLoginInfoStore = create<LoginInfoState>((set) => ({
  loginInfo: {
    email: '',
    password: '',
  },
  setLoginInfo: (newLoginInfo) => {
    set(() => ({
      loginInfo: newLoginInfo,
    }));
  },
}));
