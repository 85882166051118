import { useQuery, UseQueryResult } from 'react-query';
import { Dayjs } from 'dayjs';
import { CORE_API_SUFFIX, dataFetch } from '../../../axios/customAxios';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';
import { appendQueryParamIfNotEmpty, formatDate, generateQueryKey } from '../../../constants/hookConstants';

type OperatingSystemVersionInterface = {
        version: string,
        count: number,
}[]

const useGetOperatingSystemVersions = (
  identifier: string,
  operatingSystem: string,
  isEnabled: boolean,
  adCode: string,
  from: Dayjs | null,
  to: Dayjs | null,
): UseQueryResult<ApiResponse<OperatingSystemVersionInterface>, ApiError<OperatingSystemVersionInterface>> => {
  const queryParams = new URLSearchParams();
  appendQueryParamIfNotEmpty(queryParams, 'from', formatDate(from));
  appendQueryParamIfNotEmpty(queryParams, 'to', formatDate(to));

  const queryKey = generateQueryKey(identifier, queryParams);

  return useQuery<ApiResponse<OperatingSystemVersionInterface>, ApiError<OperatingSystemVersionInterface>>(
    [`latest_operatingSystemVersions_${queryKey}`],
    () => dataFetch.get(`${CORE_API_SUFFIX}form-submission/operating-systems/versions/${adCode}?os=${operatingSystem}&${queryParams}`),
    {
      enabled: isEnabled,
    },
  );
};

export default useGetOperatingSystemVersions;
