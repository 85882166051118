import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license-pro';
import router from './router';

// eslint-disable-next-line max-len
LicenseInfo.setLicenseKey('7bff1e4f77143e3fac5b4728f2a63df5Tz03MDkwOCxFPTE3MjEzMDA3OTYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const App = () => (
  <RouterProvider router={router} />
);

export default App;
