import { Button, Grid } from '@mui/material';
import React from 'react';
import { FormikValues, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import TextInput from '../components/inputs/textInput';
import { useUpdateCampaign } from '../apiHooks/mutations/adManagementMutations/campaignsMutations';
import { CampaignInterface } from '../interfaces/campaign.interface';
import { campaignsFormValidationSchema } from '../validations/campaignsFormValidationSchema';
import TrackingSourceSelect from '../components/dropdownSelects/trackingSourceSelect';
import SecondaryButton from '../components/buttons/secondaryButton';
import PrimaryButton from '../components/buttons/primaryButton';
import LoadingSpinner from '../components/loadingSpinner/loadingSpinner';

interface CampaignsFormInterface {
  data?: CampaignInterface;
  closeDialog: () => void;
}

const CampaignsForm: React.FC<CampaignsFormInterface> = (props) => {
  const { mutateAsync: updateCampaign, isLoading: isUpdatingCampaign } = useUpdateCampaign();
  const { t } = useTranslation();

  const formik: FormikValues = useFormik({
    initialValues: {
      id: props.data?.id,
      name: props.data?.name || '',
      conversionValue: props.data?.conversionValue,
      campaignCode: props.data?.campaignCode || '',
      trackingSourceId: props.data?.trackingSource?.id || '',
    },
    validationSchema: () => campaignsFormValidationSchema(t),
    onSubmit: async (values: any) => {
      const request = {
        id: values.id,
        name: values.name,
        conversionValue: values.conversionValue,
        trackingSourceId: values.trackingSourceId,
        campaignCode: values.campaignCode,
      };

      await updateCampaign(request);

      props.closeDialog();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        sx={{
          display: 'flex', columnGap: '3rem', rowGap: '2.5rem', paddingBlock: '1rem', minWidth: '300px',
        }}
      >
        <Grid item xs={12}>
          <TextInput
            myKey="name"
            name="name"
            value={formik.values.name}
            label={t('name')}
            placeholder={t('name')}
            fullWidth
            onChange={formik.handleChange}
            error={Boolean(formik.touched.name) && Boolean(formik.errors.name)}
            helperText={Boolean(formik.touched.name) && formik.errors.name}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            myKey="campaignCode"
            name="campaignCode"
            value={formik.values.campaignCode}
            label={t('campaignCode')}
            placeholder={t('campaignCode')}
            fullWidth
            onChange={formik.handleChange}
            error={Boolean(formik.touched.campaignCode) && Boolean(formik.errors.campaignCode)}
            helperText={Boolean(formik.touched.campaignCode) && formik.errors.campaignCode}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            myKey="conversionValue"
            name="conversionValue"
            value={formik.values.conversionValue}
            label={t('conversionValue')}
            placeholder={t('conversionValue')}
            fullWidth
            onChange={formik.handleChange}
            error={Boolean(formik.touched.conversionValue) && Boolean(formik.errors.conversionValue)}
            helperText={Boolean(formik.touched.conversionValue) && formik.errors.conversionValue}
          />
        </Grid>
        <Grid item xs={12}>
          <TrackingSourceSelect
            data={props.data?.trackingSource}
            formik={formik}
          />
        </Grid>
      </Grid>
      <div className="ml-auto mt-5 flex w-fit gap-3">
        <SecondaryButton type="button" onClick={props.closeDialog} dataCy="campaigns-editor-cancel">
          {t('cancel')}
        </SecondaryButton>
        {isUpdatingCampaign
          ? (
            <div className="flex h-[43px] w-[102px] items-center justify-center">
              <LoadingSpinner size={25} variant="primary" color="primary" />
            </div>
          )
          : (
            <PrimaryButton type="submit" disabled={isUpdatingCampaign} dataCy="ads-editor-submit">
              {t('submit')}
            </PrimaryButton>
          )}
      </div>
    </form>
  );
};

export default CampaignsForm;
