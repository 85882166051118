import React, { FC } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import InfoIcon from '../../assets/icons/info-icon.svg';
import useGetSeoReport from '../../apiHooks/queries/reports/useGetSeoReport';
import LoadingSpinner from '../loadingSpinner/loadingSpinner';
import { SEO_REPORT } from '../../constants/pageIdentifiers';
import { useDashboardFilterDateStore } from '../../store/dateRangeStore';
import VatDisplayer from '../../partials/VATDisplayer';
import GoogleIcon from '../../assets/icons/tab/google-tab-icon.svg';
import BingIcon from '../../assets/icons/tab/bing-tab-icon.svg';
import YandexIcon from '../../assets/icons/yandexIcon-gray.svg';
import YahooIcon from '../../assets/icons/yahooIcon-gray.svg';
import DuckduckgoIcon from '../../assets/icons/duckduckgoIcon-gray.svg';
import { SeoReportRowInterface } from '../../interfaces/seoReportRow.interface';
import EmptyBigCard from '../dashboard/dashboardCards/emptyBigCard';
import { useOrganizationSettingsStore } from '../../store/organizationSettingsStore';
import OrganizationType from '../enums/organizationTypesEnum';

interface trackingSourceObjectType {
  [key: string]: { icon: string, alt: string, };
}

const maxRows = 7;

const areAllIntegerValuesZero = (obj: SeoReportRowInterface): boolean => Object.values(obj)
  .filter((value) => typeof value === 'number')
  .every((value) => value === 0);

const isReportEmpty = (emptyRows: number, array?: SeoReportRowInterface[]) => (
  !array ? true : isEmpty(array) || array.length - emptyRows === 0
);

const numberToArray = (emptyRows: number, number: number) => (
  Array.from({ length: maxRows + emptyRows - number }, (_, index) => index + 1)
);

const trackingSourcesIcons: trackingSourceObjectType = {
  Google: {
    icon: GoogleIcon,
    alt: 'google',
  },
  Bing: {
    icon: BingIcon,
    alt: 'bing',
  },
  Yahoo: {
    icon: YahooIcon,
    alt: 'yahoo',
  },
  Yandex: {
    icon: YandexIcon,
    alt: 'yandex',
  },
  DuckDuckGo: {
    icon: DuckduckgoIcon,
    alt: 'duckduckgo',
  },
};

const eCommerceSeoReportHeaders = ['salesSeo', 'incomeSeo'];

const seoReportHeaders = ['fsCountSeo', 'salesSeo', 'cvRateSeo', 'incomeSeo'];

const SeoReport: FC = () => {
  const dashboardFilterDateRange = useDashboardFilterDateStore((state) => state.dashboardFilterDateRange);
  const organizationType = useOrganizationSettingsStore((state) => state.organizationSettings?.organizationType);
  const { t } = useTranslation();
  let emptyRows = 0;

  const {
    isFetching,
    isError,
    data: seoReport,
  } = useGetSeoReport(
    SEO_REPORT,
    dashboardFilterDateRange[0],
    dashboardFilterDateRange[1],
  );

  return (
    <div>
      <div className="flex items-center justify-between rounded-t-lg border-b bg-white px-5 py-2.5">
        <h2 className="flex items-center text-2xl font-medium leading-[1.5] text-primary">
          {t('seoReportTitle')}
        </h2>
        <div>
          <Tooltip title={t('seoReportInfo')}>
            <img src={InfoIcon} alt="" className="max-w-[20px]" />
          </Tooltip>
        </div>
      </div>
      <div className="small-scroll overflow-auto pb-3">
        {!isError && isFetching && (
          <div className="h-[400px]">
            <LoadingSpinner center color="primary" />
          </div>
        )}
        {isError && !isFetching && <p>{t('errorRetrievingData')}</p>}
        {!isFetching && !isError && !isReportEmpty(emptyRows, seoReport?.data?.data) && (
          <table className="mt-3 max-h-[430px] w-full border-separate border-spacing-x-2 px-1">
            <thead className="w-full">
              <tr>
                <th>&nbsp;</th>
                {organizationType === OrganizationType.ECOMMERCE ? (
                  eCommerceSeoReportHeaders.map((textKey) => (
                    <th
                      key={textKey}
                      className="relative rounded-t-lg bg-green/5 px-4 py-3 text-xs font-semibold text-primary"
                    >
                      {t(textKey)}
                      <div className="absolute bottom-0 left-2 h-px w-[calc(100%-16px)] bg-primary" />
                    </th>
                  ))
                ) : (
                  seoReportHeaders.map((textKey) => (
                    <th
                      key={textKey}
                      className="relative rounded-t-lg bg-green/5 px-4 py-3 text-xs font-semibold text-primary"
                    >
                      {t(textKey)}
                      <div className="absolute bottom-0 left-2 h-px w-[calc(100%-16px)] bg-primary" />
                    </th>
                  ))
                )}
              </tr>
            </thead>
            <tbody className="w-full">
              {seoReport?.data?.data.map((item: SeoReportRowInterface, idx) => {
                const {
                  icon,
                  alt,
                } = trackingSourcesIcons[item.name];

                if (areAllIntegerValuesZero(item)) {
                  emptyRows += 1;
                  return null;
                }

                return (
                  <tr key={item.name}>
                    <td
                      className={`flex min-w-[160px] items-center gap-2 border-b py-2 ${idx === 0 ? 'border-t' : ''}`}
                    >
                      <div className="grid h-10 w-10 place-content-center rounded-lg border p-2">
                        <img src={icon} alt={alt} className="h-4 w-4 object-contain" />
                      </div>
                      <p className="text-sm leading-[1.5] text-primary">
                        {item.name}
                      </p>
                    </td>
                    {organizationType !== OrganizationType.ECOMMERCE && (
                      <td className="relative min-w-[100px] bg-green/5 text-center text-sm leading-[1.5] text-primary">
                        {item.formSubmissions}
                        <div className="absolute bottom-0  left-2 h-px w-[calc(100%-16px)] bg-green" />
                      </td>
                    )}
                    <td className="relative min-w-[100px] bg-green/5 text-center text-sm leading-[1.5] text-primary">
                      {item.sales}
                      <div className="absolute bottom-0  left-2 h-px w-[calc(100%-16px)] bg-green" />

                    </td>
                    {organizationType !== OrganizationType.ECOMMERCE && (
                      <td className="relative min-w-[100px] bg-green/5 text-center text-sm leading-[1.5] text-primary">
                        {Math.round((item.sales / (item.formSubmissions || 1)) * 100)}
                        %
                        <div className="absolute bottom-0  left-2 h-px w-[calc(100%-16px)] bg-green" />
                      </td>
                    )}
                    <td className="relative  min-w-[100px] bg-green/5 text-center text-sm leading-[1.5] text-primary">
                      <VatDisplayer
                        value={item.income}
                      />
                      <div className="absolute bottom-0  left-2 h-px w-[calc(100%-16px)] bg-green" />
                    </td>
                  </tr>
                );
              })}

              {numberToArray(emptyRows, seoReport?.data?.data.length || maxRows)
                .map((_, index) => {
                  const newIndex = index + 1;

                  return (
                    <tr key={`empty-${newIndex}`}>
                      <td />
                      {organizationType === OrganizationType.ECOMMERCE ? (
                        eCommerceSeoReportHeaders.map((header) => (
                          <td
                            key={`data-${newIndex}-${header}`}
                            className={`
                              bg-green/5 py-[27px] 
                              ${index === (seoReport?.data?.data.length || 0) - emptyRows ? 'rounded-b-xl' : ''}
                            `}
                          />
                        ))
                      ) : (
                        seoReportHeaders.map((header) => (
                          <td
                            key={`data-${newIndex}-${header}`}
                            className={`
                              bg-green/5 py-[27px] 
                              ${index === (seoReport?.data?.data.length || 0) - emptyRows ? 'rounded-b-xl' : ''}
                            `}
                          />
                        ))
                      )}

                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
        {!isFetching && !isError && isReportEmpty(emptyRows, seoReport?.data?.data) && (
          <div className="mt-[115px]">
            <EmptyBigCard />
          </div>
        )}
      </div>
    </div>
  );
};

export default SeoReport;
