import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../../../components/buttons/customButton';
import { statusCard } from '../../settings/integrations/googleTabPanel';
import { useDisconnectFromPaddle } from '../../../apiHooks/mutations/paddleMutations/paddleConnectionMutations';
import ConfirmDialog from '../../../dialogs/confirmDialog';

interface DisconnectFromPaddleScreenProps {
    refetchOrganizationSettings: () => void;
}

const DisconnectFromPaddleScreen: FC<DisconnectFromPaddleScreenProps> = (props) => {
  const { t } = useTranslation();
  const [isDisconnectFromPaddleDialogOpen, setIsDisconnectFromPaddleDialogOpen] = useState(false);
  const { mutateAsync: disconnectFromPaddle, isLoading } = useDisconnectFromPaddle();

  return (
    <>
      <ConfirmDialog
        variant="danger"
        open={isDisconnectFromPaddleDialogOpen}
        title="areYouSureYouWantToDisconnect"
        text="thisActionIsGoingToDisconnectTheAppFromPaddle"
        onCloseDialog={() => setIsDisconnectFromPaddleDialogOpen(false)}
        onSubmit={() => disconnectFromPaddle().then(() => props.refetchOrganizationSettings())}
        isDisabled={isLoading}
      />
      <div className="flex gap-3.5">
        <div className="flex min-w-[245px] items-center justify-between gap-3.5 rounded-lg border border-dark px-5 py-3.5">
          <p className="text-xs font-medium text-primary">
            {t('accountStatus')}
          </p>
          {statusCard(t, true)}
        </div>
        <div className="flex w-full items-center justify-between gap-5 rounded-lg border border-dark px-5 py-4">
          <div className="max-lg:w-[60%]">
            <p className="text-xs font-semibold leading-[1.5] text-primary">
              {t('disconnectPaddleIntegrationAsPaymentProvider')}
            </p>
            <p className="text-xs leading-[1.5] text-primary">
              {t('disconnectPaddleIntegrationAsPaymentProviderDescription')}
            </p>
          </div>
          <CustomButton
            disabled={false}
            className="px-6 py-2.5 text-sm"
            onClick={() => {
              setIsDisconnectFromPaddleDialogOpen(true);
            }}
          >
            {t('disconnectFromPaddle')}
          </CustomButton>
        </div>
      </div>
    </>
  );
};

export default DisconnectFromPaddleScreen;
