import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuthStore } from '../../../store/authStore';

const ImpersonateRoute = () => {
  const [searchParams] = useSearchParams();

  const login = useAuthStore((state) => state.login);

  useEffect(() => {
    const token = searchParams.get('token');

    if (token) {
      login(token, { organizationId: '-1', userId: '-1' });
      window.location.replace('/dashboard');
    }
  }, []);

  return (
    <div />
  );
};

export default ImpersonateRoute;
