import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import closableEnqueueSnackbar from '../../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';
import { handleApiError } from '../../requestHelpers/requestHelpers';

export const useSendGoogleInvite = () => {
  const { t } = useTranslation();
  return useMutation<ApiResponse<null>, ApiError<null>, string>(
    (clientId: string) => dataFetch.post<string, ApiResponse<null>>(
      `${DIGGER_API_SUFFIX}google-integration/extend-invite`,
      {
        clientId,
      },
    ),
    {
      onError: (error: ApiError<null>) => {
        handleApiError(error, t);
      },
      onSuccess: (response) => {
        const { message } = response.data.status;

        if (typeof message === 'string') {
          closableEnqueueSnackbar(t(message), 'success');
        }
      },
    },
  );
};
