import React, {useEffect} from 'react';
import SimpleTabs from '../../../components/tabs/simpleTabs';
import {useLocation, useNavigate} from 'react-router-dom';
import {useOrganizationSettingsStore} from "../../../store/organizationSettingsStore";

enum OrganizationSettingsTabLinks {
    managerialAccount,
  calculations,
  reporting,
  'page-views',
}

const OrganizationSettings = () => {
  const location = useLocation();
  const currentTab = location.pathname.split('/').pop();
  const navigate = useNavigate();
  const activePanel = OrganizationSettingsTabLinks[currentTab as keyof typeof OrganizationSettingsTabLinks] || 0;
  const { organizationSettings } = useOrganizationSettingsStore();

  const normalTabs = [
      {
          tabLabel: 'managerialAccount',
          href: 'managerialAccount',
          identifierName: 'managerial-account',
      },
      {
          tabLabel: 'Calculations',
          href: 'calculations',
          identifierName: 'calculations-panel',
      },
      {
          tabLabel: 'Reporting',
          href: 'reporting',
          identifierName: 'reporting-panel',
      },
      {
          tabLabel: 'Page views',
          href: 'page-views',
          identifierName: 'page-views-panel',
      },
  ]

    const filteredTabs = [
        {
            tabLabel: 'Calculations',
            href: 'calculations',
            identifierName: 'calculations-panel',
        },
        {
            tabLabel: 'Reporting',
            href: 'reporting',
            identifierName: 'reporting-panel',
        },
        {
            tabLabel: 'Page views',
            href: 'page-views',
            identifierName: 'page-views-panel',
        },
    ]

  return (
    <div className="bg-white px-[30px] py-[25px]">
      <SimpleTabs
        currentTab={activePanel}
        tabs={organizationSettings.managerialStatus === 4 ? filteredTabs : normalTabs}
      />
    </div>
  );
};

export default OrganizationSettings;
