import React, { FC, ReactNode, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import InfoIcon from '../../assets/icons/info-icon.svg';
import InfoIconWhite from '../../assets/icons/info-icon-white.svg';
import { Colors } from '../../constants/constants';
import SecondaryButton from '../buttons/secondaryButton';
import CustomDateRangePicker from '../../partials/customDateRangePicker/customDateRangePicker';
import GoogleIcon from '../../assets/icons/tab/google-tab-icon.svg';
import GoogleIconPrimary from '../../assets/icons/google-icon-primary.svg';
import BingIcon from '../../assets/icons/tab/bing-tab-icon.svg';
import BingIconPrimary from '../../assets/icons/bing-icon-primary.svg';
import FacebookIcon from '../../assets/icons/tab/facebook-tab-icon.svg';
import FacebookIconPrimary from '../../assets/icons/facebook-icon-primary.svg';
import { TrackingSourceEnum } from '../enums/trackingSourceEnum';
import EmailAtIconPrimary from '../../assets/icons/email-at-icon-primary.svg';
import EmailAtIcon from '../../assets/icons/email-at-icon-gray.svg';
import RightArrow from '../../assets/icons/rightArrowBack.svg';
import { useAdPageBackStore } from '../../store/adPageStore';

// TODO: props.value props.isLoading and props.isError are not used in the component. Remove or implement them.

interface StaticCardProps {
    title?: string,
    variant?: 'primary' | 'secondary',
    info?: string,
    children: ReactNode;
    filterDateRange?: DateRange<Dayjs>;
    setFilterDateRange?: (_range: DateRange<Dayjs>) => void;
    border?: boolean,
    classGrid?: string,
    active?: boolean,
    alternateHeader?: boolean,
    trackingSourceID?: number,
    variantStatus?: string,
    adName?: string,
    backExist?: string,
}

interface trackingSourceObjectType {
    [key: number]: { icon: string; iconPrimary: string, name: string };
}

export const trackingSourcesMap: trackingSourceObjectType = {
  [TrackingSourceEnum.Google]: { icon: GoogleIcon, iconPrimary: GoogleIconPrimary, name: 'Google' },
  [TrackingSourceEnum.Direct]: { icon: '', iconPrimary: '', name: 'Direct' },
  [TrackingSourceEnum.Newsletter]: { icon: EmailAtIcon, iconPrimary: EmailAtIconPrimary, name: 'Newsletter' },
  [TrackingSourceEnum.Bing]: { icon: BingIcon, iconPrimary: BingIconPrimary, name: 'Bing' },
  [TrackingSourceEnum.Content]: { icon: '', iconPrimary: '', name: 'Content' },
  [TrackingSourceEnum.Adstraction]: { icon: '', iconPrimary: '', name: 'Adstraction' },
  [TrackingSourceEnum.Facebook]: { icon: FacebookIcon, iconPrimary: FacebookIconPrimary, name: 'Facebook' },
};

const StaticCard: FC<StaticCardProps> = (props) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const { setSelectedOs, setSelectedIndex } = useAdPageBackStore((state) => state);

  const goBack = () => {
    setSelectedOs('');
    setSelectedIndex(null);
  };

  const { icon, name } = trackingSourcesMap[props.trackingSourceID || 1] || { icon: '', name: '' };

  return (
    <div
      className={`
        flex h-full w-full flex-1 flex-col rounded-lg border-2 ${props.classGrid}
        ${props.variant === 'primary' ? 'bg-white text-primary' : 'bg-primary text-white'}
      `}
    >
      {props.alternateHeader ? (
        <div className="flex items-center justify-between border-b-2 px-[25px] py-[10px]">
          <div className="flex items-center rounded-xl border">
            <div className="h-full rounded-l-xl bg-[#CEDADF] p-4 text-xs font-medium text-primary">AD NAME</div>
            <div className="min-w-[400px] px-4 py-2.5 text-sm text-primary">{props.adName}</div>
          </div>
          <div className="flex items-center gap-4">
            <div>{t('trackingSource')}</div>
            <div className="flex items-center gap-2 rounded-lg border border-primary px-3 py-2">
              <img src={icon} alt={name} />
              <div className="text-sm">{name}</div>
            </div>
            <div>{t('adStatus')}</div>
            <SecondaryButton
              style={{
                border: `1px solid ${props.active ? Colors.green : Colors.red}`,
                color: props.active ? Colors.green : Colors.red,
                height: '40px',
                fontSize: 14,
              }}
            >
              {props.active ? t('active') : t('notActive')}
            </SecondaryButton>
            {props.filterDateRange && (
              <SecondaryButton
                type="button"
                style={{ paddingInline: '1rem', border: '1px solid #EAEAEA', borderRadius: '8px' }}
                onMouseDown={() => setIsCalendarOpen(true)}
                dataCy="dashboard-actions-calendar-toggle"
              >
                <CustomDateRangePicker
                  filterRange={props.filterDateRange}
                  setFilterRange={(newFilterRange: DateRange<Dayjs>) => (
                    props.setFilterDateRange ? props.setFilterDateRange(newFilterRange) : props.filterDateRange
                  )}
                  isCalendarOpen={isCalendarOpen}
                  setIsCalendarOpen={setIsCalendarOpen}
                  iconButton
                  showFilterDates
                />
              </SecondaryButton>
            )}
          </div>
        </div>
      ) : (
        <div className={`flex items-center justify-between gap-4 px-[25px] py-[10px] ${props.border && 'border-b-2'}`}>
          <div className="flex items-center gap-4">
            <h2 className="text-[20px] font-medium leading-[36px]">
              {props.title}
            </h2>
            <Tooltip title={props.info}>
              {props.variant === 'primary' ? (
                <img src={InfoIcon} alt="info icon" className="max-w-[20px]" />
              ) : (
                <img src={InfoIconWhite} alt="white info icon" className="max-w-[20px]" />
              )}
            </Tooltip>
          </div>
          {props.backExist !== '' && props.variantStatus === 'adDetails' && (
          <button onClick={goBack} className="flex items-center gap-1 text-sm">
            <img src={RightArrow} />
            Back
          </button>
          ) }
        </div>
      )}
      <div className={`${props.variantStatus !== 'adDetails' ? 'px-[25px] pb-3 pt-[15px]' : ''}`}>
        {props.children}
      </div>
    </div>
  );
};

export default StaticCard;
