import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { ApiError, ApiResponse } from '../../interfaces/apiResponse.type';
import { dataFetch, DIGGER_API_SUFFIX } from '../../axios/customAxios';
import closableEnqueueSnackbar from '../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';

export interface UnsubscribeUrlResponse {
  cancel: string;
}

const useGetUnsubscribeUrl = (
  identifier: string,
  onUrlRetrieved: (_cancelUrl: string) => void,
) => {
  const { t } = useTranslation();
  return useQuery<AxiosResponse, ApiError<null>, ApiResponse<UnsubscribeUrlResponse>>(
    [`currentUser_${identifier}`],
    () => dataFetch.get(`${DIGGER_API_SUFFIX}organizations/subscriptions/cancel-url`),
    {
      onSuccess: (res) => {
        onUrlRetrieved(res.data.data.cancel);
      },
      onError: () => {
        closableEnqueueSnackbar(t('thereWasAnErrorRetrievingTheCancelSubscriptionUrl'), 'error');
      },
    },
  );
};

export default useGetUnsubscribeUrl;
