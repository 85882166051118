import React, {
  FC, FormEvent, SyntheticEvent, useEffect, useState,
} from 'react';
import { FormikValues } from 'formik';
import _ from 'lodash';
import AutoCompleteInput from '../inputs/autoCompleteInput';
import { TRACKING_SOURCE_OPTIONS } from '../../constants/pageIdentifiers';
import { TrackingSourceInterface } from '../../interfaces/trackingSource.interface';
import { useGetTrackingSourceOptions } from '../../apiHooks/queries/adManagement/useGetTrackingSources';

interface TrackingSourceSelectInterface {
  data?: TrackingSourceInterface,
  formik?: FormikValues,
  fullWidth?: boolean,
  size?: 'small' | 'medium',
  setSelectedTrackingSourceId?: (_newSelectedTrackingSourceId: string) => void,
  setSelectedTrackingSource?: (_newSelectedTrackingSource: TrackingSourceInterface | null) => void,
  disabled?: boolean,
}

const TrackingSourceSelect: FC<TrackingSourceSelectInterface> = (props) => {
  const [
    selectedValue,
    setSelectedValue,
  ] = useState<TrackingSourceInterface | null>(props.data || null);
  const [searchedValue, setSearchedValue] = useState<string>('');

  const {
    data: trackingSourcesOptionsData,
    refetch: refetchTrackingSourcesOptionsData,
    isFetching: isRefetchingTrackingSourcesOptionsData,
  } = useGetTrackingSourceOptions(TRACKING_SOURCE_OPTIONS, searchedValue);

  useEffect(() => {
    if (props.data) {
      setSelectedValue(props.data);
    }
  }, [props.data]);
  // TODO: check if this useEffect is remotely needed

  const debouncedRefetchTrackingSourceOptionsData = _.debounce(refetchTrackingSourcesOptionsData, 500);

  const onChangeAutoComplete = (event: FormEvent, newValue: TrackingSourceInterface) => {
    if (newValue?.id) {
      setSelectedValue(newValue);
      props.formik?.setFieldValue?.('trackingSourceId', newValue.id);
      props.setSelectedTrackingSourceId?.(JSON.stringify(newValue.id));
      props.setSelectedTrackingSource?.(newValue);
      setSearchedValue('');
    } else {
      setSelectedValue(null);
      props.formik?.setFieldValue?.('trackingSourceId', '');
      props.setSelectedTrackingSourceId?.('');
      props.setSelectedTrackingSource?.(null);
      setSearchedValue('');
    }
  };

  const onInputChangeAutoComplete = async (event: SyntheticEvent, value: string) => {
    setSelectedValue(null);
    setSearchedValue(value);
    await debouncedRefetchTrackingSourceOptionsData();
  };

  return (
    <AutoCompleteInput
      key="trackingSourceId"
      name="trackingSourceId"
      label="selectTrackingSource"
      placeholder="selectTrackingSource"
      size={props.size}
      style={{ minWidth: 213 }}
      options={trackingSourcesOptionsData?.data ? trackingSourcesOptionsData.data : []}
      getOptionLabel={(option: TrackingSourceInterface) => option?.name || ''}
      value={selectedValue}
      loading={isRefetchingTrackingSourcesOptionsData}
      onChange={(event: FormEvent, newValue: TrackingSourceInterface) => onChangeAutoComplete(event, newValue)}
      inputValue={selectedValue?.name || searchedValue}
      onInputChange={async (event: FormEvent, value: string) => {
        await onInputChangeAutoComplete(event, value);
      }}
      isOptionEqualToValue={(option: TrackingSourceInterface, value: TrackingSourceInterface) => (
        option.id === value.id
      )}
      error={Boolean(props.formik?.touched.trackingSourceId) && Boolean(props.formik?.errors.trackingSourceId)}
      helperText={Boolean(props.formik?.touched.trackingSourceId) && props.formik?.errors.trackingSourceId}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
    />
  );
};

export default TrackingSourceSelect;
