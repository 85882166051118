import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BasicDialog from './basicDialog';
import TwoFactorInput from '../components/twoFactorInput/twoFactorInput';

interface TwoFaDialogInterface{
    open: boolean;
    onClose: () => void;
    allowCloseOnBackdrop?: boolean;
}

const TwoFaDialog : FC<TwoFaDialogInterface> = (props) => {
  const { t } = useTranslation();
  const [twoFaCode, setTwoFaCode] = useState<string>('');

  return (
    <BasicDialog
      closeDialog={() => props.onClose()}
      open={props.open}
      title={t('welcomeBack')}
      allowCloseOnBackdrop={props.allowCloseOnBackdrop}
      headerColor="white"
      twoFaStyle
    >
      <form
        className="mx-auto flex w-full max-w-[400px] flex-col gap-3.5 pb-3 pt-1"
      >
        <TwoFactorInput
          title="enterAuthenticationCodeLogin"
          login
          onCodeChange={setTwoFaCode}
          closeDialog={() => props.onClose()}
          closeButton={false}
        />
      </form>
    </BasicDialog>
  );
};

export default TwoFaDialog;
