import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import ConversionSetupBox from '../../components/conversionSetupBox/conversionSetupBox';
import submitLeadIcon from '../../assets/icons/submitLeadFormIcon.svg';
import qualifiedLead from '../../assets/icons/qualifiedLeadIcon.svg';
import convertedLead from '../../assets/icons/convertedLeadIcon.svg';
import { useOtherConversionStore } from '../../store/conversionStepsStore';

const ConversionSubmitTypePage : FC = () => {
  const navigate = useNavigate();
  const { updateConversionType } = useOtherConversionStore();
  const createSubmitType = (conversionType : string) => {
    updateConversionType(conversionType);
    navigate('/tracking/google-conversions/other-conversions');
  };

  return (
    <div className="flex h-full w-full flex-col items-center justify-start">
      <ConversionSetupBox
        title="On Form Submit"
        onClick={() => createSubmitType('ON_SUBMIT')}
        imgSource={submitLeadIcon}
        horizontal
      />
      <ConversionSetupBox
        title="On Approve"
        onClick={() => createSubmitType('ON_QUALIFY')}
        imgSource={qualifiedLead}
        horizontal
      />
      <ConversionSetupBox
        title="On Convert"
        onClick={() => createSubmitType('ON_CONVERT')}
        imgSource={convertedLead}
        horizontal
      />
    </div>
  );
};

export default ConversionSubmitTypePage;
