import DashboardIconPrimary from '../../assets/icons/sidebar/dashboard-icon-primary2.svg';
import AdministrationIconPrimary from '../../assets/icons/sidebar/administration-icon-primary2.svg';
import AdManagementIconPrimary from '../../assets/icons/sidebar/ad-management-icon-primary2.svg';
import IntegrationsIconPrimary from '../../assets/icons/sidebar/integrations-icon-primary2.svg';
import SettingsIconPrimary from '../../assets/icons/sidebar/settings-icon-primary2.svg';
import DevelopersIconPrimary from '../../assets/icons/sidebar/developers-icon-primary2.svg';
import TrackingIcon from '../../assets/icons/sidebar/trackingIconSidebarPrimary2.svg';

interface NavigationList {
    label: string;
    iconPrimary: string;
    href: string;
    subList?: {
        href: string;
        label: string;
    }[]
}

// eslint-disable-next-line import/prefer-default-export
export const navigationList: NavigationList[] = [
  {
    label: 'dashboard',
    iconPrimary: DashboardIconPrimary,
    href: '/dashboard',
  },
  {
    label: 'administration',
    iconPrimary: AdministrationIconPrimary,
    href: '/administration',
  },
  {
    label: 'adManagement',
    iconPrimary: AdManagementIconPrimary,
    href: '/ad-management',
  },
  {
    label: 'tracking',
    iconPrimary: TrackingIcon,
    href: '/tracking',
  },
  {
    label: 'integrations',
    iconPrimary: IntegrationsIconPrimary,
    href: '/integrations',
  },
  {
    label: 'settings',
    iconPrimary: SettingsIconPrimary,
    href: '/settings',
  },
  {
    label: 'developers',
    iconPrimary: DevelopersIconPrimary,
    href: '/developers',
  },
];
