import React, {
  FC, useState, useEffect, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../loadingSpinner/loadingSpinner';
import useGetSalesByTrackingSource from '../../apiHooks/queries/adSalesTrackingSource/getAdSalesByTrackingSource';
import faceBookIcon from '../../assets/icons/facebookSalesIconDashboard.svg';
import GoogleIcon from '../../assets/icons/google-icon-primary.svg';
import bingIcon from '../../assets/icons/bing-icon-primary.svg';
import careDownIcon from '../../assets/icons/caret-down-primary.svg';
import { useDashboardFilterDateStore } from '../../store/dateRangeStore';

const SalesReportCard: FC = () => {
  const [selectedSource, setSelectedSource] = useState<number>(1);
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { dashboardFilterDateRange } = useDashboardFilterDateStore();
  const { data, isLoading } = useGetSalesByTrackingSource(
    'GET_AD_SALES',
    selectedSource,
    dashboardFilterDateRange[0],
    dashboardFilterDateRange[1],
  );
  const { t } = useTranslation();

  const getIcon = () => {
    switch (selectedSource) {
      case 1:
        return GoogleIcon;
      case 4:
        return bingIcon;
      case 10:
        return faceBookIcon;
      default:
        return GoogleIcon;
    }
  };

  const handleSelectChange = (value: number) => {
    setSelectedSource(value);
    setShowDropDown(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setShowDropDown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="flex h-[150px] w-full flex-col justify-between rounded-[8px] bg-white px-[28px] py-[25px]">
      <div className="flex w-full items-center justify-between">
        <div className="relative w-full">
          <div className="relative flex w-full items-center">
            <div ref={dropdownRef} className="relative inline-block w-full">
              <div
                onClick={() => setShowDropDown(!showDropDown)}
                className="border-gray-300 flex w-full items-center justify-start gap-4 rounded-[8px]
                bg-white py-2 leading-tight text-primary hover:cursor-pointer focus:outline-none focus:ring-primary"
              >
                <span>
                  {selectedSource === 1
                    ? t('googleSales')
                    : selectedSource === 4 ? t('bingSales') : t('facebookSales')}
                </span>
                <img src={careDownIcon} alt="" className="ml-2" />
              </div>
              {showDropDown && (
                <div className="absolute left-0 mt-2 w-full rounded-[8px] border border-[#E0E0E0] bg-white shadow-lg">
                  <div
                    className={`cursor-pointer p-2 text-primary ${selectedSource === 1 ? 'bg-gray-100' : ''}`}
                    onClick={() => handleSelectChange(1)}
                  >
                    {t('googleSales')}
                  </div>
                  <div
                    className={`cursor-pointer p-2 text-primary ${selectedSource === 4 ? 'bg-gray-100' : ''}`}
                    onClick={() => handleSelectChange(4)}
                  >
                    {t('bingSales')}
                  </div>
                  <div
                    className={`cursor-pointer p-2 text-primary ${selectedSource === 10 ? 'bg-gray-100' : ''}`}
                    onClick={() => handleSelectChange(10)}
                  >
                    {t('facebookSales')}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mb-2 border-b border-b-gray" />
      <div className="flex items-center justify-between">
        <p className="w-full text-[32px] font-medium text-primary">
          {isLoading ? (
            <LoadingSpinner color="primary" size={25} />
          ) : (
            <div className="flex w-full items-center justify-between">
              <span>
                {Math.round(Number(data?.data?.data)).toLocaleString()}
              </span>
              <img className="h-6 w-6" src={getIcon()} alt="" />
            </div>
          )}
        </p>
      </div>
    </div>
  );
};

export default SalesReportCard;
