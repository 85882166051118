import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import * as React from 'react';
import { ReactElement, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import {
  Button, Collapse, tableCellClasses, Tooltip,
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useSearchParams } from 'react-router-dom';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import FilterIcon from '../../assets/icons/filter-icon.svg';
import EditIcon from '../../assets/icons/edit-icon.svg';
import EmbedIcon from '../../assets/icons/embed-icon.svg';
import DeleteIcon from '../../assets/icons/delete-icon.svg';
import KeyIcon from '../../assets/icons/key-icon.svg';
import PlusIcon from '../../assets/icons/plus-icon.svg';
import { TableColumnInterface } from '../../interfaces/tableColumn.interface';
import TableFilters from '../tableFilters/tableFilters';
import CustomTablePagination from '../customTablePagination/customTablePagination';
import LoadingSpinner from '../loadingSpinner/loadingSpinner';
import { useToggledTableColumnsStore } from '../../store/store';
import {
  parseField, RenderEmptyTable, RenderError, RenderLoader,
} from './tableHelpers/tableHelpers';
import SwitchInput from '../inputs/switchInput';
import { Colors } from '../../constants/constants';
import CustomDateRangePicker from '../../partials/customDateRangePicker/customDateRangePicker';
import SecondaryButton from '../buttons/secondaryButton';
import { CustomButton } from '../buttons/customButton';
import TableExportButton from '../TableExportButton/tableExportButton';

interface BasicTableInterface {
  data: any,
  columns: TableColumnInterface[],
  isError: boolean,
  isFetching: boolean,
  isDeleting?: boolean,
  onEdit?: (_editObject: any) => void,
  onDelete?: (_id: number) => void,
  onEmbed?: (_id: string) => void,
  refetch: () => void,
  searchInput?: any,
  setSearchInput?: any,
  page: number,
  setPage: (_newPage: number) => void,
  setRowsPerPage: (_rowsPerPage: number) => void,
  rowsPerPage: number,
  setEditDialogIsOpen?: (_bool: boolean) => void,
  setIsEmbedDialogOpen?: (_bool: boolean) => void,
  formInputs?: any,
  setIsAreYouSureDialogOpen?: (_bool: boolean) => void,
  canGenerateNewPassword?: boolean,
  handleNavigation?: (_campaignName: any) => void,
  customFilters?: ReactElement,
  extendedColumns?: TableColumnInterface[],
  sortBy?: string,
  setSortBy?: (_sortBy: string) => void,
  orderBy?: string,
  setOrderBy?: (_orderBy: string) => void,
  sortablesList?: string[],
  filterDateRange?: DateRange<Dayjs>,
  setFilterDateRange?: (_newDateRange: DateRange<Dayjs>) => void,
  borderless?: boolean,
  hasNextPage?: boolean,
  fetchDownload?: (_acceptHeader: string) => void;
}

const BasicTable: React.FC<BasicTableInterface> = (props) => {
  const isTableColumnsToggled = useToggledTableColumnsStore((state) => state.isTableColumnsToggled);
  const setIsTableColumnsToggled = useToggledTableColumnsStore((state) => state.setIsTableColumnsToggled);
  const [columns, setColumns] = useState<TableColumnInterface[]>(
    (isTableColumnsToggled && props.extendedColumns) ? props.extendedColumns : props.columns,
  );
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams.entries());
  const isAnySearchInputNotEmpty = Object.values(params)
    .some((value) => value !== '');
  const [isFilterListOpen, setIsFilterListOpen] = useState<boolean>(isAnySearchInputNotEmpty);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const hasActions = !!props.onDelete || !!props.onEdit || props.canGenerateNewPassword || !!props.onEmbed;

  const COLUMN_LENGTH = hasActions ? columns.length + 1 : columns.length;

  console.log(props.data, 'data');

  const toggleTableColumns = () => {
    if (columns === props.columns && props.extendedColumns) {
      setColumns(props.extendedColumns);
      setIsTableColumnsToggled(true);
    } else {
      setColumns(props.columns);
      setIsTableColumnsToggled(false);
    }
  };

  const toggleOrderBy = () => {
    if (props.orderBy === 'asc') {
      props.setOrderBy?.('desc');
    } else if (props.orderBy === 'desc') {
      props.setOrderBy?.('');
      props.setSortBy?.('');
    } else {
      props.setOrderBy?.('asc');
    }
  };

  const renderOnEdit = (item: any) => (
    <Tooltip title={t('editRow')} arrow placement="top">
      <Button
        style={{
          opacity: props.isDeleting ? '0' : '1',
          minWidth: '30px',
        }}
        onClick={() => {
          props.onEdit?.(item);
          props.setEditDialogIsOpen?.(true);
        }}
      >
        <img src={EditIcon} alt="" className="max-w-[20px]" />
      </Button>
    </Tooltip>
  );

  const renderOnEmbedClick = (item: any) => (
    <Tooltip title={t('embed')} arrow placement="top">
      <Button
        style={{
          opacity: props.isDeleting ? '0' : '1',
          minWidth: '30px',
        }}
        onClick={() => {
          props.onEmbed?.(item.id);
          props.setIsEmbedDialogOpen?.(true);
        }}
      >
        <img src={EmbedIcon} alt="" className="max-w-[20px]" />
      </Button>
    </Tooltip>
  );

  const renderOnDelete = (item: any) => (
    <div>
      {props.isDeleting && (
        <LoadingSpinner color="primary" size={15} center />
      )}
      {!props.isDeleting && (
        <Tooltip title={t('deleteRow')} arrow placement="top">
          <Button
            style={{ minWidth: '30px' }}
            onClick={() => props.onDelete?.(item.id)}
          >
            <img src={DeleteIcon} alt="" className="max-w-[20px]" />
          </Button>
        </Tooltip>
      )}
    </div>
  );

  const renderOnGeneratePassword = (item: any) => (
    <Tooltip title={t('generateNewPassword')} arrow placement="top">
      <Button
        style={{
          opacity: props.isDeleting ? '0' : '1',
          minWidth: '30px',
        }}
        onClick={() => {
          props.onEdit?.(item);
          props.setIsAreYouSureDialogOpen?.(true);
        }}
      >
        <img src={KeyIcon} alt="" className="max-w-[20px]" />
      </Button>
    </Tooltip>
  );

  const renderHeaders = (column: TableColumnInterface, index: number) => (
    <TableCell
      key={column.field}
      style={{
        cursor: props.sortablesList?.includes(column.headerName) ? 'pointer' : '',
        fontSize: 12,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'left',
      }}
      className="border-x border-b bg-[#F5F5F5] duration-100 hover:bg-[#EAEAEA]"
      onClick={() => {
        if (props.sortablesList?.includes(column.headerName)) {
          props.setSortBy?.(column.headerName);
          toggleOrderBy();
        }
      }}
    >
      <p className="uppercase text-primary">
        {t(column.headerName)}
        {props.sortBy === column.headerName && props.orderBy !== '' && (
          <span>
            {props.orderBy === 'asc' && <ArrowUpwardIcon fontSize="small" />}
            {props.orderBy === 'desc' && <ArrowDownwardIcon fontSize="small" />}
          </span>
        )}
      </p>
    </TableCell>
  );

  const renderTableContent = (item: any, index: number) => (
    <TableRow
      key={item.id}
      className="border-2 hover:bg-[#FAFAFA]"
      sx={{
        [`& .${tableCellClasses.root}`]: {
          borderBottom: 'none',
        },
      }}
    >
      {columns?.map((column: TableColumnInterface, colIndex: number) => {
        const itemToRender = parseField(column.field, item);

        return (
          <TableCell
            key={crypto.randomUUID()}
            style={{
              color: Colors.primary,
              maxWidth: '350px',
              minWidth: '125px',
              cursor: props.handleNavigation ? 'pointer' : '',
              textAlign: 'left',
            }}
            className="truncate border-2"
            onClick={() => props.handleNavigation?.(item)}
          >
            <span>
              {column.cellRenderer && itemToRender !== undefined
                ? column.headerName === 'ctr'
                  ? `${itemToRender > 0 ? itemToRender.toFixed(2) : 0}%`
                  : column.cellRenderer(itemToRender)

                : (
                  <Tooltip title={itemToRender}>
                    <span>
                      {itemToRender}
                    </span>
                  </Tooltip>
                )}
            </span>
          </TableCell>
        );
      })}
      {hasActions && columns === props.columns && (
        <TableCell style={{ maxWidth: '90px' }}>
          <div className="flex items-center justify-end">
            {props.canGenerateNewPassword && renderOnGeneratePassword(item)}
            {props.onEdit && renderOnEdit(item)}
            {props.onDelete && renderOnDelete(item)}
            {props.onEmbed && renderOnEmbedClick(item)}
          </div>
        </TableCell>
      )}
    </TableRow>
  );

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: '8px',
        boxShadow: 'none',
        border: props.borderless ? 'none' : '1px solid #EAEAEA',
        width: '100%',
      }}
    >
      <Box px="16px" py="14px">
        <div className="flex items-center justify-between">
          {props.searchInput && (
            <>
              {props.extendedColumns && (
              <button
                type="button"
                className="flex h-[40px] cursor-pointer items-center gap-4 rounded-lg border px-4"
                onClick={toggleTableColumns}
              >
                <p className="select-none text-xs text-primary">
                  {t('moreDetails')}
                </p>
                <SwitchInput checked={columns !== props.columns} />
              </button>
              )}
              <div className="flex items-center gap-4">
                <div>
                  {props.filterDateRange && props.setFilterDateRange && (
                  <SecondaryButton
                    type="button"
                    onMouseDown={() => setIsCalendarOpen(true)}
                    dataCy="dashboard-actions-calendar-toggle"
                    style={{
                      paddingInline: '1rem',
                      border: `1px solid ${isCalendarOpen ? Colors.primary : '#eaeaea'}`,
                    }}
                  >
                    <CustomDateRangePicker
                      filterRange={props.filterDateRange}
                      setFilterRange={props.setFilterDateRange}
                      isCalendarOpen={isCalendarOpen}
                      setIsCalendarOpen={setIsCalendarOpen}
                      iconButton
                      showFilterDates
                    />
                  </SecondaryButton>
                  )}
                </div>
                <div>
                  <TableExportButton fetchDownload={props.fetchDownload} />
                </div>
                <Tooltip
                  arrow
                  placement="top"
                  title={t('toggleFilters')}
                  className={isFilterListOpen ? 'border-primary' : ''}
                >
                  <CustomButton
                    variant="iconOutlined"
                    onClick={() => setIsFilterListOpen((prev) => !prev)}
                  >
                    <img
                      src={FilterIcon}
                      alt=""
                      className="mx-auto max-h-[15px] max-w-[15px] object-contain"
                    />
                  </CustomButton>
                </Tooltip>
                {props.onEdit && (
                <Tooltip arrow placement="top" title={t('newRow')}>
                  <CustomButton
                    variant="icon"
                    onClick={() => {
                      props.onEdit?.(props.formInputs);
                      props.setEditDialogIsOpen?.(true);
                    }}
                  >
                    <img
                      src={PlusIcon}
                      alt=""
                      className="h-[15px] w-[15px] object-contain"
                    />
                  </CustomButton>
                </Tooltip>
                )}
              </div>
            </>
          )}
        </div>
        <Collapse
          in={props.searchInput && isFilterListOpen}
          timeout="auto"
          unmountOnExit
          className="mt-2"
        >
          <TableFilters
            searchInput={props.searchInput}
            setSearchInput={props.setSearchInput}
            refetch={props.refetch}
            setPage={props.setPage}
          >
            {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
            <>{props.customFilters}</>
          </TableFilters>
        </Collapse>
      </Box>
      <Box
        sx={{
          overflowX: 'auto',
          borderBottom: '1px solid #bdbdbd',
          borderTop: props.searchInput ? '1px solid #bdbdbd' : 'none',
        }}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {columns.map((column: TableColumnInterface, index) => renderHeaders(column, index))}
              {hasActions && columns === props.columns && (
                <TableCell
                  className="bg-[#F5F5F5] duration-100 hover:bg-[#EAEAEA]"
                  style={{
                    paddingRight: '1.5rem',
                    maxWidth: '90px',
                    borderBottom: '1px solid #bdbdbd',
                  }}
                >
                  <p className="text-right text-xs uppercase text-primary">
                    {t('actions')}
                  </p>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {!props.isFetching
                  && !props.isError
                  && props.data?.data?.map((item: any, index: number) => renderTableContent(item, index))}

            {props.isError && <RenderError columnLength={COLUMN_LENGTH} />}

            {!props.isFetching
                  && !props.isError
                  && !props.data?.data?.length && (
                  <RenderEmptyTable columnLength={COLUMN_LENGTH} />
            )}

            {props.isFetching && <RenderLoader columnLength={COLUMN_LENGTH} />}
          </TableBody>
        </Table>
      </Box>
      {!props.isFetching && (
        <CustomTablePagination
          count={props.data?.meta?.total}
          page={props.page}
          rowsPerPage={props.rowsPerPage}
          setPage={props.setPage}
          refetch={props.refetch}
          setRowsPerPage={props.setRowsPerPage}
          hasNextPage={props.hasNextPage}
        />
      )}
    </TableContainer>
  );
};

export default BasicTable;
