import React, { FC } from 'react';
import { Button, Grid } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '../../../../assets/icons/delete-icon.svg';
import { FieldTypeEnum } from '../../../enums/fieldTypeEnum';

interface CustomFieldsDataProps {
  field: any,
  deleteCustomField: (_id: number) => void,
  refetchCustomFields: () => void,
}

const CustomFieldsData: FC<CustomFieldsDataProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Grid container gap={2} key={props.field.id} className="items-center justify-between px-2 py-2">
      <Grid item md={0.5} className="font-bold">
        {props.field.id}
      </Grid>
      <Grid item md={2.5}>
        {t(props.field.fieldName)}
      </Grid>
      <Grid item md={2.5}>
        {t(FieldTypeEnum[props.field.fieldType])}
      </Grid>
      <Grid item md={2.5}>
        {props.field.isRequired ? <DoneIcon htmlColor="green" /> : <CloseIcon htmlColor="red" />}
      </Grid>
      <Grid item md={2.5}>
        <Button
          type="button"
          onClick={async () => {
            await props.deleteCustomField(props.field.id);
            await props.refetchCustomFields();
          }}
        >
          <img src={DeleteIcon} alt="" />
        </Button>
      </Grid>
    </Grid>
  );
};

export default CustomFieldsData;
