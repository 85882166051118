import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Chip } from '@mui/material';
import { CustomButton } from '../../../../components/buttons/customButton';
import TextInput from '../../../../components/inputs/textInput';
import { useOrganizationSettingsStore } from '../../../../store/organizationSettingsStore';
import {
  useSendRequestManager,
} from '../../../../apiHooks/mutations/sendRequestManagerialAccount/sendRequestManagerialAccount';
import LoadingSpinner from '../../../../components/loadingSpinner/loadingSpinner';
import XIcon from '../../../../assets/icons/managerialDisconnectX.svg';
import ConfirmDialog from '../../../../dialogs/confirmDialog';
import {
  useSendDisconnectOrganizationManagement,
} from '../../../../apiHooks/mutations/disconnectOrganizationManagment/sendDisconnectOrganizationManagment';
import {
  useSendInviteOrganizationManage,
} from '../../../../apiHooks/mutations/inviteOrganizationsManage/sendInviteOrganizationManage';
import useGetManagedOrganizations from '../../../../apiHooks/queries/managedOrganizations/getManagedOrganizations';
import { formatDateAndTime } from '../../../../constants/constants';
import useGetManagedByOrganizations
  from '../../../../apiHooks/queries/getManagedByOrganizations/getManagedByOrganizations';

export enum ManagerialStatus {
  DEFAULT = 0,
  PENDING = 1,
  ACTIVE = 2,
  IS_MANAGED = 3,
  IN_ORGANIZATION = 4,
}

enum AccountStatus {
  ACTIVE = 'ACTIVE',
  DECLINED = 'DECLINED',
  PENDING = 'PENDING',
}

const ManagerialAccountPanel = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [dialogState, setDialogState] = useState<boolean>(false);
  const { organizationSettings } = useOrganizationSettingsStore();
  const [organizationID, setOrganizationID] = useState<string>('');

  const { mutate: disconnectFromManager } = useSendDisconnectOrganizationManagement();
  const { mutate: sendRequestManager } = useSendRequestManager();
  const { mutate: sendRequestInvite } = useSendInviteOrganizationManage();
  const disconnectOrganization = () => {
    disconnectFromManager();
    setDialogState(false);
  };

  const { data: managedOrganizations } = useGetManagedOrganizations('MANAGERIAL_ACCOUNT_ORGANIZATIONS');
  const { data: managedByOrganizations } = useGetManagedByOrganizations('MANAGED_ACCOUNT_ORGANIZATIONS');

  if (organizationSettings.managerialStatus === ManagerialStatus.IN_ORGANIZATION) {
    navigate('/settings/organization-settings/calculations', { replace: true });
    return null; // Return null to avoid rendering the component
  }

  const renderStatusChip = (status: AccountStatus) => {
    let color: 'default' | 'error' | 'success' | 'warning' | 'info' | 'primary' | 'secondary' | undefined;
    switch (status) {
      case AccountStatus.ACTIVE:
        color = 'success';
        break;
      case AccountStatus.DECLINED:
        color = 'error';
        break;
      case AccountStatus.PENDING:
        color = 'warning';
        break;
      default:
        color = 'default';
    }
    return <Chip sx={{ borderRadius: '8px', paddingLeft: '10px', paddingRight: '10px' }} label={status} color={color} variant="outlined" />;
  };

  return (
    <>
      <div>
        <div className="mb-8 flex flex-col border-b-[2px]">
          <span className="my-3 text-[22px] font-[600] text-primary">{t('managerialAccount')}</span>
          <p className="pb-3 text-[12px] text-[#0C485E99]">
            {organizationSettings.managerialStatus && organizationSettings.managerialStatus === ManagerialStatus.IS_MANAGED ? t('seeWhoisManage')
              : organizationSettings.managerialStatus === ManagerialStatus.DEFAULT ? t('setupManage') : organizationSettings.managerialStatus === ManagerialStatus.IN_ORGANIZATION && t('manageOrganizationAndUsers')}
          </p>
        </div>
        {organizationSettings.managerialStatus === ManagerialStatus.ACTIVE || organizationSettings.managerialStatus === ManagerialStatus.IS_MANAGED
          ? (
            <div>
              {organizationSettings.managerialStatus !== ManagerialStatus.IS_MANAGED && (
                <div className="mb-6 flex justify-between gap-5 rounded-xl border p-4">
                  <div className="flex gap-5">
                    <div className="h-[40px]">
                      <TextInput
                        name="Organization Id"
                        label="Organization ID"
                        size="small"
                        placeholder=""
                        value={organizationID}
                        onChange={(e) => setOrganizationID(e.target.value)}
                        className="h-full"
                      />
                    </div>
                    <CustomButton
                      className="h-[40px] px-[20px] text-sm"
                      onClick={() => sendRequestInvite(organizationID)}
                    >
                      {t('sendInvite')}
                    </CustomButton>
                  </div>
                </div>
              )}
              <TableContainer component={Paper} sx={{ boxShadow: 'none', border: '1px solid #EAEAEA', borderRadius: 2 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: '#0C485E' }}>{organizationSettings.managerialStatus === ManagerialStatus.IS_MANAGED ? t('managerialNameAndID') : t('organizationNameAndId')}</TableCell>
                      <TableCell sx={{ color: '#0C485E' }}>{organizationSettings.managerialStatus === ManagerialStatus.IS_MANAGED ? t('dateInvited') : t('inviteDate')}</TableCell>
                      <TableCell sx={{ color: '#0C485E' }}>{organizationSettings.managerialStatus === ManagerialStatus.IS_MANAGED ? t('action') : t('accountStatus')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {organizationSettings.managerialStatus === ManagerialStatus.ACTIVE && managedOrganizations?.data?.data.map((row, index) => (
                      <TableRow key={row.orgId} sx={{ backgroundColor: index % 2 === 0 ? '#FAFAFA' : '#FFFFFF' }}>
                        <TableCell sx={{ border: 'none', color: '#0C485E' }} component="th" scope="row">
                          {`${row.orgName}  #${row.orgId}`}
                        </TableCell>
                        <TableCell sx={{ border: 'none', color: '#0C485E' }}>{formatDateAndTime(row.inviteDate)}</TableCell>
                        <TableCell sx={{ border: 'none', color: '#0C485E' }}>{renderStatusChip(row.accountStatus as AccountStatus)}</TableCell>
                      </TableRow>
                    ))}
                    {organizationSettings.managerialStatus === ManagerialStatus.IS_MANAGED && managedByOrganizations?.data?.data
                      && (
                      <TableRow key={managedByOrganizations?.data?.data.orgId} sx={{ backgroundColor: '#FAFAFA' }}>
                        <TableCell sx={{ border: 'none', color: '#0C485E' }} component="th" scope="row">
                          {`${managedByOrganizations?.data?.data.orgName}  #${managedByOrganizations?.data?.data.orgId}`}
                        </TableCell>
                        <TableCell sx={{ border: 'none', color: '#0C485E' }}>{formatDateAndTime(managedByOrganizations?.data?.data.inviteDate)}</TableCell>
                        <TableCell sx={{ border: 'none', color: '#0C485E' }}>
                          <button
                            onClick={() => setDialogState(true)}
                            className="flex h-[32px] items-center gap-2 rounded-xl border bg-white px-[10px]
                              text-xs font-normal text-[#DB3A34]"
                          >
                            <img src={XIcon} alt="" />
                            Disconnect
                          </button>
                        </TableCell>
                      </TableRow>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )
          : organizationSettings.managerialStatus === ManagerialStatus.DEFAULT ? (
            <div className="flex w-full gap-5 rounded-xl border p-4">
              <CustomButton
                className="h-[48px] px-[20px] text-sm"
                onClick={() => sendRequestManager()}
              >
                {t('requestPermission')}
              </CustomButton>
            </div>
          )
            : organizationSettings.managerialStatus === ManagerialStatus.PENDING ? (
              <div className="flex w-full gap-5 rounded-xl border p-4">
                <div className="flex h-[48px] items-center justify-center rounded-xl bg-[#EAEAEA] px-5 text-primary">
                  {t('permissionPending')}
                </div>
              </div>
            ) : (
              <div className="my-6 w-full">
                <LoadingSpinner size={50} color="primary" center />
              </div>
            )}
      </div>
      <ConfirmDialog
        variant="danger"
        onCloseDialog={() => setDialogState(false)}
        title="sureYouWantDisconnect"
        text="thisActionDisconnectManagerialOrganization"
        onSubmit={() => disconnectOrganization()}
        open={dialogState}
      />
    </>
  );
};

export default ManagerialAccountPanel;
