import React, { FC } from 'react';
import CalculationsOrganizationSettingsForm
  from '../../../../forms/organizationSettingsForms/calculationsOrganizationSettingsForm';
import { useOrganizationSettingsStore } from '../../../../store/organizationSettingsStore';

const CalculationsPanel: FC = () => {
  const { organizationSettings } = useOrganizationSettingsStore();
  const { setOrganizationSettings } = useOrganizationSettingsStore();
  return (
    <CalculationsOrganizationSettingsForm
      organizationSettings={organizationSettings}
      setOrganizationSettings={setOrganizationSettings}
    />
  );
};

export default CalculationsPanel;
