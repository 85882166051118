import React from 'react';
import TextInput from './textInput';

interface EmailInputInterface {
  id: string,
  myKey: string,
  name: string,
  value: string,
  label: string,
  placeholder: string,
  size?: 'small' | 'medium',
  onChange: (_event: React.ChangeEvent<HTMLInputElement>) => void,
  error?: boolean,
  helperText?: string,
  fullWidth: boolean,
  style?: React.CSSProperties;
  dataCy?: string;
}

const EmailInput: React.FC<EmailInputInterface> = (props) => (
  <TextInput
    id={props.id}
    key={props.myKey}
    name={props.name}
    value={props.value}
    label={props.label}
    placeholder={props.placeholder}
    onChange={props.onChange}
    error={props.error}
    helperText={props.helperText}
    fullWidth={props.fullWidth}
    style={props.style}
    size={props.size}
    InputProps={{ 'data-cy': props.dataCy } as any}
  />
);

export default EmailInput;
