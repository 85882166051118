import { useQuery, UseQueryResult } from 'react-query';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';

type ManagedByOrganizationsInterface = {
    orgName: string,
    orgId: string,
    inviteDate: string,
}

const useGetManagedByOrganizations = (
  identifier: string,
): UseQueryResult<ApiResponse<ManagedByOrganizationsInterface>, ApiError<ManagedByOrganizationsInterface>> => useQuery<ApiResponse<ManagedByOrganizationsInterface>, ApiError<ManagedByOrganizationsInterface>>(
  [`managed_organizations_${identifier}`],
  () => dataFetch.get(`${DIGGER_API_SUFFIX}managerial-accounts/managed-account`),
);

export default useGetManagedByOrganizations;
