import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import { dataFetch, DIGGER_API_SUFFIX } from '../../axios/customAxios';
import { appendQueryParamIfNotEmpty, formatDate, generateQueryKey } from '../../constants/hookConstants';

const useGetUsers = (
  identifier: string,
  firstName: string,
  lastName: string,
  email: string,
  orderBy: string,
  sortBy: string,
  from: Dayjs | null,
  to: Dayjs | null,
  rows: number,
  page: number,
) => {
  const navigate = useNavigate();

  const queryParams = new URLSearchParams();
  appendQueryParamIfNotEmpty(queryParams, 'firstName', firstName.trim());
  appendQueryParamIfNotEmpty(queryParams, 'lastName', lastName.trim());
  appendQueryParamIfNotEmpty(queryParams, 'email', email.trim());
  appendQueryParamIfNotEmpty(queryParams, 'orderBy', orderBy.trim());
  appendQueryParamIfNotEmpty(queryParams, 'sortBy', sortBy.trim());
  appendQueryParamIfNotEmpty(queryParams, 'from', formatDate(from));
  appendQueryParamIfNotEmpty(queryParams, 'to', formatDate(to));
  appendQueryParamIfNotEmpty(queryParams, 'rows', rows);
  appendQueryParamIfNotEmpty(queryParams, 'page', page);

  const queryKey = generateQueryKey(identifier, queryParams);

  return useQuery(
    [`users_${queryKey}`],
    () => dataFetch.get(`${DIGGER_API_SUFFIX}users?${queryParams}`).then((res) => res.data),
    {
      onSuccess: () => {
        navigate(`${window.location.pathname}?${queryParams.toString()}`, { replace: true });
      },
    },
  );
};

export default useGetUsers;
