import * as yup from 'yup';
import { TFunction } from 'i18next';

export const calculationsOrganizationSettingsFormValidationSchema = (t: TFunction) => yup.object({
  costVAT: yup.number().typeError(t('inputMustBeNumber') ?? ''),
  incomeVAT: yup.number().typeError(t('inputMustBeNumber') ?? ''),
});

export const reportingOrganizationSettingsFormValidationSchema = (t: TFunction) => yup.object({
  monthsForAdReports: yup
    .number()
    .typeError(t('inputMustBeNumber') ?? '')
    .integer(t('numberMustBeInteger') ?? '')
    .min(1, t('numberMustBeGreaterThanZero') ?? '')
    .max(36, t('numberCannotBeGreaterThan36') ?? ''),
  currency: yup.string(),
  landingPage: yup.string(),
});

export const pageViewsOrganizationSettingsFormValidationSchema = (t: any) => yup.object({
  isPageViewsEnabled: yup.boolean(),
});

export const bingOrganizationSettingsFormValidationSchema = (t: TFunction) => yup.object({
  microsoftAccountId: yup
    .number()
    .typeError(t('inputMustBeNumber') ?? '')
    .integer(t('numberMustBeInteger') ?? '')
    .min(1, t('numberMustBeGreaterThanZero') ?? ''),
});

export const facebookOrganizationSettingsValidationSchema = (t: TFunction) => yup.object({
  microsoftAccountId: yup
    .number()
    .typeError(t('inputMustBeNumber') ?? '')
    .integer(t('numberMustBeInteger') ?? '')
    .min(1, t('numberMustBeGreaterThanZero') ?? ''),
});
