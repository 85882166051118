import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ConversionSetupBox from '../../components/conversionSetupBox/conversionSetupBox';
import loanBrokerBlueIcon from '../../assets/icons/loanBrokerIcon.svg';
import loanBrokerGrayIcon from '../../assets/icons/loanBrokerGrayIcon.svg';
import ecomBlueIcom from '../../assets/icons/ecomBlueIcon.svg';
import ecomGrayIcom from '../../assets/icons/ecomGrayIcon.svg';
import sassBlueIcon from '../../assets/icons/sassBlueIcon.svg';
import sassGrayIcon from '../../assets/icons/sassIcon.svg';
import otherBlueIcon from '../../assets/icons/otherBlueIcon.svg';
import otherGrayIcon from '../../assets/icons/otherIcon.svg';
import {
  useSendConversionOrganization,
} from '../../apiHooks/mutations/conversionOrganization/sendConversionOrganization';
import { useOrganizationSettingsStore } from '../../store/organizationSettingsStore';
import closableEnqueueSnackbar from '../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';

const GoogleConversionsPage: FC = () => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<string>('');
  const navigate = useNavigate();
  const organizationSettings = useOrganizationSettingsStore((state) => state.organizationSettings);

  const { mutate: sendOrganization } = useSendConversionOrganization();

  const handleOptionSelected = (title: string) => {
    setSelectedOption(title);
  };

  const navigateSendRequest = () => {
    if (!organizationSettings.googleClientId) {
      closableEnqueueSnackbar(t('pleaseConnectGoogle'), 'error');
    } else {
      sendOrganization(selectedOption);
    }
  };

  useEffect(() => {
    if (organizationSettings.organizationType) {
      navigate('/tracking/google-conversions/conversions', { replace: true });
    }
  }, []);

  return (
    <div className="h-full rounded-lg bg-white p-8">
      <div className="flex h-full max-h-[280px] flex-col">
        <p className="text-2xl font-normal text-primary">
          {t('chooseOrganization')}
        </p>
        <div className="flex h-full w-full items-center justify-between">
          <ConversionSetupBox
            title="loanBroker"
            imgSource={selectedOption === 'loanBroker' ? loanBrokerBlueIcon : loanBrokerGrayIcon}
            onSelected={() => handleOptionSelected('loanBroker')}
            selected={selectedOption === 'loanBroker'}
          />
          <ConversionSetupBox
            title="eCommerce"
            imgSource={selectedOption === 'eCommerce' ? ecomBlueIcom : ecomGrayIcom}
            onSelected={() => handleOptionSelected('eCommerce')}
            selected={selectedOption === 'eCommerce'}
          />
          <ConversionSetupBox
            title="saas"
            imgSource={selectedOption === 'saas' ? sassBlueIcon : sassGrayIcon}
            onSelected={() => handleOptionSelected('saas')}
            selected={selectedOption === 'saas'}
          />
          <ConversionSetupBox
            title="other"
            imgSource={selectedOption === 'other' ? otherBlueIcon : otherGrayIcon}
            onSelected={() => handleOptionSelected('other')}
            selected={selectedOption === 'other'}
            isLast
          />
        </div>
        <div className="flex w-full justify-end">
          <button
            type="button"
            disabled={selectedOption == null}
            onClick={navigateSendRequest}
            className="rounded-lg bg-primary px-6 py-2 text-white"
          >
            {t('continue')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default GoogleConversionsPage;
