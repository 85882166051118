import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import { handleApiError } from '../../requestHelpers/requestHelpers';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';
import closableEnqueueSnackbar from '../../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';
import { useOrganizationSettingsStore } from '../../../store/organizationSettingsStore';

export const useSendDisconnectOrganizationManagement = () => {
  const { t } = useTranslation();
  const { organizationSettings, setOrganizationSettings } = useOrganizationSettingsStore();

  return useMutation<ApiResponse<null>, ApiError<null>>(
    () => dataFetch.put(
      `${DIGGER_API_SUFFIX}organizations/disconnect-management`,
    ),
    {
      onSuccess: () => {
        closableEnqueueSnackbar(t('disconnectedSuccess'), 'success');
        setOrganizationSettings({ ...organizationSettings, managerialStatus: 0 });
      },
      onError: (error: any) => {
        handleApiError(error, t);
      },
    },
  );
};
