import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RefreshIcon from '../../../assets/icons/reverseRefreshIcon-white.svg';
import useGetLatestFormSubmissions, {
  LatestFormSubmissionsResponse,
} from '../../../apiHooks/queries/latestFormSubmissions/useGetLatestFormSubmissions';
import { LATEST_FORM_SUBMISSIONS, LATEST_FS_UPDATES } from '../../../constants/pageIdentifiers';
import redIcon from '../../../assets/icons/redIcon.svg';
import greenIcon from '../../../assets/icons/greenIcon.svg';
import yellowIcon from '../../../assets/icons/yellowIcon.svg';
import LoadingSpinner from '../../loadingSpinner/loadingSpinner';
import {
  isUUID, isValidBrowser, isValidLink, keysToExclude,
} from '../../../constants/validation';
import errorImage from '../../../assets/images/empty/errorPerson.png';
import useGetLatestFsUpdate, { LatestFsUpdateResponse } from '../../../apiHooks/queries/fsUpdate/useGetLatestFsUpdate';
import { useSyncAds } from '../../../apiHooks/mutations/syncLatestAds/useSyncAds';
import FullDevAccordion from '../../fullDevAccordion/fullDevAccordion';
import { CustomButton } from '../../buttons/customButton';

type DevConsoleDataArray = LatestFormSubmissionsResponse['data'] | LatestFsUpdateResponse['data'];

function iconValueDisplay(icon : string) {
  if (icon === 'red') return redIcon;
  if (icon === 'yellow') return yellowIcon;
  return greenIcon;
}

const DeveloperTable = () => {
  const { t } = useTranslation();
  const [isOn, setIsOn] = useState<boolean>(false);
  const [expandedAccordion, setExpandedAccordion] = useState<null | string>(null);
  const [clickedAccordion, setClickedAccordion] = useState<null | string>(null);

  const handleAccordionChange = (panel : null | string) => {
    setExpandedAccordion((prevState) => (panel === prevState ? null : panel));
    if (panel === clickedAccordion) {
      setClickedAccordion(null);
    } else {
      setClickedAccordion(panel);
    }
  };

  const toggleSwitch = () => {
    setIsOn(!isOn);
  };

  const checkConditions = (key: string, value: any) => {
    switch (key) {
      case 'adCode':
        if (value === '') return { color: 'yellow', reason: 'adCode is empty' };
        break;
      case 'sessionId':
        if (value === '') return { color: 'red', reason: 'sessionId is Empty' };
        if (!isUUID(value)) return { color: 'yellow', reason: 'Invalid UUID' };
        break;
      case 'path':
        if (value === '') return { color: 'red', reason: 'Path is empty' };
        if (!isValidLink(value)) return { color: 'red', reason: "path isn't a valid link" };
        break;
      case 'referrer':
        if (value === '') return { color: 'yellow', reason: 'referrer is empty' };
        if (!isValidLink(value)) return { color: 'yellow', reason: "referrer isn't a valid link" };
        break;
      case 'browser':
        if (value === '') return { color: 'red', reason: 'browser is empty' };
        if (!isValidBrowser(value)) return { color: 'red', reason: "browser isn't a valid browser" };
        break;
      case 'status':
        if (value === '') return { color: 'red', reason: 'status id is empty' };
        if (![0, 1, 2, 3].includes(value)) return { color: 'red', reason: "status id isn't valid" };
        break;
      case 'income':
        if (value === '0') return { color: 'yellow', reason: 'income is empty' };
        break;
      case 'originalReferrer':
        if (value === '') return { color: 'yellow', reason: 'originalReferrer is empty' };
        if (!isValidLink(value)) return { color: 'yellow', reason: "originalReferrer isn't a valid link" };
        break;
      default:
        return { color: 'green', reason: 'Correct' };
    }
    return { color: 'green', reason: 'Correct' };
  };

  const {
    data: formSubmissionsData,
    refetch: refetchFormSubmissionData,
    isFetching,
    isError,
  } = useGetLatestFormSubmissions(
    LATEST_FORM_SUBMISSIONS,
  );

  const {
    data: updateData,
    refetch: refetchUpdateData,
    isFetching: updateIsFetching,
    isError: updateError,
  } = useGetLatestFsUpdate(
    LATEST_FS_UPDATES,
  );

  const {
    mutate: syncLastMonths,
  } = useSyncAds();

  // TODO: Fix this unholy typing
  const devConsoleDataArray: DevConsoleDataArray = (isOn ? updateData?.data?.data != null ? updateData?.data?.data : [] || formSubmissionsData?.data?.data : formSubmissionsData?.data?.data || updateData?.data?.data) as unknown as DevConsoleDataArray;

  return (
    <div className="mt-4 rounded-[8px] border-[1px] bg-white">
      <div className="flex items-center justify-between border-b-[1px] px-4 py-3.5">
        <div className="flex w-full max-w-[175px] items-center gap-2
          rounded-[8px] border-[1px] px-4 py-3.5 text-[14px] text-primary"
        >
          {t('create')}
          /
          {t('update')}
          <div
            className={`relative inline-block h-3 w-6 cursor-pointer 
            rounded-full ${isOn ? 'bg-[#4C956C]' : 'bg-[#D9D9D9]'}`}
            onClick={toggleSwitch}
          >
            <div
              className={`absolute mt-[2px] h-2 w-2 
              rounded-full transition-transform duration-300 
              ${isOn ? 'translate-x-3.5 bg-white' : 'translate-x-0.5 bg-primary'}`}
            />
          </div>
        </div>
        <div className="flex w-full max-w-[400px] items-center gap-2">
          <CustomButton
            variant="outline"
            onClick={() => syncLastMonths()}
            className="flex w-full max-w-[260px] items-center justify-center rounded-[8px] border-[1px] px-3 py-3.5 text-[14px] text-primary"
          >
            {t('lastMonthsOfAds')}
          </CustomButton>
          <CustomButton
            onClick={() => (isOn ? refetchUpdateData() : refetchFormSubmissionData())}
            className="flex w-full max-w-[165px] items-center justify-center gap-2 rounded-[8px] border-[1px] bg-primary px-4 py-3.5 text-[14px] text-white"
          >
            Refresh Latest
            {isFetching || updateIsFetching
              ? (
                <div className="ml-[6px] flex w-full max-w-[10px] items-center justify-center">
                  <LoadingSpinner color="#fff" size={10} center />
                </div>
              )
              : <img src={RefreshIcon} alt="" />}
          </CustomButton>
        </div>
      </div>
      <div className="flex items-center justify-between border-b-[1px] px-4 py-3.5 text-sm font-medium text-primary">
        <div>#</div>
        <div>{t('id')}</div>
        <div>{t('createdAt')}</div>
        <div />
      </div>
      <div>
        {isError
                && (
                <div className="flex min-h-[400px] items-center justify-center px-3 py-2 text-primary">
                  <div className="grid place-items-center text-primary">
                    <img className="mb-4 max-h-[240px] max-w-[240px]" src={errorImage} alt="Error" />
                    <p>{t('wereSorryIssue')}</p>
                    <button
                      type="button"
                      onClick={() => (isOn ? refetchUpdateData() : refetchFormSubmissionData())}
                      className="text-[#4C956C]"
                    >
                      {t('tryToRefreshLatest')}
                    </button>
                  </div>
                </div>
                )}
        {(isFetching && !isError) || (updateIsFetching && !updateError) ? (
          <div className="flex h-full min-h-[400px] items-center justify-center">
            <LoadingSpinner color="primary" size={50} center />
          </div>
        ) : devConsoleDataArray?.map((item: any, index: number) => {
          let accordionColor = 'green';

          for (const [key, value] of Object.entries(isOn ? JSON.parse(item.fsJson) : item)) {
            if (!keysToExclude.includes(key)) {
              const { color } = checkConditions(key, value);

              if (color === 'red') {
                accordionColor = 'red';
                break;
              } else if (color === 'yellow') {
                accordionColor = 'yellow';
              }
            }
          }

          return (
            <FullDevAccordion
              accordionColor={accordionColor}
              checkConditions={checkConditions}
              clickedAccordion={clickedAccordion}
              expandedAccordion={expandedAccordion}
              handleAccordionChange={handleAccordionChange}
              iconValueDisplay={iconValueDisplay}
              index={index.toString()}
              isOn={isOn}
              item={item}
            />
          );
        })}
      </div>
    </div>
  );
};

export default DeveloperTable;
