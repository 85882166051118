import { useQuery } from 'react-query';
import { CORE_API_SUFFIX, dataFetch } from '../../../axios/customAxios';
import { appendQueryParamIfNotEmpty, formatDate, generateQueryKey } from '../../../constants/hookConstants';
import { QueryDefaultParams, QueryHook } from '../../../interfaces/query/queryHook.type';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';

interface UseGetProfitableAdsParams extends QueryDefaultParams{
    page: number;
    enabled: boolean;
}

export const useGetProfitableAds: QueryHook<UseGetProfitableAdsParams, ApiResponse<any>, ApiError<any>
> = (params) => {
  const queryParams = new URLSearchParams();
  queryParams.append('page', params.page.toString());
  appendQueryParamIfNotEmpty(queryParams, 'from', formatDate(params.from || null));
  appendQueryParamIfNotEmpty(queryParams, 'to', formatDate(params.to || null));

  const queryKey = generateQueryKey(params.identifier, queryParams);

  return useQuery(
    [`reports_${queryKey}`],
    async () => {
      const response = await dataFetch.get(`${CORE_API_SUFFIX}ad/top-profitable?${queryParams}`, {
        headers: {
          Accept: 'application/json',
        },
      });

      return response;
    },
    {
      enabled: params.enabled,
    },
  );
};
