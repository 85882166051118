import { Navigate, useLocation } from 'react-router-dom';
import React, { FC, useEffect } from 'react';
import { useAuthStore } from '../../store/authStore';
import { dataFetch } from '../../axios/customAxios';
import axiosInterceptor from '../../axios/axiosInterceptors';
import { useLandingPageStore } from '../../store/landingPageStore';
import useSendLoginHours from '../../apiHooks/queries/loginHoursQuery/loginHoursQuery';

interface ProtectedRouteProps {
  children: React.ReactElement<any, any> | null;
}

const ProtectedRoute: FC<ProtectedRouteProps> = (props) => {
  const { isLoggedIn } = useAuthStore();
  const { landingPage } = useLandingPageStore();
  const { data } = useSendLoginHours(isLoggedIn);

  const location = useLocation();
  const currentRoute = location.pathname;

  axiosInterceptor();

  if ((isLoggedIn && currentRoute.startsWith('/auth')) || (isLoggedIn && currentRoute === '/')) {
    return <Navigate to={`/${landingPage}`} />;
  }
  if (!isLoggedIn && !currentRoute.startsWith('/auth')) {
    return <Navigate to="/auth" />;
  }

  return props.children;
};

export default ProtectedRoute;
