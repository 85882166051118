import React from 'react';
import { useTranslation } from 'react-i18next';

const Error = () => {
  const { t } = useTranslation();
  return (
    <h2>
      {t('somethingWentWrong')}
    </h2>
  );
};

export default Error;
