import { create } from 'zustand';

interface LandingPageState {
  landingPage: string | null | undefined,
  setLandingPage: (_newLandingPage: string | null | undefined) => void,
}

export const useLandingPageStore = create<LandingPageState>((set) => {
  const currentLandingPage = localStorage.getItem('landingPage') || 'dashboard';

  return {
    landingPage: currentLandingPage,
    setLandingPage: (newLandingPage: string | null | undefined) => {
      localStorage.setItem('landingPage', newLandingPage || '');
      set(() => ({
        landingPage: newLandingPage,
      }));
    },
  };
});
