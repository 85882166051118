import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface AdPageSmallBoxPropsInterface {
    title: string,
    value: number | string,
}

const AdPageSmallBox : FC<AdPageSmallBoxPropsInterface> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="flex w-[20%] flex-col items-center">
      <div className="w-full rounded-t-xl bg-[#CEDADF] px-3 py-2">{t(props.title)}</div>
      <div className="w-full rounded-b-xl border  px-3 py-2">{props.value}</div>
    </div>
  );
};

export default AdPageSmallBox;
