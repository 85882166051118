import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FC } from 'react';
import { useAuthStore } from '../../store/authStore';
import { useUserInfoStore } from '../../store/store';
import { CustomButton } from '../buttons/customButton';

const AccountMenu : FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const { logout } = useAuthStore();
  const userInitials = useUserInfoStore((state) => state.userInitials);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleClose();
  };

  return (
    <>
      <CustomButton
        variant="sideBar"
        className={`
             rounded-[50%] border-[1px] border-primary text-[10px] font-medium 
            text-white hover:border-primary ${open ? 'border-green text-green ' : ''} `}
      >
        {userInitials}
      </CustomButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={{ zIndex: 1306 }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            mt: 1.5,
            borderRadius: '0px 0px 8px 8px',
            border: '1px solid #ddd',
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => navigate('/profile-page/profile')} className="border-b-2">
          Profile
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          {t('logout')}
        </MenuItem>
      </Menu>
    </>
  );
};

export default AccountMenu;
