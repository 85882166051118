import React, { FC, ReactNode, useState } from 'react';

interface CustomTooltipProps{
    text: string,
    children: ReactNode,
}

const CustomTooltip : FC<CustomTooltipProps> = (props) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const showTooltip = () => {
    setTooltipVisible(true);
  };

  const hideTooltip = () => {
    setTooltipVisible(false);
  };

  return (
    <div className="relative inline-block" onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      {isTooltipVisible && (
        <div className="shadow-xs shadow-gray left-30 absolute top-12 z-10 flex -translate-x-0 items-center justify-start rounded bg-primary p-2 text-start text-sm text-white transition-opacity duration-200">
          <div className="shadow-xs shadow-gray absolute -top-1 left-2 z-10 h-3 w-3 -rotate-45 border-x-4 border-t-4 border-primary bg-primary" />
          {props.text}
        </div>
      )}
      {props.children}
    </div>
  );
};

export default CustomTooltip;
