import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TableColumnInterface } from '../../interfaces/tableColumn.interface';
import BasicTable from './basicTable';
import { TRACKING_SOURCES_TABLE } from '../../constants/pageIdentifiers';
import { DEFAULT_ROWS_PER_PAGE, eCommerceExtendedColumns, extendedColumns } from '../../constants/constants';
import DateCell from '../../partials/dateCell';
import useGetTrackingSources from '../../apiHooks/queries/adManagement/useGetTrackingSources';
import { useAdManagementFilterDateStore } from '../../store/dateRangeStore';
import OrganizationType from '../enums/organizationTypesEnum';
import { useOrganizationSettingsStore } from '../../store/organizationSettingsStore';
import {
  useGetExportTrackingSourcesTable,
} from '../../apiHooks/mutations/reportsExportMutations/useGetExportTrackingSourcesTable';

const columns: TableColumnInterface[] = [
  { field: 'name', headerName: 'name' },
  {
    field: 'createdAt',
    headerName: 'createdAt',
    cellRenderer: (params: string) => <DateCell params={params} />,
  },
];

const sortablesList = ['name', 'createdAt'];

const TrackingSourcesTable : FC = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState<string>(searchParams.get('sortBy') || '');
  const [orderBy, setOrderBy] = useState<string>(searchParams.get('orderBy') || '');
  const organizationType = useOrganizationSettingsStore((state) => state.organizationSettings?.organizationType);
  const [searchInput, setSearchInput] = useState({
    name: searchParams.get('name') || '',
  });

  localStorage.setItem('from', searchParams.get('adManagementFilterDateFrom') || '');
  localStorage.setItem('to', searchParams.get('adManagementFilterDateTo') || '');

  const adManagementFilterDateRange = useAdManagementFilterDateStore(
    (state) => state.adManagementFilterDateRange,
  );
  const setAdManagementFilterDateRange = useAdManagementFilterDateStore(
    (state) => state.setAdManagementFilterDateRange,
  );

  const {
    data: trackingSourcesData,
    isError,
    refetch: refetchTrackingSourcesData,
    isFetching,
  } = useGetTrackingSources(
    TRACKING_SOURCES_TABLE,
    searchInput.name,
    adManagementFilterDateRange[0],
    adManagementFilterDateRange[1],
    sortBy,
    orderBy,
    rowsPerPage,
    page + 1,
  );

  const { mutate: fetchDownload } = useGetExportTrackingSourcesTable(
    {
      name: searchInput.name,
      from: adManagementFilterDateRange[0],
      to: adManagementFilterDateRange[1],
      sortBy,
      orderBy,
      rows: rowsPerPage,
      page: page + 1,
    },
  );

  useEffect(() => {
    setPage(0);
  }, [adManagementFilterDateRange, sortBy, orderBy]);

  const handleGoToCampaigns = (trackingSource: any) => {
    navigate(`/ad-management/campaigns?trackingSourceId=${trackingSource.id}`);
  };

  return (
    <BasicTable
      data={trackingSourcesData?.data}
      refetch={refetchTrackingSourcesData}
      fetchDownload={fetchDownload}
      setPage={(newPage) => setPage(newPage)}
      setRowsPerPage={(newRowsPerPage) => setRowsPerPage(newRowsPerPage)}
      setSearchInput={setSearchInput}
      handleNavigation={(trackingSourceId) => handleGoToCampaigns(trackingSourceId)}
      extendedColumns={organizationType === OrganizationType.ECOMMERCE ? eCommerceExtendedColumns : extendedColumns}
      setSortBy={setSortBy}
      setOrderBy={setOrderBy}
      filterDateRange={adManagementFilterDateRange}
      setFilterDateRange={setAdManagementFilterDateRange}
      {...{
        isError, isFetching, page, searchInput, columns, rowsPerPage, sortBy, orderBy, sortablesList,
      }}
    />
  );
};

export default TrackingSourcesTable;
