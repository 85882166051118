import { create } from 'zustand';

interface OsBackState {
    selectedOs: string,
    setSelectedOs: (_newSelectedOs: string) => void,
    selectedIndex: number | null,
    setSelectedIndex: (_newSelectedOs: number | null) => void,
}

export const useAdPageBackStore = create<OsBackState>((set) => ({
  selectedOs: '',
  setSelectedOs: (newSelectedOs: string) => set(() => ({
    selectedOs: newSelectedOs,
  })),
  selectedIndex: null,
  setSelectedIndex: (newSelectedIndex: number | null) => set(() => ({
    selectedIndex: newSelectedIndex,
  })),

}));
