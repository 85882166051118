import { useLocation } from 'react-router-dom';
import { useInitiateShopify } from '../apiHooks/mutations/shopifyMutations/shopifyInitiateMutation';

const SHOPIFY_QUERY_KEY = 'shopifyQueryString';
const SHOPIFY_SHOP_KEY = 'shopifyShop';

export const useDiggerShopify = () => {
  const { mutate } = useInitiateShopify();

  const setShopifyLocalStorage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    localStorage.setItem(SHOPIFY_QUERY_KEY, location.search);
    localStorage.setItem(SHOPIFY_SHOP_KEY, queryParams.get('shop') ?? '');
  };

  const removeShopifyLocalStorage = () => {
    localStorage.removeItem(SHOPIFY_QUERY_KEY);
    localStorage.removeItem(SHOPIFY_SHOP_KEY);
  };

  const initiateShopifyConnection = () => {
    mutate(localStorage.getItem(SHOPIFY_QUERY_KEY) ?? '');
    removeShopifyLocalStorage();
  };

  const shopifyQuery = localStorage.getItem(SHOPIFY_QUERY_KEY) ?? '';
  const shopifyShop = localStorage.getItem(SHOPIFY_SHOP_KEY) ?? '';

  return {
    setShopifyLocalStorage,
    removeShopifyLocalStorage,
    initiateShopifyConnection,
    shopifyQuery,
    shopifyShop,
  };
};
