import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { CORE_API_SUFFIX, dataFetch } from '../../../axios/customAxios';
import closableEnqueueSnackbar from '../../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';
import { handleApiError } from '../../requestHelpers/requestHelpers';

export const useSyncAds = () => {
  const { t } = useTranslation();

  return useMutation(
    () => dataFetch.post(
      `${CORE_API_SUFFIX}ad/sync/months`,
    ),
    {
      onSuccess: () => {
        closableEnqueueSnackbar(t('adsSyncedSuccessfully'), 'success');
      },
      onError: (error: any) => {
        handleApiError(error, t);
      },
    },
  );
};
