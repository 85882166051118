import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CustomFieldsHeaderColumns = [
  '#',
  'fieldName',
  'fieldType',
  'required',
  'actions',
];

const CustomFieldsHeader = () => {
  const { t } = useTranslation();

  return (
    <Grid container gap={2} className="items-center justify-between px-2 py-2">
      {CustomFieldsHeaderColumns.map((item) => (
        <Grid key={item} item md={item === '#' ? 0.5 : 2.5}>
          <p className="font-bold">
            {t(item)}
          </p>
        </Grid>
      ))}
    </Grid>
  );
};

export default CustomFieldsHeader;
