import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TickIcon from '../../assets/icons/tick-icon-gray.svg';
import { Colors } from '../../constants/constants';
import useGetUnsubscribeUrl from '../../apiHooks/queries/useGetUnsubscribeUrl';
import { UNSUBSCRIBE_URL } from '../../constants/pageIdentifiers';
import closableEnqueueSnackbar from '../closableEnqueueSnackbar/closableEnqueueSnackbar';
import { useUpdateSubscription } from '../../apiHooks/mutations/subscriptionMutations/subscriptionMutation';
import ConfirmDialog from '../../dialogs/confirmDialog';

interface SubscriptionCardProps {
  title: string;
  description: string;
  price?: number;
  features: string[];
  buttonText: string;
  color: 'primary' | 'yellow' | 'green' | 'gray-4';
  isActive?: boolean;
  featureInclusionText?: string;
  priceId?: string;
}

const SubscriptionCard: FC<SubscriptionCardProps> = (props) => {
  const { t } = useTranslation();
  const [isCardHovered, setCardIsHovered] = useState(false);
  const [cancelSubscriptionUrl, setCancelSubscriptionUrl] = useState<string>();
  const [isAreYouSureDialogOpen, setIsAreYouSureDialogOpen] = useState(false);

  const chosenColor = Colors[props.color];
  const backgroundColor = props.title === 'litePlan' ? Colors['gray-5'] : chosenColor;
  const buttonColor = isCardHovered ? chosenColor : 'white';

  const { mutateAsync: updateSubscription } = useUpdateSubscription();

  const handleUpdateSubscription = async () => {
    await updateSubscription(props.priceId);
    setIsAreYouSureDialogOpen(false);
  };

  const buttonStyle = {
    backgroundColor: isCardHovered ? 'white' : backgroundColor,
    border: isCardHovered
      ? `1px solid ${props.title === 'litePlan' ? Colors.primary : chosenColor}`
      : `1px solid ${props.title === 'litePlan' ? Colors.primary : 'transparent'}`,
    color: props.title === 'litePlan' || props.title === 'standardPlan'
      ? Colors.primary
      : buttonColor,
    transition: 'all 0.3s',
  };

  const onUrlRetrieved = (cancelUrl: string) => {
    setCancelSubscriptionUrl(cancelUrl);
  };

  const {
    data: unsubscribeUrl,
    isFetching: isFetchingUnsubscribeUrl,
    isError: isErrorUnsubscribeUrl,
  } = useGetUnsubscribeUrl(UNSUBSCRIBE_URL, onUrlRetrieved);

  return (
    <>
      {props.priceId && (
        <ConfirmDialog
          variant="success"
          open={isAreYouSureDialogOpen}
          title="wellDone"
          text="upgradingYourPlanWillIncreaseYourPotentialForGrowth"
          description="areYouSureYouWantToChangeYourPlan"
          onCloseDialog={() => setIsAreYouSureDialogOpen(false)}
          onSubmit={handleUpdateSubscription}
          isDisabled={isFetchingUnsubscribeUrl || isErrorUnsubscribeUrl}
        />
      )}
      <div className="flex flex-col items-center">
        <div
          className={`relative mb-6 mt-2 w-full min-w-[270px] rounded-xl border border-dark px-[30px] pb-[27px] pt-[35px] 
            text-primary ${props.isActive ? 'mt-[43px] min-h-[570px]' : 'min-h-[550px]'}
          `}
          style={{
            borderTop: `15px solid ${props.title === 'litePlan' ? Colors['gray-3'] : chosenColor}`,
            boxShadow: props.isActive ? ` 0 0 0 6px white, 0 0 0 8px ${Colors.primary}` : 'none',
            marginInline: props.isActive ? '8px' : '',
          }}
        >
          <h2 className="text-lg font-bold leading-[1.5]">
            {t(props.title)}
          </h2>
          <p className="mt-2 text-sm leading-[1.5]">
            {t(props.description)}
          </p>
          {props.price ? (
            <p className="mt-4 flex items-center gap-[14px] text-4xl font-bold leading-[1.5]">
              {props.price}
              €
              <span className="text-base font-normal leading-[1.5]">
                /
                {t('month')}
              </span>
            </p>
          ) : (
            <p className="mt-4 flex items-center gap-[14px] text-4xl font-bold leading-[1.5]">
              {t('letsTalk')}
            </p>
          )}
          <p className="text-[10px] font-semibold leading-[1.5] text-gray-4">
            {
              props.title !== 'enterPrisePlan'
                ? <>{t('salesOrOtherTaxesMayApply')}</>
                : <> &nbsp; </>
            }
          </p>
          <button
            className="mt-4 w-full rounded-lg bg-dark py-[14px] text-sm text-primary"
            style={buttonStyle}
            onMouseEnter={() => setCardIsHovered(true)}
            onMouseLeave={() => setCardIsHovered(false)}
            onClick={() => {
              if (props.isActive) {
                closableEnqueueSnackbar(t('youAreAlreadySubscribedToThisPlan'), 'warning');
                return;
              }

              if (props.title === 'enterPrisePlan') {
                window.open('https://www.digtective.com/book-demo', '_blank');
                return;
              }

              setIsAreYouSureDialogOpen(true);
            }}
            type="button"
          >
            {props.isActive ? t('yourCurrentPlan') : t(props.buttonText)}
          </button>
          <ul className="mt-4 flex flex-col gap-[14px]">
            {props.featureInclusionText && (
              <p className="text-sm font-semibold">
                {t(props.featureInclusionText)}
              </p>
            )}
            {props.features.map((feature, index) => {
              const i = index + 1;
              return (
                <li key={i} className="flex items-center gap-2.5">
                  <img src={TickIcon} alt="tick icon" />
                  <p className="text-xs">
                    {t(feature)}
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
        {props.isActive && (
          <div className="mb-3">
            <button
              className="
              relative cursor-pointer text-sm font-semibold leading-[1.5] text-gray-4 disabled:cursor-not-allowed
              disabled:opacity-50
            "
              onClick={() => unsubscribeUrl && window.open(cancelSubscriptionUrl, '_blank')}
              type="button"
              disabled={isFetchingUnsubscribeUrl || isErrorUnsubscribeUrl}
            >
              {t('cancelSubscription')}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default SubscriptionCard;
