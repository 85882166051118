import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../buttons/customButton';
import { useDisconnectHubspot } from '../../apiHooks/mutations/deleteHubspot/deleteHubspotMutation';

const DisconnectHubspot = () => {
  const { t } = useTranslation();

  const { mutate: disconnectHubspot } = useDisconnectHubspot();

  return (
    <div className="my-4 flex items-center justify-between rounded-lg border border-dark bg-white px-6 py-4">
      <div>
        <span className="text-sm font-semibold text-primary">{t('disconnectHubspotIntegration')}</span>
        <p className="text-sm text-primary">{t('thisWillRemoveHubspot')}</p>
      </div>
      <CustomButton
        className="h-full px-6 py-3"
        onClick={() => disconnectHubspot()}
      >
        {t('disconnectHubspot')}
      </CustomButton>
    </div>
  );
};

export default DisconnectHubspot;
