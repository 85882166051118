import React, { useEffect, useState, FC } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Step from '../step/step';
import { useConversionStepsStore } from '../../store/conversionStepsStore';

const OtherConversion: FC = () => {
  const { currentStep, setCurrentStep } = useConversionStepsStore((state) => state);

  const navigate = useNavigate();
  const location = useLocation();

  const steps = [
    {
      name: 'Conversion', value: 'conversion', isActive: currentStep === 1, isCompleted: currentStep > 1, route: 'goals',
    },
    {
      name: 'Name', value: 'name', isActive: currentStep === 2, isCompleted: currentStep > 2, route: 'conversion-name',
    },
    {
      name: 'Value', value: 'value', isActive: currentStep === 3, isCompleted: currentStep > 3, route: 'value',
    },
    {
      name: 'What\'s Next', value: 'whats-next', isActive: currentStep === 4, isCompleted: currentStep > 4, route: 'configured',
    },
  ];

  useEffect(() => {
    const routeName = location.pathname.split('/').pop();
    const currentStepIndex = steps.findIndex((step) => step.route === routeName);
    if (currentStepIndex !== -1) {
      setCurrentStep(currentStepIndex + 1);
    }
  }, [location.pathname]);

  return (
    <div className="h-full w-full rounded-lg border bg-white">
      <div className="flex items-center gap-4 border-b px-8 pb-5 pt-6">
        {steps.map((step, index) => (
          <Step
            key={index}
            name={step.name}
            value={step.value}
            isActive={step.isActive}
            isCompleted={step.isCompleted}
            lastChild={index === steps.length - 1}
          />
        ))}
      </div>
      <div className="px-8 py-6">
        <div>
          {currentStep < 5 && <Outlet />}
        </div>
        {/* <div className="flex w-full justify-end"> */}
        {/*  <button className="mt-4 rounded bg-primary px-4 py-2 text-white" onClick={handleContinue}> */}
        {/*    {currentStep < 4 ? 'Continue' : 'Finish'} */}
        {/*  </button> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default OtherConversion;
