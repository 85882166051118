import React, { useState } from 'react';
import QRCode from 'qrcode';
import { useTranslation } from 'react-i18next';
import useGetQrCode from '../../apiHooks/queries/qrCode/useGetQrCode';
import { GET_QR_CODE } from '../../constants/pageIdentifiers';
import LoadingSpinner from '../loadingSpinner/loadingSpinner';

interface QrCodeGeneratorInterface {
    closeDialog : (_dialogState : boolean) => void;
    next : (_nextModule : boolean) => void;
}

const QrCodeGenerator: React.FC<QrCodeGeneratorInterface> = (props) => {
  const { t } = useTranslation();
  const [src, setSrc] = useState('');

  const { isFetching } = useGetQrCode(GET_QR_CODE, setSrc);

  return (
    <div className="flex h-[380px] items-center justify-center">
      <div className="flex h-full w-full max-w-[450px] flex-col items-center justify-between">
        <p className="text-sm text-primary">{t('scanQr')}</p>
        {isFetching
          ? <LoadingSpinner center variant="primary" color="primary" />
          : <img src={src} alt="" />}
        <div className="flex w-full items-center justify-end gap-2">
          <button
            className="px-4 py-2  text-primary"
            onClick={() => props.closeDialog(false)}
            type="button"
          >
            {t('cancel')}
          </button>
          <button
            onClick={() => props.next(true)}
            className="rounded-lg bg-primary px-4 py-2 text-white"
            type="button"
          >
            {t('next')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default QrCodeGenerator;
