import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { keysToExclude } from '../../constants/validation';
import CustomTooltip from '../CustomTooltip/CustomTooltip';
import DevAccordion from '../devAccordion/devAccordion';

interface FullAccordion {
    item: any;
    isOn: boolean;
    accordionColor: string;
    expandedAccordion: string | null;
    handleAccordionChange: (_panel: string) => void;
    index: string;
    clickedAccordion: string | null;
    checkConditions: (_key: string, _value: any) => { color: string; reason: string };
    iconValueDisplay : (_icon : string) => string;
}

const FullDevAccordion : FC<FullAccordion> = (props) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState<number>();

  return (
    <DevAccordion
      key={props.item.id}
      id={props.isOn ? props.item.fsId : props.item.id}
      adCode={props.index + 1}
      createdAt={props.item.createdAt}
      color={props.accordionColor}
      expanded={props.expandedAccordion === props.index}
      toggleAccordion={() => props.handleAccordionChange(props.index)}
      index={props.index}
      clickedAccordion={props.clickedAccordion}
    >
      <div className="grid grid-cols-3 grid-rows-1 gap-x-20
        gap-y-10 border-y-[1px] bg-[#fafafa] py-3.5 text-sm text-primary"
      >
        <div className="flex pl-[30%]">{t('fieldName')}</div>
        <div className="flex">{t('fieldValue')}</div>
        <div className="flex">{t('fieldValidity')}</div>
      </div>
      <div className="flex flex-col">
        {Object.entries(props.isOn ? JSON.parse(props.item.fsJson) : props.item)
          .filter(([key]: any) => !keysToExclude.includes(key))
          .map((entry: any, index:number) => {
            const [key, value] = entry;
            const { color, reason } = props.checkConditions(key, value);

            const truncatedValue = value.length > 125 ? `${value.substring(0, 125)}...` : value;

            return (
              <div
                key={value}
                className="grid grid-cols-3 grid-rows-1 gap-x-20 gap-y-10 border-b-[0.5px] py-3.5"
              >
                <div className="flex items-center pl-[30%] text-sm font-[300] text-primary ">{key}</div>
                <div className="col-span-1 flex items-center text-sm font-[300] text-primary">
                  {value === '' ? 'No data' : (
                    <span
                      className={`truncate ${isExpanded === index
                        ? 'whitespace-normal'
                        : 'whitespace-nowrap hover:cursor-pointer'}`}
                      title={isExpanded === index ? value : undefined}
                      onClick={() => setIsExpanded(index)}
                    >
                      {isExpanded === index ? value : truncatedValue}
                    </span>
                  )}
                </div>
                <CustomTooltip text={`${reason}`}>
                  <div className="hover:cursor-pointer">
                    <img src={props.iconValueDisplay(color)} alt="" />
                  </div>
                </CustomTooltip>
              </div>
            );
          })}
      </div>
    </DevAccordion>
  );
};

export default FullDevAccordion;
