import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { FormikValues, useFormik } from 'formik';
import { CustomButton } from '../../../components/buttons/customButton';
import { facebookIntegrationUrl } from '../../../constants/integrationConstants';
import useGetOrganizationSettings from '../../../apiHooks/queries/useGetOrganizations';
import { GET_PROVIDER_DETAILS, ORGANIZATION_SETTINGS } from '../../../constants/pageIdentifiers';
import { AdProviderIDs, Colors } from '../../../constants/constants';
import SecondaryButton from '../../../components/buttons/secondaryButton';
import {
  useUpdateOrganizationSettings,
} from '../../../apiHooks/mutations/organizationMutations/organizationSettingsMutations';
import TextInput from '../../../components/inputs/textInput';
import PrimaryButton from '../../../components/buttons/primaryButton';
import {
  facebookOrganizationSettingsValidationSchema,
} from '../../../validations/organizationSettingsFormValidationSchema';
import { useOrganizationSettingsStore } from '../../../store/organizationSettingsStore';
import ProviderNumberBoxes from '../../../components/providerNumberBoxes/providerNumberBoxes';
import useGetAdProviderDetails from '../../../apiHooks/queries/adProviderDetails/useGetAdProviderDetails';

const FacebookPanel = () => {
  const { t } = useTranslation();
  const { setOrganizationSettings } = useOrganizationSettingsStore();

  const {
    mutateAsync: updateOrganizationSettings,
    isLoading: isUpdatingOrganizationSettings,
  } = useUpdateOrganizationSettings();

  const { data } = useGetOrganizationSettings(ORGANIZATION_SETTINGS);
  const organizationSettings = data?.data?.data;

  const formik: FormikValues = useFormik({
    initialValues: {
      facebookCustomerId: organizationSettings?.facebookCustomerId || '',
    },
    validationSchema: () => facebookOrganizationSettingsValidationSchema(t),
    onSubmit: async (values: any) => {
      updateOrganizationSettings(values).then(() => setOrganizationSettings({ ...organizationSettings, ...values }));
    },
  });

  const { data: adProviderDetailsData, isFetching } = useGetAdProviderDetails(
    GET_PROVIDER_DETAILS,
    AdProviderIDs.FACEBOOK,
    !!organizationSettings?.facebookToken || false,
  );

  if (organizationSettings && organizationSettings.facebookToken) {
    return (
      <div>
        <div className="flex flex-1 justify-between rounded-xl border p-4">
          <form onSubmit={formik.handleSubmit} className="flex gap-4">
            <Grid item sm={12} md={6} lg={2.5}>
              <TextInput
                myKey="facebookCustomerId"
                name="facebookCustomerId"
                value={formik.values.facebookCustomerId}
                label={t('facebookCustomerId')}
                placeholder={t('facebookCustomerId')}
                size="small"
                fullWidth
                type="number"
                onChange={formik.handleChange}
                error={Boolean(formik.touched.facebookCustomerId) && Boolean(formik.errors.facebookCustomerId)}
                helperText={Boolean(formik.touched.facebookCustomerId) && formik.errors.facebookCustomerId}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={2.5} gap={4}>
              <PrimaryButton
                type="submit"
                className="max-h-[40px]"
                disabled={isUpdatingOrganizationSettings}
                dataCy="bing-org-settings-submit"
              >
                {t('save')}
              </PrimaryButton>
            </Grid>
          </form>
          <div className="flex items-center justify-center gap-3 text-xs font-medium text-primary">
            <div>
              {t('accountStatus')}
            </div>
            <SecondaryButton
              style={{
                border: `1px solid ${Colors.green}`,
                color: Colors.green,
                height: '40px',
                fontSize: 14,
              }}
            >
              {t('active')}
            </SecondaryButton>
          </div>
        </div>
        <div className="mt-4 flex justify-between gap-4 rounded-xl border p-4 ">
          <ProviderNumberBoxes isLoading={isFetching} tracked stats={adProviderDetailsData?.data?.data?.ads_tracked || 0} />
          <ProviderNumberBoxes isLoading={isFetching} revenue stats={adProviderDetailsData?.data?.data?.revenue_tracked || 0} />
          <ProviderNumberBoxes isLoading={isFetching} leads stats={adProviderDetailsData?.data?.data?.leads_attributed || 0} />
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-between gap-5">
      <div className="flex gap-5">
        <CustomButton
          disabled={false}
          className="h-[40px] px-[20px] text-sm"
          onClick={() => {
            window.location.replace(facebookIntegrationUrl);
          }}
        >
          {t('linkAccount')}
        </CustomButton>
      </div>
    </div>
  );
};

export default FacebookPanel;
