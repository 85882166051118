import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import { handleApiError } from '../../requestHelpers/requestHelpers';
import { ApiError } from '../../../interfaces/apiResponse.type';
import closableEnqueueSnackbar from '../../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';

export const useDisconnectShopify = () => {
  const { t } = useTranslation();

  return useMutation(
    () => dataFetch.post(
      `${DIGGER_API_SUFFIX}shopify/disconnect`,
    ),
    {
      onSuccess: () => {
        closableEnqueueSnackbar(t('storeDisconnectSuccess'), 'success');
      },
      onError: (error: ApiError<null>) => {
        handleApiError(error, t);
      },
    },
  );
};
