import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikValues, useFormik } from 'formik';
import TextInput from '../inputs/textInput';
import { useUserInfoStore } from '../../store/store';
import { useUpdateProfileName } from '../../apiHooks/mutations/profileMutations/profileNameMutation';
import { profileUpdateNameFormValidationSchema } from '../../validations/profileUpdateValidationSchema';
import { CustomButton } from '../buttons/customButton';
import useGetProfileName from '../../apiHooks/queries/myProfile/useGetProfileName';
import { GET_PROFILE_NAME } from '../../constants/pageIdentifiers';

const MyProfile = () => {
  const { t } = useTranslation();
  const { setUserInitials } = useUserInfoStore((state) => state);

  const {
    data: nameValues,
  } = useGetProfileName(
    GET_PROFILE_NAME,
  );

  const {
    mutateAsync: updateProfileName,
    isLoading: isProfileLoading,
  } = useUpdateProfileName();

  const formik: FormikValues = useFormik({
    initialValues: {
      firstName: nameValues?.data?.data.firstName || '',
      lastName: nameValues?.data?.data.lastName || '',
    },
    enableReinitialize: true,
    validationSchema: () => profileUpdateNameFormValidationSchema(t),
    onSubmit: async (values: any) => {
      await updateProfileName(values);

      const firstNameInitial = formik.values.firstName[0];
      const lastNameInitial = formik.values.lastName[0];

      setUserInitials(`${firstNameInitial}${lastNameInitial}`);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-8 flex flex-col border-b-[2px]">
        <span className="my-3 text-[22px] font-[600] text-primary">{t('myProfile')}</span>
        <p className="pb-3 text-[12px] text-[#0C485E99]">
          {t('myProfileText')}
        </p>
      </div>
      <div className="flex items-center gap-20">
        <div className="max-w-[365px]">
          <TextInput
            myKey="firstName"
            name="firstName"
            value={formik.values.firstName}
            label={t('firstName')}
            placeholder={t('firstName')}
            size="small"
            fullWidth
            onChange={formik.handleChange}
            error={Boolean(formik.touched.firstName) && Boolean(formik.errors.firstName)}
            helperText={Boolean(formik.touched.firstName) && formik.errors.firstName}
          />
          <p className="ml-1 mt-2 text-[14px] text-primary">{t('myProfileFirstPart')}</p>
        </div>
        <div>
          <TextInput
            myKey="lastName"
            name="lastName"
            value={formik.values.lastName}
            label={t('lastName')}
            placeholder={t('lastName')}
            size="small"
            fullWidth
            onChange={formik.handleChange}
            error={Boolean(formik.touched.firstName) && Boolean(formik.errors.firstName)}
            helperText={Boolean(formik.touched.firstName) && formik.errors.firstName}
          />
          <p className="ml-1 mt-2 text-[14px] text-primary">{t('myProfileLastPart')}</p>
        </div>
      </div>
      <div className="flex w-full items-center justify-end">
        <CustomButton type="submit" disabled={isProfileLoading} dataCy="calc-org-settings-submit">
          {t('update')}
        </CustomButton>
      </div>
    </form>
  );
};
export default MyProfile;
