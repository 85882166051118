import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '../../assets/icons/language-icon.svg';
import { CustomButton } from '../buttons/customButton';

const LanguageMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t, i18n } = useTranslation();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageChange = async (language: string) => {
    setAnchorEl(null);
    await i18n.changeLanguage(language);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CustomButton variant="iconOutlined" onClick={handleClick}>
        <img src={LanguageIcon} alt="language" />
      </CustomButton>
      <Menu
        id="flag-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ zIndex: 1306 }}
        MenuListProps={{
          'aria-labelledby': 'flag-button',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.9,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              left: 26,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-40%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
      >
        <MenuItem onClick={() => handleLanguageChange('en')}>{t('english')}</MenuItem>
        <MenuItem onClick={() => handleLanguageChange('no')}>{t('norwegian')}</MenuItem>
        <MenuItem onClick={() => handleLanguageChange('de')}>{t('german')}</MenuItem>
      </Menu>
    </>
  );
};

export default LanguageMenu;
