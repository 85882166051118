import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikValues, useFormik } from 'formik';
import { Grid } from '@mui/material';
import PrimaryButton from '../../../components/buttons/primaryButton';
import SecondaryButton from '../../../components/buttons/secondaryButton';
import { AdProviderIDs, Colors } from '../../../constants/constants';
import { useOrganizationSettingsStore } from '../../../store/organizationSettingsStore';
import { microsoftIntegrationURL } from '../../../constants/integrationConstants';
import {
  useUpdateOrganizationSettings,
} from '../../../apiHooks/mutations/organizationMutations/organizationSettingsMutations';
import {
  bingOrganizationSettingsFormValidationSchema,
} from '../../../validations/organizationSettingsFormValidationSchema';
import TextInput from '../../../components/inputs/textInput';
import ProviderNumberBoxes from '../../../components/providerNumberBoxes/providerNumberBoxes';
import useGetAdProviderDetails from '../../../apiHooks/queries/adProviderDetails/useGetAdProviderDetails';
import { GET_PROVIDER_DETAILS } from '../../../constants/pageIdentifiers';

const BingPanel = () => {
  const { organizationSettings } = useOrganizationSettingsStore();
  const { setOrganizationSettings } = useOrganizationSettingsStore();
  const hasToken = !!organizationSettings?.microsoftRefreshToken;

  const { data: adProviderDetailsData, isFetching } = useGetAdProviderDetails(
    GET_PROVIDER_DETAILS,
    AdProviderIDs.BING,
    hasToken,
  );

  const { t } = useTranslation();
  const {
    mutateAsync: updateOrganizationSettings,
    isLoading: isUpdatingOrganizationSettings,
  } = useUpdateOrganizationSettings();

  const formik: FormikValues = useFormik({
    initialValues: {
      microsoftAccountId: organizationSettings?.microsoftAccountId || '',
    },
    validationSchema: () => bingOrganizationSettingsFormValidationSchema(t),
    onSubmit: async (values: any) => {
      updateOrganizationSettings(values).then(() => setOrganizationSettings({ ...organizationSettings, ...values }));
    },
  });

  return (
    <div>
      <div className="flex justify-between rounded-xl border p-4">
        {!hasToken ? (
          <PrimaryButton type="button" onClick={() => window.location.replace(microsoftIntegrationURL)}>
            BING
          </PrimaryButton>
        ) : (
          <form onSubmit={formik.handleSubmit} className="flex gap-4">
            <Grid item sm={12} md={6} lg={2.5}>
              <TextInput
                myKey="microsoftAccountId"
                name="microsoftAccountId"
                value={formik.values.microsoftAccountId}
                label={t('microsoftAccountId')}
                placeholder={t('microsoftAccountId')}
                size="small"
                fullWidth
                type="number"
                onChange={formik.handleChange}
                error={Boolean(formik.touched.microsoftAccountId) && Boolean(formik.errors.microsoftAccountId)}
                helperText={Boolean(formik.touched.microsoftAccountId) && formik.errors.microsoftAccountId}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={2.5} gap={4}>
              <PrimaryButton
                type="submit"
                className="max-h-[40px]"
                disabled={isUpdatingOrganizationSettings}
                dataCy="bing-org-settings-submit"
              >
                {t('save')}
              </PrimaryButton>
            </Grid>
          </form>
        )}
        <div className="flex items-center gap-3">
          <p className="text-xs font-[500] text-primary">
            {t('accountStatus')}
          </p>
          <SecondaryButton
            style={{
              border: `1px solid ${hasToken ? Colors.green : Colors.red}`,
              color: hasToken ? Colors.green : Colors.red,
              height: '40px',
              fontSize: 14,
            }}
          >
            {hasToken ? t('Active') : t('notActive')}
          </SecondaryButton>
        </div>
      </div>
      {hasToken
          && (
          <div className="mt-4 flex justify-between gap-4 rounded-xl border p-4 ">
            <ProviderNumberBoxes isLoading={isFetching} tracked stats={adProviderDetailsData?.data?.data?.ads_tracked || 0} />
            <ProviderNumberBoxes isLoading={isFetching} revenue stats={adProviderDetailsData?.data?.data?.revenue_tracked || 0} />
            <ProviderNumberBoxes isLoading={isFetching} leads stats={adProviderDetailsData?.data?.data?.leads_attributed || 0} />
          </div>
          )}
    </div>
  );
};

export default BingPanel;
