import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import closableEnqueueSnackbar from '../../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';
import { handleApiError } from '../../requestHelpers/requestHelpers';
import { trackingSourcesMap } from '../../../components/staticCard/StaticCard';

export const useStartAdMutation = (trackingSourceId: number, onSuccess: () => void) => {
  const { t } = useTranslation();
  const trackingSourceName = trackingSourcesMap[trackingSourceId]?.name?.toLowerCase();

  return useMutation(
    (adCode: string) => dataFetch.post(
      `${DIGGER_API_SUFFIX}${trackingSourceName}/start/${adCode}`,
    ),
    {
      onError: (error: any) => {
        error?.response?.data?.status?.message.map((message: string) => {
          closableEnqueueSnackbar(t(message), 'error');
          return error;
        });
      },
      onSuccess: () => {
        onSuccess();
      },
    },
  );
};

export const useStopAdMutation = (trackingSourceId: number, onSuccess: () => void) => {
  const { t } = useTranslation();
  const trackingSourceName = trackingSourcesMap[trackingSourceId]?.name?.toLowerCase();

  return useMutation(
    (adCode: string) => dataFetch.post(
      `${DIGGER_API_SUFFIX}${trackingSourceName}/stop/${adCode}`,
    ),
    {
      onError: (error: any) => {
        handleApiError(error, t);
      },
      onSuccess: () => {
        onSuccess();
      },
    },
  );
};
