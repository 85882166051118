import React from 'react';
import { useTranslation } from 'react-i18next';
import HubspotContactSettingsForm from './hubspotContactSettingsForm';
import {
  useGetHubspotContactSettingsContentData,
  useGetHubspotContactSettingsFormData,
} from '../../../../apiHooks/queries/useGetHubspotForms';
import LoadingSpinner from '../../../../components/loadingSpinner/loadingSpinner';
import HubspotContactSettingsContent from './hubspotContactSettingsContent';

const ContactSettings = () => {
  const { t } = useTranslation();
  const {
    data: contactSettingsFormData,
    isFetching: isFetchingSettingsFormData,
    isError: isErrorSettingsFormData,
  } = useGetHubspotContactSettingsFormData();

  const {
    data: contactSettingsContentData,
    isFetching: isFetchingContactSettingsContentData,
    isError: isErrorContactSettingsContentData,
    refetch: refetchContactSettingsContentData,
  } = useGetHubspotContactSettingsContentData();

  return (
    <div className="h-max p-5">
      {isFetchingSettingsFormData && !isErrorSettingsFormData && (
        <LoadingSpinner color="primary" size={25} />
      )}
      {isErrorSettingsFormData && !isFetchingSettingsFormData && (
        <p>{t('somethingWentWrong')}</p>
      )}
      {!isErrorSettingsFormData && !isFetchingSettingsFormData && contactSettingsFormData?.data?.data && (
        <HubspotContactSettingsForm
          data={contactSettingsFormData.data.data}
        />
      )}
      <HubspotContactSettingsContent
        contactSettingsContentData={contactSettingsContentData?.data?.data}
        isFetchingContactSettingsContentData={isFetchingContactSettingsContentData}
        isErrorContactSettingsContentData={isErrorContactSettingsContentData}
        refetchContactSettingsContentData={refetchContactSettingsContentData}
      />
    </div>
  );
};

export default ContactSettings;
