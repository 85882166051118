import { create } from 'zustand';

interface ConversionStepsStoreState {
  currentStep: number;
  setCurrentStep: (_newSteps: number) => void;
}

export const useConversionStepsStore = create<ConversionStepsStoreState>((set) => ({
  currentStep: 1,
  setCurrentStep: (newSteps: number) => {
    set(() => ({
      currentStep: newSteps,
    }));
  },
}));

const EventCategory = {
  checkboxOther: 0,
  checkboxPageView: 3,
  checkboxPurchase: 4,
  checkboxSign: 5,
  checkboxAddToCart: 8,
  checkboxBeginCheckout: 9,
  checkboxSubscribe: 10,
  checkboxSubmit: 13,
  checkboxBook: 14,
  checkboxRequest: 15,
  checkboxDirections: 16,
  checkboxOutbound: 17,
  checkboxContact: 18,
  checkboxQualifiedLead: 22,
  checkboxConvertedLead: 23,
};

export type EventCategoryKey = keyof typeof EventCategory;

type ConversionStepsState = {
  data: {
    name: string;
    conversionType: string;
    value: any;
    currency: string;
    googleConversionType: any;
  };
  updateName: (_name: string) => void;
  updateConversionType: (_category: any) => void;
  updateValue: (_value: any) => void;
  updateCurrency: (_currency: string) => void;
  updateGoogleConversionType: (_googleConversionType: EventCategoryKey) => void;
};

export const useOtherConversionStore = create<ConversionStepsState>((set) => ({
  data: {
    name: '',
    googleConversionType: EventCategory,
    value: 0,
    currency: '',
    conversionType: '',
  },
  updateName: (name) => set((state) => ({ data: { ...state.data, name } })),
  updateGoogleConversionType: (category) => set((state) => ({ data: { ...state.data, googleConversionType: EventCategory[category] } })),
  updateValue: (value) => set((state) => ({ data: { ...state.data, value } })),
  updateCurrency: (currency) => set((state) => ({ data: { ...state.data, currency } })),
  updateConversionType: (conversionType) => set((state) => ({ data: { ...state.data, conversionType } })),
}));

interface ConversionNameState {
  conversionName: string | null | undefined,
  setConversionName: (_newConversionName: string | null | undefined) => void,
}

export const useConversionNameStore = create<ConversionNameState>((set) => {
  const currentConversionName = localStorage.getItem('conversionName');

  return {
    conversionName: currentConversionName,
    setConversionName: (newConversionName: string | null | undefined) => {
      localStorage.setItem('conversionName', newConversionName || '');
      set(() => ({
        conversionName: newConversionName,
      }));
    },
  };
});
