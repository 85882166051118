import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikValues, useFormik } from 'formik';
import { profileUpdatePasswordValidationSchema } from '../../validations/profileUpdateValidationSchema';
import { CustomButton } from '../buttons/customButton';
import { useChangePassword } from '../../apiHooks/mutations/profileMutations/changePasswordMutation';
import PasswordInput from '../inputs/passwordInput';

const ResetPassword = () => {
  const { t } = useTranslation();

  const {
    mutate: changePassword,
    isLoading,
  } = useChangePassword();

  const formik: FormikValues = useFormik({
    initialValues: {
      password: '',
      newPassword: '',
    },
    validationSchema: () => profileUpdatePasswordValidationSchema(t),
    onSubmit: async (values: any) => {
      changePassword(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-8 flex flex-col border-b-2">
        <span className="my-3 text-[22px] font-semibold text-primary">{t('changePassword')}</span>
        <p className="pb-3 text-xs text-[#0C485E99]">
          {t('changePasswordText')}
        </p>
      </div>
      <div className="flex items-center gap-20">
        <div className="max-w-[335px]">
          <PasswordInput
            isVisibilityToggleable
            id="password"
            myKey="password"
            name="password"
            value={formik.values.password}
            label={t('currentPassword')}
            placeholder={t('currentPassword')}
            size="small"
            fullWidth
            onChange={formik.handleChange}
            error={Boolean(formik.touched.password) && Boolean(formik.errors.password)}
            helperText={Boolean(formik.touched.password) && formik.errors.password}
          />
          <p className="ml-1 mt-2 text-sm text-primary">{t('enterCurrentPassword')}</p>
        </div>
        <div>
          <PasswordInput
            isVisibilityToggleable
            id="newPassword"
            myKey="newPassword"
            name="newPassword"
            value={formik.values.newPassword}
            label={t('newPassword')}
            placeholder={t('newPassword')}
            size="small"
            fullWidth
            onChange={formik.handleChange}
            error={Boolean(formik.touched.newPassword) && Boolean(formik.errors.newPassword)}
            helperText={Boolean(formik.touched.newPassword) && formik.errors.newPassword}
          />
          <p className="ml-1 mt-2 text-sm text-primary">{t('enterNewPassword')}</p>
        </div>
      </div>
      <div className="flex w-full items-center justify-end">
        <CustomButton type="submit" disabled={isLoading} dataCy="calc-org-settings-submit">
          {t('update')}
        </CustomButton>
      </div>
    </form>
  );
};
export default ResetPassword;
