import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import closableEnqueueSnackbar from '../../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';
import { UserInterface } from '../../../interfaces/user.interface';
import { handleApiError } from '../../requestHelpers/requestHelpers';
import { ApiError, ApiResponse, DataFetchResponse } from '../../../interfaces/apiResponse.type';

export const useUpdateUser = () => {
  const { t } = useTranslation();
  return useMutation<ApiResponse<null>, ApiError<null>, UserInterface>(
    (postRequest) => dataFetch[postRequest.id ? 'put' : 'post'](
      `${DIGGER_API_SUFFIX}users${postRequest.id ? (`/${postRequest.id}`) : ''}`,
      {
        firstName: postRequest.firstName,
        lastName: postRequest.lastName,
        email: postRequest.email,
      },
    ),
    {
      onError: (error) => {
        handleApiError(error, t);
      },
    },
  );
};

export const useDeleteUser = () => {
  const { t } = useTranslation();
  return useMutation<ApiResponse<DataFetchResponse>, ApiError<null>, number>(
    (id) => dataFetch.delete(
      `${DIGGER_API_SUFFIX}users/${id}`,
    ),
    {
      onSuccess: () => {
        closableEnqueueSnackbar(t('userDeletedSuccessfully'), 'success');
      },
      onError: (error) => {
        handleApiError(error, t);
      },
    },
  );
};

export const useGenerateNewPassword = () => {
  const { t } = useTranslation();

  return useMutation(
    (id?: number) => dataFetch.post(`${DIGGER_API_SUFFIX}users/generate-new-password/${id}`),
    {
      onError: () => {
        closableEnqueueSnackbar(t('somethingWentWrong'), 'error');
      },
    },
  );
};
