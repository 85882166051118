import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FC, ReactNode } from 'react';

interface SimpleAccordionProps {
  title: string,
  defaultExpanded?: boolean,
  children: ReactNode,
}

const SimpleAccordion: FC<SimpleAccordionProps> = (props) => (
  <Accordion defaultExpanded={props.defaultExpanded}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography fontWeight="700">
        {props.title}
      </Typography>
    </AccordionSummary>
    <AccordionDetails style={{ paddingTop: '0.25rem' }}>
      {props.children}
    </AccordionDetails>
  </Accordion>
);

export default SimpleAccordion;
