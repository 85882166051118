import * as yup from 'yup';

export const authFormValidationSchema = (t: any) => yup.object({
  email: yup
    .string()
    .email(t('enterValidEmail'))
    .required(t('emailRequired')),
  password: yup
    .string()
    .min(8, t('passwordMinimumEightChars'))
    .required(t('passwordRequired')),
});
