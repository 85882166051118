import { Navigate } from 'react-router-dom';
import React from 'react';
import { useAuthStore } from '../../../store/authStore';
import { useDiggerShopify } from '../../../hooks/useDiggerShopify';

const ShopifyRoute = () => {
  const { setShopifyLocalStorage } = useDiggerShopify();
  const { isLoggedIn } = useAuthStore();

  setShopifyLocalStorage();

  if (isLoggedIn) {
    return <Navigate to="/dashboard" />;
  }

  return <Navigate to="/auth" />;
};

export default ShopifyRoute;
