import React from 'react';
import { useTranslation } from 'react-i18next';
import DigtectiveLogoColorful from '../../assets/images/logos/digtective-logo-colorful.svg';
import AuthForm from '../../forms/authForm';

const AuthContent = () => {
  const { t } = useTranslation();

  return (
    <div className="flex h-full w-[50%] flex-1 items-center justify-center">
      <div className="flex h-full w-full max-w-[408px] flex-1 flex-col items-start justify-between pt-4">
        <div className="h-full max-h-[65px] w-full max-w-[220px]">
          <img src={DigtectiveLogoColorful} alt="" className="h-full w-full object-contain" />
        </div>
        <div className="relative flex h-full w-full max-w-[408px] flex-1 flex-col items-center justify-center">
          <div className="flex w-full translate-y-[-60px] flex-col items-center justify-center gap-[90px]">
            <div className="h-full max-h-[65px] w-full max-w-[298px]" />
            <AuthForm />
          </div>
          <p className="absolute bottom-0 text-sm text-primary">
            {t('allRightsReserveDigtective')}
          </p>
        </div>
      </div>
    </div>
  );
};
export default AuthContent;
