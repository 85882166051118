import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import VerticalTabs from '../../../components/tabs/verticalTabs';
import { HubspotIntegrationsTabLinks } from '../../../components/enums/tabEnums';
import { CustomButton } from '../../../components/buttons/customButton';
import { hubspotIntegrationUrl } from '../../../constants/integrationConstants';
import { useOrganizationSettingsStore } from '../../../store/organizationSettingsStore';

const HubSpotPanel = () => {
  const location = useLocation();
  const currentTab = location.pathname.split('/')
    .pop();
  const activePanel = HubspotIntegrationsTabLinks[currentTab as keyof typeof HubspotIntegrationsTabLinks] || 0;
  const { t } = useTranslation();
  const organizationSettings = useOrganizationSettingsStore((state) => state.organizationSettings);

  if (organizationSettings?.isConnectedToHubspot) {
    return (
      <div className="-ml-6 -mt-6 flex h-full w-[calc(100%+48px)]">
        <VerticalTabs
          currentTab={activePanel}
          tabs={[
            {
              tabLabel: t('forms') || 'Forms',
              href: 'forms',
              identifierName: 'forms-panel',
            }, {
              tabLabel: t('dealSettings') || 'Deal Settings',
              href: 'deal-settings',
              identifierName: 'deal-settings-panel',
            }, {
              tabLabel: t('contactSettings') || 'Contact Settings',
              href: 'contact-settings',
              identifierName: 'contact-settings-panel',
            },
          ]}
        />
      </div>
    );
  }

  return (
    <CustomButton
      loading
      type="button"
      onClick={() => window.location.replace(hubspotIntegrationUrl)}
    >
      {t('connectToHubspot')}
    </CustomButton>
  );
};

export default HubSpotPanel;
