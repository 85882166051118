import React, {
  FC, FormEvent, SyntheticEvent, useEffect, useState,
} from 'react';
import { FormikValues } from 'formik';
import _ from 'lodash';
import AutoCompleteInput from '../inputs/autoCompleteInput';
import { AD_GROUP_OPTIONS, SELECTED_AD_GROUP_OPTION } from '../../constants/pageIdentifiers';
import { AdGroupInterface } from '../../interfaces/adGroup.interface';
import { useGetAdGroupById, useGetAdGroupOptions } from '../../apiHooks/queries/adManagement/useGetAdGroups';

interface AdGroupSelectInterface {
  identifier: string;
  formik?: FormikValues,
  fullWidth?: boolean,
  size?: 'small' | 'medium',
  selectedAdGroupId?: string,
  setSelectedAdGroupId?: (_newSelectedAdGroupId: string) => void,
  disabled?: boolean,
}

const AdGroupSelect: FC<AdGroupSelectInterface> = (props) => {
  const { data: selectedAdGroupData } = useGetAdGroupById(
    `${SELECTED_AD_GROUP_OPTION}_${props.identifier}`,
    props.selectedAdGroupId,
  );

  const [
    selectedValue,
    setSelectedValue,
  ] = useState<AdGroupInterface | null>(selectedAdGroupData?.data || null);
  const [searchedValue, setSearchedValue] = useState<string>('');

  const {
    data: adGroupsOptionsData,
    refetch: refetchAdGroupsOptionsData,
    isFetching: isRefetchingAdGroupsOptionsData,
  } = useGetAdGroupOptions(AD_GROUP_OPTIONS, searchedValue);

  useEffect(() => {
    if (selectedAdGroupData?.data) {
      setSelectedValue(selectedAdGroupData?.data);
    }
  }, [selectedAdGroupData?.data]);

  const debouncedRefetchAdGroupOptionsData = _.debounce(refetchAdGroupsOptionsData, 500);

  const onChangeAutoComplete = (event: FormEvent, newValue: AdGroupInterface) => {
    if (newValue?.id) {
      setSelectedValue(newValue);
      props.formik?.setFieldValue?.('adGroupId', newValue.id);
      props.setSelectedAdGroupId?.(JSON.stringify(newValue.id));
      setSearchedValue('');
    } else {
      setSelectedValue(null);
      props.formik?.setFieldValue?.('adGroupId', '');
      props.setSelectedAdGroupId?.('');
      setSearchedValue('');
    }
  };

  const onInputChangeAutoComplete = async (event: SyntheticEvent, value: string) => {
    setSelectedValue(null);
    setSearchedValue(value);
    await debouncedRefetchAdGroupOptionsData();
  };

  return (
    <AutoCompleteInput
      key="adGroupId"
      name="adGroupId"
      label="adGroup"
      placeholder="adGroup"
      size={props.size}
      style={{ minWidth: 213 }}
      options={adGroupsOptionsData?.data ? adGroupsOptionsData.data : []}
      getOptionLabel={(option: AdGroupInterface) => option?.name || ''}
      value={selectedValue}
      loading={isRefetchingAdGroupsOptionsData}
      onChange={(event: FormEvent, newValue: AdGroupInterface) => onChangeAutoComplete(event, newValue)}
      inputValue={selectedValue?.name || searchedValue}
      onInputChange={async (event: FormEvent, value: string) => {
        await onInputChangeAutoComplete(event, value);
      }}
      isOptionEqualToValue={(option: AdGroupInterface, value: AdGroupInterface) => (option.id === value.id)}
      error={Boolean(props.formik?.touched?.adGroupId) && Boolean(props.formik?.errors?.adGroupId)}
      helperText={Boolean(props.formik?.touched?.adGroupId) && props.formik?.errors?.adGroupId}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
    />
  );
};

export default AdGroupSelect;
