import { useMutation } from 'react-query';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import { useAuthStore } from '../../../store/authStore';
import { AuthRequest, AuthResponse } from '../../../types/api/auth';
import { handleApiError, handleAuthError } from '../../requestHelpers/requestHelpers';
import { useUserInfoStore } from '../../../store/store';
import { useLandingPageStore } from '../../../store/landingPageStore';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';
import closableEnqueueSnackbar from '../../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';
import { ResetPasswordInterface } from '../../../interfaces/resetPassword.interface';
import { useIsTwoFaEnabledStore, useLoginInfoStore, useTwoFaStore } from '../../../store/twoFaStore';

const useMutateLogin = () => {
  const { login } = useAuthStore();
  const { setUserInitials } = useUserInfoStore();
  const { setLandingPage } = useLandingPageStore();
  const { setTwoFaActive } = useTwoFaStore();
  const { setLoginInfo } = useLoginInfoStore();
  const { setTwoFaEnabled } = useIsTwoFaEnabledStore();
  const { t } = useTranslation();

  return useMutation<AxiosResponse<AuthResponse>, ApiError<null>, AuthRequest>(
    (postRequest) => {
      const requestPayload :AuthRequest = {
        email: postRequest.email,
        password: postRequest.password,
      };

      if (postRequest.twoFACode) {
        requestPayload.twoFACode = postRequest.twoFACode;
      } else {
        setLoginInfo({ email: postRequest.email, password: postRequest.password });
      }

      return dataFetch.post<AuthResponse, AxiosResponse<AuthResponse>, AuthRequest>(
        `${DIGGER_API_SUFFIX}auth/login`,
        requestPayload,
      );
    },
    {
      onSuccess: ({ data: authResponse }) => {
        if (!authResponse.data) {
          // TODO add a middleware that checks AuthResponse.data !== undefined for all API successful responses
          throw new Error('Missing data!');
        }
        const { organizationId, id: userId } = authResponse.data.user;
        const userInitials = `${authResponse?.data?.user?.firstName?.[0]}${authResponse?.data?.user?.lastName?.[0]}`;
        setUserInitials(userInitials);
        setLandingPage(authResponse?.data?.organization?.landingPage);

        if (authResponse?.data.twoFARequired) {
          setTwoFaActive(true);
          setTwoFaEnabled(true);
        } else {
          login(authResponse.data.authorization.token, { organizationId, userId: userId.toString() });
        }
      },
      onError: (err) => {
        handleAuthError(err, t);
      },
    },
  );
};

export const useSendResetPasswordEmail = (setHasSentEmail: (_newHasSentEmail: boolean) => void, setCountdown: (_newCountDown: number) => void) => {
  const { t } = useTranslation();
  return useMutation<ApiResponse<null>, ApiError<null>, string>(
    (email: string) => dataFetch.post(
      `${DIGGER_API_SUFFIX}auth/forgot-password`,
      {
        email,
      },
    ),
    {
      onError: (error) => {
        handleApiError(error, t);
      },
      onSuccess: () => {
        closableEnqueueSnackbar(t('resetPasswordEmailSuccess'), 'success');
        setHasSentEmail(true);

        setTimeout(() => {
          setHasSentEmail(false);
        }, 60 * 1000);

        setCountdown(60);
      },
    },
  );
};

export const useResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return useMutation<ApiResponse<null>, ApiError<null>, ResetPasswordInterface>(
    (request) => dataFetch.post(
      `${DIGGER_API_SUFFIX}auth/reset-password`,
      {
        password: request.password,
        confirmPassword: request.confirmPassword,
        token: request.token,
        email: request.email,
      },
    ),
    {
      onError: (error) => {
        handleApiError(error, t);
      },
      onSuccess: () => {
        closableEnqueueSnackbar(t('resetPasswordSuccess'), 'success');
        navigate('../');
      },
    },
  );
};

export default useMutateLogin;
