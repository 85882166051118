import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import AdGroupsTable from '../../../components/tables/adGroupsTable';
import InfoboxContainer from '../../../components/infoBox/InfoboxContainer';

const AdGroups = () => {
  const [searchParams] = useSearchParams();
  const [selectedCampaignId, setSelectedCampaignId] = useState<string>(
    searchParams.get('campaignId') || '',
  );

  return (
    <>
      <InfoboxContainer
        groupingType="adGroups"
      />
      <AdGroupsTable
        selectedCampaignId={selectedCampaignId}
        setSelectedCampaignId={setSelectedCampaignId}
      />
    </>
  );
};

export default AdGroups;
