import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import closableEnqueueSnackbar from '../../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';
import { handleApiError } from '../../requestHelpers/requestHelpers';

export const useUpdateAdGroup = () => {
  const { t } = useTranslation();
  return useMutation(
    (postRequest: any) => dataFetch[postRequest.id ? 'put' : 'post'](
      `${DIGGER_API_SUFFIX}ad-groups${postRequest.id ? (`/${postRequest.id}`) : ''}`,
      {
        name: postRequest.name,
        campaignId: postRequest.campaignId,
        adGroupCode: postRequest.adGroupCode,
      },
    ),
    {
      onSuccess: () => {
        closableEnqueueSnackbar(t('adGroupCreatedSuccesfully'), 'success');
      },
      onError: (error: any) => {
        handleApiError(error, t);
      },
    },
  );
};

export const useDeleteAdGroup = () => {
  const { t } = useTranslation();

  return useMutation(
    (id: number) => dataFetch.delete(
      `${DIGGER_API_SUFFIX}ad-groups/${id}`,
    ),
    {
      onSuccess: () => {
        closableEnqueueSnackbar(t('adGroupDeletedSuccessfully'), 'success');
      },
      onError: () => {
        closableEnqueueSnackbar(t('somethingWentWrong'), 'error');
      },
    },
  );
};
