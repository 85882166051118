import { useQuery, UseQueryResult } from 'react-query';
import { Dayjs } from 'dayjs';
import { CORE_API_SUFFIX, dataFetch } from '../../../axios/customAxios';
import { appendQueryParamIfNotEmpty, formatDate, generateQueryKey } from '../../../constants/hookConstants';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';

interface GetSalesInterface {
  data: number;
}

const useGetSalesByTrackingSource = (
  identifier: string,
  trackingSource: number,
  from: Dayjs | null,
  to: Dayjs | null,
) : UseQueryResult<ApiResponse<GetSalesInterface>, ApiError<GetSalesInterface>> => {
  const queryParams = new URLSearchParams();
  appendQueryParamIfNotEmpty(queryParams, 'trackingSource', trackingSource.toString());
  appendQueryParamIfNotEmpty(queryParams, 'from', formatDate(from));
  appendQueryParamIfNotEmpty(queryParams, 'to', formatDate(to));

  const queryKey = generateQueryKey(identifier, queryParams);

  return useQuery<ApiResponse<GetSalesInterface>, ApiError<GetSalesInterface>>(
    [`ad_sales-trackingSource_${queryKey}_${from}_${to}`],
    () => dataFetch.get(`${CORE_API_SUFFIX}ad/total/sales?${queryParams}`),
  );
};

export default useGetSalesByTrackingSource;
