import { useMutation } from 'react-query';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';

interface SendTrackedCodesRequest {
    code: string,
    status: number,
}

interface OptionsInterface {
    onSuccess: () => void;
}

export const sendTrackedCodes = (id : number, options : OptionsInterface) => useMutation<ApiResponse<null>, ApiError<null>, SendTrackedCodesRequest>(
  (postRequest) => dataFetch.patch(
    `${DIGGER_API_SUFFIX}untracked-code/${id}`,
    postRequest,
  ),
  options,
);
