import React, {
  FC, useState, useRef, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import CsvIcon from '../../assets/icons/csvIcon.svg';
import ExcelIcon from '../../assets/icons/excelIcon.svg';
import LockIconPrimary from '../../assets/icons/lock-icon-primary.svg';
import { useOrganizationSettingsStore } from '../../store/organizationSettingsStore';
import { DiggerPlanEnum } from '../enums/DiggerPlanEnum';
import LockedWrapper from '../../lockedContent/lockedWrapper';

interface ExportButtonInterface {
    fetchDownload?: (_acceptHeader: string) => void;
}

const TableExportButton: FC<ExportButtonInterface> = (props) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState<boolean>(false);
  const containerRef = useRef<any>(null);
  const productId = useOrganizationSettingsStore((state) => state.organizationSettings.productId);
  const isLocked = typeof productId === 'number' && (
    productId === DiggerPlanEnum.lite || productId === DiggerPlanEnum.standard
  );

  const handleDownloadClick = (type: string) => {
    if (!isLocked) {
      setOpened(!opened);
      props.fetchDownload?.(type);
    }
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setOpened(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative top-0 z-40 flex flex-col" ref={containerRef}>
      <button
        type="button"
        onClick={() => setOpened(!opened)}
        className="h-[40px] rounded-lg border bg-white px-4 text-[#4C956C] hover:bg-[#F2F2F2]"
      >
        {t('export')}
      </button>
      {opened && (
        <div className="absolute right-0 top-12 rounded-lg border">
          <LockedWrapper disableLockedWrapper={!isLocked} disableBlur>
            <div
              onClick={() => handleDownloadClick('text/csv')}
              className="
                flex w-full items-center justify-center gap-2 rounded-t-lg border-b bg-white px-6 py-2 text-primary
                hover:cursor-pointer hover:bg-dark
              "
            >
              <div className="w-4">
                <img src={CsvIcon} alt="csv" />
              </div>
              <p>
                {t('csv')}
              </p>
              {isLocked && (
              <div className="w-4">
                <img src={LockIconPrimary} alt="lock icon" />
              </div>
              )}
            </div>
          </LockedWrapper>
          <LockedWrapper disableLockedWrapper={!isLocked} disableBlur>
            <div
              onClick={() => handleDownloadClick('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')}
              className="
                flex w-full items-center justify-center gap-2 rounded-b-lg bg-white px-6 py-2 text-primary
                hover:cursor-pointer hover:bg-dark
              "
            >
              <div className="w-4">
                <img src={ExcelIcon} alt="excel" />
              </div>
              <p>
                {t('excel')}
              </p>
              {isLocked && (
              <div className="w-4">
                <img src={LockIconPrimary} alt="lock icon" />
              </div>
              )}
            </div>
          </LockedWrapper>
        </div>
      )}
    </div>
  );
};

export default TableExportButton;
