import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../components/buttons/customButton';
import BasicDialog from './basicDialog';
import QuestionMarkWarningIcon from '../assets/icons/questionmark-warning-icon.svg';
import TickSuccessIcon from '../assets/icons/tick-success-icon.svg';
import TriangleDangerIcon from '../assets/icons/triangle-danger-icon.svg';

interface ConfirmDialogProps {
  onCloseDialog: () => void;
  title: string;
  text: string;
  description?: string;
  submitButtonText?: string;
  onSubmit: () => void;
  open: boolean;
  isDisabled?: boolean;
  variant?: 'warning' | 'success' | 'danger';
  noCancelButton?: boolean;
}

const confirmDialogVariantsConfig = {
  success: { iconSrc: TickSuccessIcon, textColor: 'text-green' },
  danger: { iconSrc: TriangleDangerIcon, textColor: 'text-red' },
  warning: { iconSrc: QuestionMarkWarningIcon, textColor: 'text-yellow' },
};

const ConfirmDialog:FC<ConfirmDialogProps> = (props) => {
  const { t } = useTranslation();
  const { iconSrc, textColor } = confirmDialogVariantsConfig[props.variant || 'warning'];

  return (
    <BasicDialog
      open={props.open}
      title={t(props.title)}
      trackingModal
      headerColor="white"
    >
      <div className="my-1 flex flex-col items-center justify-center gap-2">
        <img src={iconSrc} alt="icon" className="h-6 w-6 object-contain" />
        <p className={`mx-auto max-w-[404px] text-center leading-[1.5] ${textColor}`}>
          {t(props.text)}
        </p>
        {props.description && (
          <p className="mt-4 leading-[1.5] text-primary">
            {t(props.description)}
          </p>
        )}
      </div>
      <div className="flex w-full items-center justify-end gap-2 pb-2 pt-8">
        {!props.noCancelButton && (
          <CustomButton
            variant="noOutline"
            type="button"
            onClick={() => props.onCloseDialog()}
          >
            {t('cancel')}
          </CustomButton>
        )}
        <CustomButton
          onClick={props.onSubmit}
          disabled={props.isDisabled}
        >
          {t(props.submitButtonText || 'continue')}
        </CustomButton>
      </div>
    </BasicDialog>
  );
};

export default ConfirmDialog;
