import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import { handleApiError } from '../../requestHelpers/requestHelpers';
import closableEnqueueSnackbar from '../../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';
import { WelcomeSignUpRequest } from '../../../types/api/auth';

const useMutateWelcomeSignUp = (token: string) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return useMutation<ApiResponse<null>, ApiError<null>, WelcomeSignUpRequest>(
    (request) => dataFetch.post(
      `${DIGGER_API_SUFFIX}paddle/welcome`,
      {
        ...request,
        token,
      },
    ),
    {
      onSuccess: () => {
        closableEnqueueSnackbar(t('accountCreatedSuccessfully'), 'success');
        navigate('/auth');
      },
      onError: (error) => {
        handleApiError(error, t);
      },
    },
  );
};

export default useMutateWelcomeSignUp;
