import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useConversionNameStore, useOtherConversionStore } from '../../store/conversionStepsStore';
import { useSendOtherConversion } from '../../apiHooks/mutations/sendOtherConversion/sendOtherConversion';

const ConfiguredOtherConversion : FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { conversionName } = useConversionNameStore((state) => state);

  const createConversion = () => {
    localStorage.removeItem('conversionName');
    navigate('/tracking/google-conversions/conversions', { replace: true });
  };

  return (
    <div className="w-full">
      <div className="mb-4 flex h-[50px] w-full flex-col justify-around border-b">
        <p className="text-lg font-semibold text-primary">What’s Next</p>
      </div>
      <div className="flex flex-col">
        <p className="mb-4 text-2xl text-primary ">
          You have set up your
          <strong className="mx-2">{conversionName}</strong>
          conversion
        </p>
        <p className="mb-4 text-base text-primary">You’re configured to measure website leads using Digtective.</p>
        <p className="mb-4 text-base text-primary"> When you click Finish, you’ll be taken to the conversion summary, where you’ll see the conversion you’ve just set up.</p>
      </div>
      <div className="flex w-full justify-end">
        <button
          className="mt-4 rounded bg-primary px-4 py-2 text-white"
          onClick={() => createConversion()}
        >
          Finish
        </button>
      </div>
    </div>
  );
};

export default ConfiguredOtherConversion;
