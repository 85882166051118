import * as React from 'react';
import { FC } from 'react';
import { DateRange, DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { useTranslation } from 'react-i18next';
import { shortcutsItems } from './dateRangeShortcuts';
import { Colors } from '../../constants/constants';
import CalendarIconPrimary from '../../assets/icons/calendar-icon-primary.svg';

interface CustomDateRangePickerProps {
  filterRange: DateRange<Dayjs>;
  setFilterRange: (_newFilterRange: DateRange<Dayjs>) => void;
  iconButton?: boolean;
  isCalendarOpen: boolean;
  setIsCalendarOpen: (_isCalendarOpen: boolean) => void;
  showFilterDates?: boolean;
}

const CustomDateRangePicker: FC<CustomDateRangePickerProps> = (props) => {
  const { t } = useTranslation();
  return (
    <div className="relative flex items-center gap-4 text-primary">
      {props.iconButton && (
        <div className="absolute">
          <img src={CalendarIconPrimary} alt="calendar icon" className="h-[18px] w-[18px]" />
        </div>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangePicker
          open={props.isCalendarOpen}
          onOpen={() => props.setIsCalendarOpen(true)}
          onClose={() => props.setIsCalendarOpen(false)}
          value={props.filterRange}
          onChange={async (newFilterRange) => {
            await props.setFilterRange(newFilterRange);
            if (newFilterRange[1] !== props.filterRange[1]) props.setIsCalendarOpen(false);
          }}
          slots={{
            field: SingleInputDateRangeField,
          }}
          slotProps={{
            shortcuts: {
              items: shortcutsItems(t),
              sx: {
                borderRight: '1px solid #eaeaea',
                paddingBlock: '12px',
                paddingInline: '14px',
                display: 'flex',
                flexDirection: 'column',
                gap: '6px',
                '& li': {
                  padding: 0,
                },
                '& .MuiButtonBase-root': {
                  minWidth: '115px',
                  marginInline: 'auto',
                  borderRadius: '8px',
                  border: '1px solid #eaeaea',
                  backgroundColor: 'transparent',
                  color: Colors.primary,
                  fontWeight: 500,
                  fontSize: 12,
                  height: 38,
                  '&:focus': {
                    boxShadow: 'none',
                  },
                  '&:hover': {
                    backgroundColor: '#eaeaea',
                    transition: 'background-color 0.2s ease-in',
                  },
                },
              },
            },
            actionBar: { actions: [] },
            desktopPaper: {
              sx: {
                zIndex: 1310,
                boxShadow: '2px 2px 20px 0px #0C485E33',
                borderRadius: '8px',
                border: '1px solid #EAEAEA',
                position: 'relative',
                left: 88,
                top: 14,
              },
            },
          }}
          disableFuture
          calendars={2}
          sx={props.iconButton ? {
            width: 18,
            height: 18,
            opacity: 0,
            input: {
              cursor: 'pointer',
              padding: 0,
              opacity: 0,
            },
          } : {
            backgroundColor: 'white',
            borderRadius: '8px',
            width: 255,
            '& .MuiOutlinedInput-root': {
              color: Colors.primary,
              borderRadius: '8px',
            },
            '& input': {
              paddingBlock: 0,
              height: 40,
            },
          }}
        />
      </LocalizationProvider>
      {props.showFilterDates && (
        <span className="text-sm leading-[1]">
          {props.filterRange?.[0] ? (
            <>
              {props.filterRange[0].format('DD MMM YYYY')}
              {' - '}
              {props.filterRange?.[1]?.format('DD MMM YYYY') || t('today')}
            </>
          ) : (
            <>
              {t('allTime')}
            </>
          )}
        </span>
      )}
    </div>
  );
};

export default CustomDateRangePicker;
