import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumbs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import breadCrumbSeperator from '../../assets/icons/breadCrumbSeperator.svg';
import { navigationList } from './constants';

const Breadcrumb: FC = () => {
  const location = useLocation();

  if (location.pathname.includes('dashboard')) {
    return null;
  }

  const pathnames = location.pathname.split('/').filter((x) => x);
  const { t } = useTranslation();

  const breadcrumbItems = pathnames.map((_, index) => {
    const url = `/${pathnames.slice(0, index + 1).join('/')}`;
    const item = navigationList.find((navItem) => navItem.href === url);
    if (item) {
      return item;
    }
    return {
      label: pathnames[index],
      href: url,
      iconPrimary: '',
    };
  });

  return (
    <Breadcrumbs
      separator={<img className="h-[10px] w-[10px]" src={breadCrumbSeperator} alt="" />}
      aria-label="breadcrumb"
      style={{
        marginBottom: '20px', borderBottom: '1px solid #EAEAEA', paddingBottom: '5px', alignItems: 'center', display: 'flex',
      }}
    >
      {breadcrumbItems.map((item, index) => (
        index === 0 || index === 1 ? (
          <div
            key={`${item.href}`}
            style={{
              display: 'flex', alignItems: 'center', gap: '8px', textDecoration: 'none', color: 'inherit',
            }}
          >
            {item.iconPrimary && (
              <div className="flex items-center gap-2">
                <img className="h-[10px] w-[10px] text-[#6D919E]" src={item.iconPrimary ?? ''} alt="" />
                <img className="h-[10px] w-[10px]" src={breadCrumbSeperator} alt="" />
              </div>
            )}
            <p className="text-[10px] text-[#6D919E]">
              {t(item?.label)}
            </p>
          </div>
        ) : (
          <Link
            key={`_${item.href}`}
            to={item?.href || '/'}
            style={{
              display: 'flex', alignItems: 'center', gap: '8px', textDecoration: 'none', color: 'inherit',
            }}
          >
            <p className="text-[10px] text-[#6D919E]">
              {t(item?.label)}
            </p>
          </Link>
        )
      ))}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
