import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Checkbox from '../checkbox/Checkbox';
import { EventCategoryKey, useConversionStepsStore, useOtherConversionStore } from '../../store/conversionStepsStore';
import closableEnqueueSnackbar from '../closableEnqueueSnackbar/closableEnqueueSnackbar';

const GoalOptimization : FC = () => {
  const { t } = useTranslation();
  const { data, updateGoogleConversionType } = useOtherConversionStore((state) => state);
  console.log(data, 'DATA');
  const { currentStep, setCurrentStep } = useConversionStepsStore((state) => state);
  const navigate = useNavigate();
  const [checkboxes, setCheckboxes] = useState({
    checkboxQualifiedLead: false,
    checkboxConvertedLead: false,
    checkboxContact: false,
    checkboxSubmit: false,
    checkboxBook: false,
    checkboxSign: false,
    checkboxRequest: false,
    checkboxDirections: false,
    checkboxOutbound: false,
    checkboxPurchase: false,
    checkboxAddToCart: false,
    checkboxBeginCheckout: false,
    checkboxSubscribe: false,
    checkboxPageView: false,
    checkboxOther: false,
  });

  const formik = useFormik({
    initialValues: {
      isCheckboxSelected: false,
    },
    onSubmit: () => {
      if (formik.values.isCheckboxSelected) {
        setCurrentStep(currentStep + 1);
        navigate('/tracking/google-conversions/other-conversions/conversion-name', { replace: true });
      } else {
        closableEnqueueSnackbar('Please select one of the checkboxes', 'error');
      }
    },
  });

  const handleCheckboxChange = (clickedCheckbox : EventCategoryKey) => {
    updateGoogleConversionType(clickedCheckbox);
    setCheckboxes((prevCheckboxes) => {
      const updatedCheckboxes : any = { ...prevCheckboxes };

      for (const checkbox in updatedCheckboxes) {
        updatedCheckboxes[checkbox] = checkbox === clickedCheckbox;
      }

      const isAtLeastOneCheckboxSelected = Object.values(updatedCheckboxes).some((value) => value);

      formik.setFieldValue('isCheckboxSelected', isAtLeastOneCheckboxSelected);

      return updatedCheckboxes;
    });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex h-[80px] w-full flex-col justify-around border-b">
        <p className="text-lg font-semibold text-primary">Goal and action optimization</p>
        <p className="text-xs font-medium text-[#9EB6BF]">Select the Goal category for this action</p>
      </div>
      <div className="mt-6 flex items-baseline justify-between space-x-4">
        <div className="category-box min-h-[0] w-[33%] rounded-lg border p-8">
          <p className="mb-3 text-xs text-[#9EB6BF]">Lead Categories</p>
          <div className="category-content flex flex-col">
            <label className="mb-2 flex items-center gap-2 text-base font-normal text-primary hover:cursor-pointer">
              <Checkbox id="checkboxes.checkboxQualifiedLead" defaultChecked={checkboxes.checkboxQualifiedLead} onChange={() => handleCheckboxChange('checkboxQualifiedLead')} />
              Qualified Lead
              <p className="text-[#4C956C]">(Recommended)</p>
            </label>
            <label className="mb-2 flex items-center gap-2  text-base font-normal text-primary hover:cursor-pointer">
              <Checkbox id="checkboxes.checkboxConvertedLead" defaultChecked={checkboxes.checkboxConvertedLead} onChange={() => handleCheckboxChange('checkboxConvertedLead')} />
              Converted Lead
              <p className="text-[#4C956C]">(Recommended)</p>
            </label>
            <label className="mb-2 flex items-center gap-2  text-base font-normal text-primary hover:cursor-pointer">
              <Checkbox id="checkboxes.checkboxContact" defaultChecked={checkboxes.checkboxContact} onChange={() => handleCheckboxChange('checkboxContact')} />
              Contact
            </label>
            <label className="mb-2 flex items-center gap-2  text-base font-normal text-primary hover:cursor-pointer">
              <Checkbox id="checkboxes.checkboxSubmit" defaultChecked={checkboxes.checkboxSubmit} onChange={() => handleCheckboxChange('checkboxSubmit')} />
              Submit Lead Form
            </label>
            <label className="mb-2 flex items-center gap-2  text-base font-normal text-primary hover:cursor-pointer">
              <Checkbox id="checkboxes.checkboxBook" defaultChecked={checkboxes.checkboxBook} onChange={() => handleCheckboxChange('checkboxBook')} />
              Book appointment
            </label>
            <label className="mb-2 flex items-center gap-2  text-base font-normal text-primary hover:cursor-pointer">
              <Checkbox id="checkboxes.checkboxSign" defaultChecked={checkboxes.checkboxSign} onChange={() => handleCheckboxChange('checkboxSign')} />
              Sign-up
            </label>
            <label className="mb-2 flex items-center gap-2  text-base font-normal text-primary hover:cursor-pointer">
              <Checkbox id="checkboxes.checkboxRequest" defaultChecked={checkboxes.checkboxRequest} onChange={() => handleCheckboxChange('checkboxRequest')} />
              Request quote
            </label>
            <label className="mb-2 flex items-center gap-2  text-base font-normal text-primary hover:cursor-pointer">
              <Checkbox id="checkboxes.checkboxDirections" defaultChecked={checkboxes.checkboxDirections} onChange={() => handleCheckboxChange('checkboxDirections')} />
              Get directions
            </label>
            <label className="mb-2 flex items-center gap-2  text-base font-normal text-primary hover:cursor-pointer">
              <Checkbox id="checkboxes.checkboxOutbound" defaultChecked={checkboxes.checkboxOutbound} onChange={() => handleCheckboxChange('checkboxOutbound')} />
              Outbound click
            </label>
          </div>
        </div>

        <div className="category-box min-h-[0] w-[33%] rounded-lg border p-8">
          <p className="mb-3 text-xs text-[#9EB6BF]">Sales Categories</p>
          <div className="category-content flex flex-col">
            <label className="mb-2 flex items-center gap-2  text-base font-normal text-primary hover:cursor-pointer">
              <Checkbox id="checkboxes.checkboxPurchase" defaultChecked={checkboxes.checkboxPurchase} onChange={() => handleCheckboxChange('checkboxPurchase')} />
              Purchase
            </label>
            <label className="mb-2 flex items-center gap-2  text-base font-normal text-primary hover:cursor-pointer">
              <Checkbox id="checkboxes.checkboxAddToCart" defaultChecked={checkboxes.checkboxAddToCart} onChange={() => handleCheckboxChange('checkboxAddToCart')} />
              Add to cart
            </label>
            <label className="mb-2 flex items-center gap-2  text-base font-normal text-primary hover:cursor-pointer">
              <Checkbox id="checkboxes.checkboxBeginCheckout" defaultChecked={checkboxes.checkboxBeginCheckout} onChange={() => handleCheckboxChange('checkboxBeginCheckout')} />
              Begin checkout
            </label>
            <label className="mb-2 flex items-center gap-2  text-base font-normal text-primary hover:cursor-pointer">
              <Checkbox id="checkboxes.checkboxSubscribe" defaultChecked={checkboxes.checkboxSubscribe} onChange={() => handleCheckboxChange('checkboxSubscribe')} />
              Subscribe
            </label>
          </div>
        </div>

        <div className="category-box min-h-[0] w-[33%] rounded-lg border p-8">
          <p className="mb-3 text-xs text-[#9EB6BF]">More Categories</p>
          <div className="category-content flex flex-col">
            <label className="mb-2 flex items-center gap-2  text-base font-normal text-primary hover:cursor-pointer">
              <Checkbox id="checkboxes.checkboxPageView" defaultChecked={checkboxes.checkboxPageView} onChange={() => handleCheckboxChange('checkboxPageView')} />
              Page view
            </label>
            <label className="mb-2 flex items-center gap-2  text-base font-normal text-primary hover:cursor-pointer">
              <Checkbox id="checkboxes.checkboxOther" defaultChecked={checkboxes.checkboxOther} onChange={() => handleCheckboxChange('checkboxOther')} />
              Other
            </label>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-end">
        <button
          type="submit"
          className="mt-4 rounded bg-primary px-4 py-2 text-white"
        >
          Continue
        </button>
      </div>
    </form>
  );
};

export default GoalOptimization;
