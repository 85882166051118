import { useQuery } from 'react-query';
import { Dayjs } from 'dayjs';
import { CORE_API_SUFFIX, dataFetch } from '../../../axios/customAxios';
import { appendQueryParamIfNotEmpty, formatDate, generateQueryKey } from '../../../constants/hookConstants';

export const useGetMonthlyAdHistogram = (
  identifier: string,
  trackingSourceId: number | null,
  from: Dayjs | null,
  to: Dayjs | null,
) => {
  const queryParams = new URLSearchParams();
  appendQueryParamIfNotEmpty(queryParams, 'from', formatDate(from));
  appendQueryParamIfNotEmpty(queryParams, 'to', formatDate(to));
  appendQueryParamIfNotEmpty(queryParams, 'trackingSourceId', trackingSourceId ?? '');

  const queryKey = generateQueryKey(identifier, queryParams);

  return useQuery(
    [`reports_${queryKey}`],
    () => dataFetch.get(`${CORE_API_SUFFIX}ad/histogram/monthly?${queryParams}`).then((res) => res.data),
  );
};
