import React, { FC, ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import BackIconWhite from '../assets/icons/back-icon-white.svg';
import BackIconPrimary from '../assets/icons/back-icon-primary.svg';
import closeIcon from '../assets/icons/close-icon.svg';
import { ColorKey, Colors } from '../constants/constants';

interface BasicDialogProps {
  closeDialog?: () => void,
  open: boolean,
  title: string,
  children: ReactNode;
  allowCloseOnBackdrop?: boolean,
  headerColor?: ColorKey,
  loginStyle?: boolean,
  trackingModal?: boolean,
  twoFaStyle?: boolean,
}

const BasicDialog: FC<BasicDialogProps> = (props) => {
  const { t } = useTranslation();
  const titleParts = props.title.split('/');

  return (
    <Dialog
      open={props.open}
      maxWidth="md"
      onClose={(event, reason) => {
        if (!props.allowCloseOnBackdrop && reason === 'backdropClick') {
          return;
        }
        props.closeDialog?.();
      }}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: Colors.primary,
          opacity: '20% !important',
        },
        '& .MuiDialog-paper': {
          borderRadius: '8px',
          minWidth: props.loginStyle ? 505 : 555,
          minHeight: props.twoFaStyle ? 100 : 200,
          boxShadow: 'none',
        },
        '@media (max-width: 768px)': {
          '& .MuiDialog-paper': {
            minWidth: 'unset',
          },
        },
      }}
    >
      <div
        className={props.trackingModal ? 'flex items-center justify-center gap-5 rounded-t-lg border-b px-6 py-4'
          : props.twoFaStyle ? 'flex flex-row-reverse items-center justify-between gap-5 rounded-t-lg border-b px-6 py-4'
            : 'flex items-center gap-5 rounded-lg px-[30px] py-[21px]'}
        style={{ backgroundColor: props.headerColor ? Colors[props.headerColor] : Colors['gray-5'] }}
      >
        {props.headerColor === 'gray-5' || !props.headerColor ? (
          <>
            {props.closeDialog && (
            <button
              type="button"
              className="p-1 duration-100 hover:opacity-70 active:opacity-50"
              onClick={props.closeDialog}
            >
              <img src={BackIconPrimary} alt="" />
            </button>
            )}
            <p className="font-medium text-primary">
              {t(props.title)}
            </p>
          </>
        ) : (
          <>
            {props.closeDialog && (
            <button
              type="button"
              className="p-1 duration-100 hover:opacity-70 active:opacity-50"
              onClick={props.closeDialog}
            >
              <img src={props.twoFaStyle ? closeIcon : BackIconWhite} alt="" />
            </button>
            )}
            <p className={`${props.headerColor === 'white' ? 'text-center' : 'text-white'}`}>
              <span className={`font-medium ${titleParts[1] ? 'text-[#BDBDBD]' : 'text-primary'}`}>
                {' '}
                {t(titleParts[0])}
              </span>
              {titleParts[1] && (
              <span className="font-semibold text-primary">
                {' '}
                /
                {' '}
                {t(titleParts[1])}
              </span>
              )}
            </p>
          </>
        )}
      </div>
      <div className="px-7 py-5">
        {props.children}
      </div>
    </Dialog>
  );
};

export default BasicDialog;
