import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { toNumber } from 'lodash';
import VatDisplayer from '../../partials/VATDisplayer';
import LoadingSpinner from '../loadingSpinner/loadingSpinner';
import { formatToTwoDecimalPlaces } from '../../constants/constants';

interface ReportCardProps {
    title: string;
    value: number;
    color: string;
    icon?: string;
    additionalInfo?: string;
    additionalValue?: string;
    isLoading: boolean;
}

const ReportCard: FC<ReportCardProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div className={`flex h-[150px] w-full  flex-col rounded-[8px] bg-white px-[28px] py-[25px] ${props.icon ? '' : 'flex-col-reverse justify-end'}`}>
      <div className="flex items-center justify-between">
        {props.icon && <img src={props.icon} alt={props.title} className="h-[32px] w-[32px]" />}
        <p className={`text-[32px] font-medium text-primary ${!props.icon && 'mt-4'}`}>
          {props.isLoading
            ? <LoadingSpinner color="primary" size={25} />
            : (
              <VatDisplayer
                value={props.value}
              />
            )}
        </p>
      </div>
      {props.icon && <p className="mt-4 text-sm text-primary">{t(props.title)}</p> }
      <div className={`${props.color} mb-1 border-b`} />
      <div className="flex w-full justify-end">
        {props.additionalInfo && (
        <p className="text-sm text-gray-500">
          {t(props.additionalInfo)}
          {/* {props.additionalValue && toNumber(props.additionalValue) > 0 ? `+${formatToTwoDecimalPlaces(props.additionalValue)}` : formatToTwoDecimalPlaces(props.additionalValue)} */}
        </p>
        )}
      </div>
      {!props.icon && <p className="text-sm text-primary">{t(props.title)}</p> }
    </div>
  );
};

export default ReportCard;
