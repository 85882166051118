export const isValidBrowser = (browserString :string) => {
    const browserRegexes = [
        /MSIE|Trident/i,
        /Edge/i,
        /Firefox/i,
        /OPR|Opera/i,
        /Chrome/i,
        /Safari/i,
    ];
    return browserRegexes.some((regex) => regex.test(browserString));
}

export const isValidLink =(link : string)=> {
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return urlPattern.test(link);
}

export const isUUID =(input : string)=> {
    const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidPattern.test(input);
}

export const keysToExclude = ["id", "createdAt", "incomeAt", "organizationId", "hubspotContactId","fsId"];