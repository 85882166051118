import { Navigate, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useAuthStore } from '../../../store/authStore';
import { useMicrosoftCode } from '../../../apiHooks/mutations/integrationMutations/microsoftMutations';
import closableEnqueueSnackbar from '../../closableEnqueueSnackbar/closableEnqueueSnackbar';

const MicrosoftRoute = () => {
  const { isLoggedIn } = useAuthStore();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const codeParam = queryParams.get('code');
  const errorParam = queryParams.get('error');
  const errorDescriptionParam = queryParams.get('error_description');
  const { mutateAsync: sendMicrosoftCode } = useMicrosoftCode();

  useEffect(() => {
    if (codeParam && isLoggedIn) {
      sendMicrosoftCode(codeParam).then(() => {});
    }

    if (codeParam && !isLoggedIn) {
      localStorage.setItem('microsoftCode', codeParam);
    }

    if (localStorage.getItem('microsoftCode') && isLoggedIn) {
      sendMicrosoftCode(localStorage.getItem('microsoftCode') as string).then(() => {});
    }

    if (errorParam && errorDescriptionParam) {
      closableEnqueueSnackbar(errorDescriptionParam, 'error');
    }
  }, [isLoggedIn]);

  if (isLoggedIn) {
    return <Navigate to="/dashboard" />;
  }
  return <Navigate to="/auth" />;
};

export default MicrosoftRoute;
