import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikValues, useFormik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import { CustomButton } from '../components/buttons/customButton';
import { ResetPasswordFormValidationSchema } from '../validations/resetPasswordFormValidationSchema';
import { useResetPassword } from '../apiHooks/mutations/authMutations/authMutations';
import PasswordInput from '../components/inputs/passwordInput';

const ResetPasswordDialog: FC = () => {
  const { t } = useTranslation();
  const {
    mutate: sendResetPasswordRequest,
    isLoading: isSendingPasswordResetRequest,
  } = useResetPassword();
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token') || '';
  const email = searchParams.get('email') || '';

  const formik: FormikValues = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: () => ResetPasswordFormValidationSchema(t),
    onSubmit: (values) => {
      const resetPasswordRequest = { ...values, token, email };
      sendResetPasswordRequest(resetPasswordRequest);
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="mx-auto flex w-full max-w-[400px] flex-col gap-3.5 pb-8 pt-1"
    >
      <h4 className="text-[32px] font-semibold text-[#4C956C]">{t('changeYourPassword')}</h4>
      <p className="text-medium mb-1.5 text-primary">
        {t('yourPasswordMustBeAtLeastSixCharacters')}
      </p>
      <PasswordInput
        id="password"
        myKey="password"
        name="password"
        value={formik.values.password}
        label={t('password')}
        placeholder={t('password')}
        isVisibilityToggleable
        fullWidth
        size="small"
        onChange={formik.handleChange}
        error={Boolean(formik.touched.password) && Boolean(formik.errors.password)}
        helperText={Boolean(formik.touched.password) && formik.errors.password}
      />
      <PasswordInput
        id="confirmPassword"
        myKey="confirmPassword"
        name="confirmPassword"
        value={formik.values.confirmPassword}
        label={t('confirmPassword')}
        placeholder={t('confirmPassword')}
        isVisibilityToggleable
        fullWidth
        size="small"
        onChange={formik.handleChange}
        error={Boolean(formik.touched.confirmPassword) && Boolean(formik.errors.confirmPassword)}
        helperText={Boolean(formik.touched.confirmPassword) && formik.errors.confirmPassword}
      />
      <CustomButton type="submit" className="grid" disabled={isSendingPasswordResetRequest}>
        {t('setPassword')}
      </CustomButton>
    </form>
  );
};

export default ResetPasswordDialog;
