import React from 'react';
import { useTranslation } from 'react-i18next';
import ConnectToPaddleScreen from './connectToPaddleScreen';
import DisconnectFromPaddleScreen from './disconnectFromPaddleScreen';
import LoadingSpinner from '../../../components/loadingSpinner/loadingSpinner';
import useGetOrganizationSettings from '../../../apiHooks/queries/useGetOrganizations';
import { ORGANIZATION_SETTINGS } from '../../../constants/pageIdentifiers';

const PaddleTabPanel = () => {
  const { t } = useTranslation();

  const {
    data: organizationSettings,
    isFetching,
    isError,
    refetch,
  } = useGetOrganizationSettings(ORGANIZATION_SETTINGS);

  if (isFetching) {
    return (
      <LoadingSpinner center color="primary" />
    );
  }

  if (isError) {
    return (
      <div>
        {t('somethingWentWrong')}
      </div>
    );
  }

  if (organizationSettings?.data?.data?.isConnectedToPaddle) {
    return (
      <DisconnectFromPaddleScreen refetchOrganizationSettings={refetch} />
    );
  }

  return (
    <ConnectToPaddleScreen refetchOrganizationSettings={refetch} />
  );
};

export default PaddleTabPanel;
