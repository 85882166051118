import React, { useState } from 'react';
import checkmark from '../../assets/icons/whiteConversionCheckmark.svg';

interface StepProps {
    name: string;
    value: string;
    isActive: boolean;
    isCompleted: boolean;
    lastChild :boolean;
}

const Step: React.FC<StepProps> = ({
  name, value, isActive, isCompleted, lastChild,
}) => (
  <div className={`flex items-center gap-4 ${isActive ? 'text-primary' : 'text-gray-500'}`}>
    <div
      className={`flex h-4 w-4 items-center justify-center rounded-full border-2 ${
        isCompleted ? 'border-primary bg-primary' : ''
      } ${isActive ? 'border-2 border-primary' : ''}`}
    >
      {isCompleted ? <img className="h-1.5" src={checkmark} alt="" /> : ''}
    </div>
    <span className="text-sm">{name}</span>
    {!lastChild && <div className="w-10 border" />}
  </div>
);

export default Step;
