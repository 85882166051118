import { useQuery, UseQueryResult } from 'react-query';
import { Dayjs } from 'dayjs';
import { CORE_API_SUFFIX, dataFetch } from '../../../axios/customAxios';
import { appendQueryParamIfNotEmpty, formatDate, generateQueryKey } from '../../../constants/hookConstants';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';

interface GroupedHistogramResponse {
    labels : string[],
    dataSets: {
        earned: number[];
        lostIncome: number[];
        potentialIncome: number[];
    };
}

export const useGetGroupedHistogram = (
  identifier: string,
  id: number | undefined,
  from: Dayjs | null,
  to: Dayjs | null,
) : UseQueryResult<ApiResponse<GroupedHistogramResponse>, ApiError<GroupedHistogramResponse>> => {
  const queryParams = new URLSearchParams();
  appendQueryParamIfNotEmpty(queryParams, 'from', formatDate(from));
  appendQueryParamIfNotEmpty(queryParams, 'to', formatDate(to));

  const queryKey = generateQueryKey(identifier, queryParams);

  return useQuery<ApiResponse<GroupedHistogramResponse>, ApiError<GroupedHistogramResponse>>(
    [`grouped_reports_${queryKey}`],
    () => dataFetch.get(`${CORE_API_SUFFIX}ad/histogram/${id}?${queryParams}`),
  );
};
