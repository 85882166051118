import * as yup from 'yup';

export const usersFormValidationSchema = (t: any) => yup.object({
  firstName: yup
    .string()
    .matches(/^[A-Za-z\x80-\xFF_\- ]+$/, t('nameOnlyContainLetters'))
    .required(t('inputIsRequired')),
  lastName: yup
    .string()
    .matches(/^[A-Za-z\x80-\xFF_\- ]+$/, t('nameOnlyContainLetters'))
    .required(t('inputIsRequired')),
  email: yup
    .string()
    .email(t('emailMustBeValid'))
    .required(t('inputIsRequired')),
});
