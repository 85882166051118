import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import HubspotPipelineSelect from '../../../../components/dropdownSelects/hubspotPipelineSelect';
import DealSettingsContent from './dealSettingsContent';
import {
  useGetHubspotPipelineStages,
  useGetHubspotPipelineStagesMapped,
} from '../../../../apiHooks/queries/useGetHubspotForms';
import { PIPELINE_STAGES, PIPELINE_STAGES_MAPPED } from '../../../../constants/pageIdentifiers';

const DealSettings = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [
    hubspotPipelineId,
    setHubspotPipelineId,
  ] = useState<string>(searchParams.get('hubspotPipelineId') || '');

  const {
    data: pipelineStagesData,
    refetch: refetchPipelineStages,
    isFetching: isFetchingPipelineStages,
    isError: isErrorPipelineStages,
  } = useGetHubspotPipelineStages(PIPELINE_STAGES, hubspotPipelineId);

  const {
    data: pipelineStagesMappedData,
    refetch: refetchPipelineStagesMapped,
    isFetching: isFetchingPipelineStagesMapped,
    isError: isErrorPipelineStagesMapped,
  } = useGetHubspotPipelineStagesMapped(PIPELINE_STAGES_MAPPED, hubspotPipelineId);

  const refetchPipelineStagesAndPipelineStagesMapped = () => {
    refetchPipelineStages().then();
    refetchPipelineStagesMapped().then();
  };

  return (
    <div className="h-max p-5">
      <div className="flex gap-3">
        <div className="flex w-full gap-[30px]">
          <HubspotPipelineSelect
            size="small"
            initialSelectedHubspotPipelineOptionId={hubspotPipelineId}
            setHubspotPipelineId={setHubspotPipelineId}
          />
        </div>
        <div className="relative w-full max-w-[298px]">
          <p
            className="relative inline-block min-h-[38px] text-lg text-primary before:absolute before:top-[27px]
            before:h-[3px] before:w-full before:rounded-t-lg before:bg-primary"
          >
            {t('hubspotStatus')}
          </p>
          <p className="text-[8px] font-[500] text-red">
            {t('pleaseInsertHubspotCardsIntoCategories')}
          </p>
        </div>
      </div>
      {hubspotPipelineId && (
        <DealSettingsContent
          pipelineStagesData={pipelineStagesData?.data?.data}
          refetchAll={refetchPipelineStagesAndPipelineStagesMapped}
          isFetchingPipelineStages={isFetchingPipelineStages}
          isErrorPipelineStages={isErrorPipelineStages}
          pipelineStagesMappedData={pipelineStagesMappedData?.data?.data}
          isFetchingPipelineStagesMapped={isFetchingPipelineStagesMapped}
          isErrorPipelineStagesMapped={isErrorPipelineStagesMapped}
          pipelineId={hubspotPipelineId}
        />
      )}
      {!hubspotPipelineId && (
        <p className="mt-6 text-primary">
          {t('pleaseSelectAHubspotPipeline')}
        </p>
      )}
    </div>
  );
};
export default DealSettings;
