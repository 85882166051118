import { create } from 'zustand';

interface UsersInfoState {
  userInitials: string,
  setUserInitials: (_newUserInitials: string) => void,
}

interface ToggledTableColumnsState {
  isTableColumnsToggled: boolean,
  setIsTableColumnsToggled: (_newIsTableColumnsToggled: boolean) => void,
}

export const useUserInfoStore = create<UsersInfoState>((set) => ({
  userInitials: localStorage.getItem('userInitials') || '',
  setUserInitials: (newUserInitials: string) => {
    localStorage.setItem('userInitials', newUserInitials);
    set(() => ({
      userInitials: newUserInitials,
    }));
  },
}));

export const useToggledTableColumnsStore = create<ToggledTableColumnsState>((set) => ({
  isTableColumnsToggled: false,
  setIsTableColumnsToggled: (newIsTableColumnsToggled: boolean) => set(() => ({
    isTableColumnsToggled: newIsTableColumnsToggled,
  })),
}));