import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikValues, useFormik } from 'formik';
import TextInput from '../../../../components/inputs/textInput';
import { CustomButton } from '../../../../components/buttons/customButton';
import { useCreateCustomField } from '../../../../apiHooks/mutations/customFieldsMutations/customFieldsMutations';
import { FieldTypeEnum } from '../../../../components/enums/fieldTypeEnum';
import { customFieldsValidationsSchema } from '../../../../validations/customFieldsValidationsSchema';
import {
  useCreateHubspotContactSettingsContentCard,
} from '../../../../apiHooks/mutations/hubspotFormMutations/hubspotFormMutations';
import {
  HubspotContactSettingsContentFormValidationSchema
} from '../../../../validations/hubspotContactSettingsFormValidationSchema';

interface HubspotContactSettingsContentFormProps {
  status: boolean;
  refetch: () => void;
}

const HubspotContactSettingsContentForm: FC<HubspotContactSettingsContentFormProps> = (props) => {
  const { t } = useTranslation();

  const {
    mutateAsync: createCard,
    isLoading: isCreatingCard,
  } = useCreateHubspotContactSettingsContentCard(props.status);

  const formik: FormikValues = useFormik({
    initialValues: {
      label: '',
    },
    validationSchema: () => HubspotContactSettingsContentFormValidationSchema(t),
    onSubmit: async (values) => {
      await createCard(values.label);
      props.refetch();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="flex items-center justify-between gap-4">
      <TextInput
        name="label"
        value={formik.values.label}
        label={t('label')}
        placeholder="label"
        size="small"
        fullWidth
        onChange={formik.handleChange}
      />
      <div>
        <CustomButton type="submit" className="px-6 py-2.5" disabled={isCreatingCard}>
          {t('add')}
        </CustomButton>
      </div>
    </form>
  );
};

export default HubspotContactSettingsContentForm;
