import React from 'react';
import TrackingSourcesTable from '../../../components/tables/trackingSourcesTable';
import InfoboxContainer from '../../../components/infoBox/InfoboxContainer';

const TrackingSources = () => (
  <>
    <InfoboxContainer
      groupingType="trackingSources"
    />
    <TrackingSourcesTable />
  </>
);

export default TrackingSources;
