import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js';
import DashboardBigCard from '../dashboardCards/dashboardBigCard';
import { useGetMonthlyAdHistogram } from '../../../apiHooks/queries/graphs/useGetMonthlyAdHistogram';
import { MONTHLY_AD_HISTOGRAM } from '../../../constants/pageIdentifiers';
import { useDashboardFilterDateStore } from '../../../store/dateRangeStore';
import { TrackingSourceInterface } from '../../../interfaces/trackingSource.interface';
import { formatNumber } from '../../../constants/constants';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const colors = ['#6D919E', '#94BFA7', '#E98985', '#FFC577'];

interface MonthlyAdHistogramData {
  data: {
    labels: string[];
    dataSets: Record<string, number[]>;
  };
}

interface Visibility {
  cost: boolean;
  earned: boolean;
  lostIncome: boolean;
  potentialIncome: boolean;
}

const buildData = (
  monthlyAdHistogramData: MonthlyAdHistogramData | undefined,
  t: (key: string) => string,
  visibility: Visibility,
) => ({
  labels: monthlyAdHistogramData?.data?.labels.map((label: string) => label.slice(0, 3)) || [],
  datasets: Object.entries(monthlyAdHistogramData?.data?.dataSets || {}).map(([key, value], index) => ({
    label: t(key),
    data: value.map((v: number) => (visibility[key as keyof Visibility] ? v : 0)),
    fill: true,
    backgroundColor: colors[index % colors.length],
    borderRadius: 5,
    borderWidth: 1,
  })),
});

interface MonthlyAdHistogramProps {
  selectedTracking : TrackingSourceInterface | null;
}

const MonthlyAdHistogram: FC<MonthlyAdHistogramProps> = (props) => {
  const { t } = useTranslation();
  const { dashboardFilterDateRange } = useDashboardFilterDateStore();
  const { data: monthlyAdHistogramData, isError, isLoading } = useGetMonthlyAdHistogram(
    MONTHLY_AD_HISTOGRAM,
    props.selectedTracking?.id ?? null,
    dashboardFilterDateRange[0],
    dashboardFilterDateRange[1],
  );

  const options: ChartOptions<'bar'> = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: 'Poppins',
          },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          display: true,
          callback(value) {
            return formatNumber(Number(value));
          },
          font: {
            family: 'Poppins',
          },
        },
        border: {
          display: false,
          dash: [2, 2],
          color: '#e0e0e0',
        },
      },
    },
    layout: {
      padding: {
        top: 20,
        left: 20,
        right: 20,
        bottom: 20,
      },
    },
  };

  const initialVisibility: Visibility = {
    cost: true,
    earned: true,
    lostIncome: true,
    potentialIncome: true,
  };

  const [visibility, setVisibility] = useState<Visibility>(initialVisibility);
  const toggleDataset = (datasetLabel: keyof Visibility) => {
    setVisibility((prev) => ({
      ...prev,
      [datasetLabel]: !prev[datasetLabel],
    }));
  };

  return (
    <DashboardBigCard title="Monthly Ad Performance" isLoading={isLoading} isError={isError}>
      {monthlyAdHistogramData?.data?.dataSets && !isError && !isLoading && (
        <div className="mb-4 h-full max-h-[400px] w-full">
          <div className="flex w-full min-w-[160px] justify-center gap-2 pb-4">
            {Object.keys(initialVisibility).map((key) => (
              <button
                key={key}
                onClick={() => toggleDataset(key as keyof Visibility)}
                className={`flex h-[35px] w-full min-w-[130px] items-center gap-2 rounded-xl border py-2 pl-4 pr-2 text-[10px] outline-none focus:outline-none ${
                  visibility[key as keyof Visibility] ? 'bg-white text-primary' : 'text-black'
                }`}
              >
                <div
                  className="h-2 w-2 rounded-full"
                  style={{ backgroundColor: visibility[key as keyof Visibility] ? colors[Object.keys(initialVisibility).indexOf(key) % colors.length] : 'gray' }}
                />
                {t(key)}
              </button>
            ))}
          </div>
          <div className="flex h-full w-full justify-center">
            <Bar options={options} data={buildData(monthlyAdHistogramData, t, visibility)} />
          </div>
        </div>
      )}
    </DashboardBigCard>
  );
};

export default MonthlyAdHistogram;
