import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NavbarWithSubMenu from './navbarWithSubMenu';
import BasicNavbar from './basicNavbar';
import Header from '../header/header';
import DigtectiveLogo from '../../assets/images/logos/digtective-logo_primary.svg';
import DigtectiveLogoSmall from '../../assets/images/logos/digtective-logo-small_primary.svg';
import { Drawer, DrawerHeader } from './sidebarMixins';
import LoadingSpinner from '../loadingSpinner/loadingSpinner';
import useGetOrganizationSettings from '../../apiHooks/queries/useGetOrganizations';
import { ORGANIZATION_SETTINGS } from '../../constants/pageIdentifiers';
import DashboardIconPrimary from '../../assets/icons/sidebar/dashboard-icon-primary.svg';
import DashboardIconWhite from '../../assets/icons/sidebar/dashboard-icon-white.svg';
import AdministrationIconPrimary from '../../assets/icons/sidebar/administration-icon-primary.svg';
import AdministrationIconWhite from '../../assets/icons/sidebar/administration-icon-white.svg';
import DevelopersIconPrimary from '../../assets/icons/sidebar/developers-icon-primary.svg';
import DevelopersIconWhite from '../../assets/icons/sidebar/developers-icon-white.svg';
import AdManagementIconPrimary from '../../assets/icons/sidebar/ad-management-icon-primary.svg';
import AdManagementIconWhite from '../../assets/icons/sidebar/ad-management-icon-white.svg';
import SettingsIconPrimary from '../../assets/icons/sidebar/settings-icon-primary.svg';
import SettingsIconWhite from '../../assets/icons/sidebar/settings-icon-white.svg';
import UsersIconWhite from '../../assets/icons/sidebar/submenu/users-icon-white.svg';
import ConsoleIconWhite from '../../assets/icons/sidebar/submenu/console-icon-white.svg';
import APIDocsIconWhite from '../../assets/icons/sidebar/submenu/api-docs-icon-white.svg';
import AdsIconWhite from '../../assets/icons/sidebar/submenu/ads-icon-white.svg';
import IntegrationsListIconWhite from '../../assets/icons/sidebar/submenu/integrations-list-icon-white.svg';
import PaymentProviderIconWhite from '../../assets/icons/sidebar/submenu/payment-provider-icon-white.svg';
import AdGroupsIconWhite from '../../assets/icons/sidebar/submenu/ad-groups-white.svg';
import CampaignsIconWhite from '../../assets/icons/sidebar/submenu/campaigns-icon-white.svg';
import IntegrationsIconPrimary from '../../assets/icons/sidebar/integrations-icon-primary.svg';
import IntegrationsIconWhite from '../../assets/icons/sidebar/integrations-icon-white.svg';
import SubscriptionIconWhite from '../../assets/icons/sidebar/subscription-icon-white.svg';
import CustomFieldsIconWhite from '../../assets/icons/sidebar/submenu/custom-fields-icon-white.svg';
import OrgSettingsIconWhite from '../../assets/icons/sidebar/submenu/org-settings-icon-white.svg';
import EcommerceIcon from '../../assets/icons/sidebar/e-commerce-icon.svg';
import GoogleConversionsIcon from '../../assets/icons/googleConversionIcon.svg';
import TrackingCodeIcon from '../../assets/icons/sidebar/tackingCodeIconSidebar.svg';
import TrackingIconWhite from '../../assets/icons/sidebar/trackingIconSidebar.svg';
import TrackingIcon from '../../assets/icons/sidebar/tackingIconSidebarPrimary.svg';
import TrackingSourceNewIcon from '../../assets/icons/sidebar/newTrackingSourceIcon.svg';
import SidebarQuestionMark from '../../assets/icons/sidebar/sidebar_questionmark.svg';
import { useOrganizationSettingsStore } from '../../store/organizationSettingsStore';
import { useSidebarStore } from '../../store/sidebarStore';
import { OrganizationSettingsInterface } from '../../interfaces/organizationSettings.interface';
import { DiggerPlanEnum } from '../enums/DiggerPlanEnum';
import Breadcrumb from '../breadcrumb/breadcrumb';
import ConfirmDialog from '../../dialogs/confirmDialog';
import { useSendAcceptManage } from '../../apiHooks/mutations/acceptManagement/sendAcceptManagment';
import { useSendDeclineManage } from '../../apiHooks/mutations/declineManagement/sendDeclineManagment';
import { ManagerialStatus } from '../../pages/administration/organizationSettings/organizationSettingsPanels/managerialAccountPanel';
import AccountMenu from '../accountMenu/accountMenu';
import { useSwitchOrganizations } from '../../apiHooks/mutations/switchOrganizations/useSwitchOrganizations';
import useGetManagedOrganizationsNames
  from '../../apiHooks/queries/organizationManagedNames/getOrganizationsManagedNames';
import OrganizationDropdownButton from '../organizationDropdown/organizationDropdown';
import OrganizationDropdownList from '../organizationDropdownList/organizationDropdownList';

const Sidebar = () => {
  const isSidebarOpen = useSidebarStore((state) => state.isSidebarOpen);
  const setIsSidebarOpen = useSidebarStore((state) => state.setIsSidebarOpen);
  const [openedSubMenu, setOpenedSubMenu] = useState('');
  const organizationSettings = useOrganizationSettingsStore((state) => state.organizationSettings);
  const setOrganizationSettings = useOrganizationSettingsStore((state) => state.setOrganizationSettings);
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [urlToken, setUrlToken] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);
  const { data: organizations } = useGetManagedOrganizationsNames('MANAGED_ORGANIZATIONS_NAMES');
  const { mutate: switchOrganization } = useSwitchOrganizations();

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const { mutate: sendAccept } = useSendAcceptManage();
  const { mutate: sendDecline } = useSendDeclineManage();

  const closeAndDisconnect = () => {
    sendDecline(urlToken);
    setOpenModal(false);
  };

  const closeAndAccept = () => {
    sendAccept(urlToken);
    setOpenModal(false);
  };

  const onOrganizationSettingsRetrieved = (organizationSettingsRetrieved: OrganizationSettingsInterface) => {
    setOrganizationSettings(organizationSettingsRetrieved);
    if (organizationSettingsRetrieved.manageToken) { setUrlToken(organizationSettingsRetrieved.manageToken); setOpenModal(true); }
  };

  const {
    isLoading: isLoadingOrganizationSettings,
    isError: isErrorOrganizationSettings,
  } = useGetOrganizationSettings(ORGANIZATION_SETTINGS, onOrganizationSettingsRetrieved);

  const handleSubMenuClick = (href: string) => {
    if (openedSubMenu !== href) {
      setOpenedSubMenu(href);
    } else {
      setOpenedSubMenu('');
    }
  };

  const toggleDrawerOpen = () => {
    setIsSidebarOpen(!isSidebarOpen);
    if (isSidebarOpen) {
      setOpenedSubMenu('');
    }
  };

  const closeDropdown = (e: MouseEvent) => {
    if (
      buttonRef.current && buttonRef.current.contains(e.target as Node)
        || dropdownRef.current && dropdownRef.current.contains(e.target as Node)
    ) {
      return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', closeDropdown);
    } else {
      document.removeEventListener('mousedown', closeDropdown);
    }

    return () => {
      document.removeEventListener('mousedown', closeDropdown);
    };
  }, [isOpen]);

  if (isLoadingOrganizationSettings) (<LoadingSpinner center color="primary" />);
  if (isErrorOrganizationSettings) (<div>{t('somethingWentWrong')}</div>);

  const navigationList = [
    {
      label: 'dashboard',
      iconPrimary: DashboardIconPrimary,
      iconSecondary: DashboardIconWhite,
      href: '/dashboard',
    }, {
      label: 'administration',
      iconPrimary: AdministrationIconPrimary,
      iconSecondary: AdministrationIconWhite,
      href: '/administration',
      isOpen: openedSubMenu === '/administration',
      subList: [{
        label: 'users',
        iconWhite: UsersIconWhite,
        href: 'users',
      }],
    }, {
      label: 'adManagement',
      iconPrimary: AdManagementIconPrimary,
      iconSecondary: AdManagementIconWhite,
      href: '/ad-management',
      isOpen: openedSubMenu === '/ad-management',
      subList: [{
        label: 'ads',
        iconWhite: AdsIconWhite,
        href: 'ads',
      }, {
        label: 'adGroups',
        iconWhite: AdGroupsIconWhite,
        href: 'ad-groups',
      }, {
        label: 'campaigns',
        iconWhite: CampaignsIconWhite,
        href: 'campaigns',
      }, {
        label: 'trackingSources',
        iconWhite: TrackingSourceNewIcon,
        href: 'tracking-sources',
      }],
    },
    {
      label: 'tracking',
      iconPrimary: TrackingIcon,
      iconSecondary: TrackingIconWhite,
      href: '/tracking',
      isOpen: openedSubMenu === '/tracking',
      subList: [
        {
          label: 'tracking-code',
          iconWhite: TrackingCodeIcon,
          href: 'tracking-code',
        },
        {
          label: 'conversionActions',
          iconWhite: GoogleConversionsIcon,
          href: !organizationSettings.organizationType ? 'google-conversions' : 'google-conversions/conversions',
        },
      ],
    },
    {
      label: 'integrations',
      iconPrimary: IntegrationsIconPrimary,
      iconSecondary: IntegrationsIconWhite,
      href: '/integrations',
      isOpen: openedSubMenu === '/integrations',
      subList: [{
        label: 'CRM',
        iconWhite: IntegrationsListIconWhite,
        href: 'crm/hubspot',
        isLocked:
            organizationSettings.productId && (
              organizationSettings.productId === DiggerPlanEnum.lite
                || organizationSettings.productId === DiggerPlanEnum.standard
            ),
      },
      {
        label: 'ad-providers',
        iconWhite: IntegrationsListIconWhite,
        href: 'ad-providers/google',
      },
      {
        label: 'payment-provider',
        iconWhite: PaymentProviderIconWhite,
        href: 'payment-provider/paddle',
      },
      {
        label: 'e-commerce',
        iconWhite: EcommerceIcon,
        href: 'e-commerce/shopify',
      },
      ],
    },
    {
      label: 'settings',
      iconPrimary: SettingsIconPrimary,
      iconSecondary: SettingsIconWhite,
      href: '/settings',
      isOpen: openedSubMenu === '/settings',
      subList: [
        {
          label: 'customFields',
          iconWhite: CustomFieldsIconWhite,
          href: 'custom-fields',
        },
        {
          label: 'organizationSettings',
          iconWhite: OrgSettingsIconWhite,
          href: organizationSettings.managerialStatus === ManagerialStatus.IN_ORGANIZATION ? 'organization-settings/calculations' : 'organization-settings/managerialAccount',
        },
        {
          label: 'subscription',
          iconWhite: SubscriptionIconWhite,
          href: 'subscription',
        }],
    },
    {
      label: 'developers',
      iconPrimary: DevelopersIconPrimary,
      iconSecondary: DevelopersIconWhite,
      href: '/developers',
      isOpen: openedSubMenu === '/developers',
      subList: [{
        label: 'console',
        iconWhite: ConsoleIconWhite,
        href: 'console',
      }, {
        label: 'api-docs',
        iconWhite: APIDocsIconWhite,
        href: 'api-docs',
      }],
    },
  ];

  const handleMouseEnter = () => {
    setIsSidebarOpen(true);
  };

  const handleMouseLeave = () => {
    setIsSidebarOpen(false);
    setOpenedSubMenu('');
  };

  const handleMouseEnterButton = () => {
    if (buttonRef.current) {
      buttonRef.current.style.backgroundColor = '#3d6d7e';
    }
  };

  const handleMouseLeaveButton = () => {
    if (buttonRef.current) {
      buttonRef.current.style.backgroundColor = '';
    }
  };

  return (
    <>
      <Box data-cy="sidebar" className="flex">
        <CssBaseline />
        <Header open={isSidebarOpen} />
        <Drawer
          variant="permanent"
          open={isSidebarOpen}
          className="z-[9999999999999999]"
          elevation={100}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          sx={{
            boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.00)',
          }}
        >
          <div className="flex w-full grow flex-col items-center justify-start">
            <DrawerHeader
              className="bg-primary"
              style={{
                height: 80,
                padding: 0,
              }}
            >
              <IconButton
                onClick={toggleDrawerOpen}
                disableRipple
                style={{
                  transition: '200ms',
                  marginBottom: '12px',
                  width: '230px',
                }}
              >
                {isSidebarOpen ? (
                  <img
                    src={DigtectiveLogo}
                    alt=""
                    className="max-h-[65px] w-full max-w-[220px] object-cover duration-200"
                  />
                ) : (
                  <img src={DigtectiveLogoSmall} alt="" className="max-h-[50px] object-contain duration-200" />
                )}
              </IconButton>
            </DrawerHeader>
            <List
              sx={{
                width: '100%',
              }}
            >
              {navigationList.map((item) => {
                const hasSubList = !!item.subList;
                return (
                  <div key={item.label} className="w-full text-[12px]">
                    {hasSubList ? (
                      <NavbarWithSubMenu
                        item={item}
                        setOpenedSubMenu={setOpenedSubMenu}
                        handleSubMenuClick={handleSubMenuClick}
                      />
                    ) : (
                      <BasicNavbar
                        item={item}
                        handleSubMenuClick={handleSubMenuClick}
                      />
                    )}
                  </div>
                );
              })}
            </List>
            <div className="mb-4 mt-auto flex w-full flex-col items-center justify-center">
              <div className={`mb-4 mt-2 flex ${isSidebarOpen ? 'w-[calc(100%-25px)] gap-4' : 'w-[calc(100%-15px)]'} items-center justify-center border-b border-b-white pb-4`}>
                <img className="h-4 w-4" src={SidebarQuestionMark} alt="settings" />
                {isSidebarOpen && <div className="text-xs text-white">{t('helpAndSupport')}</div>}
              </div>
              <div
                onClick={handleToggle}
                onMouseEnter={handleMouseEnterButton}
                onMouseLeave={handleMouseLeaveButton}
                className={`${isSidebarOpen ? 'justify-start rounded-lg border border-[#fff] px-2 py-1 hover:bg-[#3d6d7e]' : 'justify-center'} flex h-[54px] w-full max-w-[200px] items-center gap-2`}
              >
                {isSidebarOpen ? null : <AccountMenu />}

                {isSidebarOpen && <OrganizationDropdownButton organizations={organizations} ref={buttonRef} handleToggle={handleToggle} isOpen={isOpen} />}
              </div>
            </div>
          </div>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            backgroundColor: '#F5F5F5',
            minHeight: '100vh',
            overflowX: 'hidden',
            maxWidth: '100%',
          }}
        >
          <DrawerHeader />
          <div className="h-[calc(100%-80px)] max-w-[1920px] duration-200">
            <Breadcrumb />
            {organizationSettings && <Outlet />}
            <OrganizationDropdownList ref={dropdownRef} isOpen={isOpen} setIsOpen={setIsOpen} organizations={organizations} switchOrganization={switchOrganization} />
          </div>
        </Box>
      </Box>
      <ConfirmDialog
        variant="warning"
        onCloseDialog={() => closeAndDisconnect()}
        title="Managerial Organization"
        text="Do you want to have your account managed?"
        submitButtonText="accept"
        onSubmit={() => closeAndAccept()}
        open={openModal}
      />
    </>
  );
};

export default Sidebar;
