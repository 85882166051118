export const USERS_TABLE = 'USERS_TABLE';
export const HUBSPOT_FORMS_TABLE = 'HUBSPOT_FORMS_TABLE';
export const ADS_TABLE = 'ADS_TABLE';
export const ADS_GROUPS_TABLE = 'ADS_GROUPS_TABLE';
export const CAMPAIGNS_TABLE = 'CAMPAIGNS_TABLE';
export const TRACKING_SOURCES_TABLE = 'TRACKING_SOURCES_TABLE';
export const CAMPAIGN_OPTIONS = 'CAMPAIGN_OPTIONS';
export const TRACKING_SOURCE_OPTIONS = 'TRACKING_SOURCE_OPTIONS';
export const ORGANIZATIONS_OPTIONS = 'ORGANIZATIONS_OPTIONS';
export const HUBSPOT_PIPELINE__OPTIONS = 'HUBSPOT_PIPELINE__OPTIONS';
export const AD_GROUP_OPTIONS = 'AD_GROUP_OPTIONS';
export const SELECTED_CAMPAIGN_OPTION = 'SELECTED_CAMPAIGN_OPTION';
export const SELECTED_TRACKING_SOURCE_OPTION = 'SELECTED_TRACKING_SOURCE_OPTION';
export const SELECTED_AD_GROUP_OPTION = 'SELECTED_AD_GROUP_OPTION';
export const CUSTOM_FIELDS = 'CUSTOM_FIELDS';
export const PAGE_VIEW_CUSTOM_FIELDS = 'PAGE_VIEW_CUSTOM_FIELDS';
export const LOST_INCOME = 'LOST_INCOME';
export const POTENTIAL_INCOME = 'POTENTIAL_INCOME';
export const EARNED_INCOME = 'EARNED_INCOME';
export const ORGANIZATION_SETTINGS = 'ORGANIZATION_SETTINGS';
export const USER_SETTINGS = 'USER_SETTINGS';
export const TOP_ADS_THAT_EARN_MONEY = 'TOP_ADS_THAT_EARN_MONEY';
export const TOP_ADS_THAT_LOSE_MONEY = 'TOP_ADS_THAT_LOSE_MONEY';
export const TOP_ADS_THAT_HAVE_POTENTIAL = 'TOP_ADS_THAT_HAVE_POTENTIAL';
export const TOP_ADS_PROFITABLE = 'TOP_ADS_PROFITABLE';
export const MONTHLY_AD_HISTOGRAM = 'MONTHLY_AD_HISTOGRAM';
export const WASTED_AD_SPEND = 'WASTED_AD_SPEND';
export const TOTAL_REVENUE = 'TOTAL_REVENUE';
export const FORM_SUBMISSIONS = 'FORM_SUBMISSIONS';
export const DASHBOARD_LAYOUT = 'DASHBOARD_LAYOUT';
export const TOTAL_AD_COST = 'TOTAL_AD_COST';
export const AD_TOTALS = 'AD_TOTALS';
export const AD_DETAILS = 'AD_DETAILS';
export const AD_TIME_TO_INCOME = 'AD_TIME_TO_INCOME';
export const AD_INCOME_INSIGHT = 'AD_INCOME_INSIGHT';
export const AD_DEVICE_DISTRIBUTION = 'AD_DEVICE_DISTRIBUTION';
export const LONG_LASTING_TOKEN = 'LONG_LASTING_TOKEN';
export const LATEST_FORM_SUBMISSIONS = 'LATEST_FORM_SUBMISSIONS';
export const LATEST_FS_UPDATES = 'LATEST_FS_UPDATES';
export const GROUPED_AD_HISTOGRAM = 'GROUPED_AD_HISTOGRAM';
export const REFERRER_REPORT = 'REFERRER_REPORT';
export const SEO_REPORT = 'SEO_REPORT';
export const GET_PROFILE_NAME = 'GET_PROFILE_NAME';
export const GET_QR_CODE = 'GET_QR_CODE';
export const PIPELINE_STAGES = 'PIPELINE_STAGES';
export const PIPELINE_STAGES_MAPPED = 'PIPELINE_STAGES_MAPPED';
export const UNSUBSCRIBE_URL = 'UNSUBSCRIBE_URL';
export const GET_UNTRACKED_CODES = 'GET_UNTRACKED_CODES';
export const GET_PROVIDER_DETAILS = 'GET_PROVIDER_DETAILS';
