import React, { FC } from 'react';
import ReportingOrganizationSettingsForm
  from '../../../../forms/organizationSettingsForms/reportingOrganizationSettingsForm';
import { useOrganizationSettingsStore } from '../../../../store/organizationSettingsStore';

const ReportingPanel: FC = () => {
  const { organizationSettings } = useOrganizationSettingsStore();
  const { setOrganizationSettings } = useOrganizationSettingsStore();
  return (
    <ReportingOrganizationSettingsForm
      organizationSettings={organizationSettings}
      setOrganizationSettings={setOrganizationSettings}
    />
  );
};

export default ReportingPanel;
