import React, { FC, SyntheticEvent, useState } from 'react';
import { Tabs } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { TabInterface } from '../../interfaces/simpleTabs.interface';
import { Colors } from '../../constants/constants';
import VerticalLinkTab from './verticalLinkTab';

interface SimpleTabProps {
  tabs: TabInterface[],
  currentTab: number,
}

const VerticalTabs: FC<SimpleTabProps> = (props) => {
  const [activePanel, setActivePanel] = useState<number>(props.currentTab);

  const onPanelChange = (_e: SyntheticEvent, value: any) => {
    setActivePanel(value);
  };

  return (
    <div className="flex w-full">
      <div className="h-[calc(100%+25px)] w-full min-w-[160px] max-w-[160px] bg-green/10 px-2.5 py-7">
        <Tabs
          value={activePanel}
          onChange={onPanelChange}
          aria-label="vertical basic tabs"
          orientation="vertical"
          TabIndicatorProps={{
            style: {
              backgroundColor: Colors.green,
              borderTopLeftRadius: 25,
              borderTopRightRadius: 25,
              width: '100%',
              height: 2,
              display: 'inline-block',
              marginTop: 24,
            },
          }}
        >
          {props.tabs.map((tab, index) => (
            <VerticalLinkTab
              key={index}
              label={tab.tabLabel.toUpperCase()}
              id={`tab.identifierName-vertical-${index}`}
              href={tab.href}
              icon={tab.icon ? <img src={tab.icon} alt="" className="h-4 object-contain" /> : undefined}
            />
          ))}
        </Tabs>
      </div>
      <div className="h-full w-full overflow-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default VerticalTabs;
