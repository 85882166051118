import { Button, Grid } from '@mui/material';
import React from 'react';
import { FormikValues, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import TextInput from '../components/inputs/textInput';
import { AdInterface } from '../interfaces/ads.interface';
import { useUpdateAd } from '../apiHooks/mutations/adManagementMutations/adsMutations';
import { adsFormValidationSchema } from '../validations/adsFormValidationSchema';
import AdGroupSelect from '../components/dropdownSelects/adGroupSelect';
import SecondaryButton from '../components/buttons/secondaryButton';
import PrimaryButton from '../components/buttons/primaryButton';
import LoadingSpinner from '../components/loadingSpinner/loadingSpinner';

interface AdsFormInterface {
  data?: AdInterface;
  closeDialog: () => void;
}

const AdsForm: React.FC<AdsFormInterface> = (props) => {
  const { mutateAsync: updateAd, isLoading: isUpdatingAd } = useUpdateAd();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const formik: FormikValues = useFormik({
    initialValues: {
      id: props.data?.id,
      name: props.data?.name || '',
      code: props.data?.code || '',
      description: props.data?.description || '',
      adGroupId: searchParams.get('adGroupId') || props.data?.adGroup?.id || '',
    },
    validationSchema: () => adsFormValidationSchema(t),
    onSubmit: async (values: any) => {
      await updateAd(values);

      props.closeDialog();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        sx={{
          display: 'flex', columnGap: '3rem', rowGap: '2.5rem', paddingBlock: '1rem',
        }}
      >
        <Grid item xs={12} sm={5}>
          <TextInput
            myKey="name"
            name="name"
            value={formik.values.name}
            label={t('name')}
            placeholder={t('name')}
            fullWidth
            onChange={formik.handleChange}
            error={Boolean(formik.touched.name) && Boolean(formik.errors.name)}
            helperText={Boolean(formik.touched.name) && formik.errors.name}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextInput
            myKey="code"
            name="code"
            value={formik.values.code}
            label={t('code')}
            placeholder={t('code')}
            fullWidth
            disabled={!!props.data?.id}
            onChange={formik.handleChange}
            error={Boolean(formik.touched.code) && Boolean(formik.errors.code)}
            helperText={Boolean(formik.touched.code) && formik.errors.code}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextInput
            myKey="description"
            name="description"
            value={formik.values.description}
            label={t('description')}
            placeholder={t('description')}
            fullWidth
            onChange={formik.handleChange}
            error={Boolean(formik.touched.description) && Boolean(formik.errors.description)}
            helperText={Boolean(formik.touched.description) && formik.errors.description}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <AdGroupSelect
            identifier={`form-ad-group-select-${formik.values.adGroupId}`}
            selectedAdGroupId={formik.values.adGroupId}
            formik={formik}
          />
        </Grid>
      </Grid>
      <div className="ml-auto mt-5 flex w-fit gap-3">
        <SecondaryButton type="button" onClick={props.closeDialog} dataCy="ads-editor-cancel">
          {t('cancel')}
        </SecondaryButton>
        {isUpdatingAd
          ? (
            <div className="flex h-[43px] w-[102px] items-center justify-center">
              <LoadingSpinner size={25} variant="primary" color="primary" />
            </div>
          )
          : (
            <PrimaryButton type="submit" disabled={isUpdatingAd} dataCy="ads-editor-submit">
              {t('submit')}
            </PrimaryButton>
          )}

      </div>
    </form>
  );
};

export default AdsForm;
