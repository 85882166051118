import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Navigate, useSearchParams } from 'react-router-dom';
import PasswordInput from '../../components/inputs/passwordInput';
import { WelcomeSignUpRequest } from '../../types/api/auth';
import TextInput from '../../components/inputs/textInput';
import { welcomeSignUpValidationSchema } from '../../validations/welcomeSignUpValidationSchema';
import useMutateWelcomeSignUp from '../../apiHooks/mutations/authMutations/welcomeSignUpMutations';
import closableEnqueueSnackbar from '../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';
import { CustomButton } from '../../components/buttons/customButton';

const WelcomeForm = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  if (!searchParams.get('token')) {
    closableEnqueueSnackbar(t('theLinkProvidedWasInvalid'), 'error');
    return (
      <Navigate to="../" />
    );
  }

  const {
    mutate: sendMutateSignUp,
    isLoading,
  } = useMutateWelcomeSignUp(searchParams.get('token') || '');

  const formik = useFormik<WelcomeSignUpRequest>({
    initialValues: {
      firstName: '',
      lastName: '',
      orgName: '',
      companyNumber: '',
      taxIdentifier: '',
      country: '',
      region: '',
      street: '',
      zipCode: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: () => welcomeSignUpValidationSchema(t),
    onSubmit: (values) => {
      sendMutateSignUp(values);
    },
  });

  return (
    <div className="flex justify-center">
      <form
        onSubmit={formik.handleSubmit}
        className={`
        ${Object.keys(formik.errors).length > 3 && Object.keys(formik.touched).length > 3 ? 'mt-24' : 'mt-4'}
        flex max-h-[400px] w-full flex-1 flex-col items-center justify-center gap-3
      `}
      >
        <div className="flex gap-2">
          <TextInput
            id="firstName"
            name="firstName"
            value={formik.values.firstName}
            label={t('firstName')}
            placeholder={t('firstName')}
            onChange={formik.handleChange}
            size="small"
            error={Boolean(formik.touched.firstName) && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : ''}
            fullWidth
          />
          <TextInput
            id="lastName"
            name="lastName"
            value={formik.values.lastName}
            label={t('lastName')}
            placeholder={t('lastName')}
            onChange={formik.handleChange}
            size="small"
            error={Boolean(formik.touched.lastName) && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : ''}
            fullWidth
          />
        </div>
        <TextInput
          id="orgName"
          name="orgName"
          value={formik.values.orgName}
          label={t('orgName')}
          placeholder={t('orgName')}
          onChange={formik.handleChange}
          size="small"
          error={Boolean(formik.touched.orgName) && Boolean(formik.errors.orgName)}
          helperText={formik.touched.orgName && formik.errors.orgName ? formik.errors.orgName : ''}
          fullWidth
        />
        <div className="flex gap-2 ">
          <TextInput
            id="companyNumber"
            name="companyNumber"
            value={formik.values.companyNumber}
            label={t('companyNumber')}
            placeholder={t('companyNumber')}
            onChange={formik.handleChange}
            size="small"
            error={Boolean(formik.touched.companyNumber) && Boolean(formik.errors.companyNumber)}
            helperText={formik.touched.companyNumber && formik.errors.companyNumber ? formik.errors.companyNumber : ''}
            fullWidth
          />
          <TextInput
            id="taxIdentifier"
            name="taxIdentifier"
            value={formik.values.taxIdentifier}
            label={t('taxIdentifier')}
            placeholder={t('taxIdentifier')}
            onChange={formik.handleChange}
            size="small"
            error={Boolean(formik.touched.taxIdentifier) && Boolean(formik.errors.taxIdentifier)}
            helperText={formik.touched.taxIdentifier && formik.errors.taxIdentifier ? formik.errors.taxIdentifier : ''}
            fullWidth
          />
        </div>
        <div className="flex gap-2">
          <TextInput
            id="country"
            name="country"
            value={formik.values.country}
            label={t('country')}
            placeholder={t('country')}
            onChange={formik.handleChange}
            size="small"
            error={Boolean(formik.touched.country) && Boolean(formik.errors.country)}
            helperText={formik.touched.country && formik.errors.country ? formik.errors.country : ''}
            fullWidth
          />
          <TextInput
            id="region"
            name="region"
            value={formik.values.region}
            label={t('region')}
            placeholder={t('region')}
            onChange={formik.handleChange}
            size="small"
            error={Boolean(formik.touched.region) && Boolean(formik.errors.region)}
            helperText={formik.touched.region && formik.errors.region ? formik.errors.region : ''}
            fullWidth
          />
        </div>
        <div className="flex gap-2">
          <TextInput
            id="street"
            name="street"
            value={formik.values.street}
            label={t('street')}
            placeholder={t('street')}
            onChange={formik.handleChange}
            size="small"
            error={Boolean(formik.touched.street) && Boolean(formik.errors.street)}
            helperText={formik.touched.street && formik.errors.street ? formik.errors.street : ''}
            fullWidth
          />
          <TextInput
            id="zipCode"
            name="zipCode"
            value={formik.values.zipCode}
            label={t('zipCode')}
            placeholder={t('zipCode')}
            onChange={formik.handleChange}
            size="small"
            error={Boolean(formik.touched.zipCode) && Boolean(formik.errors.zipCode)}
            helperText={formik.touched.zipCode && formik.errors.zipCode ? formik.errors.zipCode : ''}
            fullWidth
          />
        </div>
        <PasswordInput
          id="password"
          myKey="password"
          name="password"
          label={t('password')}
          placeholder={t('password')}
          size="small"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={Boolean(formik.touched.password) && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password ? formik.errors.password : ''}
          fullWidth
          isVisibilityToggleable
          dataCy="login-password"
        />
        <PasswordInput
          id="confirmPassword"
          myKey="confirmPassword"
          name="confirmPassword"
          label={t('confirmPassword')}
          placeholder={t('confirmPassword')}
          size="small"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          error={Boolean(formik.touched.confirmPassword) && Boolean(formik.errors.confirmPassword)}
          helperText={formik.touched.confirmPassword && formik.errors.confirmPassword ? formik.errors.confirmPassword : ''}
          fullWidth
          isVisibilityToggleable
          dataCy="login-password"
        />
        <CustomButton
          disabled={isLoading}
          type="submit"
          className="grid w-full place-items-center py-3"
        >
          {t('signup')
            .toUpperCase()}
        </CustomButton>
      </form>
    </div>
  );
};

export default WelcomeForm;
