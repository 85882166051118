import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import Checkbox from '../checkbox/Checkbox';
import { useConversionStepsStore, useOtherConversionStore } from '../../store/conversionStepsStore';
import closableEnqueueSnackbar from '../closableEnqueueSnackbar/closableEnqueueSnackbar';
import { useSendOtherConversion } from '../../apiHooks/mutations/sendOtherConversion/sendOtherConversion';

interface CheckboxState {
  eur: boolean;
  nok: boolean;
  usd: boolean;
  gbp: boolean;
  sek: boolean;
}

const ConversionValue = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentStep, setCurrentStep } = useConversionStepsStore((state) => state);
  const { data, updateValue, updateCurrency } = useOtherConversionStore((state) => state);
  const { mutate: sendConversionInfo } = useSendOtherConversion();
  const [checkboxes, setCheckboxes] = useState<CheckboxState>({
    eur: false,
    nok: false,
    usd: false,
    gbp: false,
    sek: false,
  });

  const handleCheckboxChange = (clickedCheckbox: string) => {
    updateCurrency(clickedCheckbox.toUpperCase());
    setCheckboxes((prevCheckboxes) => {
      const updatedCheckboxes: CheckboxState = { ...prevCheckboxes };

      for (const checkbox in updatedCheckboxes) {
        updatedCheckboxes[checkbox as keyof CheckboxState] = checkbox === clickedCheckbox;
      }

      return updatedCheckboxes;
    });
  };

  const formik = useFormik({
    initialValues: {
      value: '',
    },
    validationSchema: Yup.object({
      value: Yup.number().required('Value is required').min(0, 'Value must be a positive number'),
    }),
    onSubmit: (values) => {
      if (Object.values(checkboxes).some((value) => value)) {
        updateValue(parseInt(values.value, 10));
        sendConversionInfo(data);
        setCurrentStep(currentStep + 1);
        navigate('/tracking/google-conversions/other-conversions/configured', { replace: true });
      } else {
        closableEnqueueSnackbar('Please select one of the checkboxes', 'error');
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-4 flex h-[80px] w-full flex-col justify-around border-b">
        <p className="text-lg font-semibold text-primary">Value</p>
        <p className="text-xs font-medium text-[#9EB6BF]">Measure the impact of your advertising by giving conversions a value</p>
      </div>
      <div className="flex items-start gap-4">
        <div className="h-[110px] rounded-lg border p-4">
          <p className="my-2.5 text-xs text-[#9EB6BF]">Currencies</p>
          <div className="flex items-center gap-3">
            {Object.entries(checkboxes).map(([currency, isChecked]) => (
              <label
                key={currency}
                className="mb-2 flex items-center gap-2 text-base font-normal text-primary hover:cursor-pointer"
              >
                <Checkbox
                  id={currency}
                  defaultChecked={isChecked}
                  onChange={() => handleCheckboxChange(currency)}
                />
                {currency.toUpperCase()}
              </label>
            ))}
          </div>
        </div>
        <div>
          <div className="flex h-[110px] w-[420px] flex-col justify-center rounded-lg border p-3">
            <p className="mb-3 text-xs text-[#9EB6BF]">Value</p>
            <input
              type="text"
              id="value"
              name="value"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.value}
              placeholder="0000"
              className={`rounded-lg border ${
                formik.touched.value && formik.errors.value ? 'border-red-500' : 'border-primary'
              } p-2 text-sm text-primary outline-none`}
            />
          </div>
          {formik.touched.value && formik.errors.value ? (
            <div className="ml-3 mt-1 w-[420px] text-xs text-red">{formik.errors.value}</div>
          ) : null}
        </div>
      </div>
      <div className="flex w-full justify-end">
        <button
          type="submit"
          className="mt-4 rounded bg-primary px-4 py-2 text-white"
        >
          Continue
        </button>
      </div>
    </form>
  );
};

export default ConversionValue;
