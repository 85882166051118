import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../axios/customAxios';
import closableEnqueueSnackbar from '../../../components/closableEnqueueSnackbar/closableEnqueueSnackbar';
import { handleApiError } from '../../requestHelpers/requestHelpers';
import { ApiError, ApiResponse } from '../../../interfaces/apiResponse.type';

export const useSendConversionOrganization = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return useMutation<ApiResponse<null>, ApiError<null>, string>(
    (postRequest) => dataFetch.post(
      `${DIGGER_API_SUFFIX}google/organization-type`,
      {
        organizationType: postRequest,
      },
    ),
    {
      onSuccess: () => {
        navigate(
          '/settings/google-conversions/conversions',
          { replace: true },
        );
      },
      onError: (error: any) => {
        handleApiError(error, t);
      },
    },
  );
};
