import React, { useState } from 'react';
import ReportCard from '../../components/reportCard/ReportCard';
import leadValue from '../../assets/icons/leadValue_icon.svg';
import totalAdSpend from '../../assets/icons/totalAdSpend_icon.svg';
import reportedIncome from '../../assets/icons/reportedIncome_icon.svg';
import ProfitableAds from '../../components/dashboard/dashboardComponents/profitableAds';
import SeoReport from '../../components/seoReport/seoReport';
import MonthlyAdHistogram from '../../components/dashboard/dashboardComponents/monthlyAdHistogram';
import ReferrerReport from '../../components/referrerReport/referrerReport';
import TrackingSourceSelect from '../../components/dropdownSelects/trackingSourceSelect';
import { useDashboardFilterDateStore } from '../../store/dateRangeStore';
import { TrackingSourceInterface } from '../../interfaces/trackingSource.interface';
import SecondaryButton from '../../components/buttons/secondaryButton';
import { Colors, formatToTwoDecimalPlaces } from '../../constants/constants';
import CustomDateRangePicker from '../../partials/customDateRangePicker/customDateRangePicker';
import { useGetTotalAdCost } from '../../apiHooks/queries/reports/useGetTotalAdCost';
import {
  EARNED_INCOME,
  FORM_SUBMISSIONS,
  LOST_INCOME,
  POTENTIAL_INCOME,
  TOTAL_AD_COST,
  TOTAL_REVENUE,
  WASTED_AD_SPEND,
} from '../../constants/pageIdentifiers';
import { useGetIncome } from '../../apiHooks/queries/reports/useGetIncome';
import { AdStatusEnum } from '../../components/enums/adStatusEnum';
import { useGetFormSubmissionsByOrganizationId } from '../../apiHooks/queries/reports/useGetFormSubmissions';
import { useGetTotalRevenue } from '../../apiHooks/queries/reports/useGetTotalRevenue';
import { useGetWastedAdSpend } from '../../apiHooks/queries/reports/useGetWastedAdSpend';
import SalesReportCard from '../../components/salesReportCard/SalesReportCard';
import AdsThatLoseMoney from '../../components/dashboard/dashboardComponents/adsThatLoseMoney';
import ConfirmDialog from '../../dialogs/confirmDialog';
import { useDiggerShopify } from '../../hooks/useDiggerShopify';

const NewDashboard = () => {
  const { dashboardFilterDateRange } = useDashboardFilterDateStore();
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  const setDashboardFilterDateRange = useDashboardFilterDateStore((state) => state.setDashboardFilterDateRange);
  const {
    shopifyQuery, initiateShopifyConnection, removeShopifyLocalStorage, shopifyShop,
  } = useDiggerShopify();
  const [isShopifyDialogOpen, setIsShopifyDialogOpen] = useState<boolean>(!!shopifyQuery);

  const [selectedTrackingSource, setSelectedTrackingSource] = useState<TrackingSourceInterface | null>(null);

  const [filter, setFilter] = useState('all');

  const {
    data: totalAdCostData,
    isLoading: totalAdCostIsLoading,
  } = useGetTotalAdCost(
    TOTAL_AD_COST,
    dashboardFilterDateRange[0],
    dashboardFilterDateRange[1],
  );

  const {
    data: potentialIncomeData,
    isLoading: potentialIncomeDataIsLoading,
  } = useGetIncome(
    POTENTIAL_INCOME,
    AdStatusEnum.InProgress,
    dashboardFilterDateRange[0],
    dashboardFilterDateRange[1],
  );

  const {
    data: totalAdRevenueData,
    isLoading: totalAdRevenueDataIsLoading,
  } = useGetTotalRevenue(
    TOTAL_REVENUE,
    dashboardFilterDateRange[0],
    dashboardFilterDateRange[1],
  );

  const {
    data: earnedIncomeData,
    isLoading: earnedIncomeDataIsLoading,
  } = useGetIncome(
    EARNED_INCOME,
    AdStatusEnum.Won,
    dashboardFilterDateRange[0],
    dashboardFilterDateRange[1],
  );

  const {
    data: formSubmissionsData,
    isLoading: formSubmissionsDataIsLoading,
  } = useGetFormSubmissionsByOrganizationId(
    FORM_SUBMISSIONS,
    dashboardFilterDateRange[0],
    dashboardFilterDateRange[1],
  );

  const {
    data: wastedAdSpendData,
    isLoading: wastedAdSpendDataIsLoading,
  } = useGetWastedAdSpend(
    WASTED_AD_SPEND,
    dashboardFilterDateRange[0],
    dashboardFilterDateRange[1],
  );

  const {
    data: lostDealValueData,
    isLoading: lostDealValueDataIsLoading,
  } = useGetIncome(
    LOST_INCOME,
    AdStatusEnum.Lost,
    dashboardFilterDateRange[0],
    dashboardFilterDateRange[1],
  );

  const renderReportCards = () => (
    <div className="grid w-full grid-cols-4 gap-5">
      <ReportCard
        title="Lead Value"
        value={potentialIncomeData?.data?.incomeByStatus}
        isLoading={potentialIncomeDataIsLoading}
        color="border-b-[#FF9F1C]"
        icon={leadValue}
        additionalInfo={`${potentialIncomeData?.data?.thisWeeksDifference > 0
          ? '+'
          : ''}${formatToTwoDecimalPlaces(potentialIncomeData?.data?.thisWeeksDifference)} This month`}
      />
      <ReportCard
        title="Total Ad Spend"
        value={totalAdCostData?.data?.totalCost}
        isLoading={totalAdCostIsLoading}
        color="border-red"
        icon={totalAdSpend}
        additionalInfo={`${totalAdCostData?.data?.thisWeeksDifference > 0
          ? '+'
          : ''}${formatToTwoDecimalPlaces(totalAdCostData?.data?.thisWeeksDifference)} This month`}
      />
      <ReportCard
        title="Reported Income"
        value={earnedIncomeData?.data?.incomeByStatus}
        isLoading={earnedIncomeDataIsLoading}
        color="border-green"
        icon={reportedIncome}
        additionalInfo={`${earnedIncomeData?.data?.thisWeeksDifference > 0
          ? '+'
          : ''}${formatToTwoDecimalPlaces(earnedIncomeData?.data?.thisWeeksDifference)} This month`}
      />
      <SalesReportCard />
      <ReportCard
        title="Wasted Ad Spend"
        value={wastedAdSpendData?.data}
        isLoading={wastedAdSpendDataIsLoading}
        color="border-b-gray"
      />
      <ReportCard
        title="Total Ad Revenue"
        value={totalAdRevenueData?.data?.totalRevenue}
        isLoading={totalAdRevenueDataIsLoading}
        color="border-gray"
      />
      <ReportCard
        title="Lost Deal Value"
        value={lostDealValueData?.data?.incomeByStatus}
        isLoading={lostDealValueDataIsLoading}
        color="border-gray"
      />
      <ReportCard
        title="Form Submissions"
        value={formSubmissionsData?.data}
        isLoading={formSubmissionsDataIsLoading}
        color="border-gray"
      />
    </div>
  );

  return (
    <div>
      <div className="mb-6 flex items-center justify-between">
        <div className="flex w-[25%] justify-start gap-4">
          <button
            onClick={() => setFilter('all')}
            className={
              `rounded-lg px-4 py-3 text-sm ${filter === 'all'
                ? 'bg-[#ffffff] text-primary'
                : 'bg-[#EAEAEA] text-[#828282]'}`
          }
          >
            All
          </button>
          <button
            type="button"
            onClick={() => setFilter('reports')}
            className={`rounded-lg px-4 py-3 text-sm ${filter === 'reports'
              ? 'bg-[#ffffff] text-primary'
              : 'bg-[#EAEAEA] text-[#828282]'}`}
          >
            Reports
          </button>
          <button
            type="button"
            onClick={() => setFilter('tables')}
            className={`rounded-lg px-4 py-3 text-sm ${filter === 'tables'
              ? 'bg-[#ffffff] text-primary'
              : 'bg-[#EAEAEA] text-[#828282]'}`}
          >
            Tables
          </button>
          <button
            type="button"
            onClick={() => setFilter('graphs')}
            className={`rounded-lg px-4 py-3 text-sm ${filter === 'graphs'
              ? 'bg-[#ffffff] text-primary'
              : 'bg-[#EAEAEA] text-[#828282]'}`}
          >
            Graphs
          </button>
        </div>
        <div className="flex w-full items-center justify-end">
          <div className="w-[250px] rounded-xl border-none bg-white">
            <TrackingSourceSelect
              size="small"
              fullWidth
              data={selectedTrackingSource ?? undefined}
              setSelectedTrackingSource={(value) => {
                setSelectedTrackingSource(value);
              }}
            />
          </div>
          <SecondaryButton
            type="button"
            style={{
              paddingInline: '1rem',
              height: '40px',
              marginLeft: '1rem',
              paddingBlock: 0,
              backgroundColor: 'white',
              border: `1px solid ${isCalendarOpen ? Colors.primary : '#eaeaea'}`,
            }}
            onMouseDown={() => setIsCalendarOpen(true)}
            dataCy="dashboard-actions-calendar-toggle"
          >
            <CustomDateRangePicker
              filterRange={dashboardFilterDateRange}
              setFilterRange={setDashboardFilterDateRange}
              isCalendarOpen={isCalendarOpen}
              setIsCalendarOpen={setIsCalendarOpen}
              iconButton
              showFilterDates
            />
          </SecondaryButton>
        </div>
      </div>
      {filter === 'all' && (
        <>
          {renderReportCards()}
          <div className="mt-4 flex max-h-[510px] w-full max-w-[1792px] items-start justify-between gap-4">
            <div className="h-[510px] w-[calc(50%-8px)] max-w-[888px] rounded-xl bg-white">
              <MonthlyAdHistogram selectedTracking={selectedTrackingSource} />
            </div>
            <div className="h-full min-h-[499px] w-[calc(50%-8px)] min-w-[calc(50%-8px)] max-w-[888px] rounded-xl bg-white">
              <ReferrerReport />
            </div>
          </div>
          <div className="mt-4 flex max-h-[510px] w-full max-w-[1792px] items-center justify-between gap-4">
            <div className="h-[499px] w-[calc(50%-8px)] max-w-[888px] rounded-xl bg-white">
              <ProfitableAds />
            </div>
            <div className="h-[499px] w-[calc(50%-8px)] max-w-[888px] rounded-xl bg-white">
              <AdsThatLoseMoney />
            </div>
          </div>
          <div className="mt-4 flex max-h-[510px] items-center justify-between gap-4">
            <div className="h-[499px] w-full max-w-[888px] rounded-xl bg-white">
              <SeoReport />
            </div>
            <div className="h-[480px] w-full max-w-[888px]" />
          </div>
        </>
      )}
      {filter === 'graphs' && (
        <div className="mt-4 flex max-h-[499px] items-center justify-between gap-4">
          <div className="h-[510px] w-full max-w-[880px] rounded-xl bg-white">
            <MonthlyAdHistogram selectedTracking={selectedTrackingSource} />
          </div>
        </div>
      )}
      {filter === 'reports' && renderReportCards()}
      {filter === 'tables' && (
      <div>
        <div className="mt-4 flex max-h-[499px] items-center justify-between gap-4">
          <div className="h-[499px] w-[calc(50%-8px)] max-w-[888px] rounded-xl bg-white">
            <ProfitableAds />
          </div>
          <div className="h-[499px] w-[calc(50%-8px)] rounded-xl bg-white">
            <SeoReport />
          </div>

        </div>
        <div className="mt-4 flex max-h-[510px] w-full items-center justify-between gap-4">
          <div className="h-full max-h-[499px] min-h-[499px] w-[calc(50%-8px)] max-w-[888px] rounded-xl bg-white">
            <ReferrerReport />
          </div>
          <div className="h-[499px] w-[calc(50%-8px)] max-w-[888px] rounded-xl bg-white">
            <AdsThatLoseMoney />
          </div>

        </div>
      </div>
      )}
      {isShopifyDialogOpen && (
        <ConfirmDialog
          onCloseDialog={() => {
            removeShopifyLocalStorage();
            setIsShopifyDialogOpen(false);
          }}
          title={`Connect to Shopify Store ${shopifyShop}`}
          text="Are you sure you want to connect to this store"
          onSubmit={() => {
            initiateShopifyConnection();
            setIsShopifyDialogOpen(false);
          }}
          open={isShopifyDialogOpen}
        />
      )}
    </div>
  );
};

export default NewDashboard;
