import { create } from 'zustand';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';

interface DashboardFilterState {
  dashboardFilterDateRange: DateRange<Dayjs>,
  setDashboardFilterDateRange: (_newDateRange: DateRange<Dayjs>) => void,
}

interface AdManagementFilterState {
  adManagementFilterDateRange: DateRange<Dayjs>,
  setAdManagementFilterDateRange: (_newDateRange: DateRange<Dayjs>) => void,
}

export const useDashboardFilterDateStore = create<DashboardFilterState>((set) => ({
  dashboardFilterDateRange: [null, null],
  setDashboardFilterDateRange: (newDateRange) => {
    set(() => ({
      dashboardFilterDateRange: newDateRange,
    }));
  },
}));

export const useAdManagementFilterDateStore = create<AdManagementFilterState>((set) => ({
  adManagementFilterDateRange: [
    localStorage.getItem('adManagementFilterDateFrom') ? dayjs(localStorage.getItem('from')) : null,
    localStorage.getItem('adManagementFilterDateTo') ? dayjs(localStorage.getItem('to')) : null,
  ],
  setAdManagementFilterDateRange: (newDateRange) => {
    set(() => ({
      adManagementFilterDateRange: newDateRange,
    }));
  },
}));
