import { useQuery, UseQueryResult } from 'react-query';
import { dataFetch, DIGGER_API_SUFFIX } from '../../../../axios/customAxios';
import { ApiError, ApiResponse } from '../../../../interfaces/apiResponse.type';

interface IntegrationStatusResponse {
    isGoogleIntegrationActive?: boolean;
}

const useGetGoogleIntegrationStatus = (): UseQueryResult<ApiResponse<IntegrationStatusResponse>, ApiError<IntegrationStatusResponse>> => useQuery<ApiResponse<IntegrationStatusResponse>, ApiError<IntegrationStatusResponse>>(
  ['googleIntegrationStatus}'],
  () => dataFetch.get(`${DIGGER_API_SUFFIX}google-integration/status`),
);

export default useGetGoogleIntegrationStatus;
