import React from 'react';
import { TableColumnInterface } from '../interfaces/tableColumn.interface';
import DateCell from '../partials/dateCell';
import RoundedCell from '../partials/roundedCell';
import VatDisplayer from '../partials/VATDisplayer';
import PlayStopAdCell from '../partials/playStopAdCell';
import AdNameCell from '../partials/adNameCell';
import DashboardIconPrimary from '../assets/icons/sidebar/dashboard-icon-primary.svg';
import DashboardIconWhite from '../assets/icons/sidebar/dashboard-icon-white.svg';
import AdministrationIconPrimary from '../assets/icons/sidebar/administration-icon-primary.svg';
import AdministrationIconWhite from '../assets/icons/sidebar/administration-icon-white.svg';
import UsersIconWhite from '../assets/icons/sidebar/submenu/users-icon-white.svg';
import AdManagementIconPrimary from '../assets/icons/sidebar/ad-management-icon-primary.svg';
import AdManagementIconWhite from '../assets/icons/sidebar/ad-management-icon-white.svg';
import AdsIconWhite from '../assets/icons/sidebar/submenu/ads-icon-white.svg';
import AdGroupsIconWhite from '../assets/icons/sidebar/submenu/ad-groups-white.svg';
import CampaignsIconWhite from '../assets/icons/sidebar/submenu/campaigns-icon-white.svg';
import TrackingSourceNewIcon from '../assets/icons/sidebar/newTrackingSourceIcon.svg';
import TrackingIcon from '../assets/icons/sidebar/tackingIconSidebarPrimary.svg';
import TrackingIconWhite from '../assets/icons/sidebar/trackingIconSidebar.svg';
import TrackingCodeIcon from '../assets/icons/sidebar/tackingCodeIconSidebar.svg';
import GoogleConversionsIcon from '../assets/icons/googleConversionIcon.svg';
import IntegrationsIconPrimary from '../assets/icons/sidebar/integrations-icon-primary.svg';
import IntegrationsIconWhite from '../assets/icons/sidebar/integrations-icon-white.svg';
import IntegrationsListIconWhite from '../assets/icons/sidebar/submenu/integrations-list-icon-white.svg';
import PaymentProviderIconWhite from '../assets/icons/sidebar/submenu/payment-provider-icon-white.svg';
import SettingsIconPrimary from '../assets/icons/sidebar/settings-icon-primary.svg';
import SettingsIconWhite from '../assets/icons/sidebar/settings-icon-white.svg';
import CustomFieldsIconWhite from '../assets/icons/sidebar/submenu/custom-fields-icon-white.svg';
import OrgSettingsIconWhite from '../assets/icons/sidebar/submenu/org-settings-icon-white.svg';
import SubscriptionIconWhite from '../assets/icons/sidebar/subscription-icon-white.svg';
import DevelopersIconPrimary from '../assets/icons/sidebar/developers-icon-primary.svg';
import DevelopersIconWhite from '../assets/icons/sidebar/developers-icon-white.svg';
import ConsoleIconWhite from '../assets/icons/sidebar/submenu/console-icon-white.svg';
import APIDocsIconWhite from '../assets/icons/sidebar/submenu/api-docs-icon-white.svg';

export const drawerWidth = 230;

export const ROWS_PER_PAGE_OPTIONS = [5, 10, 15, 25, 50];
export const DEFAULT_ROWS_PER_PAGE = ROWS_PER_PAGE_OPTIONS[1];

export const extendedColumns: TableColumnInterface[] = [
  {
    field: 'name',
    headerName: 'name',
  },
  {
    field: 'clicks',
    headerName: 'clicks',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'sales',
    headerName: 'sales',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'formSubmissions',
    headerName: 'formSubmissions',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'ctr',
    headerName: 'ctr',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'cost',
    headerName: 'cost',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'netIncome',
    headerName: 'netIncome',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'lostIncome',
    headerName: 'lostIncome',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'earnedIncome',
    headerName: 'earnedIncome',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'potentialIncome',
    headerName: 'potentialIncome',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'impressions',
    headerName: 'impressions',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'interactions',
    headerName: 'interactions',
  },
  {
    field: 'interactionsRate',
    headerName: 'interactionsRate',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'averageCpc',
    headerName: 'averageCpc',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'createdAt',
    headerName: 'createdAt',
    cellRenderer: (params: string) => <DateCell params={params} />,
  },
];

export const eCommerceExtendedColumns: TableColumnInterface[] = [
  {
    field: 'name',
    headerName: 'name',
  },
  {
    field: 'clicks',
    headerName: 'clicks',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'sales',
    headerName: 'sales',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'ctr',
    headerName: 'ctr',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'cost',
    headerName: 'cost',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'netIncome',
    headerName: 'netIncome',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'lostIncome',
    headerName: 'lostIncome',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'earnedIncome',
    headerName: 'earnedIncome',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'potentialIncome',
    headerName: 'potentialIncome',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'impressions',
    headerName: 'impressions',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'interactions',
    headerName: 'interactions',
  },
  {
    field: 'interactionsRate',
    headerName: 'interactionsRate',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'averageCpc',
    headerName: 'averageCpc',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'createdAt',
    headerName: 'createdAt',
    cellRenderer: (params: string) => <DateCell params={params} />,
  },
];

export const columns: TableColumnInterface[] = [
  {
    field: 'name',
    headerName: 'name',
  },
  {
    field: 'clicks',
    headerName: 'clicks',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'formSubmissionsCount',
    headerName: 'formSubmissions',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'ctr',
    headerName: 'ctr',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'cost',
    headerName: 'cost',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'netIncome',
    headerName: 'netIncome',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'lostIncome',
    headerName: 'lostIncome',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'earnedIncome',
    headerName: 'earnedIncome',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'potentialIncome',
    headerName: 'potentialIncome',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'impressions',
    headerName: 'impressions',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'interactions',
    headerName: 'interactions',
  },
  {
    field: 'interactionsRate',
    headerName: 'interactionsRate',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
  {
    field: 'averageCpc',
    headerName: 'averageCpc',
    cellRenderer: (params: number) => <RoundedCell params={params} />,
  },
];

export const topAdsColumns: TableColumnInterface[] = [
  {
    field: 'reportedFormSubmissions',
    headerName: 'fsCountSeo',
    cellRenderer: (params) => (
      <span>
        {
          params
            ? (Math.round(params)?.toLocaleString())
            : (0)
        }
      </span>
    ),
  },
  {
    field: 'sales',
    headerName: 'sales',
    cellRenderer: (params) => (
      <span>
        {
          params
            ? (Math.round(params)?.toLocaleString())
            : (0)
        }
      </span>
    ),
  },
  {
    field: 'cost',
    headerName: 'cost',
    cellRenderer: (params) => (
      <span>
        {
          params
            ? (Math.round(params)?.toLocaleString())
            : (0)
        }
      </span>
    ),
  },
  {
    field: 'reportedNumber',
    headerName: 'reported',
    cellRenderer: (params) => <VatDisplayer value={params} />,
  },
];
export const eCommerceTopAdsColumns: TableColumnInterface[] = [
  {
    field: 'sales',
    headerName: 'sales',
    cellRenderer: (params) => (
      <span>
        {
          params
            ? (Math.round(params)?.toLocaleString())
            : (0)
        }
      </span>
    ),
  },
  {
    field: 'cost',
    headerName: 'cost',
    cellRenderer: (params) => (
      <span>
        {
          params
            ? (Math.round(params)?.toLocaleString())
            : (0)
        }
      </span>
    ),
  },
  {
    field: 'reportedNumber',
    headerName: 'reported',
    cellRenderer: (params) => <VatDisplayer value={params} />,
  },
];

export const topAdsFixedColumns: TableColumnInterface[] = [
  {
    field: 'status',
    headerName: '',
    cellRenderer: (params, rowObject) => <PlayStopAdCell params={params} rowObject={rowObject} />,
  },
  {
    field: 'name',
    headerName: 'adNameAndId',
    cellRenderer: (params, rowObject) => (
      <AdNameCell params={params} rowObject={rowObject} />
    ),
  },
];

export const Colors = {
  primary: '#0C485E',
  red: '#DB3A34',
  yellow: '#FF9F1C',
  green: '#4C956C',
  'gray-1': '#4F4F4F',
  'gray-2': '#828282',
  'gray-3': '#BDBDBD',
  'gray-4': '#E0E0E0',
  'gray-5': '#EAEAEA',
  'gray-6': '#F5F5F5',
  white: '#FFF',
};

export type ColorKey = keyof typeof Colors;

export function formatDateAndTime(date: string) {
  type OptionsType = {
    year: 'numeric' | '2-digit',
    month: 'numeric' | '2-digit' | 'short' | 'long' | 'narrow' | undefined,
    day: 'numeric' | '2-digit',
    hour: 'numeric' | '2-digit',
    minute: 'numeric' | '2-digit',
    hour12: boolean,
    timeZone: string,
  };

  const options: OptionsType = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZone: 'UTC',
  };

  return new Date(date).toLocaleString('en-GB', options);
}

export const AdProviderIDs = {
  GOOGLE: 1,
  BING: 4,
  FACEBOOK: 10,
};

export const truncateText = (text: string, length: number, suffix: string = '...') => {
  if (text.length > length) {
    return text.substring(0, length - 3) + suffix;
  }
  return text;
};

export const formatNumber = (num: number) => {
  if (num >= 1_000_000) {
    return `${(num / 1_000_000).toFixed(1).replace(/\.0$/, '')}m`;
  } if (num >= 10_000) {
    return `${(num / 1_000).toFixed(1).replace(/\.0$/, '')}k`;
  }
  return num.toString();
};

export function formatToTwoDecimalPlaces(value: any) {
  const num = parseFloat(value);
  if (isNaN(num)) {
    return '0';
  }

  if (Number.isInteger(num)) {
    return num.toString();
  }

  return num.toFixed(2);
}

export const embedScript = (region?: string, portalId?: string, formId?: string) => `<script 
    charSet="utf-8" 
    type="text/javascript" 
    src="//js-${region}.hsforms.net/forms/embed/v2.js"
  ></script>
  <script>
    hbspt.forms.create({
      region: "${region}",
      portalId: "${portalId}",
      formId: "${formId}"
    });
  </script>
`;
