import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import leftArrow from '../../assets/icons/leftArrowPagination.svg';
import rightArrow from '../../assets/icons/rightArrowPagination.svg';
import TrackingCodeComponent from '../../components/trackingCodeComponent/trackingCodeComponent';
import BasicDialog from '../../dialogs/basicDialog';
import useGetUntrackedCodes from '../../apiHooks/queries/untrackedCodes/useGetUntrackedCodes';
import { sendTrackedCodes } from '../../apiHooks/mutations/sendTrackedCodes/sendTrackedCodes';
import { GET_UNTRACKED_CODES } from '../../constants/pageIdentifiers';
import LoadingSpinner from '../../components/loadingSpinner/loadingSpinner';
import { CustomButton } from '../../components/buttons/customButton';
import CampaignTrackingForm from '../../components/CampaignTrackingForm/campaignTrackingForm';
import AdGroupForm from '../../components/adGroupTrackingForm/adGroupTrackingForm';
import AdForm from '../../components/adTrackingForm/adTrackingForm';
import TableSkeleton from '../../components/skeletons/tableSkeleton';
import ConfirmDialog from '../../dialogs/confirmDialog';

const TrackingCodePage = () => {
  const { t } = useTranslation();
  const [modal, setModal] = useState<boolean>(false);
  const [campaignModal, setCampaignModal] = useState<boolean>(false);
  const [adGroupModal, setAdGroupModal] = useState<boolean>(false);
  const [adModal, setAdModal] = useState<boolean>(false);
  const [clickedCode, setClickedCode] = useState<string>('');
  const [clickedId, setClickedId] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [selectedCampaignCode, setSelectedCampaignCode] = useState<string>('');
  const [selectedAdGroupId, setSelectedAdGroupId] = useState<string>('');

  const {
    data: trackingCodes,
    refetch: refetchTrackedCodes,
    isFetching,
  } = useGetUntrackedCodes(GET_UNTRACKED_CODES, pageNumber);

  const { mutateAsync: sendCode, isLoading } = sendTrackedCodes(clickedId, {
    onSuccess: () => {
      refetchTrackedCodes();
    },
  });

  const handlePreviousPage = () => {
    setPageNumber((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleNextPage = () => {
    setPageNumber((prevPage) => Math.min(prevPage + 1, trackingCodes?.data?.data?.meta?.last_page || 0));
  };

  useEffect(() => {
    refetchTrackedCodes();
  }, [pageNumber]);

  const sendTrackingCodeRequest = (code: string, type: number) => {
    sendCode({ code, status: type }).then(() => setModal(false));
  };

  const cancelModal = (code: string) => {
    setClickedCode(code);
    setModal(!modal);
  };

  const handleCampaignSubmit = () => {
    setCampaignModal(false);
    setTimeout(() => setAdGroupModal(true), 250);
  };

  const handleAdGroupSubmit = () => {
    setAdGroupModal(false);
    setTimeout(() => setAdModal(true), 250);
  };

  const openCampaignModal = (code: string) => {
    setClickedCode(code);
    setCampaignModal(true);
  };

  const setId = (id : number) => {
    setClickedId(id);
  };

  return (
    <>
      <div className="flex w-full flex-col items-end justify-between rounded-lg border bg-white px-6 py-4">
        <div className="flex w-full justify-center border-b border-primary">
          <div className="flex h-[50px] w-[95%] items-center justify-between  text-sm font-semibold text-primary">
            <p className="flex w-[30%] justify-start">
              {t('trackingCodeCapital')}
            </p>
            <p className="flex w-[30%] justify-start">
              {t('createdAtCapital')}
            </p>
            <p className="flex w-[24%] justify-start">
              {t('countCapital')}
            </p>
            <p className="flex w-[16%] justify-start">
              {t('createAsAnAd')}
            </p>
          </div>
        </div>
        <div className="h-full w-full">
          {trackingCodes?.data?.data?.data ? (
            trackingCodes?.data?.data?.data?.length > 0 ? (
              trackingCodes.data.data.data.map((item: any) => (
                <TrackingCodeComponent
                  key={item.code}
                  code={item.code}
                  id={item.id}
                  createdAt={item.createdAt}
                  count={item.count}
                  setId={() => setId(item.id)}
                  open={() => openCampaignModal(item.code)}
                  cancel={() => cancelModal(item.code)}
                />
              ))
            ) : (
              <div className="flex h-[250px] w-full items-center justify-center text-xl text-primary">
                {t('noTrackingCodesAvailable')}
              </div>
            )
          ) : isFetching
            ? (
              <table className="w-full">
                <tbody>
                  <TableSkeleton colSpan={4} rows={5} />
                </tbody>
              </table>
            )
            : (
              <LoadingSpinner />
            )}
        </div>
        {trackingCodes?.data?.data?.data && trackingCodes?.data?.data?.data?.length > 0 && (
          <div className="flex items-center gap-4 pt-4 text-primary">
            <span>
              {trackingCodes?.data?.data?.meta?.current_page || 0}
              {' '}
              {t('of')}
              {' '}
              {trackingCodes?.data?.data?.meta?.last_page || 0}
            </span>
            <div className="flex">
              <CustomButton
                onClick={handlePreviousPage}
                disabled={pageNumber === 1}
                variant="iconOutlined"
                className="border-none"
              >
                <img
                  className={`${pageNumber === 1 ? 'opacity-50' : ''}`}
                  src={leftArrow}
                  alt="left arrow icon"
                />
              </CustomButton>
              <CustomButton
                onClick={handleNextPage}
                disabled={pageNumber >= (trackingCodes?.data?.data?.meta?.last_page || 0)}
                variant="iconOutlined"
                className="border-none"
              >
                <img
                  className={`${pageNumber >= (trackingCodes?.data?.data?.meta?.last_page || 0) ? 'opacity-50' : ''}`}
                  src={rightArrow}
                  alt="right arrow icon"
                />
              </CustomButton>
            </div>
          </div>
        )}
      </div>
      <ConfirmDialog
        open={modal}
        variant="danger"
        title="areYouSureRemove"
        text="thisActionRemoveTrackingCode"
        isDisabled={isLoading}
        onCloseDialog={() => setModal(false)}
        onSubmit={() => {
          sendTrackingCodeRequest(clickedCode, 2);
        }}
      />
      <BasicDialog trackingModal headerColor="white" open={campaignModal} title="createAsAAd/createAdCreatCampaign">
        <div className="flex flex-col items-center">
          <CustomButton
            type="button"
            onClick={() => {
              setCampaignModal(false); setTimeout(() => setAdGroupModal(true), 250);
            }}
            className="mb-6 mt-2 flex w-full justify-center rounded-lg border bg-green px-5 py-3
            text-center text-sm font-normal text-white hover:cursor-pointer "
          >
            {t('skipThisStepCampaign')}
          </CustomButton>
          <div className="mb-4 flex w-full items-center">
            <div className="w-full border" />
            <p className="mx-4 text-xs text-primary">{t('or')}</p>
            <div className="w-full border" />
          </div>
          <CampaignTrackingForm
            setSelectedCampaignCode={setSelectedCampaignCode}
            onSubmit={handleCampaignSubmit}
            close={() => setCampaignModal(false)}
          />
        </div>
      </BasicDialog>
      <BasicDialog trackingModal headerColor="white" open={adGroupModal} title="createAsAAd/createAdCreateAdGroup">
        <div className="flex flex-col items-center">
          <CustomButton
            type="button"
            onClick={() => {
              setAdGroupModal(false); setTimeout(() => setAdModal(true), 250);
            }}
            className="mb-6 mt-2 flex w-full justify-center rounded-lg border bg-green px-5 py-3 text-center text-sm
            font-normal text-white hover:cursor-pointer "
          >
            {t('skipThisStepAdgroup')}
          </CustomButton>
          <div className="mb-6 flex w-full items-center">
            <div className="w-full border" />
            <p className="mx-4 text-xs text-primary">
              {t('or')}
            </p>
            <div className="w-full border" />
          </div>
          <div className="flex w-full flex-col">
            <AdGroupForm
              onSubmit={handleAdGroupSubmit}
              close={() => setAdGroupModal(false)}
              setSelectedAdGroupId={setSelectedAdGroupId}
              selectedCampaignCode={selectedCampaignCode}
              setSelectedCampaignCode={setSelectedCampaignCode}
            />
          </div>
        </div>
      </BasicDialog>
      <BasicDialog trackingModal headerColor="white" open={adModal} title="createAsAAd/createAdCreateAdGroup">
        <div className="flex flex-col items-center">
          <div className="flex w-full flex-col">
            <AdForm
              onSubmit={() => setAdModal(false)}
              close={() => setAdModal(false)}
              clickedCode={clickedCode}
              sendTrackingCodeRequest={sendTrackingCodeRequest}
              selectedAdGroupId={selectedAdGroupId}
              setSelectedAdGroupId={setSelectedAdGroupId}
            />
          </div>
        </div>
      </BasicDialog>
    </>
  );
};

export default TrackingCodePage;
