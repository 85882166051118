import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import CampaignsTable from '../../../components/tables/campaignsTable';
import InfoboxContainer from '../../../components/infoBox/InfoboxContainer';

const Campaigns = () => {
  const [searchParams] = useSearchParams();
  const [selectedTrackingSourceId, setSelectedTrackingSourceId] = useState<string>(
    searchParams.get('trackingSourceId') || '',
  );

  return (
    <>
      <InfoboxContainer
        groupingType="campaigns"
      />
      <CampaignsTable
        selectedTrackingSourceId={selectedTrackingSourceId}
        setSelectedTrackingSourceId={setSelectedTrackingSourceId}
      />
    </>
  );
};

export default Campaigns;
