import React, { FC } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Collapse } from '@mui/material';
import { Link, NavLink, useLocation } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import { useTranslation } from 'react-i18next';
import CaretLeftPrimary from '../../assets/icons/caret-left-primary.svg';
import CaretDownWhite from '../../assets/icons/caret-down-white.svg';
import LockIconWhite from '../../assets/icons/lock-icon-white.svg';
import { useSidebarStore } from '../../store/sidebarStore';

interface NavBarWithSubMenuProps {
  item: any,
  setOpenedSubMenu: (_label: string) => void,
  handleSubMenuClick: (_label: string) => void,
}

const NavbarWithSubMenu: FC<NavBarWithSubMenuProps> = (props) => {
  const isSidebarOpen = useSidebarStore((state) => state.isSidebarOpen);
  const setIsSidebarOpen = useSidebarStore((state) => state.setIsSidebarOpen);
  const location = useLocation();
  const { t } = useTranslation();
  const isListActive = location.pathname.startsWith(props.item.href);

  return (
    <ListItem
      key={props.item.label}
      disablePadding
      sx={{ display: 'block', color: 'white' }}
    >
      <ListItemButton
        sx={{
          minHeight: 50,
          justifyContent: isSidebarOpen ? 'initial' : 'center',
          pl: isSidebarOpen ? '21px' : '30px',
          pr: 0,
          mt: 1.5,
          transition: 'all 0.2s ease-in-out',
          backgroundColor: isListActive ? 'white !important' : '',
          borderTopLeftRadius: '25px',
          borderBottomLeftRadius: '25px',
          color: isListActive ? '#0c485e' : 'white',
        }}
        onClick={() => {
          if (!isSidebarOpen) {
            setIsSidebarOpen(true);
            props.setOpenedSubMenu(props.item.href);
          } else {
            props.handleSubMenuClick(props.item.href);
          }
        }}
        data-cy={`nav${props.item.href}`}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: isSidebarOpen ? 2 : 'auto',
            justifyContent: 'center',
            color: isListActive ? '#0c485e' : 'white',
          }}
        >
          {isListActive ? (
            <img src={props.item.iconPrimary} alt="" className="h-4 w-4 object-contain" />
          ) : (
            <img src={props.item.iconSecondary} alt="" className="h-4 w-4 object-contain" />
          )}
        </ListItemIcon>
        <p className={`${isSidebarOpen ? '' : 'hidden'} font-medium`}>
          {t(props.item.label)}
        </p>
        <div className="ml-auto">
          {isSidebarOpen && (
            isListActive
              ? (
                <img
                  src={CaretLeftPrimary}
                  alt="Caret Left Icon"
                  className={`duration-200 ${props.item.isOpen ? '' : '-rotate-90'}`}
                />
              )
              : (
                <img
                  src={CaretDownWhite}
                  alt="Caret Down Icon"
                  className={`duration-200 ${props.item.isOpen ? 'rotate-90' : ''}`}
                />
              )
          )}
        </div>
      </ListItemButton>
      <Collapse in={props.item.isOpen} timeout="auto" unmountOnExit>
        {props.item.subList.map((subItem: any) => {
          const isSubListActive = location.pathname.split('/')[2] === subItem.href.split('/')[0];
          return (
            <NavLink
              key={subItem.href}
              onClick={() => {
                if (isSubListActive) {
                  props.handleSubMenuClick(props.item.href);
                  setIsSidebarOpen(false);
                }
              }}
              to={`${!subItem.isLocked ? `${props.item.href}/${subItem.href}` : '/settings/subscription'} `}
              className="flex items-center"
              data-cy={`nav${props.item.href}/${subItem.href}`}
              style={{ marginTop: '0.5rem' }}
            >
              <div
                className={`
                  relative ${isSubListActive ? 'opacity-100' : 'opacity-0'} h-[30px] w-[3px] rounded-r-2xl bg-white
                  `}
              />
              <ListItemButton sx={{ minHeight: 25, pl: '31px' }} className="text-xs">
                <ListItemIcon
                  sx={{ minWidth: 0, mr: 2 }}
                >
                  <img src={subItem.iconWhite} alt="" className="h-4 w-4 object-contain" />
                </ListItemIcon>
                <p>
                  {t(subItem.label)}
                </p>
                <div
                  className={`${!subItem?.isLocked ? 'hidden' : ''}
                  group ml-4 grid h-5 w-5 place-content-center rounded-[4px] border border-white hover:w-[50px]`}
                >
                  <img src={LockIconWhite} alt="lock icon" className="object-contain group-hover:hidden" />
                  <p className="hidden text-[8px] text-white group-hover:block"> Upgrade </p>
                </div>
              </ListItemButton>
            </NavLink>
          );
        })}
      </Collapse>
    </ListItem>
  );
};

export default NavbarWithSubMenu;
