import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import StatusContainer from '../../../../components/containers/statusContainer';
import { HubspotContactSettingsContentInterface } from '../../../../interfaces/hubspot.interface';
import CloseIcon from '../../../../assets/icons/close-icon.svg';
import { CustomButton } from '../../../../components/buttons/customButton';
import TextInput from '../../../../components/inputs/textInput';
import HubspotContactSettingsContentForm from './hubspotContactSettingsContentForm';
import HubspotContactSettingsContentCard from './hubspotContactSettingsContentCard';

interface HubspotContactSettingsContentProps {
  contactSettingsContentData?: HubspotContactSettingsContentInterface[];
  isFetchingContactSettingsContentData: boolean;
  isErrorContactSettingsContentData: boolean;
  refetchContactSettingsContentData: () => void;
}

const HubspotContactSettingsContent: FC<HubspotContactSettingsContentProps> = (props) => {
  const { t } = useTranslation();
  const wonContactSettingsContentData = props.contactSettingsContentData && props.contactSettingsContentData.filter(
    (card) => card.status,
  );
  const lostContactSettingsContentData = props.contactSettingsContentData && props.contactSettingsContentData.filter(
    (card) => !card.status,
  );

  return (
    <div className="mt-4 flex w-full gap-5">
      <StatusContainer
        title="won"
        titleColor="green"
        isFetching={props.isFetchingContactSettingsContentData}
        isError={props.isErrorContactSettingsContentData}
      >
        <div className="flex flex-col gap-4">
          {wonContactSettingsContentData?.map((card) => (
            <HubspotContactSettingsContentCard key={card.id} card={card} refetch={props.refetchContactSettingsContentData} />
          ))}
          <HubspotContactSettingsContentForm status refetch={props.refetchContactSettingsContentData} />
        </div>
      </StatusContainer>
      <StatusContainer
        title="lost"
        titleColor="red"
        isFetching={props.isFetchingContactSettingsContentData}
        isError={props.isErrorContactSettingsContentData}
      >
        <div className="flex flex-col gap-4">
          {lostContactSettingsContentData?.map((card) => (
            <HubspotContactSettingsContentCard key={card.id} card={card} refetch={props.refetchContactSettingsContentData} />
          ))}
          <HubspotContactSettingsContentForm status={false} refetch={props.refetchContactSettingsContentData} />
        </div>
      </StatusContainer>
    </div>
  );
};

export default HubspotContactSettingsContent;
