import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import { CORE_API_SUFFIX, dataFetch } from '../../../axios/customAxios';
import { appendQueryParamIfNotEmpty, formatDate, generateQueryKey } from '../../../constants/hookConstants';
import { ApiResponse } from '../../../interfaces/apiResponse.type';
import { AdTotalsResponse } from '../../../interfaces/responses/adTotals.response';

const useGetAdTotals = (
  identifier: string,
  groupingType: string,
  from: Dayjs | null,
  to: Dayjs | null,
  groupingTypeId?: string,
) => {
  const navigate = useNavigate();

  const queryParams = new URLSearchParams();
  appendQueryParamIfNotEmpty(queryParams, 'from', formatDate(from));
  appendQueryParamIfNotEmpty(queryParams, 'to', formatDate(to));
  appendQueryParamIfNotEmpty(queryParams, 'groupingType', groupingType.trim());
  if (groupingTypeId) {
    appendQueryParamIfNotEmpty(queryParams, 'groupingTypeId', groupingTypeId.trim());
  }

  const queryKey = generateQueryKey(identifier, queryParams);

  return useQuery(
    [`ads_${queryKey}`],
    () => dataFetch.get(`${CORE_API_SUFFIX}ad/total-ad-info?${queryParams}`).then((res: ApiResponse<AdTotalsResponse>) => res.data),
    {
      onSuccess: () => {
        navigate(`${window.location.pathname}?${queryParams.toString()}`, { replace: true });
      },
    },
  );
};

export default useGetAdTotals;
