import { Dayjs } from 'dayjs';
import { CORE_API_SUFFIX, dataFetch, DIGGER_API_SUFFIX } from '../axios/customAxios';
import closableEnqueueSnackbar from '../components/closableEnqueueSnackbar/closableEnqueueSnackbar';

export const appendQueryParamIfNotEmpty = (params: URLSearchParams, key: string, value: string | number) => {
  if (value !== '' && !Number.isNaN(value as number)) {
    params.append(key, value.toString());
  }
};

export const generateQueryKey = (identifier: string, queryParams: URLSearchParams) => {
  const queryKeyParams = [
    identifier,
    ...queryParams.toString().split('&').filter((param) => param !== ''),
  ];

  return queryKeyParams.join('_');
};

export function formatDate(filterDateRange: Dayjs | null) {
  if (!filterDateRange) {
    return '';
  }

  return filterDateRange.format('YYYY-MM-DD');
}

export const downloadFile = async (url: string, acceptHeader: string, t: any) => {
  try {
    const response = await dataFetch.get(CORE_API_SUFFIX + url, {
      headers: {
        Accept: acceptHeader,
      },
      responseType: 'blob',
    });

    const filename = response.headers['content-disposition']?.split('filename=')[1] || 'download';

    const blob = new Blob([response.data], { type: acceptHeader });
    const blobUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = blobUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(blobUrl);
  } catch (error) {
    closableEnqueueSnackbar(t('somethingWentWrong'), 'error');
  }
};

export const downloadDiggerFile = async (url: string, acceptHeader: string, t: any) => {
  try {
    const response = await dataFetch.get(DIGGER_API_SUFFIX + url, {
      headers: {
        Accept: acceptHeader,
      },
      responseType: 'blob',
    });

    const filename = response.headers['content-disposition']?.split('filename=')[1] || 'download';

    const blob = new Blob([response.data], { type: acceptHeader });
    const blobUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = blobUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(blobUrl);
  } catch (error) {
    closableEnqueueSnackbar(t('somethingWentWrong'), 'error');
  }
};
