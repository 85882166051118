import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import TextInput from '../../../components/inputs/textInput';
import { useSendGoogleInvite } from '../../../apiHooks/mutations/integrationMutations/googleMutations';
import { CustomButton } from '../../../components/buttons/customButton';
import LoadingSpinner from '../../../components/loadingSpinner/loadingSpinner';
import useGetGoogleIntegrationStatus from '../../../apiHooks/queries/integrations/google/useGetGoogleIntegrationStatus';
import { useOrganizationSettingsStore } from '../../../store/organizationSettingsStore';
import ProviderNumberBoxes from '../../../components/providerNumberBoxes/providerNumberBoxes';
import useGetAdProviderDetails from '../../../apiHooks/queries/adProviderDetails/useGetAdProviderDetails';
import { GET_PROVIDER_DETAILS } from '../../../constants/pageIdentifiers';
import { AdProviderIDs } from '../../../constants/constants';

export const statusCard = (t: typeof i18next.t, isActive?: boolean, isLoadingStatus?: boolean) => {
  if (isLoadingStatus) {
    return <LoadingSpinner color="primary" size={20} />;
  }

  if (isActive) {
    return (
      <div
        className="flex h-[40px] items-center justify-center rounded-lg border border-green px-5 text-sm leading-[0]
         text-green"
      >
        {t('active')}
      </div>
    );
  }

  return (
    <div
      className="flex h-[40px] items-center justify-center rounded-lg border border-red px-5 text-sm leading-[0]
        text-red"
    >
      {t('notActive')}
    </div>
  );
};

const GoogleTabPanel = () => {
  const organizationSettings = useOrganizationSettingsStore((state) => state.organizationSettings);

  const {
    mutate: sendInvite,
    isLoading: isSendingInvite,
  } = useSendGoogleInvite();

  const {
    data: integrationStatus,
    isLoading: isLoadingIntegrationStatus,
  } = useGetGoogleIntegrationStatus();

  const { data: adProviderDetailsData, isFetching } = useGetAdProviderDetails(
    GET_PROVIDER_DETAILS,
    AdProviderIDs.GOOGLE,
    integrationStatus?.data.data.isGoogleIntegrationActive || false,
  );

  const { t } = useTranslation();
  const [googleId, setGoogleId] = useState<string>(
    organizationSettings?.googleClientId ?? '',
  );

  return (
    <div>
      <div className="flex justify-between gap-5 rounded-xl border p-4">
        <div className="flex gap-5">
          <TextInput
            name="Google Id"
            value={googleId}
            label="Google ID"
            size="small"
            placeholder="123-123-1234"
            onChange={(e) => setGoogleId(e.target.value)}
          />
          <CustomButton
            disabled={googleId === '' || isSendingInvite}
            className="h-[40px] px-[20px] text-sm"
            onClick={() => {
              sendInvite(googleId);
            }}
          >
            {t('linkAccount')}
          </CustomButton>
        </div>
        <div className="flex items-center gap-3">
          <p className="text-xs font-[500] text-primary">
            {t('accountStatus')}
          </p>
          {statusCard(t, integrationStatus?.data.data.isGoogleIntegrationActive, isLoadingIntegrationStatus)}
        </div>
      </div>
      {integrationStatus?.data.data.isGoogleIntegrationActive
      && (
      <div className="mt-4 flex justify-between gap-4 rounded-xl border p-4 ">
        <ProviderNumberBoxes isLoading={isFetching} tracked stats={adProviderDetailsData?.data?.data?.ads_tracked || 0} />
        <ProviderNumberBoxes isLoading={isFetching} revenue stats={adProviderDetailsData?.data?.data?.revenue_tracked || 0} />
        <ProviderNumberBoxes isLoading={isFetching} leads stats={adProviderDetailsData?.data?.data?.leads_attributed || 0} />
      </div>
      )}
    </div>
  );
};

export default GoogleTabPanel;
