import React from 'react';
import { useTranslation } from 'react-i18next';
import WelcomeImage from '../../assets/images/welcome/welcome-big-image.png';
import DigtectiveLogoColorful from '../../assets/images/logos/digtective-logo-colorful.svg';
import WelcomeForm from './welcomeForm';

const Welcome = () => {
  const { t } = useTranslation();

  return (
    <div className="flex h-[100vh] max-h-[1000px] max-w-[1920px] items-center justify-between gap-5 p-5">
      <div className="flex h-full w-[50%] justify-end p-1.5 md:w-[42%]">
        <div className="flex h-full w-full max-w-[408px] flex-col justify-between">
          <div className="h-[20px] max-w-[220px]">
            <img src={DigtectiveLogoColorful} alt="digtective logo" className="mt-4 h-[48px] w-full object-contain" />
          </div>
          <div className="mt-8 flex w-full flex-col leading-[1.5] text-primary">
            <h1 className="text-[24px] font-semibold">
              {t('welcomePageTitle')}
            </h1>
            <p className="mt-2 text-sm">
              {t('welcomePageDescription')}
            </p>
            <WelcomeForm />
          </div>
          <p className="text-center text-sm text-primary">
            {t('allRightsReserveDigtective')}
          </p>
        </div>
      </div>
      <div
        className="hidden h-full w-[50%] max-w-[1040px] place-content-center rounded-[38px] bg-[#f5f5f5] p-5 md:grid"
        style={{ background: '#F5F5F5', boxShadow: 'inset 0px 0px 20px 0px #BDBDBDCC' }}
      >
        <div className="h-full max-h-[750px] w-full max-w-[551px] p-5">
          <img src={WelcomeImage} alt="welcome" className="h-full w-full object-contain" />
        </div>
      </div>
    </div>
  );
};

export default Welcome;
