import React, { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import CopyToClipboard from 'react-copy-to-clipboard';
import RefreshToken from '../../assets/icons/refreshIcon-white.svg';
import CopyIcon from '../../assets/icons/copyIcon.svg';
import useGetLongLastingToken from '../../apiHooks/queries/generateToken/useGetLongLastingToken';
import { LONG_LASTING_TOKEN } from '../../constants/pageIdentifiers';
import LoadingSpinner from '../loadingSpinner/loadingSpinner';
import closableEnqueueSnackbar from '../closableEnqueueSnackbar/closableEnqueueSnackbar';
import { CustomButton } from '../buttons/customButton';

const GenerateToken: FC = () => {
  const { t } = useTranslation();
  const inputRef = useRef(null);

  const {
    data: tokenData,
    refetch: fetchToken,
    isFetching,
  } = useGetLongLastingToken(LONG_LASTING_TOKEN);

  const handleButtonClick = () => {
    closableEnqueueSnackbar(t('tokenCopied'), 'success');
  };

  const tokenValue = tokenData?.data?.data?.authorization?.token || '';
  const isTokenEmpty = tokenValue.trim() === '';

  return (
    <div
      className="flex w-full max-w-5xl items-center justify-between gap-2
       rounded-[8px] border-[1px] bg-white px-[16px] py-[11px]"
    >
      <CopyToClipboard text={tokenData?.data?.data?.authorization?.token || ''}>
        <input
          onClick={() => closableEnqueueSnackbar(t('tokenCopied'), 'success')}
          className="user-select-none
          flex w-full max-w-3xl items-center rounded-[8px] border-[1px]
          bg-white px-[9px] py-[11px] text-sm text-primary"
          type="text"
          value={tokenData?.data?.data?.authorization?.token}
          placeholder="API Token"
          readOnly
          ref={inputRef}
          disabled={isTokenEmpty}
        />
      </CopyToClipboard>
      <CopyToClipboard text={tokenData?.data?.data?.authorization?.token || ''}>
        <CustomButton
          onClick={handleButtonClick}
          disabled={isTokenEmpty}
          variant="iconOutlined"
          className="min-h-[45px] min-w-[45px]"
        >
          <img src={CopyIcon} alt="" />
        </CustomButton>
      </CopyToClipboard>
      <CustomButton
        type="button"
        onClick={() => fetchToken()}
        className="hover:bg-gray flex h-[45px] min-w-[161px]
        items-center justify-between rounded-[8px] border-[1px] bg-[#4C956C] px-[9px] py-[11px]"
      >
        {isFetching ? (
          <div className="ml-[6px] flex  w-full max-w-[10px] items-center justify-center">
            <LoadingSpinner color="#fff" size={10} center />
          </div>
        ) : (
          <img src={RefreshToken} alt="" />
        )}
        <p className="ml-2 flex min-w-[108px] text-sm text-white">{t('generateToken')}</p>
      </CustomButton>
    </div>
  );
};

export default GenerateToken;
